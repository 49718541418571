import React, { useState } from "react";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import termsData from "../../data/terms.json";
import { RightPopup } from "component/basic/popup";
import MemberBox from "component/member/memberBox";
import { BtnBox } from "component/basic/btns";
import { ChkBox } from "component/basic/formItems";
import { Header } from "component/elements/header";
import useGet from "api/useGet";

const Terms = () => {
    const param = useParams();
    const type = param.type;
    const navigate = useNavigate();

    const chkData = termsData?.data;

    const [checkItems, setCheckItems] = useState([]);
    const [checkItemTitles, setCheckItemTitles] = useState([]);
    const [btnChk,setBtnChk] =  useState(true);
    const [termsId,setTermsId] =  useState("");
    const [terms,setTerms] =  useState("");

    const termsList = useGet({
        url:`/term?type=${termsId}`
    });

    // 체크박스 선택
    const handleSingleCheck = (checked, id, text) => {
         if (checked) {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));

            setCheckItemTitles(prev => [...prev, text]);
            setCheckItems(prev => [...prev, id]);
        } else {
            setCheckItemTitles(checkItemTitles.filter((el) => el !== text));
            setCheckItems(checkItems.filter((el) => el !== id));
        }
    };

    // 체크박스 전체 선택
    const handleAllCheck = (checked) => {
        if(checked) {
        const idArr = [],
            textArr = [];
        chkData.forEach((el) => {idArr.push(el.id);textArr.push(el.text)});
            setCheckItemTitles(textArr);
            setCheckItems(idArr);
        }
        else {
            setCheckItemTitles([]);
            setCheckItems([]);
        }
    }

    const rightOpen = (id) => {
        setTerms({
            id:id,
            title:chkData[Number(id)].text,
            text:""
        })
    }

    useEffect(() => {
        if(checkItemTitles.includes("서비스 이용약관")&&checkItemTitles.includes("개인정보처리방침")&&checkItemTitles.includes("개인정보 제3자 제공 동의")){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [checkItems]);

    return (
        <div className="memberPage headerInPage pageSizing btnInPage">
            <Header
                pageBack={true}
                headTitle="약관 동의"
                func={() => navigate(-1)}
            >
                <p className="headerCaption btnHeaderRight"><span>1</span> / 3</p>
            </Header>
            <MemberBox title="약관 동의" caption="<span>1</span> / 3" pcChk={true} backFunc={()=>{navigate(-1)}}>
                <div className="memberInput_section">
                    <ChkBox
                        addClass="allChkItem"
                        func={(e) => handleAllCheck(e.target.checked)}
                        checkedType={checkItems.length === chkData.length ? true : false}
                        chkSubType=""
                        label="전체 동의"

                    />
                    {chkData?.map((data, key) => (
                        <ChkBox
                            addClass=""
                            func={(e) => handleSingleCheck(e.target.checked,data.id,data.text)}
                            checkedType={checkItems.includes(data.id) ? true : false}
                            chkSubType={data.type}
                            label={data.text}
                            key={data.id}
                            chkFunc={data.id == 3 ? null : (e) => {setTermsId(data.id);rightOpen(data.id == "service" ? 0 : data.id == "privacy" ? 1 : 2)}}
                        />
                    ))}
                </div>
                <BtnBox
                    addClass="pageInType mChType"
                    text="다음"
                    disabled={btnChk}
                    func={()=>{navigate(`/member/join/${checkItemTitles.includes("마케팅 정보 알림 동의") ? "1" : "0"}/${type}`)}}
                />
            </MemberBox>
            <RightPopup
                topTitle={terms?.title}
                text={termsList?.data?.term?.value}
                btnType="only"
                popupBtnFunc={()=>handleSingleCheck(true,terms?.id == 0 ? "service" : terms?.id == 1 ? "privacy" : "privacy_public",terms?.title)}
                closePopup={() => {setTerms("");setTermsId("")}}
            />
        </div>
    );
};

export default Terms;