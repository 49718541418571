import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

//middlebanner
export default function MiddleBanner(data){
    const [swiper, setSwiper] = useState(null);

    const middle_bannersPc = data?.data ? data?.data.filter((el) => el.os == 0) : [];
    const middle_bannersMo = data?.data ? data?.data.filter((el) => el.os == 1) : [];

    return (
        <>
        <div className="middleBannerSection pcItem">
        {data?.data && middle_bannersPc.length > 0 ?
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            modules={[Pagination,Navigation]}
            pagination={{
                el: '.middleBanner_pagination',
                type: "fraction",
                renderFraction:function (current, total) {
                    return `<button class="middleBanner_prev"><img src="/assets/images/basic/prev.svg"/></button><span class="${current}"></span><span class="sizing"> / </span><span class="${total}"></span><button class="middleBanner_next"><img src="/assets/images/basic/next.svg"/></button>`;
                },
            }}
            navigation={{
                prevEl: '.middleBanner_prev',
                nextEl: '.middleBanner_next',
            }}
            loop={true}
            onSwiper={(el) => {
                setSwiper(el);
            }}
        >
            {middle_bannersPc.map((bannerItem, index) => (
                <SwiperSlide key={bannerItem.id}>
                    <a target="_blank" href={`${bannerItem.link}`} className="middleBanner" style={{backgroundImage:`url('${bannerItem.image_url}')`}}></a>
                </SwiperSlide>
            ))}
            <div className="middleBanner_pagination"></div>
        </Swiper>
        :""}
        </div>
        <div className="middleBannerSection mItem">
        {data?.data && middle_bannersMo.length > 0 ?
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            modules={[Pagination,Navigation]}
            pagination={{
                el: '.middleBanner_pagination',
                type: "fraction",
                renderFraction:function (current, total) {
                    return `<button class="middleBanner_prev"><img src="/assets/images/basic/prev.svg"/></button><span class="${current}"></span><span class="sizing"> / </span><span class="${total}"></span><button class="middleBanner_next"><img src="/assets/images/basic/next.svg"/></button>`;
                },
            }}
            navigation={{
                prevEl: '.middleBanner_prev',
                nextEl: '.middleBanner_next',
            }}
            loop={true}
            onSwiper={(el) => {
                setSwiper(el);
            }}
        >
            {middle_bannersMo.map((bannerItem, index) => (
                <SwiperSlide key={bannerItem.id}>
                    <a target="_blank" href={`${bannerItem.link}`} className="middleBanner" style={{backgroundImage:`url('${bannerItem.image_url}')`}}></a>
                </SwiperSlide>
            ))}
            <div className="middleBanner_pagination"></div>
        </Swiper>
        :""}
        </div>
        </>
    );
}