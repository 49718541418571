import { PhoneSetting } from "js/function";
import { Fragment, useEffect, useRef, useState } from "react";
import * as fatchSet from "api/api";
import ReactDatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from "react";
import { PopupBtnBox } from "./btns";
import { useCallback } from "react";
import DaumPostcodeEmbed, { useDaumPostcodePopup } from "react-daum-postcode";
import { ErrPopup } from "./popup";

//기본 input
function InputItemBox(data) {
  const [val, setVal] = useState(data.value ? data.value : "");
  const [val2, setVal2] = useState(data.value2 ? data.value2 : "");
  const [count, setCount] = useState(0);
  const [pwCh, setPwCh] = useState(true);

  function valSetting(e) {
    let value = e.target.value;
    if (data.regexp) {
      value = value.replace(data.regexp, "");
    }
    if (data.type == "number") {
      value = value.replace(/[^0-9]/gi, "");
      if (data.numberMax && value > data.numberMax) {
        value = data.numberMax;
      }
    }
    if (
      data.type == "smsChk_phone" ||
      data.type == "smsChk_basic" ||
      data.type == "smsChk" ||
      data.type == "phoneBasic"
    ) {
      value = value.replace(/[^0-9]/gi, "");
      if (data.numberMax && value > data.numberMax) {
        value = data.numberMax;
      }
    }

    setVal(data.max ? value.slice(0, data.max) : value);
    data.func(val);
  }

  useEffect(() => {
    setVal(data.value ? data.value : "");
  }, [data.setChk]);

  return (
    <div
      className={`inputItemBox ${
        (val !== "" || val2 !== "") && (val || val2) ? "active" : ""
      }`}
    >
      {data.inputName && data.inputName !== "" ? (
        <h2 className="inputName">{data.inputName}</h2>
      ) : (
        ""
      )}
      {data.type == "text" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          }`}
        >
          <input
            type="text"
            value={val}
            max={data.max && data.max !== "" ? data.max : "null"}
            className="inputItem"
            onInput={(e) => {
              valSetting(e);
            }}
            onPaste={(e) => {
              valSetting(e);
            }}
            onChange={(e) => {
              valSetting(e);
            }}
            onKeyUp={(e) => {
              valSetting(e);
            }}
            placeholder={
              data.placeholder && data.placeholder !== ""
                ? data.placeholder
                : ""
            }
            readOnly={data.readOnly ? data.readOnly : false}
            disabled={data.disabled ? data.disabled : false}
          />
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "phoneBasic" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          }`}
        >
          <input
            type="text"
            value={val}
            max={data.max && data.max !== "" ? data.max : "null"}
            onInput={(e) => {
              valSetting(e);
            }}
            onPaste={(e) => {
              valSetting(e);
            }}
            onChange={(e) => {
              valSetting(e);
            }}
            onKeyUp={(e) => {
              valSetting(e);
            }}
            inputMode="numeric"
            pattern="[0-9]*"
            className="inputItem"
            placeholder={
              data.placeholder && data.placeholder !== ""
                ? data.placeholder
                : ""
            }
            readOnly={data.readOnly ? data.readOnly : false}
            disabled={data.disabled ? data.disabled : false}
          />
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "phone" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          }`}
        >
          <input
            type="text"
            value={val}
            max={data.max && data.max !== "" ? data.max : "null"}
            onInput={(e) => {
              setVal(PhoneSetting(e.target.value).slice(0, 13));
              data.func(val);
            }}
            onPaste={(e) => {
              setVal(PhoneSetting(e.target.value).slice(0, 13));
              data.func(val);
            }}
            onChange={(e) => {
              setVal(PhoneSetting(e.target.value).slice(0, 13));
              data.func(val);
            }}
            onKeyUp={(e) => {
              setVal(PhoneSetting(e.target.value).slice(0, 13));
              data.func(val);
            }}
            inputMode="numeric"
            pattern="[0-9]*"
            className="inputItem"
            placeholder={
              data.placeholder && data.placeholder !== ""
                ? data.placeholder
                : ""
            }
            readOnly={data.readOnly ? data.readOnly : false}
            disabled={data.disabled ? data.disabled : false}
          />
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "smsChk_phone" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          } ${data.errMsg && data.errMsg !== "" ? "err" : ""}`}
        >
          <div className="btnCom">
            <div className="certificationInput">
              <input
                type="text"
                value={val}
                max={data.max && data.max !== "" ? data.max : "null"}
                onInput={(e) => {
                  valSetting(e);
                }}
                onPaste={(e) => {
                  valSetting(e);
                }}
                onChange={(e) => {
                  valSetting(e);
                }}
                onKeyUp={(e) => {
                  valSetting(e);
                }}
                inputMode="numeric"
                pattern="[0-9]*"
                className="inputItem"
                placeholder={
                  data.placeholder && data.placeholder !== ""
                    ? data.placeholder
                    : ""
                }
                readOnly={data.readOnly ? data.readOnly : false}
                disabled={data.disabled ? data.disabled : false}
              />
            </div>
            <button
              type="button"
              className="btn_inputSubBtn btn_line btn_re"
              disabled={val !== "" && val ? false : true}
              onClick={(e) => data.clickEv(e)}
            >
              {data.certifyType ? "재발송" : "인증번호 발송"}
            </button>
          </div>
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "smsChk_basic" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          } ${data.errMsg && data.errMsg !== "" ? "err" : ""}`}
        >
          <input
            type="text"
            value={val}
            max={data.max && data.max !== "" ? data.max : "null"}
            onInput={(e) => {
              valSetting(e);
            }}
            onPaste={(e) => {
              valSetting(e);
            }}
            onChange={(e) => {
              valSetting(e);
            }}
            onKeyUp={(e) => {
              valSetting(e);
            }}
            inputMode="numeric"
            pattern="[0-9]*"
            className="inputItem certificationInputItem"
            placeholder={
              data.placeholder && data.placeholder !== ""
                ? data.placeholder
                : ""
            }
            readOnly={data.readOnly ? data.readOnly : false}
            disabled={data.disabled ? data.disabled : false}
          />
          <p className="certificationCount">{data.timer}</p>
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "smsChk" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          } ${data.errMsg && data.errMsg !== "" ? "err" : ""}`}
        >
          <div className="btnCom">
            <div className="certificationInput">
              <input
                type="text"
                value={val}
                max={data.max && data.max !== "" ? data.max : "null"}
                onInput={(e) => {
                  valSetting(e);
                }}
                onPaste={(e) => {
                  valSetting(e);
                }}
                onChange={(e) => {
                  valSetting(e);
                }}
                onKeyUp={(e) => {
                  valSetting(e);
                }}
                inputMode="numeric"
                pattern="[0-9]*"
                className="inputItem certificationInputItem"
                placeholder={
                  data.placeholder && data.placeholder !== ""
                    ? data.placeholder
                    : ""
                }
                readOnly={data.readOnly ? data.readOnly : false}
                disabled={data.disabled ? data.disabled : false}
              />
              <p className="certificationCount">{data.timer}</p>
            </div>
            <button
              type="button"
              className="btn_inputSubBtn btn_line btn_re"
              onClick={(e) => data.clickEv(e)}
            >
              재발송
            </button>
          </div>
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "registration" ? (
        <div
          className={`inputBox registrationNumberInput ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          }`}
        >
          <div className="registrationNumberBox">
            <input
              type="number"
              value={val}
              max={data.max && data.max !== "" ? data.max : "null"}
              onInput={(e) => {
                setVal(e.target.value.slice(0, 6));
                data.func(val);
              }}
              onPaste={(e) => {
                setVal(e.target.value.slice(0, 6));
                data.func(val);
              }}
              onChange={(e) => {
                setVal(e.target.value.slice(0, 6));
                data.func(val);
              }}
              onKeyUp={(e) => {
                setVal(e.target.value.slice(0, 6));
                data.func(val);
              }}
              inputMode="numeric"
              pattern="[0-9]*"
              className="inputItem"
              placeholder={
                data.placeholder && data.placeholder !== ""
                  ? data.placeholder
                  : ""
              }
              readOnly={data.readOnly ? data.readOnly : false}
              disabled={data.disabled ? data.disabled : false}
            />
            <span>-</span>
            <div className="registrationNumberBackBox">
              <input
                type="number"
                value={val2}
                max={data.max && data.max !== "" ? data.max : "null"}
                onInput={(e) => {
                  setVal2(e.target.value.slice(0, 1));
                  data.func2(val2);
                }}
                onPaste={(e) => {
                  setVal2(e.target.value.slice(0, 1));
                  data.func2(val2);
                }}
                onChange={(e) => {
                  setVal2(e.target.value.slice(0, 1));
                  data.func2(val2);
                }}
                onKeyUp={(e) => {
                  setVal2(e.target.value.slice(0, 1));
                  data.func2(val2);
                }}
                inputMode="numeric"
                pattern="[0-9]*"
                className="inputItem"
                placeholder={
                  data.placeholder2 && data.placeholder2 !== ""
                    ? data.placeholder2
                    : ""
                }
                readOnly={data.readOnly ? data.readOnly : false}
                disabled={data.disabled ? data.disabled : false}
              />
              <p className="registrationNumberBack_text">******</p>
            </div>
          </div>
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "selItem" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          }`}
        >
          <input
            type="text"
            value={data.value}
            className="inputItem customSel"
            onClick={(e) => data.clickEv(e)}
            onInput={(e) => {
              data.func(e.target.value);
            }}
            onPaste={(e) => {
              data.func(e.target.value);
            }}
            onChange={(e) => {
              data.func(e.target.value);
            }}
            onKeyUp={(e) => {
              data.func(e.target.value);
            }}
            placeholder={
              data.placeholder && data.placeholder !== ""
                ? data.placeholder
                : ""
            }
            readOnly={data.readOnly ? data.readOnly : false}
            disabled={data.disabled ? data.disabled : false}
          />
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "number" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          }`}
        >
          <input
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            value={val}
            max={data.max && data.max !== "" ? data.max : "null"}
            className="inputItem"
            onInput={(e) => {
              valSetting(e);
            }}
            onPaste={(e) => {
              valSetting(e);
            }}
            onChange={(e) => {
              valSetting(e);
            }}
            onKeyUp={(e) => {
              valSetting(e);
            }}
            placeholder={
              data.placeholder && data.placeholder !== ""
                ? data.placeholder
                : ""
            }
            readOnly={data.readOnly ? data.readOnly : false}
            disabled={data.disabled ? data.disabled : false}
          />
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "pw" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          }`}
        >
          <div className="pwBox">
            <input
              type={pwCh ? "password" : "text"}
              value={val}
              max={data.max && data.max !== "" ? data.max : "null"}
              className="inputItem"
              onInput={(e) => {
                valSetting(e);
              }}
              onPaste={(e) => {
                valSetting(e);
              }}
              onChange={(e) => {
                valSetting(e);
              }}
              onKeyUp={(e) => {
                valSetting(e);
              }}
              placeholder={
                data.placeholder && data.placeholder !== ""
                  ? data.placeholder
                  : ""
              }
              readOnly={data.readOnly ? data.readOnly : false}
              disabled={data.disabled ? data.disabled : false}
            />
            {data.pwChType ? (
              <button
                type="button"
                className={`btn_pwCh ${pwCh ? "" : "active"}`}
                onClick={() => setPwCh(!pwCh)}
              >
                {pwCh ? (
                  <img src="/assets/images/basic/pw_on.svg" />
                ) : (
                  <img src="/assets/images/basic/pw_off.svg" />
                )}
              </button>
            ) : (
              ""
            )}
          </div>
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "btnCom" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          }`}
        >
          <div className="btnCom">
            <div className="btnComInputBox">
              <input
                type="text"
                value={val}
                max={data.max && data.max !== "" ? data.max : "null"}
                onClick={(e) => {
                  data.inputClick(e);
                }}
                onInput={(e) => {
                  valSetting(e);
                }}
                onPaste={(e) => {
                  valSetting(e);
                }}
                onChange={(e) => {
                  valSetting(e);
                }}
                onKeyUp={(e) => {
                  valSetting(e);
                }}
                className="inputItem"
                placeholder={
                  data.placeholder && data.placeholder !== ""
                    ? data.placeholder
                    : ""
                }
                readOnly={data.readOnly ? data.readOnly : false}
                disabled={data.disabled ? data.disabled : false}
              />
            </div>
            <button
              type="button"
              className="btn_inputSubBtn btn_line btn_re"
              onClick={(e) => data.clickEv(e)}
            >
              {data.btnName}
            </button>
          </div>
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : data.type == "count" ? (
        <div
          className={`inputBox ${
            data.addClass && data.addClass !== "" ? data.addClass : ""
          }`}
        >
          <div className="textAreaBox maxChk">
            <input
              type="text"
              value={val}
              max={data.max && data.max !== "" ? data.max : "null"}
              className="inputItem"
              onInput={(e) => {
                valSetting(e);
                setCount(e.target.value.length);
              }}
              onPaste={(e) => {
                valSetting(e);
                setCount(e.target.value.length);
              }}
              onChange={(e) => {
                valSetting(e);
                setCount(e.target.value.length);
              }}
              onKeyUp={(e) => {
                valSetting(e);
                setCount(e.target.value.length);
              }}
              placeholder={
                data.placeholder && data.placeholder !== ""
                  ? data.placeholder
                  : ""
              }
              readOnly={data.readOnly ? data.readOnly : false}
              disabled={data.disabled ? data.disabled : false}
            />
            <p className="textareaCount">
              <span>{count >= data.max ? data.max : count}</span> / {data.max}
              {data.unit ? data.unit : ""}
            </p>
          </div>
          {data.errMsg && data.errMsg !== "" ? (
            <p className="errMsg">{data.errMsg}</p>
          ) : (
            ""
          )}
          {data.caption && data.caption !== "" ? (
            <p
              className="inputCaption"
              dangerouslySetInnerHTML={{ __html: data.caption }}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

//customSelect
function CustomSelect(data) {
  const [val, setVal] = useState(data.value ? data.value : "");
  const [valText, setValText] = useState("");
  const [optionOpen, setOptionOpen] = useState(false);
  const box = useRef(null);
  const optionBox = useRef(null);

  const closeClick = useCallback(
    (e) => {
      let inside = box.current ? box.current.contains(e.target) : true;
      if (!inside) {
        setOptionOpen(false);
        window.removeEventListener("click", closeClick);
      }
    },
    [optionOpen, box]
  );

  const onChangeEvn = (name, val) => {
    setVal(name);

    data.func(val);
    setValText(name);
    setOptionOpen(false);
    window.removeEventListener("click", closeClick);
  };

  const onClickEvn = () => {
    setOptionOpen(true);
    setTimeout(() => {
      window.addEventListener("click", closeClick);
    }, 10);
  };

  useEffect(() => {
    setVal(data.value ? data.value : "");
    if (!data.value) {
      setValText("");
    }
  }, [data.setChk]);

  useEffect(() => {
    setVal(data.value ? data.value : "");
    if (!data.value) {
      setValText("");
    }
  }, [data.value]);

  useEffect(() => {
    optionBox.current.scrollTop = 0;
  }, [optionOpen]);

  return (
    <div
      className={`inputItemBox ${val !== "" && val ? "active" : ""}`}
      ref={box}
    >
      {data.inputName && data.inputName !== "" ? (
        <h2 className="inputName">{data.inputName}</h2>
      ) : (
        ""
      )}
      <div
        className={`inputBox customSelectInputBox ${
          data.addClass && data.addClass !== "" ? data.addClass : ""
        }`}
      >
        <input
          type="text"
          value={data.setValueText ? (val ? val : "") : valText ? valText : ""}
          className="inputItem"
          onClick={onClickEvn}
          placeholder={
            data.placeholder && data.placeholder !== "" ? data.placeholder : ""
          }
          readOnly={true}
          disabled={data.disabled ? data.disabled : false}
        />
        {data.errMsg && data.errMsg !== "" ? (
          <p className="errMsg">{data.errMsg}</p>
        ) : (
          ""
        )}
        {data.caption && data.caption !== "" ? (
          <p
            className="inputCaption"
            dangerouslySetInnerHTML={{ __html: data.caption }}
          />
        ) : (
          ""
        )}
      </div>
      <div
        className={`customSelect_optionBox ${optionOpen ? "active" : ""}`}
        ref={optionBox}
      >
        {data.options &&
          data.options?.map((item, i) => (
            <button
              type="button"
              key={i}
              className={`customSelect_option ${
                val == item[data.nameKey] ? "active" : ""
              }`}
              onClick={() => {
                onChangeEvn(item[data.nameKey], item[data.valKey]);
              }}
            >
              {item[data.nameKey]}
            </button>
          ))}
      </div>
    </div>
  );
}

//textarea
function TextAreaItem(data) {
  const [val, setVal] = useState(data.value ? data.value : "");
  const [count, setCount] = useState(0);

  function valSetting(e) {
    setVal(data.max ? e.target.value.slice(0, data.max) : e.target.value);
    setCount(
      e.target.value.length >= data.max ? data.max : e.target.value.length
    );
    data.func(val);
  }

  useEffect(() => {
    if (!data.value) {
      setVal("");
      setCount(0);
    }
  }, [data.setChk]);

  useEffect(() => {
    if (!data.value) {
      setVal("");
      setCount(0);
    }
  }, [data.value]);

  return (
    <div className="inputItemBox">
      {data.inputName && data.inputName !== "" ? (
        <h2 className="inputName">{data.inputName}</h2>
      ) : (
        ""
      )}
      <div
        className={`inputBox ${
          data.addClass && data.addClass !== "" ? data.addClass : ""
        }`}
      >
        <div className={`textAreaBox ${data.maxChk ? "maxChk" : ""}`}>
          <textarea
            value={val}
            className="textareaItem"
            max={data.max && data.max !== "" ? data.max : "null"}
            onInput={(e) => {
              valSetting(e);
            }}
            onPaste={(e) => {
              valSetting(e);
            }}
            onChange={(e) => {
              valSetting(e);
            }}
            onKeyUp={(e) => {
              valSetting(e);
            }}
            onKeyDown={(e) => {
              valSetting(e);
            }}
            placeholder={
              data.placeholder && data.placeholder !== ""
                ? data.placeholder
                : ""
            }
            readOnly={data.readOnly ? data.readOnly : false}
            disabled={data.disabled ? data.disabled : false}
          ></textarea>
          {data.maxChk ? (
            <p className="textareaCount">
              <span>{count >= data.max ? data.max : count}</span> / {data.max}
              {data.unit ? data.unit : ""}
            </p>
          ) : (
            ""
          )}
        </div>
        {data.errMsg && data.errMsg !== "" ? (
          <p className="errMsg">{data.errMsg}</p>
        ) : (
          ""
        )}
        {data.caption && data.caption !== "" ? (
          <p
            className="inputCaption"
            dangerouslySetInnerHTML={{ __html: data.caption }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

//주소 input
function AddrInputItem(data) {
  const [val, setVal] = useState(data.value ? data.value : "");
  const open = useDaumPostcodePopup();

  function valSetting(e) {
    let value = e.target.value;
    if (data.regexp) {
      value = value.replace(data.regexp, "");
    }
    setVal(data.max ? value.slice(0, data.max) : value);
  }

  const handleComplete = (addrItem) => {
    setVal(addrItem.address);
    data.func(
      addrItem.address,
      addrItem.sido == "서울"
        ? "서울특별시"
        : addrItem.sido == "부산"
        ? "부산광역시"
        : addrItem.sido,
      addrItem.sigungu,
      addrItem.bname
    );
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  useEffect(() => {
    setVal(data.value ? data.value : "");
  }, [data.setChk]);

  return (
    <div className={`inputItemBox ${val !== "" && val ? "active" : ""}`}>
      {data.inputName && data.inputName !== "" ? (
        <h2 className="inputName">{data.inputName}</h2>
      ) : (
        ""
      )}
      <div
        className={`inputBox addrInputBox ${
          data.addClass && data.addClass !== "" ? data.addClass : ""
        }`}
      >
        <input
          type="text"
          value={val}
          className="inputItem"
          onClick={handleClick}
          onInput={(e) => {
            valSetting(e);
          }}
          onPaste={(e) => {
            valSetting(e);
          }}
          onChange={(e) => {
            valSetting(e);
          }}
          onKeyUp={(e) => {
            valSetting(e);
          }}
          placeholder={
            data.placeholder && data.placeholder !== "" ? data.placeholder : ""
          }
          readOnly={true}
          disabled={data.disabled ? data.disabled : false}
        />
        {data.errMsg && data.errMsg !== "" ? (
          <p className="errMsg">{data.errMsg}</p>
        ) : (
          ""
        )}
        {data.caption && data.caption !== "" ? (
          <p
            className="inputCaption"
            dangerouslySetInnerHTML={{ __html: data.caption }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

//파일첨부(api호출형)
function FileItem(data) {
  const [val, setVal] = useState(data.value ? data.value : "");
  const [err, setErr] = useState(data.errMsg ? data.errMsg : "");
  const fileInput = useRef(null);

  function changeFile(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        let formData = new FormData();
        formData.append("card_image", input.files[0]);

        fatchSet.FatchApi({
          type: "POST",
          formDataItem: formData,
          url: "/store/menu/gift/image",
          loginType: "login",
          success: (data) => {
            setErr("");
            setVal(data.data.card_image_url);
            e.target.value = "";
          },
          err: (data) => {
            setErr(data.alert);
          },
        });
      };
      reader.readAsDataURL(input.files[0]);

      return input.files[0];
    }
  }

  function delImage() {
    setErr("");
    setVal("");
    fileInput.current.value = "";
  }

  useEffect(() => {
    data.func(val);
    setErr("");
  }, [val]);

  return (
    <div className="inputItemBox">
      {data.inputName && data.inputName !== "" ? (
        <h2 className="inputName">{data.inputName}</h2>
      ) : (
        ""
      )}
      <div
        className={`inputBox ${
          data.addClass && data.addClass !== "" ? data.addClass : ""
        }`}
      >
        <div className="inputFileBox">
          <input
            type="file"
            onChange={(e) => changeFile(e.target)}
            accept="image/*"
            ref={fileInput}
            id={data.id}
            style={{ display: "none" }}
          />
          <label htmlFor={data.id} style={{ backgroundImage: `url('${val}')` }}>
            <button
              type="button"
              className="delImage"
              onClick={() => delImage()}
              style={{ display: `${val == "" ? "none" : "block"}` }}
            >
              <img src="/assets/images/basic/del.svg" />
            </button>
            <div
              className="inputFile_label_basic"
              style={{ display: `${val == "" ? "flex" : "none"}` }}
            >
              <img src="/assets/images/basic/upload.svg" />
              <p className="inputFile_label_text">이미지를 업로드 해주세요</p>
            </div>
          </label>
        </div>
        {err && err !== "" ? <p className="errMsg">{err}</p> : ""}
      </div>
    </div>
  );
}

//파일첨부(다중)
function FileItemMultipleItem(data) {
  const [img, setImg] = useState("");
  const [setItem, setSetItem] = useState(false);

  let reader = new FileReader();
  reader.onload = function (e) {
    setImg(e.target.result && e.target.result);
  };
  reader.readAsDataURL(data.data);

  useEffect(() => {
    if (img !== "") setSetItem(true);
  }, [img]);

  return (
    <>
      {setItem ? (
        <div
          className="fileItemMultipleItem"
          style={{ backgroundImage: `url('${img}')` }}
        >
          <button
            type="button"
            className="fileItemMultipleItemDel"
            onClick={() => data.delFunc()}
          >
            <img src="/assets/images/basic/close_w.svg" />
          </button>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

function FileItemMultiple(data) {
  const [err, setErr] = useState(data.errMsg ? data.errMsg : "");

  const [inputFiles, setInputFiles] = useState([]);
  const [resetInput, setResetInput] = useState(0);

  const fileInput = useRef(null);

  let mousedownChk = false,
    startPoint,
    scrollSize;

  let addfiles = [];
  let setAddFiles = [];

  function changeFile(input) {
    setAddFiles = [];
    if (data.max) {
      let setInputArr = [...inputFiles, ...input.files];
      let setInputArrSplice = setInputArr.splice(0, data.max);
      setInputFiles([...setInputArrSplice]);
    } else {
      setInputFiles([...inputFiles, ...input.files]);
    }

    setErr("");
  }

  function inputReset() {
    fileInput.current.value = "";
  }

  function addFileFunc(item) {
    setAddFiles.push(item);
  }

  function addFileSetting() {
    addfiles = [...addfiles, setAddFiles];
  }

  useEffect(() => {
    data.func(...addfiles);
  }, [addfiles]);

  useEffect(() => {
    let reSetData = [];
    setInputFiles(reSetData);
    setResetInput(0);
  }, [data.dataReset]);

  useEffect(() => {
    let slideItem = document.querySelectorAll(".inputFileList")[0];
    slideItem.addEventListener("mousedown", (e) => {
      mousedownChk = true;
      slideItem.classList.add("active");

      startPoint = e.pageX - slideItem.offsetLeft;
      scrollSize = slideItem.scrollLeft;
    });
    slideItem.addEventListener("mouseleave", () => {
      mousedownChk = false;
      slideItem.classList.remove("active");
    });
    slideItem.addEventListener("mouseup", () => {
      mousedownChk = false;
      slideItem.classList.remove("active");
    });
    slideItem.addEventListener("mousemove", (e) => {
      if (!mousedownChk) return;

      e.preventDefault();
      let x = e.pageX - slideItem.offsetLeft,
        gapSize = (x - startPoint) * 1;
      slideItem.scrollLeft = scrollSize - gapSize;
    });

    return () => {
      let slideItem = document.querySelectorAll(".inputFileList")[0];
      if (slideItem) {
        slideItem.removeEventListener("mousedown", (e) => {
          mousedownChk = true;
          slideItem.classList.add("active");

          startPoint = e.pageX - slideItem.offsetLeft;
          scrollSize = slideItem.scrollLeft;
        });
        slideItem.removeEventListener("mouseleave", () => {
          mousedownChk = false;
          slideItem.classList.remove("active");
        });
        slideItem.removeEventListener("mouseup", () => {
          mousedownChk = false;
          slideItem.classList.remove("active");
        });
        slideItem.removeEventListener("mousemove", (e) => {
          if (!mousedownChk) return;

          e.preventDefault();
          let x = e.pageX - slideItem.offsetLeft,
            gapSize = (x - startPoint) * 1;
          slideItem.scrollLeft = scrollSize - gapSize;
        });
      }
    };
  }, []);

  return (
    <div className="inputItemBox">
      {data.inputName && data.inputName !== "" ? (
        <h2 className="inputName">{data.inputName}</h2>
      ) : (
        ""
      )}
      <div
        className={`inputBox ${
          data.addClass && data.addClass !== "" ? data.addClass : ""
        }`}
      >
        <div className="inputFileBox">
          <input
            type="file"
            multiple
            onChange={(e) => changeFile(e.target)}
            accept="image/*"
            ref={fileInput}
            id={data.id}
            style={{ display: "none" }}
          />
          <div className="inputFileList">
            {inputFiles.length > 0 &&
              inputFiles.map((item, i) => (
                <Fragment key={i}>
                  {item.type.split("/")[0] == "image" ? (
                    (
                      data.maxSise
                        ? item.size <= Number(data.maxSise) * 1024 * 1024
                        : true
                    ) ? (
                      (
                        data.max
                          ? addfiles.length + setAddFiles.length < data.max
                          : true
                      ) ? (
                        <>
                          {addFileFunc(item)}
                          <FileItemMultipleItem
                            data={item}
                            delFunc={() => {
                              setErr("");

                              addfiles[0].splice(i, 1);
                              let inputFileArr = [...inputFiles];
                              inputFileArr.splice(i, 1);
                              setInputFiles(inputFileArr);
                              data.func(...addfiles);
                              setResetInput(resetInput + 1);
                            }}
                          />
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      setErr("지원하는 파일 확장자가 아닙니다.")
                    )
                  ) : (
                    setErr(`${data.maxSise} 이하의 이미지를 첨부해주세요.`)
                  )}
                  {inputFiles.length - 1 == i && inputReset()}
                  {inputFiles.length - 1 == i && addFileSetting()}
                </Fragment>
              ))}
            {data.max && inputFiles.length >= data.max ? (
              ""
            ) : (
              <label htmlFor={data.id}>
                <img src="/assets/images/basic/file_add.svg" />
              </label>
            )}
          </div>
        </div>
        {err && err !== "" ? <p className="errMsg">{err}</p> : ""}
      </div>
    </div>
  );
}

//체크박스
function ChkBox(data) {
  let id = `chk_item_${Math.floor(Math.random() * 1000)}`;
  return (
    <div
      className={`chk_item ${
        data.addClass && data.addClass !== "" ? data.addClass : ""
      }`}
    >
      <input
        type="checkbox"
        value={data.val ? data.val : ""}
        onChange={(e) => data.func(e)}
        className="chk_itemChk"
        id={id}
        checked={data.checkedType ? data.checkedType : null}
        disabled={data.disabled ? data.disabled : false}
      />
      <label htmlFor={id}>
        {data.children ? (
          data.children
        ) : (
          <p
            className="chk_item_text"
            dangerouslySetInnerHTML={{
              __html: `${data.label} ${
                data.chkSubType ? `<span>(${data.chkSubType})</span>` : ""
              }`,
            }}
          ></p>
        )}
      </label>
      {data.chkFunc ? (
        <p className="chkBoxBtn" onClick={(e) => data.chkFunc(e)}>
          보기
        </p>
      ) : (
        ""
      )}
      {data.chkTextFunc ? (
        <div
          className="chkBox_moveText"
          onClick={(e) => data.chkTextFunc(e)}
          dangerouslySetInnerHTML={{ __html: data.chkText }}
        />
      ) : (
        ""
      )}
    </div>
  );
}

//라디오박스
function RadioBox(data) {
  let id = `${data.id}_${Math.floor(Math.random() * 1000)}`;
  return (
    <div
      className={`radio_item ${
        data.addClass && data.addClass !== "" ? data.addClass : ""
      }`}
    >
      <input
        type="radio"
        name={data.id}
        value={data.val}
        onChange={(e) => data.func(data.val)}
        className="radio_itemChk"
        id={id}
        checked={data.checkedType}
      />
      <label htmlFor={id}>
        {data.colorType == "bgFullChk" ? (
          <img src="/assets/images/basic/chk_icon_w.svg" />
        ) : (
          <img src="/assets/images/basic/chk_icon.svg" />
        )}
        <p
          className="radio_item_text"
          dangerouslySetInnerHTML={{ __html: `${data.label}` }}
        ></p>
      </label>
    </div>
  );
}

function TimeSelect(data) {
  const [val, setVal] = useState(
    data.defaultValue ? data.defaultValue : data.options[0].val
  );

  useEffect(() => {
    setVal(data.defaultValue ? data.defaultValue : data.options[0].val);
  }, [data.chChk]);

  return (
    <div className="timeSelectBox">
      <select
        className="timeSelectItem"
        value={val}
        onChange={(e) => {
          data.func(e.target.value);
        }}
      >
        {data.options.map((item, i) => (
          <option key={i} value={item.val}>
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
}

function TimeSelectPopup(data) {
  const [hTime, setHTime] = useState(
    data.start ? data.start : data.min ? data.min : "06"
  );
  const [mTime, setMTime] = useState(data.end ? data.end : "00");
  const [chChk, setChChk] = useState(true);

  const optionItem = [];
  for (
    let i = Number(data.min ? data.min : "06");
    i <= Number(data.max ? data.max : "22");
    i++
  ) {
    let pushItem = i < 10 ? `0${i}` : i;
    optionItem.push({ val: pushItem, name: `${pushItem}시` });
  }

  useEffect(() => {
    setHTime(data.start ? data.start : data.min ? data.min : "06");
    setMTime(data.end ? data.end : "00");
  }, [data.start, data.end]);

  return (
    <div className="timeSelectPopup">
      <div className="timeSelect_header">
        <h1 className="timeSelect_title">{data.date}</h1>
      </div>
      <div className="timeSelectArea">
        <TimeSelect
          value={hTime}
          options={optionItem}
          chChk={chChk}
          defaultValue={hTime}
          func={(time) => {
            if (mTime !== "00" && time == (data.max ? data.max : "22")) {
              data.errFunc();
              setHTime("13");
              setMTime("30");
              setChChk(!chChk);
            } else {
              setHTime(time);
              setChChk(!chChk);
            }
          }}
        />
        <TimeSelect
          value={mTime}
          options={[
            { val: "00", name: "00분" },
            { val: "10", name: "10분" },
            { val: "20", name: "20분" },
            { val: "30", name: "30분" },
            { val: "40", name: "40분" },
            { val: "50", name: "50분" },
          ]}
          defaultValue={mTime}
          chChk={chChk}
          func={(time) => {
            if (hTime == (data.max ? data.max : "22") && time !== "00") {
              data.errFunc();
              setHTime("13");
              setMTime("30");
              setChChk(!chChk);
            } else {
              setMTime(time);
              setChChk(!chChk);
            }
          }}
        />
      </div>
      <p className="timeSelectCaption">
        {data.min ? data.min : "06"}:00 부터 {data.max ? data.max : "22"}:00까지
        선택가능합니다.
      </p>
      <PopupBtnBox
        text="선택 완료"
        btnAddClass="line"
        func={() => {
          data.func(hTime, mTime);
        }}
      />
    </div>
  );
}

function TimePicer(data) {
  const [val, setVal] = useState(data.value ? data.value : "");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [openType, setOpenType] = useState(false);
  const box = useRef(null);
  const [errPopupData, setErrPopupData] = useState(null);

  const closeClick = useCallback(
    (e) => {
      let inside = box.current ? box.current.contains(e.target) : true;
      if (!inside) {
        setOpenType(false);
        window.removeEventListener("click", closeClick);
      }
    },
    [openType, box]
  );

  const onChangeEvn = (start, end) => {
    setStartTime(start);
    setEndTime(end);
    data.func(start, end);
    setOpenType(false);
    window.removeEventListener("click", closeClick);
  };

  const onClickEvn = () => {
    setOpenType(true);
    setTimeout(() => {
      window.addEventListener("click", closeClick);
    }, 10);
  };

  useEffect(() => {
    if (startTime && endTime) setVal(`${startTime}:${endTime}`);
  }, [startTime, endTime]);

  useEffect(() => {
    if (data.value) {
      let times = data.value.split(":");
      setStartTime(times[0]);
      setEndTime(times[1]);
    }
  }, [data.value]);

  useEffect(() => {
    if (!data.value) {
      setStartTime(null);
      setEndTime(null);
      setVal("");
    } else {
      let times = data.value.split(":");
      setStartTime(times[0]);
      setEndTime(times[1]);
    }
  }, [data.dataChk]);

  return (
    <div
      className={`inputItemBox ${
        startTime !== "" && startTime ? "active" : ""
      }`}
      ref={box}
    >
      {data.inputName && data.inputName !== "" ? (
        <h2 className="inputName">{data.inputName}</h2>
      ) : (
        ""
      )}
      <div
        className={`inputBox ${
          data.addClass && data.addClass !== "" ? data.addClass : ""
        }`}
      >
        <input
          value={val}
          onClick={onClickEvn}
          type="text"
          className="inputItem"
          placeholder={
            data.placeholder && data.placeholder !== "" ? data.placeholder : ""
          }
          readOnly={true}
          disabled={data.disabled ? data.disabled : false}
        />
      </div>
      {openType && (
        <TimeSelectPopup
          start={startTime}
          end={endTime}
          date={data.date}
          min={data.min ? data.min : "06"}
          max={data.max ? data.max : "22"}
          func={(start, end) => onChangeEvn(start, end)}
          errFunc={() => {
            setErrPopupData({
              addClass: null,
              text: `${data.min ? data.min : "06"}:00부터 ${
                data.max ? data.max : "22"
              }:00까지<br/>선택 가능합니다.`,
              closeType: true,
              closeFunc: (e) => {},
              btnFunc: (e) => {},
            });
          }}
        />
      )}
      <ErrPopup data={errPopupData} />
    </div>
  );
}

function DatePicerRange(data) {
  const [startDate, setStartDate] = useState("YYYY.MM.DD");
  const [endDate, setEndDate] = useState("YYYY.MM.DD");

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    data.func(start, end);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value == "" || !data.value ? "YYYY.MM.DD - YYYY.MM.DD" : value}
      onClick={onClick}
      type="text"
      className="inputItem"
      placeholder={
        data.placeholder && data.placeholder !== "" ? data.placeholder : ""
      }
      readOnly={true}
      disabled={data.disabled ? data.disabled : false}
    />
  ));

  const renderDayContents = (day, date) => {
    return <span>{day}</span>;
  };

  useEffect(() => {
    if (!data.value) {
      setStartDate("YYYY.MM.DD");
      setEndDate("YYYY.MM.DD");
    }
  }, [data.dataChk]);

  let nowDate = new Date();

  return (
    <div
      className={`inputItemBox ${
        startDate !== "" && startDate ? "active" : ""
      }`}
    >
      {data.inputName && data.inputName !== "" ? (
        <h2 className="inputName">{data.inputName}</h2>
      ) : (
        ""
      )}
      <div
        className={`inputBox ${
          data.addClass && data.addClass !== "" ? data.addClass : ""
        }`}
      >
        <ReactDatePicker
          minDate={
            data.minCount
              ? new Date(nowDate.setDate(nowDate.getDate() + data.minCount))
              : nowDate
          }
          locale={ko}
          monthsShown={2}
          closeOnScroll={true}
          renderCalendarHeaderTitle={(date) => {}}
          dateFormat="yyyy.MM.dd"
          selected={startDate == "YYYY.MM.DD" ? "" : startDate}
          onChange={onChange}
          startDate={startDate == "YYYY.MM.DD" ? "" : startDate}
          endDate={endDate == "YYYY.MM.DD" ? "" : endDate}
          customInput={<CustomInput />}
          renderDayContents={renderDayContents}
          selectsRange
          filterDate={data.filterDate}
          onMonthChange={data.onMonthChange}
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div>
              <button
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                style={
                  customHeaderCount === 1 ? { visibility: "hidden" } : null
                }
                aria-label="Previous Month"
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                  Previous Month
                </span>
              </button>
              <div className="react-datepicker__current-month">
                {new Date(monthDate).getFullYear()}년{" "}
                {new Date(monthDate).getMonth() + 1}월
              </div>
              <button
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--next"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                style={
                  customHeaderCount === 0 ? { visibility: "hidden" } : null
                }
                aria-label="Next Month"
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                  Next Month
                </span>
              </button>
            </div>
          )}
        />
      </div>
    </div>
  );
}

function DatePicer(data) {
  const [startDate, setStartDate] = useState("YYYY.MM.DD");

  const onChange = (date) => {
    const start = date;
    setStartDate(start);
    data.func(start);
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value == "" ? "YYYY.MM.DD" : value}
      onClick={onClick}
      type="text"
      className="inputItem"
      placeholder={
        data.placeholder && data.placeholder !== "" ? data.placeholder : ""
      }
      readOnly={true}
      disabled={data.disabled ? data.disabled : false}
    />
  ));

  const renderDayContents = (day, date) => {
    return <span>{day}</span>;
  };

  useEffect(() => {
    if (!data.value) {
      setStartDate("YYYY.MM.DD");
    } else {
      setStartDate(new Date(data.value.replace(/-/g, "/")));
    }
  }, [data.dataChk]);

  let nowDate = new Date();

  return (
    <div
      className={`inputItemBox ${
        startDate !== "" && startDate ? "active" : ""
      }`}
    >
      {data.inputName && data.inputName !== "" ? (
        <h2 className="inputName">{data.inputName}</h2>
      ) : (
        ""
      )}
      <div
        className={`inputBox ${
          data.addClass && data.addClass !== "" ? data.addClass : ""
        }`}
      >
        <ReactDatePicker
          minDate={
            data.minCount
              ? new Date(nowDate.setDate(nowDate.getDate() + data.minCount))
              : nowDate
          }
          locale={ko}
          monthsShown={2}
          closeOnScroll={true}
          dateFormat="yyyy.MM.dd"
          selected={startDate == "YYYY.MM.DD" ? "" : startDate}
          onChange={onChange}
          startDate={startDate == "YYYY.MM.DD" ? "" : startDate}
          customInput={<CustomInput />}
          renderDayContents={renderDayContents}
          renderCustomHeader={({
            monthDate,
            customHeaderCount,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div>
              <button
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--previous"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                style={
                  customHeaderCount === 1 ? { visibility: "hidden" } : null
                }
                aria-label="Previous Month"
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--previous">
                  Previous Month
                </span>
              </button>
              <div className="react-datepicker__current-month">
                {new Date(monthDate).getFullYear()}년{" "}
                {new Date(monthDate).getMonth() + 1}월
              </div>
              <button
                type="button"
                className="react-datepicker__navigation react-datepicker__navigation--next"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                style={
                  customHeaderCount === 0 ? { visibility: "hidden" } : null
                }
                aria-label="Next Month"
              >
                <span className="react-datepicker__navigation-icon react-datepicker__navigation-icon--next">
                  Next Month
                </span>
              </button>
            </div>
          )}
        />
      </div>
    </div>
  );
}

export {
  InputItemBox,
  ChkBox,
  TextAreaItem,
  FileItem,
  FileItemMultiple,
  RadioBox,
  DatePicerRange,
  TimePicer,
  TimeSelectPopup,
  DatePicer,
  AddrInputItem,
  CustomSelect,
};
