import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import { NotItems } from "component/basic/notItems";
import Tap from "component/basic/tap";
import { Header } from "component/elements/header";
import { MypageContents, MypageMenu, MypageSection } from "component/mypage/mypage";
import { MypageTabelArea } from "component/mypage/mypageTable";
import { comFormat, setDateTime } from "js/function";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Point = (props) => {
    const [mainData,setMainData] = useState(null);
    let navigate = useNavigate();

    const [type,setType] = useState("");
    const [page,setPage] = useState(1);
    const [arrData,setArrData] = useState([]);

    const pointData = useGet({
        url:`/mypage/point?page=${page}&type=${type}`,
        loginType:"login"
    });

    const [lastPage,setLastPage] = useState(false);
    const [settingChk,setSettingChk] = useState(0);

    const category = [
        {id:"",category:"전체"},
        {id:"1",category:"적립"},
        {id:"0",category:"차감"}
    ]

    function tapCh(id){
        if(id !== type){
            setArrData([]);
            setPage(1);
            setType(id);
        }
    }

    function addPage(){
        if(!lastPage && settingChk == 0){
            setPage(page + 1);
            setSettingChk(1);
            setTimeout(() => {
                setSettingChk(0);
            }, 500);
        }
    };

    useEffect(() => {
        if(pointData.data?.user_points?.data && pointData.data?.user_points?.data.length > 0){
            if(page == 1){
                setArrData([...pointData.data?.user_points?.data]);
            }else{    
                setArrData(prev => [...prev, ...pointData.data?.user_points?.data]);
            }
        }else{
            setArrData([]);
        }
        setLastPage(pointData.data?.user_points?.current_page == pointData.data?.user_points?.last_page);
    }, [pointData]);

    return(
        <>
        <Header
            pageBack={true}
            headTitle="포인트"
            func={() => navigate(-1)}
        />
        <MypageSection>
            <MypageMenu
                rightOpenFunc={(id)=>{props.rightOpenFunc(id)}}
                logOut={()=>{props.logOut()}}
                dataSet={(data)=>{setMainData(data)}}
            />
            <MypageContents>
                <MypageTabelArea
                    title="포인트"
                >
                    <div className="myPointBox">
                        <h1 className="myPointTitle"><img src="/assets/images/icon/point.svg"/>현재 포인트</h1>
                        <p className="myPoint">{comFormat(pointData.data?.point ? pointData.data?.point : 0)}P</p>
                    </div>
                    <div className="myPointListArea">
                        <div className="myPageTapBox">
                            <Tap
                                val={type}
                                data={category}
                                func={(id)=>{tapCh(id)}}
                                addClass="minVar"
                            />
                        </div>
                        <div className="myPointList">
                            {arrData && arrData.length > 0 ? 
                            <>{arrData.map((item,i)=>(
                            <div className="myPointListItem" key={i}>
                                <div className="myPointListItemBox">
                                    <p className="myPointList_type">{item.reason ? item.reason : ""}</p>
                                    <p className={`myPointList_state ${item.type == 1 ? "bColor" : ""}`}>{item.type == 0 ? "차감" : item.type == 1 ? "지급" : "-"}<span>{item.created_at ? setDateTime(item.created_at,"yymmdd") : "-"}</span></p>
                                </div>
                                <div className="myPointListItem_pointBox">
                                    <p className="myPointList_nowPoint">{item.remain_point ? `${comFormat(item.remain_point)} P` : ""}</p>
                                    <p className={`myPointList_point ${item.type == 1 ? "bColor" : ""}`}>{item.type == 1 ? "+ " : "- "}{item.point || item.point == 0 ? comFormat(item.point) : ""}</p>
                                </div>
                            </div>
                            ))}</>
                            :<NotItems img="/assets/images/icon/not_list.svg" text={`포인트 ${type === "" ? "적립/차감" : type == 1 ? "적립" : "차감"} 내역이 없습니다.`}/>}
                        </div>
                        {!lastPage ? 
                        <BtnBox
                            btnAddClass="line"
                            addClass="pageInType pcMinBtn addListBtn"
                            text={"더보기"}
                            func={()=>{addPage()}}
                        />:""}
                    </div>
                </MypageTabelArea>
            </MypageContents>
        </MypageSection>
        </>
    );
}
export default Point;