function LinkPopupBox(data){
    return (
        <div className={`popupLinkArea popupPageItem ${data.addClass ? data.addClass : ""}`}>
            {data.children}
        </div>
    );
}

function LinkPopupItem(data){
    return (
        <button type="button" className="popupLinkItem" onClick={()=>{data.func()}} dangerouslySetInnerHTML={{__html:data.name}}></button>
    );
}

export {LinkPopupBox, LinkPopupItem};