export function FatchApi(fatchData){
    let headersData = (fatchData.loginType == "login" || fatchData.loginType == "snsLogin") && fatchData.type == "POST" ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json'
        },
        body: fatchData.formDataItem
    } : fatchData.type == "POST" ? {
        method: `${fatchData.type}`,
        headers: {
            'Accept' : 'application/json'
        },
        body: fatchData.formDataItem
    } : (fatchData.loginType == "login" || fatchData.loginType == "snsLogin") && fatchData.type == "DELETE" ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json'
        },
        body: fatchData.formDataItem
    } : fatchData.type == "DELETE" ? {
        method: `${fatchData.type}`,
        headers: {
            'Accept' : 'application/json'
        },
        body: fatchData.formDataItem
    } : (fatchData.loginType == "login" || fatchData.loginType == "snsLogin") ? {
        method: `${fatchData.type}`,
        headers: {
            'Authorization': 'Bearer ' + `${fatchData.loginType == "login" ? localStorage.getItem("token") : sessionStorage.getItem("token")}`,
            'Accept' : 'application/json'
        }
    } : {
        method: `${fatchData.type}`,
        headers: {
            'Accept' : 'application/json'
        }
    }

    fetch(`${process.env.REACT_APP_API_URL}${fatchData.url}`, headersData).then(function(response) {
        return response.json();
    }).then(function(data) {
        if (data.success == true) {
            fatchData.success(data);
        }else{
            fatchData.err(data);
        }
    });

    return 
}