import { useEffect } from "react";
import { useState } from "react";

//category item
function CategoryBox(data){
    const [value,setValue] = useState(data.val);

    useEffect(() => {
        setValue(data.val);
    }, [data.val]);

    return (
        <div className={`categoryBox ${data.addClass ? data.addClass : ""}`}>
            {data.data?.map((item, i) => (
                <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`categoryItem ${item.id == value ? "active" : ""}`}>{item.name}</button>
            ))}
        </div>
    );
}

//align
function AlignBox(data){
    return (
        <div className={`alignArea ${data.addClass ? data.addClass : ""}`}>
            <img src="/assets/images/basic/filter.svg"/>
            <div className="alignItem_list">
                {data.data?.map((item, i) => (
                    <button type="button" key={item.id} onClick={() => data.func(item.id)} className={`alignItem ${item.id == data.val ? "active" : ""}`}>{item.name}</button>
                ))}
            </div>
        </div>
    );
}

export {CategoryBox, AlignBox};