import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MemberBox from "component/member/memberBox";
import { BtnBox } from "component/basic/btns";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import { BottomErrMsg, ErrPopup } from "component/basic/popup";
import { InputItemBox } from "component/basic/formItems";
import { useRef } from "react";

const IdFind = () => {
    const navigate = useNavigate();

    const [btnChk,setBtnChk] =  useState(true);

    const [name,setName] =  useState("");
    const [phone,setPhone] =  useState("");
    const [certify,setCertify] =  useState("");

    const [certifyType,setCertifyType] =  useState(false);
    const [errPopupData,setErrPopupData] =  useState(null);

    const [timerCount,setTimerCount] =  useState("3:00");

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;

                    setErrPopupData({
                        addClass:null,
                        text:"입력 시간이 만료되었습니다.",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }, 1000);
        }
    }

    function cetifiedStert(){
        let formData = new FormData();
        formData.append("phone", phone);
        formData.append("is_join", 0);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/sms/certification`,
            success: (data) => {
                setCertifyType(true);
                timerStart();
                setBottomMsgData({
                    addClass : "blueMsg",
                    text : "인증번호가 발송되었습니다.",
                    chk : bottomMsgData.chk + 1
                });
            },
            err: (data) => {
                if(data.alert == "금일 SMS 인증 요청 제한 횟수를 초과했습니다."){
                    setBottomMsgData({
                        addClass : "redMsg",
                        text : data.alert,
                        chk : bottomMsgData.chk + 1
                    });
                }else{
                    setErrPopupData({
                        addClass:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }
        })
    }

    function cetifiedCom(){
        let formData = new FormData();
        formData.append("phone", phone);
        formData.append("code", certify);
        formData.append("is_find", 1);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/sms/certification/check`,
            success: (data) => {
                clearInterval(sval.current);
                sval.current = null;
                setTimerCount("3:00");

                idFindFunc();
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }
        })
    }

    function idFindFunc(){
        let formData = new FormData();
        formData.append("phone", phone);
        formData.append("code", certify);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/find/email`,
            success: (data) => {
                if(data.alert){
                    let img = data.provider == "apple" ?
                    '<img src="/assets/images/sns/apple.svg" class="snsIcon"/>' : data.provider == "google" ?
                    '<img src="/assets/images/sns/google.svg" class="snsIcon"/>' : data.provider == "naver" ?
                    '<img src="/assets/images/sns/naver.svg" class="snsIcon"/>' : data.provider == "kakao" ?
                    '<img src="/assets/images/sns/kakao.svg" class="snsIcon"/>' : "";

                    setErrPopupData({
                        addClass:null,
                        text:`${img}${data.alert}`,
                        closeType:false,
                        errBtnText:"로그인 하러가기",
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{navigate("/member/login")}
                    });
                }
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }
        })
    }

    useEffect(() => {
        if(name !== "" && phone !== "" && certify !== ""){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [name,phone,certify]);

    return (
        <div className="memberPage headerInPage pageSizing btnInPage bottomMsgPage">
            <Header
                pageBack={true}
                headTitle="아이디 찾기"
                func={() => navigate(-1)}
            />
            <MemberBox title="아이디 찾기" pcChk={true} backFunc={()=>{navigate(-1)}}>
                <div className="memberInput_section joinInput_section">
                    <InputItemBox
                        type="text"
                        inputName="이름"
                        placeholder="이름"
                        value={name}
                        max={20}
                        regexp={/[0-9#?!@$ %^&*-]/gi}
                        func={(e) => {setName(e)}}
                    />
                    <InputItemBox
                        type="smsChk_phone"
                        inputName="휴대폰 번호"
                        placeholder="휴대폰 번호"
                        max={11}
                        value={phone}
                        certifyType={certifyType}
                        func={(e) => {setPhone(e)}}
                        clickEv={(e) => {cetifiedStert()}}
                        caption="‘-’를 제외하고 입력해주세요."
                    />
                    {certifyType ? 
                    <InputItemBox
                        type="smsChk_basic"
                        inputName="인증번호"
                        placeholder="인증번호"
                        max={6}
                        value={certify}
                        timer={timerCount}
                        func={(e) => {setCertify(e)}}
                    />
                    :""}
                </div>
                <BtnBox
                    addClass="pageInType mChType"
                    text={"인증 완료"}
                    disabled={btnChk}
                    func={()=>{cetifiedCom()}}
                />
            </MemberBox>
            <ErrPopup
                data={errPopupData}
            />
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </div>
    );
};

export default IdFind;