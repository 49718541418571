import Private from "pages/private/Private";
import PublicOnly from "pages/private/publicOnly";
import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { publicRoutes, privateRoutes, publicRoutesOnly } from "./routes";
import { PcHeader } from "../component/elements/header";
import { Footer } from "component/elements/footer";
import { CustomPopup, ErrPopup, RightPopup } from "component/basic/popup";
import termsData from "data/terms.json";
import StorageLink from "component/popupContents/storageLink";
import ManagementLink from "component/popupContents/managementLink";
import RentInquiryLink from "component/popupContents/rentInquiry";
import Login from "pages/member/Login";
import Join from "pages/member/Join";
import useGet from "api/useGet";
import Main from "pages/main/Main";
import RentList from "pages/rentInquiry/List";
import MypageMain from "pages/mypage/Main";
import EditProfilePw from "pages/mypage/EditProfilePw";
import Point from "pages/mypage/Point";
import Reservation from "pages/mypage/reservation/List";
import OrderList from "pages/mypage/order/List";
import MyRentList from "pages/mypage/rent/List";
import Social from "pages/member/Social";
import ScrollToTop from "component/basic/scrollTopSet";
import CsList from "pages/cs/List";
import Core from "component/base/Core";
import TermsPrivate from "pages/terms/TermsPrivate";

const Router = () => {
  const [token, setToken] = useState(
    localStorage.getItem("token") ? true : false
  );

  const logIn = () => {
    setToken(true);
  };
  const logOut = () => {
    setToken(false);
  };

  //약관
  const [termsId, setTermsId] = useState("");
  const [terms, setTerms] = useState("");

  const termsList = useGet({
    url: `/term?type=${termsId}`,
  });

  const chkData = termsData?.data;

  const rightOpen = (id) => {
    let idChk = id == "service" ? 0 : id == "privacy" ? 1 : 2;
    setTerms({
      id: id,
      title: chkData[Number(idChk)].text,
      text: "",
    });
  };

  //팝업 활성화/비활성화 체크
  const [popupData, setPopupData] = useState(null);
  const [errPopupData, setErrPopupData] = useState(null);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <PcHeader
        logOut={logOut}
        token={token}
        popupOpenFunc={(name) => setPopupData(name)}
      />
      <Routes>
        {/* 기본접근 */}
        {publicRoutes.map(({ exact, path, component }) => (
          <Route
            key={`public-route-${path}`}
            exact={exact}
            path={path}
            element={component}
          />
        ))}

        {/* 비로그인만 접근 */}
        {publicRoutesOnly.map(({ exact, path, component }) => (
          <Route
            key={`public-route-${path}`}
            exact={exact}
            path={path}
            element={!token ? component : <PublicOnly />}
          />
        ))}

        {/* 로그인만 접근 */}
        {privateRoutes.map(({ exact, path, component }) => (
          <Route
            key={`public-route-${path}`}
            exact={exact}
            path={path}
            element={token ? component : <Private />}
          />
        ))}

        {/*main*/}
        <Route
          key={`public-route-/main`}
          exact={true}
          path={"/main"}
          element={<Main popupOpen={(e) => setPopupData(e)} />}
        />
        {/*private*/}
        <Route
          key={`public-route-/terms/termsPrivate`}
          exact={true}
          path={"/terms/private"}
          element={<TermsPrivate popupOpen={(e) => setPopupData(e)} />}
        />
        {/*rent list*/}
        <Route
          key={`public-route-/rent/list/:startDate/:startTime/:endDate/:endTime/:carInfo`}
          exact={true}
          path={"/rent/list/:startDate/:startTime/:endDate/:endTime/:carInfo"}
          element={
            token ? (
              <RentList popupOpen={(e) => setPopupData(e)} />
            ) : (
              <Private />
            )
          }
        />

        {/*cs list*/}
        <Route
          key={`public-route-/cs/list/:page`}
          exact={true}
          path={"/cs/list/:page"}
          element={<CsList token={token} />}
        />

        {/* 로그인 체크 */}
        <Route
          key={`public-route-/member/login`}
          exact={true}
          path="/member/login"
          element={
            !token ? (
              <Login
                logIn={logIn}
                rightOpenFunc={(id) => {
                  setTermsId(id);
                  rightOpen(id);
                }}
              />
            ) : (
              <PublicOnly />
            )
          }
        />
        <Route
          key={`public-route-/social/:marketing`}
          exact={true}
          path="/member/join/:marketing/:type"
          element={!token ? <Join logIn={logIn} /> : <PublicOnly />}
        />
        <Route
          key={`public-route-/social/:type`}
          exact={true}
          path="/social/:type"
          element={!token ? <Social logIn={logIn} /> : <PublicOnly />}
        />

        {/* 로그아웃 체크 */}
        <Route
          key={`public-route-/mypage/main`}
          exact={true}
          path="/mypage/main"
          element={
            token ? (
              <MypageMain
                rightOpenFunc={(id) => {
                  setTermsId(id);
                  rightOpen(id);
                }}
                logOut={logOut}
              />
            ) : (
              <Private />
            )
          }
        />
        <Route
          key={`public-route-/mypage/edit/pw`}
          exact={true}
          path="/mypage/edit/pw"
          element={token ? <EditProfilePw logOut={logOut} /> : <Private />}
        />
        <Route
          key={`public-route-/mypage/point`}
          exact={true}
          path="/mypage/point"
          element={token ? <Point logOut={logOut} /> : <Private />}
        />
        <Route
          key={`public-route-/mypage/reservation`}
          exact={true}
          path="/mypage/reservation"
          element={token ? <Reservation logOut={logOut} /> : <Private />}
        />
        <Route
          key={`public-route-/mypage/order/:type`}
          exact={true}
          path="/mypage/order/:type"
          element={token ? <OrderList logOut={logOut} /> : <Private />}
        />
        <Route
          key={`public-route-/mypage/rent`}
          exact={true}
          path="/mypage/rent/:type"
          element={token ? <MyRentList logOut={logOut} /> : <Private />}
        />
      </Routes>
      <Footer
        pcType={true}
        rightOpenFunc={(id) => {
          setTermsId(id);
          rightOpen(id);
        }}
      />
      <CustomPopup
        topTitle={popupData}
        closeType={true}
        closePopup={() => {
          setTimeout(() => {
            setPopupData(null);
          }, 200);
        }}
        maxSize={popupData == "렌터카 차량 조회" ? "670px" : "360px"}
        addClass={
          popupData == "렌터카 차량 조회"
            ? "btnPopupSetion sizingPopup"
            : "sizingPopup"
        }
      >
        {popupData == "공항 주차 지역 선택" ? (
          <StorageLink
            func={() => {
              setTimeout(() => {
                setPopupData(null);
              }, 200);
            }}
          />
        ) : popupData == "차량 정비 지역 선택" ? (
          <ManagementLink
            errSet={() => {
              setErrPopupData({
                addClass: null,
                text: "서비스 준비 중 입니다.",
                closeType: true,
                closeFunc: (e) => {
                  setErrPopupData(null);
                },
                btnFunc: (e) => {
                  setErrPopupData(null);
                },
              });
            }}
            func={() => {
              setTimeout(() => {
                setPopupData(null);
              }, 200);
            }}
          />
        ) : popupData == "렌터카 차량 조회" ? (
          <RentInquiryLink
            func={() => {
              setTimeout(() => {
                setPopupData(null);
              }, 200);
            }}
          />
        ) : null}
      </CustomPopup>
      <RightPopup
        topTitle={terms?.title}
        text={termsList?.data?.term?.value}
        closePopup={() => {
          setTerms("");
          setTermsId("");
        }}
      />
      <ErrPopup data={errPopupData} />
      <Core />
    </BrowserRouter>
  );
};

export default Router;
