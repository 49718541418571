import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import { NotItems } from "component/basic/notItems";
import { ErrPopup } from "component/basic/popup";
import Tap from "component/basic/tap";
import { FooterManu } from "component/elements/footer";
import { Header } from "component/elements/header";
import { MypageContents, MypageMenu, MypageSection } from "component/mypage/mypage";
import { MypageTabelArea, MypageTable, MypageTableState, MypageTd, MypageTdBox, MypageTh, MypageThBox, TableSubTitle } from "component/mypage/mypageTable";
import { comFormat, setDateTime } from "js/function";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const OrderList = (props) => {
    const [mainData,setMainData] = useState(null);
    let navigate = useNavigate();
    const param = useParams();
    const type = param.type;

    const [page,setPage] = useState(1);
    const [arrData,setArrData] = useState([]);
    const [errPopupData,setErrPopupData] = useState(null);

    const listData = useGet({
        url:`/mypage/estimate?category=${type}&page=${page}`,
        loginType:"login"
    });

    const [lastPage,setLastPage] = useState(false);
    const [settingChk,setSettingChk] = useState(0);

    const category = [
        {id:"repair",category:"외관 수리"},
        {id:"manages",category:"관리 서비스"}
    ]

    function addPage(){
        if(!lastPage && settingChk == 0){
            setPage(page + 1);
            setSettingChk(1);
            setTimeout(() => {
                setSettingChk(0);
            }, 500);
        }
    };

    useEffect(() => {
        if(listData.data?.user_service_estimates?.data && listData.data?.user_service_estimates?.data.length > 0){
            if(page == 1){
                setArrData([...listData.data?.user_service_estimates?.data]);
            }else{    
                setArrData(prev => [...prev, ...listData.data?.user_service_estimates?.data]);
            }
        }else{
            setArrData([]);
        }
        setLastPage(listData.data?.user_service_estimates?.current_page == listData.data?.user_service_estimates?.last_page);
    }, [listData]);

    return(
        <>
        <Header
            pageBack={true}
            headTitle="견적 내역"
            func={() => navigate(-1)}
        />
        <MypageSection>
            <MypageMenu
                rightOpenFunc={(id)=>{props.rightOpenFunc(id)}}
                logOut={()=>{props.logOut()}}
                dataSet={(data)=>{setMainData(data)}}
            />
            <MypageContents>
                <MypageTabelArea
                    title="견적 내역"
                    addClass="mTMergin"
                >
                    <div className="myPageTapBox">
                        <Tap
                            val={type}
                            data={category}
                            func={(id)=>{navigate(`/mypage/order/${id}`)}}
                            addClass="minVar"
                        />
                    </div>
                    {mainData && arrData.length > 0 ? 
                    <MypageTable mType={true}>
                        <MypageThBox>
                            <MypageTh contents="요청 번호" width="25%"/>
                            <MypageTh contents="견적 신청일자" width="25%"/>
                            <MypageTh contents="입고일" width="25%"/>
                            <MypageTh contents="처리 상태" width="25%"/>
                        </MypageThBox>
                        {arrData.map((item,i)=>(
                            <MypageTdBox key={i} func={()=>{item.status !== 21 && item.status !== 11 ? item.status == 2 ? navigate(`/mypage/reservation/detail/${item.id}/${item.type}`) : navigate(`/mypage/order/detail/${item.id}/${type == "repair" ? "repair_service" : "manage_service"}`) : 
                                setErrPopupData({
                                    addClass:null,
                                    text:"만료 또는 취소된 견적입니다.<br/>새로 견적을 신청하시면<br/>서비스 이용이 가능합니다.",
                                    closeType:true,
                                    closeFunc:(e) => {},
                                    btnFunc:(e)=>{}
                                });}}>
                                <MypageTd width="25%">
                                    <b>{item.estimate_code ? item.estimate_code : "-"}</b>
                                </MypageTd>
                                <MypageTd width="25%">
                                    <TableSubTitle text="견적 신청일자"/>{item.created_at ? setDateTime(item.created_at,"yymmdd") : "-"}
                                </MypageTd>
                                <MypageTd width="25%">
                                    <TableSubTitle text="입고일"/>{item.start_date ? setDateTime(item.start_date,"yymmdd") : "-"}
                                </MypageTd>
                                <MypageTd width="25%">
                                    <MypageTableState
                                        text={item.status == 3 ? "완료" : item.status == 1 ? "예약 완료" : item.status == 11 ? "예약 취소" : item.status == 2 ? "견적 접수" : item.status == 20 ? "견적 도착" : item.status == 21 ? "견적 취소" : ""}
                                        addClass={item.status == 1 ? "wbColor" : item.status == 2 ? "" : item.status == 20 ? "gColor" : item.status == 21 || item.status == 11 ? "rColor" : ""}
                                    />
                                </MypageTd>
                            </MypageTdBox>
                        ))}
                    </MypageTable>
                    :
                    <NotItems img="/assets/images/icon/not_list.svg" text="견적 내역이 없습니다."/>
                    }
                    {!lastPage ? 
                    <BtnBox
                        btnAddClass="line"
                        addClass="pageInType pcMinBtn addListBtn"
                        text={"더보기"}
                        func={()=>{addPage()}}
                    />:""}
                </MypageTabelArea>
            </MypageContents>
        </MypageSection>
        <ErrPopup
            data={errPopupData}
        />
        </>
    );
}
export default OrderList;