import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import SelectCarMaker from "./SelectCarMaker";
import SelectCarModel from "./SelectCarModel";
import { PopupBtnBox } from "component/basic/btns";

function DirectRegisterCar({ onSelectCarInfo, handlePreEvent, viewType }) {
  const [view, setView] = useState("maker");
  const [selectedMaker, setSelectedMaker] = useState(null);
  const [token, setToken] = useState("");

  useEffect(() => {
    if (viewType) {
      setView(viewType);
    }
  }, [viewType]);

  useEffect(() => {
    if (view === "model") {
      localStorage.setItem("view", "model");
    }
  }, [view]);

  const handleSelectMaker = (maker) => {
    setView("model");
    setSelectedMaker(maker);
  };

  const handleSelectModel = (model) => {
    onSelectCarInfo({ maker: selectedMaker, model });
  };

  const getToken = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_OPEN_API_URL}/auth/v1/consumers`,
      {
        interface_id:
          "2b760111f3f6de004b1ccf1043be5417cfe182cb755dc1c56334741972c20ecc",
        interface_pw:
          "f494b99ded5c2a505daba81c7e08583e830ae86d3de1df8b43632ed5d4861001",
        user: {
          id: "rainbow-auto",
        },
      }
    );

    setToken(data.token);
  };

  useEffect(() => {
    if (token) return;
    getToken();
  }, [token]);

  useEffect(() => {
    if (selectedMaker === null) {
      const preData = window.localStorage.getItem("marker");
      setSelectedMaker(JSON.parse(preData));
    }
  }, [selectedMaker]);

  if (!token) return;
  return (
    <Wrapper>
      {view === "maker" && (
        <SelectCarMaker token={token} onSelect={handleSelectMaker} />
      )}
      {view === "model" && (
        <SelectCarModel
          maker={selectedMaker}
          onSelect={handleSelectModel}
          token={token}
        />
      )}
      <PopupBtnBox
        text="이전"
        btnAddClass="line full"
        func={() => {
          if (view === "maker") {
            handlePreEvent();
          } else {
            setView("maker");
          }
        }}
      >
        <></>
      </PopupBtnBox>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  max-height: inherit;
  overflow-y: hidden;
`;

export default DirectRegisterCar;
