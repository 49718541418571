import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import {
  PhoneSetting,
  getDateGap,
  payTypeText,
  setDateTime,
  setDateWeek,
} from "js/function";
import { comFormat } from "js/function";
import { Header } from "component/elements/header";
import {
  OrderComHead,
  OrderComInfoText,
  OrderComSection,
  OrderComTable,
  OrderComTableItem,
} from "component/app/order";
import MiddleBanner from "component/banner/banner";
import { Footer } from "component/elements/footer";

const OrderCom = (props) => {
  const navigate = useNavigate();
  const param = useParams();
  const id = param.id;
  const type = param.type;

  //주문 정보
  const orderApi = useGet({
    url: `${
      type !== "rent"
        ? `/mypage/service/detail/ordernumber?order_number=${id}`
        : `/rentercar/reserve/view?order_number=${id}`
    }`,
    loginType: "login",
  });

  //하단 배너
  const mainData = useGet({
    url: `/main?os=pc`,
    loginType: "login",
  });

  const orderData =
    type !== "rent" ? orderApi?.data?.service : orderApi?.data?.reserve;
  const middle_banners = mainData?.data?.middle_banners;

  const [manageArr, setManageArr] = useState("");

  useEffect(() => {
    if (orderData && orderData?.manage?.length > 0) {
      let manageArrItem = "";
      orderData?.manage?.forEach((item, i) => {
        if (i == 0) {
          manageArrItem = item.manage_name;
        } else {
          manageArrItem = manageArrItem + ", " + item.manage_name;
        }
        if (orderData?.manage.length - 1 == i) {
          setManageArr(manageArrItem);
        }
      });
    }
  }, [orderData]);

  return (
    <>
      <div className="orderComPage headerInPage pageSizing">
        <Header pageBack={false} headTitle="" func={() => {}} homeBtn={true} />
        <div className="orderComPage_contents">
          <OrderComHead
            number={orderData?.code}
            type={type}
            discount={orderData?.discount_price ? orderData?.discount_price : 0}
            price={
              type !== "rent"
                ? orderData?.original_price
                  ? orderData?.original_price
                  : 0
                : orderData?.rent_place && orderData?.insurance_price
                ? Number(orderData?.rent_place) +
                  Number(orderData?.insurance_price)
                : 0
            }
          />
          {type == "storageJeju" ||
          type == "storageGimpo" ||
          type == "storageGimhea" ||
          type == "maintenance" ||
          type == "service" ||
          type == "repair" ||
          type == "management" ? (
            <OrderComSection title="이용 안내">
              <>
                {type == "storageJeju" ||
                type == "storageGimpo" ||
                type == "storageGimhea" ? (
                  <>
                    {orderData?.pickup_price !== 0 &&
                    (type == "storageJeju" || type == "storageGimpo") ? (
                      <>
                        <OrderComTable>
                          <OrderComTableItem
                            title="픽업서비스 안내 연락처"
                            text={
                              type == "storageJeju"
                                ? "010-7160-8850"
                                : "010-6635-0177"
                            }
                          />
                          <OrderComTableItem
                            title="차량 입고 시"
                            text={
                              type == "storageJeju"
                                ? "공항 도착 15분 전"
                                : "공항 도착 30분 전"
                            }
                          />
                          <OrderComTableItem
                            title="차량 출고 시"
                            text={
                              type == "storageJeju"
                                ? "공항 도착 또는 수화물 찾은 후"
                                : "공항 착륙 직후"
                            }
                          />
                        </OrderComTable>
                        <OrderComInfoText text="차량 입·출고 시 안내드린 연락처로 도착 시간에 맞춰 연락 주시기 바랍니다." />
                      </>
                    ) : (
                      <OrderComTable>
                        <OrderComTableItem
                          title="주소"
                          text={
                            type == "storageJeju"
                              ? "제주시 연삼로 75 레인보우 오토"
                              : type == "storageGimpo"
                              ? "경기도 부천시 봉오대로556번길 21 지하 6층"
                              : "부산광역시 강서구 대저2동 2047-1"
                          }
                        />
                        {type == "storageJeju" ? (
                          <OrderComTableItem
                            title="셔틀버스 안내"
                            text="셔틀버스 이용 가능 시간 - 평일 10:00 ~ 16:00 주말 운영 X 공항 픽업 장소 - 3층(출발층) 4번게이트 앞"
                          />
                        ) : (
                          ""
                        )}
                      </OrderComTable>
                    )}
                  </>
                ) : (
                  ""
                )}
              </>
              <>
                {type == "service" ? (
                  <>
                    <OrderComTable>
                      <OrderComTableItem
                        title="주소"
                        text="제주시 연삼로 75 레인보우 오토"
                      />
                    </OrderComTable>
                    <OrderComInfoText text="관리서비스의 경우 견적 접수 후 담당직원이 연락드리겠습니다. 외관수리 서비스의 경우 예약 확정 후 신청하신 일자에 센터에 방문해 주세요!" />
                  </>
                ) : (
                  ""
                )}
              </>
              <>
                {type == "maintenance" ? (
                  <OrderComInfoText text="접수 후 담당 직원이 연락드리겠습니다." />
                ) : (
                  ""
                )}
              </>
              <>
                {type == "management" || type == "repair" ? (
                  <>
                    <OrderComTable>
                      <OrderComTableItem
                        title="센터 주소"
                        text="제주시 연삼로 75 레인보우 오토"
                      />
                    </OrderComTable>
                    <OrderComInfoText text="신청하신 일자에 센터에 방문해주세요!" />
                  </>
                ) : (
                  ""
                )}
              </>
            </OrderComSection>
          ) : (
            ""
          )}
          {type == "management" || type == "repair" ? (
            <OrderComSection title="신청 내용">
              <OrderComTable>
                <OrderComTableItem
                  title="견적서"
                  text={
                    type == "management" || type == "repair"
                      ? orderData?.estimate_code
                      : id
                  }
                  link={true}
                  linkFunc={() => {
                    navigate(
                      `/mypage/order/detail/${orderData?.id}/${
                        type == "management"
                          ? "manage_service"
                          : "repair_service"
                      }`
                    );
                  }}
                />
                <OrderComTableItem
                  title="서비스 일시"
                  text={
                    orderData?.start_date && orderData?.start_date !== ""
                      ? `${setDateTime(
                          orderData?.start_date,
                          "yymmdd"
                        )} (${setDateWeek(
                          setDateTime(orderData?.start_date, "yymmdd")
                        )}) ${setDateTime(orderData?.start_date, "hhtt")}`
                      : "-"
                  }
                />
                <OrderComTableItem
                  title="차량 정보"
                  text={
                    orderData?.car_name
                      ? `${orderData?.car_name} ${orderData?.car_number}`
                      : "-"
                  }
                />
                <OrderComTableItem
                  title="연락처"
                  text={orderData?.phone ? PhoneSetting(orderData?.phone) : "-"}
                />
                <OrderComTableItem
                  title="특이사항"
                  text={orderData?.contents ? orderData?.contents : "-"}
                />
              </OrderComTable>
            </OrderComSection>
          ) : type !== "rent" ? (
            <OrderComSection title="신청 내용">
              <OrderComTable>
                <OrderComTableItem
                  title="서비스 기간"
                  text={`${
                    orderData?.start_date && orderData?.start_date !== ""
                      ? `${setDateTime(
                          orderData?.start_date,
                          "yymmdd"
                        )} (${setDateWeek(
                          orderData?.start_date?.split(" ")[0]
                        )}) ${
                          setDateTime(orderData?.start_date, "hhtt") !== "00:00"
                            ? setDateTime(orderData?.start_date, "hhtt")
                            : ""
                        }`
                      : ""
                  }
                            ${
                              orderData?.start_date &&
                              orderData?.end_date &&
                              orderData?.end_date !== "0000-00-00 00:00:00"
                                ? " - "
                                : ""
                            }
                            ${
                              orderData?.end_date &&
                              orderData?.end_date !== "" &&
                              orderData?.end_date !== "0000-00-00 00:00:00"
                                ? `${setDateTime(
                                    orderData?.end_date,
                                    "yymmdd"
                                  )} (${setDateWeek(
                                    orderData?.end_date?.split(" ")[0]
                                  )}) ${
                                    setDateTime(orderData?.end_date, "hhtt") !==
                                    "00:00"
                                      ? setDateTime(orderData?.end_date, "hhtt")
                                      : ""
                                  }`
                                : ""
                            }
                            `}
                />
                <OrderComTableItem
                  title="차량 정보"
                  text={
                    orderData?.car_name
                      ? `${orderData?.car_name} ${orderData?.car_number}`
                      : "-"
                  }
                />
                {orderData?.region == "김포" || orderData?.region == "김해" ? (
                  <OrderComTableItem
                    title="(지역) 출장지"
                    text={`${orderData?.region} 공항`}
                  />
                ) : (
                  ""
                )}
                <OrderComTableItem
                  title="연락처"
                  text={orderData?.phone ? PhoneSetting(orderData?.phone) : "-"}
                />
                {type == "maintenance" ? (
                  <OrderComTableItem
                    title="출장지 주소"
                    text={orderData?.addr ? orderData?.addr : "-"}
                  />
                ) : (
                  ""
                )}
                <OrderComTableItem
                  title="특이사항"
                  text={orderData?.contents ? orderData?.contents : "-"}
                />
              </OrderComTable>
            </OrderComSection>
          ) : (
            <OrderComSection title="신청 내용">
              <OrderComTable>
                <OrderComTableItem
                  title="선택 차량"
                  text={
                    orderData?.rent_car_name
                      ? `${orderData?.rent_car_name} (${orderData?.car_year})`
                      : "-"
                  }
                />
                <OrderComTableItem
                  title="보험 명"
                  text={
                    orderData?.insurance_name ? orderData?.insurance_name : "-"
                  }
                />
                <OrderComTableItem
                  title="이름"
                  text={orderData?.reserve_name ? orderData?.reserve_name : "-"}
                />
                <OrderComTableItem
                  title="연락처"
                  text={
                    orderData?.reserve_phone
                      ? PhoneSetting(orderData?.reserve_phone)
                      : "-"
                  }
                />
                <OrderComTableItem
                  title="생년월일"
                  text={
                    orderData?.reserve_birth ? orderData?.reserve_birth : "-"
                  }
                />
                <OrderComTableItem
                  title="대여일시"
                  text={
                    orderData?.start_date && orderData?.start_date !== ""
                      ? `${setDateTime(
                          orderData?.start_date,
                          "yymmdd"
                        )} (${setDateWeek(
                          setDateTime(orderData?.start_date, "yymmdd")
                        )}) ${setDateTime(orderData?.start_date, "hhtt")}`
                      : "-"
                  }
                />
                <OrderComTableItem
                  title="대여 장소"
                  text={orderData?.rent_place ? orderData?.rent_place : "-"}
                />
                <OrderComTableItem
                  title="반납일시"
                  text={
                    orderData?.end_date && orderData?.end_date !== ""
                      ? `${setDateTime(
                          orderData?.end_date,
                          "yymmdd"
                        )} (${setDateWeek(
                          setDateTime(orderData?.end_date, "yymmdd")
                        )}) ${setDateTime(orderData?.end_date, "hhtt")}`
                      : "-"
                  }
                />
                <OrderComTableItem
                  title="반납 장소"
                  text={orderData?.return_place ? orderData?.return_place : "-"}
                />
                <OrderComTableItem
                  title="총 대여기간"
                  text={
                    orderData?.start_date && orderData?.end_date
                      ? getDateGap(orderData?.start_date, orderData?.end_date)
                      : "-"
                  }
                />
                <OrderComTableItem
                  title="특이사항"
                  text={orderData?.contents ? orderData?.contents : "-"}
                />
              </OrderComTable>
            </OrderComSection>
          )}
          <OrderComSection
            title={
              type == "repair"
                ? "결제 예상 금액"
                : (type !== "rent"
                    ? orderData?.original_price
                      ? orderData?.original_price
                      : 0
                    : orderData?.rent_place && orderData?.insurance_price
                    ? Number(orderData?.rent_place) +
                      Number(orderData?.insurance_price)
                    : 0) !== 0
                ? "이용 요금"
                : "이용 서비스"
            }
          >
            {type == "management" ? (
              <>
                {orderData?.manage && orderData?.manage.length > 0 ? (
                  <OrderComTable>
                    {orderData?.manage.map((item, i) => (
                      <OrderComTableItem
                        key={i}
                        title={item.manage_name ? item.manage_name : "-"}
                        text={
                          item?.manage_price
                            ? `${comFormat(item?.manage_price)} 원`
                            : "0 원"
                        }
                      />
                    ))}
                  </OrderComTable>
                ) : (
                  ""
                )}
              </>
            ) : type == "repair" ? (
              <OrderComTable>
                <OrderComTableItem
                  title={"현장 결제"}
                  text={
                    orderData?.repair?.repair_price
                      ? `${comFormat(orderData?.repair?.repair_price)} 원`
                      : "0 원"
                  }
                />
              </OrderComTable>
            ) : type !== "rent" ? (
              <OrderComTable>
                {orderData?.storage_price && orderData?.storage_price > 0 ? (
                  <OrderComTableItem
                    title="공항 주차"
                    text={
                      orderData?.storage_price
                        ? `${comFormat(orderData?.storage_price)} 원`
                        : "0 원"
                    }
                  />
                ) : (
                  ""
                )}
                {orderData?.pickup_price && orderData?.pickup_price > 0 ? (
                  <OrderComTableItem
                    title="픽업 서비스"
                    text={`${comFormat(orderData?.pickup_price)}원`}
                  />
                ) : (
                  ""
                )}
                {orderData?.options &&
                orderData?.options.length > 0 &&
                (type == "storageJeju" || type == "service") ? (
                  orderData?.options.map((item, i) => (
                    <OrderComTableItem
                      title={"엔진 오일"}
                      text={
                        type == "storageJeju" ||
                        (type == "service" &&
                          (orderData?.storage_price ||
                            orderData?.storage_price > 0))
                          ? `<span>${comFormat(item?.price)}</span> ${comFormat(
                              Number(item?.price - 10000)
                            )}원`
                          : `${comFormat(item?.price)}원`
                      }
                      key={i}
                    />
                  ))
                ) : orderData?.option_price || orderData?.option_price == 0 ? (
                  <OrderComTableItem
                    title={"출장 정비"}
                    text={`${comFormat(orderData?.option_price)}원`}
                  />
                ) : (
                  ""
                )}
                {orderData?.wash ? (
                  <OrderComTableItem
                    title="세차"
                    text={
                      type == "storageJeju" ||
                      (type == "service" &&
                        (orderData?.storage_price ||
                          orderData?.storage_price > 0))
                        ? `<span>${
                            orderData?.wash?.is_wax == 1
                              ? comFormat(
                                  Number(orderData?.wash?.price) +
                                    Number(orderData?.wash?.add_price)
                                )
                              : comFormat(Number(orderData?.wash?.price))
                          }</span> 
                                    ${
                                      orderData?.wash?.is_wax == 1
                                        ? comFormat(
                                            Number(orderData?.wash?.price) +
                                              Number(
                                                orderData?.wash?.add_price
                                              ) -
                                              10000
                                          )
                                        : comFormat(
                                            Number(orderData?.wash?.price) -
                                              10000
                                          )
                                    }원`
                        : `${
                            orderData?.wash?.is_wax == 1
                              ? comFormat(
                                  Number(orderData?.wash?.price) +
                                    Number(orderData?.wash?.add_price)
                                )
                              : comFormat(Number(orderData?.wash?.price))
                          }원`
                    }
                  />
                ) : (
                  ""
                )}
                {orderData?.inspection ? (
                  <OrderComTableItem
                    title="차량 검사"
                    text={`${comFormat(orderData?.inspection?.price)}원`}
                  />
                ) : (
                  ""
                )}
                {orderData?.is_manage == 1 ? (
                  <OrderComTableItem
                    title="관리 서비스 신청"
                    text={manageArr}
                  />
                ) : (
                  ""
                )}
                {orderData?.is_repair == 1 ? (
                  <OrderComTableItem
                    title="외관 수리 신청"
                    text="상담 후 견적가 안내"
                  />
                ) : (
                  ""
                )}
              </OrderComTable>
            ) : (
              <OrderComTable>
                <OrderComTableItem
                  title="차량 렌터카 비용"
                  text={
                    orderData?.rent_place
                      ? `${comFormat(orderData?.rent_place)}원`
                      : "0 원"
                  }
                />
                <OrderComTableItem
                  title="보험 비용"
                  text={
                    orderData?.insurance_price
                      ? `${comFormat(orderData?.insurance_price)}원`
                      : "0 원"
                  }
                />
              </OrderComTable>
            )}
          </OrderComSection>

          {(type !== "rent"
            ? orderData?.original_price
              ? orderData?.original_price
              : 0
            : orderData?.rent_place && orderData?.insurance_price
            ? Number(orderData?.rent_place) + Number(orderData?.insurance_price)
            : 0) !== 0 ? (
            <OrderComSection title="결제 정보">
              <OrderComTable>
                <OrderComTableItem
                  title={`${
                    orderData?.payment_method &&
                    orderData?.payment_method !== "-"
                      ? `결제 수단 / `
                      : ""
                  }결제 일시`}
                  text={`${
                    orderData?.payment_method &&
                    orderData?.payment_method !== "-"
                      ? `${payTypeText(orderData?.payment_method)} / `
                      : ""
                  }${
                    orderData?.purchase_date
                      ? setDateTime(orderData?.purchase_date, "yymmddhhttss")
                      : "-"
                  }`}
                />
                <OrderComTableItem
                  title="총 서비스 요금"
                  text={
                    type !== "rent"
                      ? orderData?.original_price
                        ? `${comFormat(orderData?.original_price)} 원`
                        : "0 원"
                      : orderData?.rent_place && orderData?.insurance_price
                      ? `${comFormat(
                          Number(orderData?.rent_place) +
                            Number(orderData?.insurance_price)
                        )} 원`
                      : "0 원"
                  }
                />
                <OrderComTableItem
                  title="사용 포인트"
                  text={
                    orderData?.use_point
                      ? `${comFormat(orderData?.use_point)} P`
                      : "0 P"
                  }
                />
                <OrderComTableItem
                  title="총 할인가"
                  text={
                    orderData?.discount_price
                      ? `${comFormat(orderData?.discount_price)} 원`
                      : "0 원"
                  }
                />
                <OrderComTableItem
                  title="총 결제 금액"
                  text={
                    type !== "rent"
                      ? orderData?.total_price
                        ? `${comFormat(orderData?.total_price)} 원`
                        : "0 원"
                      : orderData?.price
                      ? `${comFormat(orderData?.price)} 원`
                      : "0 원"
                  }
                />
                <OrderComTableItem
                  title="적립 포인트"
                  text={
                    orderData?.add_point
                      ? `${comFormat(orderData?.add_point)} P`
                      : "0 P"
                  }
                />
              </OrderComTable>
            </OrderComSection>
          ) : (
            ""
          )}
          <BtnBox
            btnAddClass="line"
            addClass="pageInType pcMinBtn"
            text={"확인"}
            func={() => {
              if (
                type == "service" ||
                type == "maintenance" ||
                type == "storageGimhea" ||
                type == "storageGimpo" ||
                type == "storageJeju"
              ) {
                navigate("/mypage/reservation");
              } else if (type == "rent") {
                navigate("/mypage/rent/new");
              } else if (type == "management") {
                navigate("/mypage/order/manages");
              } else if (type == "repair") {
                navigate("/mypage/order/repair");
              } else {
                navigate("/");
              }
            }}
          />
          <div className="orderComBanner">
            <MiddleBanner data={middle_banners} />
          </div>
        </div>
      </div>
      <Footer rightOpenFunc={(id) => props.rightOpenFunc(id)} />
    </>
  );
};

export default OrderCom;
