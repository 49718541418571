import * as fatchSet from "../api/api";

//핸드폰번호 포멧
function PhoneSetting(str) {
    str = str.replace(/[^0-9]/g, '');
    var tmp = '';
    if (str.length < 4) {
        return str;
    } else if (str.length < 7) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3);
        return tmp;
    } else if (str.length < 11) {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 3);
        tmp += '-';
        tmp += str.substr(6);
        return tmp;
    } else {
        tmp += str.substr(0, 3);
        tmp += '-';
        tmp += str.substr(3, 4);
        tmp += '-';
        tmp += str.substr(7);
        return tmp;
    }

    return str;
}

//날짜 표기형식 세팅
function setDateTime(item,type,unit){
	let setDate = "";
	if(item){
		let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
		let date = new Date(basicDate),
			yy,
			mm,
			dd,
			th,
			tm,
			ts;

		yy = date.getFullYear();
		mm = date.getMonth() + 1 <= 9 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
		dd = date.getDate() <= 9 ? "0" + date.getDate() : date.getDate();
		th = date.getHours() <= 9 ? "0" + date.getHours() : date.getHours();
		tm = date.getMinutes() <= 9 ? "0" + date.getMinutes() : date.getMinutes();
		ts = date.getSeconds() <= 9 ? "0" + date.getSeconds() : date.getSeconds();

		if(type == "yymmdd"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일";
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd;
			}else{
				setDate = yy + "." + mm + "." + dd;
			}
		}else if(type == "yymmddhhtt"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm;
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm;
			}
		}else if(type == "yymmddhhttss"){
			if(unit == "ymd"){
				setDate = yy + "년 " + mm + "월 " + dd + "일 " + th + ":" + tm + ":" + ts;
			}else if(unit == "hymd"){
				setDate = yy + "-" + mm + "-" + dd + " " + th + ":" + tm + ":" + ts;
			}else{
				setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
			}
		}else if(type == "hhttss"){
			setDate = th + ":" + tm + ":" + ts;
		}else if(type == "hhtt"){
			setDate = th + ":" + tm;
		}else{
			setDate = yy + "." + mm + "." + dd + " " + th + ":" + tm + ":" + ts;
		}
	}
	return setDate;
}

//날짜 요일 세팅
function setDateWeek(item){
    const weekArr = ["일","월","화","수","목","금","토"];
	if(item){
		let basicDate = String(item).indexOf("-") > 0 || String(item).indexOf(".") > 0 ? String(item).replace(/-\./g,"/") : item;
		let date = new Date(basicDate)
		let setWeek = weekArr[date.getDay()];

		return setWeek;
	}else{
		return "";
	}
}

//날짜 차이
function getDateGap(start,end,type){
	let startDate = new Date(start.replace(/\.-/g,"/")),
		endDate = new Date(end.replace(/\.-/g,"/"));

	let dateGap = endDate.getTime() - startDate.getTime();
	let timeGap = new Date(0, 0, 0, 0, 0, 0, endDate - startDate);

	let setDay = Math.floor(dateGap / (1000 * 60 * 60 * 24));   
	let setH = timeGap.getHours();
	let setM  = timeGap.getMinutes();

	let setting = type == "day" ? setDay + 1 : `${setDay + 1}일 ${setH}시간 ${setM}분`;

	return setting;
}

//url 파라미터
function getParam(sname) {
	var params = window.location.search.substr(window.location.search.indexOf("?") + 1);
	var sval = "";
	params = params.split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

//url 파라미터 구글 로그인용
function getParam_google(sname) {
	var params = window.location.search.substr(window.location.search.indexOf("?") + 1);
	var sval = "";
	params = window.location.href.split("#")[1].split("&");
	for (var i = 0; i < params.length; i++) {
		let temp = params[i].split("=");
		if ([temp[0]] == sname) {
			sval = temp[1];
		}
	}
	return sval;
}

//스크롤 하단 체크
function bottomChk(){
    let scrollTop = document.documentElement.scrollTop,
		innerHeight = window.innerHeight,
		scrollHeight = document.querySelectorAll("body")[0].scrollHeight;

    if (Math.ceil(scrollTop + innerHeight) + 1 >= scrollHeight) {
        return true;
    } else {
        return false;
    }
}

//토글 슬라이드
function slideUp(target, duration){
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.boxSizing = "border-box";
	target.style.height = target.offsetHeight + "px";
	target.style.overflow = "hidden";
	window.setTimeout(() => {
		target.style.height = 0;
		target.style.paddingTop = 0;
		target.style.paddingBottom = 0;
		target.style.marginTop = 0;
		target.style.marginBottom = 0;
	}, 10);
	window.setTimeout(() => {
		target.style.display = "none";
		target.style.removeProperty("height");
		target.style.removeProperty("padding-top");
		target.style.removeProperty("padding-bottom");
		target.style.removeProperty("margin-top");
		target.style.removeProperty("margin-bottom");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideDown(target, duration, type){
	target.style.removeProperty("display");
	let display = window.getComputedStyle(target).display;

	if (display === "none") display = type == "flex" ? "flex" : "block";

	target.style.display = display;
	let height = target.offsetHeight;
	target.style.overflow = "hidden";
	target.style.height = 0;
	target.style.paddingTop = 0;
	target.style.paddingBottom = 0;
	target.style.marginTop = 0;
	target.style.marginBottom = 0;
	target.style.height = target.offsetHeight + "px";
	target.style.boxSizing = "border-box";
	target.style.transitionProperty = "height, margin, padding";
	target.style.transitionDuration = duration + "ms";
	target.style.height = height + "px";
	target.style.removeProperty("padding-top");
	target.style.removeProperty("padding-bottom");
	target.style.removeProperty("margin-top");
	target.style.removeProperty("margin-bottom");
	window.setTimeout(() => {
		target.style.removeProperty("height");
		target.style.removeProperty("overflow");
		target.style.removeProperty("transition-duration");
		target.style.removeProperty("transition-property");
	}, duration);
}
function slideToggle(target, type){
  if (window.getComputedStyle(target).display === "none") {
    return slideDown(target, 300, type);
  } else {
    return slideUp(target, 300);
  }
}

//스크롤 이동
function moveScroll(item,addTop){
	let scrollItem = document.querySelectorAll(item)[0].offsetTop;
	window.scrollTo({left: 0, top: addTop ? scrollItem - addTop : scrollItem, behavior: "smooth"});
}

//스크롤 제한(팝업용)
function bodyScroll_limit(){
	document.querySelectorAll("body")[0].classList.add("active");
}
function bodyScroll_clear(){
	document.querySelectorAll("body")[0].classList.remove("active");
}

//천단위 콤마 포멧
function comFormat(number){
	let data = Number(number);

	if (data == 0) return 0;
	var reg = /(^[+-]?\d+)(\d{3})/;
	var n = (data + '');
	while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');
	return n;
}

//dday 체크
function dDay(data){
	const now = new Date();
	const use_date = new Date(data.replace(/-/g,"/"));

	const setDate = use_date - now;

	const day = Math.floor(setDate / (1000 * 60 * 60 * 24));

	return day;
}

//평일,주말 체크
function holydayApi(){
	fatchSet.FatchApi({
		type:"GET",
		url: "/holyday",
		loginType:"login",
		success: (data) => {
			let holyday = data.data.holy_days;
			let nowDate = new Date(),
				nowDateSet = new Date(setDateTime(nowDate,"yymmdd"));
			localStorage.setItem("holyday",holyday);
			localStorage.setItem("holydayDate",nowDateSet);
		},
		err: (data) => {
			
		}
	})
}

function holydaySet(){
	let nowDate = new Date(),
		nowDateSet = new Date(setDateTime(nowDate,"yymmdd"));
	if(localStorage.getItem("holyday")){
		if(localStorage.getItem("holydayDate") && new Date(localStorage.getItem("holydayDate")).getTime() < nowDateSet.getTime()){
			holydayApi();
		}
	}else{
		holydayApi();
	}
}
holydaySet();

function getWeekCount(start,end) {
    let startDate = new Date(start.replace(/-/g,"/")),
     	endDate = new Date(end.replace(/-/g,"/"));

    let weekdayCount = 0,
		weekendCount = 0;

	while(true) {
		let setStartDate = startDate;				
		if(setStartDate.getTime() > endDate.getTime()) {
			return [weekdayCount,weekendCount];
		} else {
			let tmp = setStartDate.getDay();
			if(tmp == 0 || tmp == 6 || tmp == 5 || (localStorage.getItem("holyday") && localStorage.getItem("holyday").indexOf(setDateTime(startDate,"yymmdd","hymd")) !== -1)) {//주말에 금요일 제외 필요하면 tmp == 5 추가
				weekendCount++;
			} else {
				weekdayCount++;         
			}
			setStartDate.setDate(startDate.getDate() + 1); 
		}
	}
}

//스타일 추출
function getStyle(el, prop, type){
	let style;
    if(el.currentStyle){
        style = el.currentStyle[prop];
    }else if(window.getComputedStyle){
        style = type == "number" ? window.getComputedStyle(el,null)[prop].replace(/[a-zA-Z#?!@$ %^&*-]/gi,"") : window.getComputedStyle(el,null)[prop];
    }
    return style;
}

//로그아웃
function logout(){
	localStorage.removeItem("token");
	localStorage.removeItem("refresh_token");
}

//앱 토큰 저장
function tokenSave(token){
	let varUA = navigator.userAgent;
	if(varUA.match('chadugo/Android') != null){
		window.chadugo.postMessage(JSON.stringify({ type: 'autoLogin', token: token }));
	}else if(varUA.match('chadugo/iOS') != null){
		window.webkit.messageHandlers.autoLogin.postMessage(token);
	}
}

//결제 수단 값 리턴
function payTypeText(type){
	let setTxt="";
	if(type == "card"){
		setTxt = "신용카드"
	}
	if(type == "bank"){
		setTxt = "계좌이체"
	}
	if(type == "phone"){
		setTxt = "휴대폰 결제"
	}
	if(type == "npay" || type == "naver"){
		setTxt = "네이버 페이"
	}
	if(type == "kakao"){
		setTxt = "카카오 페이"
	}

	return setTxt;
}

export {payTypeText, PhoneSetting, bottomChk, slideUp, slideDown, slideToggle, getParam, getParam_google, comFormat, dDay, tokenSave, setDateTime, moveScroll, getDateGap, setDateWeek, getStyle, getWeekCount, bodyScroll_limit, bodyScroll_clear,logout};