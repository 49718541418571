import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import { PhoneSetting, getDateGap, payTypeText, setDateTime, setDateWeek } from "js/function";
import { comFormat } from "js/function";
import { Header } from "component/elements/header";
import { OrderComHead, OrderComInfoText, OrderComSection, OrderComTable, OrderComTableItem } from "component/app/order";
import MiddleBanner from "component/banner/banner";
import { Footer } from "component/elements/footer";
import { MypageDetailOrderLink, MypageDetailTitle } from "component/mypage/mypage";
import { InfoText } from "component/app/subItems";
import { ErrPopup, RightPopup } from "component/basic/popup";

const ReservationDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;
    const type = param.type;

    const [write,setWrite] =  useState("");
    const [code,setCode] =  useState(null);
    const [errPopupData,setErrPopupData] = useState(null);

    const orderApi = useGet({
        url:`/mypage/service/detail?id=${id}&type=${type}`,
        loginType:"login"
    });
    const orderData = orderApi?.data?.service;

    useEffect(() => {
        setCode(orderData?.code);
    }, [orderData]);

    return (
        <>
        <div className="myDetailPage headerInPage pageSizing">
            <Header
                pageBack={true}
                headTitle="서비스 예약 내역"
                func={() => {navigate(-1)}}
            />
            <div className="myDetailPage_contents">
                <MypageDetailTitle
                    title="서비스 예약 내역"
                    subNumber={code}
                    pcType={true}
                />
                <OrderComSection title="신청 내용">
                    <OrderComTable>
                        <OrderComTableItem
                            title="서비스 기간"
                            text={`${orderData?.start_date && orderData?.start_date !== "" ? `${setDateTime(orderData?.start_date,"yymmdd")} (${setDateWeek(orderData?.start_date?.split(" ")[0])}) ${setDateTime(orderData?.start_date,"hhtt") !== "00:00" ? setDateTime(orderData?.start_date,"hhtt") : ""}`:""}
                            ${orderData?.start_date && orderData?.end_date && orderData?.end_date !== "0000-00-00 00:00:00" ? " - ":""}
                            ${orderData?.end_date && orderData?.end_date !== "" && orderData?.end_date !== "0000-00-00 00:00:00" ? `${setDateTime(orderData?.end_date,"yymmdd")} (${setDateWeek(orderData?.end_date?.split(" ")[0])}) ${setDateTime(orderData?.end_date,"hhtt") !== "00:00" ? setDateTime(orderData?.end_date,"hhtt") : ""}`:""}
                            `}
                        />
                        <OrderComTableItem
                            title="차량 정보"
                            text={orderData?.car_name ? `${orderData?.car_name} ${orderData?.car_number}` : "-"}
                        />
                        {orderData?.region == "김포" || orderData?.region == "김해" ?
                        <OrderComTableItem
                            title="(지역) 출장지"
                            text={`${orderData?.region} 공항`}
                        />:""}
                        <OrderComTableItem
                            title="연락처"
                            text={orderData?.phone ? PhoneSetting(orderData?.phone) : "-"}
                        />
                        <OrderComTableItem
                            title="특이사항"
                            text={orderData?.contents ? orderData?.contents : "-"}
                        />
                    </OrderComTable>
                </OrderComSection>
                {type == "service" && orderData?.storage_price > 0 ? 
                <OrderComSection title="공항 주차" subTitle={orderData?.status == 1 ? "예약 완료" : "예약 취소"} subTitleAddClass={orderData?.status == 1 ? "wbColor" : "rColor"}>
                    <OrderComTable>
                        <OrderComTableItem
                            title="기본 요금"
                            text={orderData?.storage_price ? `${comFormat(orderData?.storage_price)} 원` : "0 원"}
                        />
                        {orderData?.pickup_price > 0 ? 
                            <OrderComTableItem
                                title="픽업 서비스"
                                text={orderData?.pickup_price ? `${comFormat(orderData?.pickup_price)} 원` : "0 원"}
                            />
                        :""}
                    </OrderComTable>
                </OrderComSection>
                :""}
                {type == "service" && orderData?.region == "제주" ? 
                <>{orderData?.options.filter((el) => el.category == "engine_oil").length > 0 ? 
                    <OrderComSection title="엔진 오일" subTitle={orderData?.status == 1 ? "예약 완료" : "예약 취소"} subTitleAddClass={orderData?.status == 1 ? "wbColor" : "rColor"}>
                        <OrderComTable>
                            {orderData?.options.filter((el) => el.category == "engine_oil").map((item,i)=>(
                                <OrderComTableItem
                                    key={i}
                                    title={item.option_name ? item.option_name : "-"}
                                    text={item.price ? `${comFormat(item.price)} 원` : "0 원"}
                                />
                            ))}
                        </OrderComTable>
                    </OrderComSection>
                :""}</>
                :
                <>{orderData?.options.length > 0 ? 
                    <OrderComSection title="출장 정비" subTitle={orderData?.status == 1 ? "예약 완료" : "예약 취소"} subTitleAddClass={orderData?.status == 1 ? "wbColor" : "rColor"}>
                        <OrderComTable>
                            {orderData?.options.map((item,i)=>(
                                <OrderComTableItem
                                    key={i}
                                    title={item.option_name ? item.option_name : "-"}
                                    text={item.price ? `${comFormat(item.price)} 원` : "0 원"}
                                />
                            ))}
                        </OrderComTable>
                    </OrderComSection>
                :""}
                </>}
                {orderData?.wash ? 
                <OrderComSection title="세차" subTitle={orderData?.status == 1 ? "예약 완료" : "예약 취소"} subTitleAddClass={orderData?.status == 1 ? "wbColor" : "rColor"}>
                    <OrderComTable>
                        <OrderComTableItem
                            title={orderData?.wash.is_wax == 1 ? "고체왁스(코팅) 추가" : "기본 세차"}
                            text={orderData?.wash.is_wax == 1 ? `${comFormat(Number(orderData?.wash.price) + Number(orderData?.wash.add_price))} 원` : `${comFormat(orderData?.wash.price)}원`}
                        />
                    </OrderComTable>
                </OrderComSection>
                :""}
                {orderData?.inspection ? 
                <OrderComSection title="차량 검사" subTitle={orderData?.status == 1 ? "예약 완료" : "예약 취소"} subTitleAddClass={orderData?.status == 1 ? "wbColor" : "rColor"}>
                    <OrderComTable>
                        <OrderComTableItem
                            title="검사 비용"
                            text={orderData?.inspection?.price ? `${comFormat(orderData?.inspection?.price)} 원` : "0 원"}
                        />
                    </OrderComTable>
                </OrderComSection>
                :""}
                {orderData?.is_manage == 1 ? 
                <OrderComSection title="관리 서비스" subTitle={orderData?.manage_status == 3 ? "완료" : orderData?.manage_status == 1 ? "예약 완료" : orderData?.manage_status == 11 ? "예약 취소" : orderData?.manage_status == 2 ? "견적 접수" : orderData?.manage_status == 20 ? "견적 도착" : orderData?.manage_status == 21 ? "견적 취소" : ""} subTitleAddClass={orderData?.manage_status == 1 ? "wbColor" : orderData?.manage_status == 2 ? "" : orderData?.manage_status == 20 ? "gColor" : orderData?.manage_status == 21 || orderData?.manage_status == 11 ? "rColor" : ""}>
                    <MypageDetailOrderLink text={orderData?.manage_status == 3 ? "서비스 이용이 완료 되었습니다." : orderData?.manage_status == 1 ? "예약이 완료 되었습니다." : orderData?.manage_status == 11 ? "예약이 취소되었습니다." : orderData?.manage_status == 2 ? "견적서가 아직 도착하지 않았습니다." : orderData?.manage_status == 20 ? "견적이 도착했어요.<br/>지금 바로 견적을 확인하세요!" : orderData?.manage_status == 21 ? "견적이 취소되었습니다." : ""} link={orderData?.manage_status == 20 || orderData?.manage_status == 1 || orderData?.manage_status == 3 ? true : false} func={()=>{navigate(`/mypage/order/detail/${orderData?.id}/manage_service`)}}/>
                </OrderComSection>
                : ""}
                {orderData?.is_repair == 1 ? 
                <OrderComSection title="외관 수리" subTitle={orderData?.repair_status == 3 ? "완료" : orderData?.repair_status == 1 ? "예약 완료" : orderData?.repair_status == 11 ? "예약 취소" : orderData?.repair_status == 2 ? "견적 접수" : orderData?.repair_status == 20 ? "견적 도착" : orderData?.repair_status == 21 ? "견적 취소" : ""} subTitleAddClass={orderData?.repair_status == 1 ? "wbColor" : orderData?.repair_status == 2 ? "" : orderData?.repair_status == 20 ? "gColor" : orderData?.repair_status == 21 || orderData?.repair_status == 11 ? "rColor" : ""}>
                    <MypageDetailOrderLink text={orderData?.repair_status == 3 ? "서비스 이용이 되었습니다." : orderData?.repair_status == 1 ? "예약이 완료 되었습니다." : orderData?.repair_status == 11 ? "예약이 취소되었습니다." : orderData?.repair_status == 2 ? "견적서가 아직 도착하지 않았습니다." : orderData?.repair_status == 20 ? "견적이 도착했어요.<br/>지금 바로 견적을 확인하세요!" : orderData?.repair_status == 21 ? "견적이 취소되었습니다." : ""} link={orderData?.repair_status == 20 || orderData?.repair_status == 1 || orderData?.repair_status == 3 ? true : false} func={()=>{navigate(`/mypage/order/detail/${orderData?.id}/repair_service`)}}/>
                </OrderComSection>
                :""}
                {orderData?.total_price == 0 && orderData?.discount_price == 0 ? "" :
                <OrderComSection title="결제 정보">
                    <OrderComTable>
                        <OrderComTableItem
                            title="결제 수단 / 결제 일시"
                            text={`${orderData?.payment_method ? payTypeText(orderData?.payment_method) : "-"} / ${orderData?.purchase_date ? setDateTime(orderData?.purchase_date,"yymmddhhttss") : "-"}`}
                        />
                        <OrderComTableItem
                            title="총 서비스 요금"
                            text={orderData?.original_price ? `${comFormat(orderData?.original_price)} 원` : "0 원"}
                        />
                        <OrderComTableItem
                            title="사용 포인트"
                            text={orderData?.use_point ? `${comFormat(orderData?.use_point)} P` : "0 P"}
                        />
                        <OrderComTableItem
                            title="총 할인가"
                            text={orderData?.discount_price ? `${comFormat(orderData?.discount_price)} 원` : "0 원"}
                        />
                        <OrderComTableItem
                            title="총 결제 금액"
                            text={orderData?.total_price ? `${comFormat(orderData?.total_price)} 원` : "0 원"}
                        />
                        <OrderComTableItem
                            title="적립 포인트"
                            text={orderData?.add_point ? `${comFormat(orderData?.add_point)} P` : "0 P"}
                        />
                    </OrderComTable>
                </OrderComSection>}
                <div className="orderComSection_col2">
                    {orderData?.is_manage == 1 && (orderData?.manage_status == 1) ? 
                    <OrderComSection title="관리 서비스 결제 정보">
                        <OrderComTable>
                            <OrderComTableItem
                                title="결제 수단 / 결제 일시"
                                text={`${orderData?.manage?.payment_method ? payTypeText(orderData?.manage?.payment_method) : "-"} / ${orderData?.manage?.purchase_date ? setDateTime(orderData?.manage?.purchase_date,"yymmddhhttss") : "-"}`}
                            />
                            <OrderComTableItem
                                title="서비스 요금"
                                text={orderData?.manage?.manage_price ? `${comFormat(orderData?.manage?.manage_price)} 원` : "0 원"}
                            />
                            <OrderComTableItem
                                title="사용 포인트"
                                text={orderData?.manage?.use_point ? `${comFormat(orderData?.manage?.use_point)} P` : "0 P"}
                            />
                            <OrderComTableItem
                                title="총 할인가"
                                text={orderData?.manage?.discount_price ? `${comFormat(orderData?.manage?.discount_price)} 원` : "0 원"}
                            />
                            <OrderComTableItem
                                title="총 결제 금액"
                                text={orderData?.manage?.total_price ? `${comFormat(orderData?.manage?.total_price)} 원` : "0 원"}
                            />
                            <OrderComTableItem
                                title="적립 포인트"
                                text={orderData?.manage?.add_point ? `${comFormat(orderData?.manage?.add_point)} P` : "0 P"}
                            />
                        </OrderComTable>
                    </OrderComSection>
                    :""}
                    {orderData?.is_repair == 1 && (orderData?.repair_status == 1) ? 
                    <OrderComSection title="외관 수리 결제 정보">
                        <OrderComTable>
                            <OrderComTableItem
                                title="결제 수단"
                                text="현장 결제"
                            />
                            <OrderComTableItem
                                title="서비스 요금"
                                text={orderData?.repair?.repair_price ? `${comFormat(orderData?.repair?.repair_price)} 원` : "0 원"}
                            />
                            <OrderComTableItem
                                title="총 결제 금액"
                                text={orderData?.repair?.total_price ? `${comFormat(orderData?.repair?.total_price)} 원` : "0 원"}
                            />
                        </OrderComTable>
                    </OrderComSection>
                    :""}
                </div>
                {orderData?.status !== 10 ? 
                <BtnBox
                    btnAddClass="line"
                    addClass="pageInType pcMinBtn"
                    text={"예약 취소 요청"}
                    func={()=>{setWrite("open")}}
                />:""}
                <div className={`payInfoTextBox ${orderData?.status !== 10 ? "" : "notLine"}`}>
                    <InfoText listType="-" text="예약일 변경 또는 취소 요청의 경우 입고일 기준 3일 전 신청 건만 가능합니다. "/>
                    <InfoText listType="-" text="입고일 기준 2일~당일 요청 건은 변경 또는 환불이 불가능 하오니 유의해 주시기 바랍니다."/>
                </div>
            </div>
        </div>
        <Footer rightOpenFunc={(id) => props.rightOpenFunc(id)}/>
        <ErrPopup
            data={errPopupData}
        />
        <RightPopup
            addClass="minVar"
            text={write}
            topTitle="1:1 문의 작성"
            cancelType={true}
            cancelNumber={code}
            cancelId={id}
            closePopup={() => {setWrite("")}}
            writePopup={true}
            addFunc={()=>{navigate(-1)}}
        />
        </>
    );
};

export default ReservationDetail;