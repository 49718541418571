import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MemberBox from "component/member/memberBox";
import { BtnBox } from "component/basic/btns";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import { BottomErrMsg, ErrPopup } from "component/basic/popup";
import { InputItemBox } from "component/basic/formItems";
import { logout } from "js/function";

const EditProfilePw = (props) => {
    const navigate = useNavigate();

    const [btnChk,setBtnChk] =  useState(true);
    
    const [oldPw,setOldPw] =  useState("");
    const [pw,setPw] =  useState("");
    const [pwChk,setPwChk] =  useState("");

    const [errPopupData,setErrPopupData] =  useState(null);

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [pwChkErr,setPwChkErr] =  useState(null);
    const [pwChkErrOld,setPwChkErrOld] =  useState(null);

    function pwChkFunc(){
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/;

        if(!pwType.test(oldPw)){
            setPwChkErrOld("영문, 숫자, 특수문자 조합 6~20자로 입력해주세요.");
        }else if(!pwType.test(pw)){
            setPwChkErr("영문, 숫자, 특수문자 조합 6~20자로 입력해주세요.");
        }else{
            let formData = new FormData();
            formData.append("password", oldPw);
            formData.append("new_password", pw);
            formData.append("new_password_check", pwChk);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: "/mypage/change/password",
                loginType:"login",
                success: (data) => {
                    setTimeout(() => {
                        logout();props.logOut();navigate("/member/login")
                    }, 2000);

                    setErrPopupData({
                        addClass:null,
                        text:"비밀번호 변경이 완료되었습니다.<br/>바뀐 비밀번호로 다시 로그인해 주세요.",
                        closeType:false,
                        errBtnText:"로그인 하러가기",
                        closeFunc:(e) => {logout();props.logOut();navigate("/member/login")},
                        btnFunc:(e)=>{logout();props.logOut();navigate("/member/login")}
                    });
                },
                err: (data) => {
                    if(data.alert){
                        setErrPopupData({
                            addClass:null,
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc:(e)=>{}
                        });
                    }
                }
            })
        }
    }

    useEffect(() => {
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/;
        if(pwType.test(oldPw)){
            setPwChkErrOld(null)
        }
    }, [oldPw]);

    useEffect(() => {
        if(pwChk !== ""){
            if(pw == pwChk){
                setPwChkErr(null)
            }else{
                setPwChkErr("비밀번호와 일치하지 않습니다.")
            }
        }
    }, [pw,pwChk]);

    useEffect(() => {
        if(pw !== "" && pwChk !== "" && pw == pwChk && oldPw !== ""){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [pw,pwChk,oldPw]);

    return (
        <div className="memberPage headerInPage pageSizing btnInPage">
            <Header
                pageBack={true}
                headTitle="비밀번호 변경"
                func={() => navigate(-1)}
            />
            <MemberBox title="비밀번호 변경" pcChk={true} backFunc={()=>{navigate(-1)}}>
                <div className="memberInput_section joinInput_section">
                    <InputItemBox
                        type="pw"
                        inputName="현재 비밀번호"
                        placeholder="현재 비밀번호"
                        value={oldPw}
                        max={20}
                        regexp={/[^a-zA-Z0-9#?!@$ %^&*-]/gi}
                        func={(e) => {setOldPw(e)}}
                        errMsg={pwChkErrOld}
                        addClass={pwChkErrOld ? "err" : ""}
                    />
                    <InputItemBox
                        type="pw"
                        inputName="새 비밀번호"
                        placeholder="새 비밀번호"
                        value={pw}
                        max={20}
                        regexp={/[^a-zA-Z0-9#?!@$ %^&*-]/gi}
                        func={(e) => {setPw(e)}}
                        addClass={pwChkErr ? "err" : ""}
                    />
                    <InputItemBox
                        type="pw"
                        inputName="새 비밀번호 확인"
                        placeholder="새 비밀번호 확인"
                        value={pwChk}
                        max={20}
                        regexp={/[^a-zA-Z0-9#?!@$ %^&*-]/gi}
                        func={(e) => {setPwChk(e)}}
                        caption={pwChkErr ? null : "영문, 숫자, 특수문자 조합 6~20자"}
                        errMsg={pwChkErr}
                        addClass={pwChkErr ? "err" : ""}
                    />
                </div>
                <BtnBox
                    addClass="pageInType mChType"
                    text="완료"
                    disabled={btnChk}
                    func={()=>{pwChkFunc()}}
                />
            </MemberBox>
            <ErrPopup
                data={errPopupData}
            />
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </div>
    );
};

export default EditProfilePw;