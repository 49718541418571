import React from "react";
import styled from "styled-components";
import { ReactComponent as Icon0 } from "../../assets/icons/main_button_0.svg";
import { ReactComponent as Icon1 } from "../../assets/icons/main_button_1.svg";
import { ReactComponent as Icon2 } from "../../assets/icons/main_button_2.svg";

function MainButton({ handleClick }) {
  return (
    <Wrapper>
      <Main onClick={() => handleClick("공항 주차 지역 선택")}>
        <Icon0 className="icon_0" />
        <h1>공항 주차</h1>
        <p>제주/김포 공항 주차</p>
      </Main>
      <div className="box">
        <Main onClick={() => handleClick("차량 정비 지역 선택")}>
          <Icon1 />
          <h1>차량 정비</h1>
          <p>제주서비스</p>
        </Main>
        <Main onClick={() => handleClick("렌터카 차량 조회")}>
          <Icon2 />
          <h1>렌터카</h1>
          <p>간편하고 빠른 렌트 서비스</p>
        </Main>
      </div>
    </Wrapper>
  );
}

export default MainButton;
const Wrapper = styled.div`
  width: 100%;
  gap: 20px;
  display: flex;

  & > div.box {
    width: calc(50% - 10px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    & > div {
      width: 100%;
    }
  }
  @media (max-width: 1024px) {
    height: auto;
    & > div.box {
      flex-direction: row;
      width: calc(66.66%);
      & > div {
        height: 310px;
      }
    }
  }
  @media (max-width: 720px) {
    & > div.box {
      flex-direction: column;
      width: calc(50% - 10px);
      height: 19.375em;
      & > div {
        height: 11.25em;
      }
    }
  }
`;
const Main = styled.div`
  width: calc(50% - 10px);
  border: 1px solid #d1d1d1;
  border-radius: 22px;
  padding: 1.875em 1.5em;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 1;
    letter-spacing: -2%;
    text-align: center;
    color: #1e1e1e;
  }
  & p {
    font-weight: 500;
    font-size: 13px;
    line-height: 18.2px;
    letter-spacing: -2%;
    text-align: center;
    color: #7e7e7e;
    margin-top: 10px;
  }
  & svg {
    margin: 0 0 1.125em;
  }
  @media (max-width: 1024px) {
    width: calc(33.33% - 13.33px);
    height: 310px;
    & svg {
      width: 130px;
      height: 130px;
    }
  }
  @media (max-width: 720px) {
    width: calc(50% - 5px);
    height: 310px;
    & svg.icon_0 {
      width: 90px;
      height: 90px;
    }
    & svg {
      width: 54px;
      min-width: 54px;
      height: 54px;
      min-height: 54px;
    }
    & h1 {
      font-size: 16px;
    }
    & p {
      font-size: 12px;
    }
  }
`;
