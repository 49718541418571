//기본 버튼
function BtnBox(data){
    return (
        <div className={`pageBtn_box ${data.addClass ? data.addClass : ""}`}>
            {data.textType ? 
                <>{
                    <>
                    <p className={`pageBtn textType ${data.textAddClass ? data.textAddClass : ""}`} dangerouslySetInnerHTML={{__html:data.textTypeText}}></p>
                    {data.btnAdd ? <button type="button" className={`pageBtn ${data.btnAddClass ? data.btnAddClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.text}}></button> : ""}
                    </>
                }</>
                :
                <>
                {data.children}
                <button type="button" className={`pageBtn ${data.btnAddClass ? data.btnAddClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.text}}></button>
                </>
            }
        </div>
    );
}
//2중 버튼
function BtnBoxV2(data){
    return (
        <div className={`pageBtn_box ${data.addClass ? data.addClass : ""}`}>
            {data.children}
            <button type="button" className={`pageBtn ${data.leftAddClass ? data.leftAddClass : ""}`} onClick={() => data.leftFunc()} disabled={data.leftDisabled ? data.leftDisabled : false} dangerouslySetInnerHTML={{__html:data.leftText}}></button>
            <button type="button" className={`pageBtn ${data.rightAddClass ? data.rightAddClass : ""}`} onClick={() => data.rightFunc()} disabled={data.rightDisabled ? data.rightDisabled : false} dangerouslySetInnerHTML={{__html:data.rightText}}></button>
        </div>
    );
}

//팝업버튼
function PopupBtnBox(data){
    return (
        <div className={`popupBtn_box ${data.addClass ? data.addClass : ""}`}>
            {data.children}
            <button type="button" className={`popupBtnBtn ${data.btnAddClass ? data.btnAddClass : ""}`} onClick={() => data.func()} disabled={data.disabled ? data.disabled : false} dangerouslySetInnerHTML={{__html:data.text}}></button>
            {data.textSub ? 
                <button type="button" className={`popupBtnBtn ${data.btnAddClassSub ? data.btnAddClassSub : ""}`} onClick={() => data.funcSub()} disabled={data.disabledSub ? data.disabledSub : false} dangerouslySetInnerHTML={{__html:data.textSub}}></button>
                :""
            }
        </div>
    );
}

//버튼 상단 텍스트
function BtnText(data){
    return (
        <p className={`btnTextType ${data.addClass ? data.addClass : ""}`} dangerouslySetInnerHTML={{__html:data.text}}></p>
    );
}

export {BtnBox, BtnBoxV2, PopupBtnBox, BtnText};