import { MypageTableState } from "component/mypage/mypageTable";
import { setDateWeek } from "js/function";

function OrderSeciton(data){
    return(
        <div className={`orderPageSection ${data.addClass ? data.addClass : ""}`}>
            <h1 className="orderPageTitle" dangerouslySetInnerHTML={{__html:data.title}}></h1>
            {data.children}
        </div>
    );
}

function OrderTimeArea(data){
    return(
        <div className="orderTimeArea">
            <p className="orderTimeTitle">{data.title}</p>
            <p className="orderData">{data.date} ({setDateWeek(data.date)})</p>
            {data.time ? <p className="orderTime">{data.time}</p> : ""}
        </div>
    );
}

function OrderCarData(data){
    const imgData = {
        car:"/assets/images/icon/order_type_0.svg",
        addr:"/assets/images/icon/order_type_1.svg",
        carV2:"/assets/images/icon/order_type_2.svg",
        phone:"/assets/images/icon/order_type_3.svg",
        time:"/assets/images/icon/order_type_4.svg",
        insurance:"/assets/images/icon/order_type_5.svg",
        memo:"/assets/images/icon/order_type_6.svg",
        birth:"/assets/images/icon/order_type_7.svg",
        name:"/assets/images/icon/order_type_8.svg"
    }

    return(
        <div className="orderCarDataArea">
            <div className="orderCarDataItem">
                <img src={imgData[data.imgKey]}/>
                <p className="orderCarDataTitle">{data.title}</p>
            </div>
            <p className="orderCarData_text">{data.text}</p>
        </div>
    );
}

function OrderOptionArea(data){
    return(
    <div className="orderOptionArea">
        <div className="orderOptionBox">
            <h2 className="orderOption_name">{data.name}</h2>
            <div className="orderOptionItem">{data.children}</div>
        </div>
        {data.del ? 
        <button type="button" onClick={()=>{data.func()}} className="orderOptionDel"><img src="/assets/images/basic/del.svg"/></button>
        :""}
    </div>
    );
}

function OrderNotPrice(data){
    return(
        <p className="serviceInfo_notPrice">{data.text}</p>
    );
}

function OrderPriceItem(data){
    return(
        <div className="orderPriceItem">
            <h3 className="orderPriceItem_name">{data.name}</h3>
            <p className="orderPriceItem_text">{data.type == "upText" ? <span>{data.text}</span> : data.text}</p>
        </div>
    );
}

function OrderPriceSubItem(data){
    return(
        <div className="orderPriceSubItem">
            {data.children}
        </div>
    );
}

function OrderPayItem(data){
    return(
        <button type="button" onClick={()=>{data.func()}} className={`btnPayItem ${data.setVal == data.val ? "active" : ""}`}>
            {data.type == "kakao" ? 
            <img src="/assets/images/sns/kakao_order.svg"/>
            : data.type == "naver" ? 
            <img src="/assets/images/sns/naver_order.svg"/> : data.text}
        </button>
    );
}

function OrderComHead(data){
    return(
        <div className="orderComHead">
            <img src="/assets/images/img/orderCom.svg"/>
            <h1 className="orderComHead_title">{data.type == "repair" ? "외관수리 예약이 완료 되었습니다!" : data.price && data.price !== 0 ? "결제가 완료되었습니다." : "서비스 신청이 완료되었습니다."}</h1>
            {data.type !== "repair" && data.type !== "management" ? <p className="orderComHead_number">예약 번호<span>{data.number}</span></p> : ""}
        </div>
    );
}
function OrderComSection(data){
    return(
        <div className="orderComSection">
            <h1 className="orderComSection_title">
                {data.subTitle ? <MypageTableState text={data.subTitle} addClass={data.subTitleAddClass}/> : ""}{data.title}</h1>
            <div className="orderComSection_contents">
                {data.children}
            </div>
        </div>
    );
}
function OrderComTable(data){
    return(
        <div className="orderComTable">
            {data.children}
        </div>
    );
}
function OrderComTableItem(data){
    return(
        <div className="orderComTableItem">
            <h2 className="orderComTableItem_name">{data.title}</h2>
            {data.link ? 
            <div className="orderComTableItem_text">
                <button type="button" onClick={()=>{data.linkFunc()}} className="orderComTableItem_textLink" dangerouslySetInnerHTML={{__html:data.text}}/>
            </div>
            :<div className="orderComTableItem_text" dangerouslySetInnerHTML={{__html:data.text}}/>
            }
        </div>
    );
}
function OrderComInfoText(data){
    return(
        <div className="orderComInfoTextBox">
            <p className="orderComInfoText" dangerouslySetInnerHTML={{__html:data.text}}></p>
        </div>
    );
}

export {OrderSeciton,OrderTimeArea,OrderCarData,OrderOptionArea,OrderNotPrice,OrderPriceItem,OrderPriceSubItem,OrderPayItem,OrderComHead,OrderComSection,OrderComTable,OrderComTableItem,OrderComInfoText};