import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { SevicePageTitle } from "component/app/subItems";
import FixedSection from "component/basic/fixedBox";
import useGet from "api/useGet";
import { Header } from "component/elements/header";
import { AlignBox, CategoryBox } from "component/basic/category";
import { NotItems } from "component/basic/notItems";
import { comFormat, getDateGap } from "js/function";
import { setDateWeek } from "js/function";
import { useEffect } from "react";

const RentList = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const startDate = param.startDate;
    const startTime = param.startTime;
    const endDate = param.endDate;
    const endTime = param.endTime;
    const carInfo = param.carInfo;

    const [category,setCategory] = useState(carInfo);
    const [align,setAlign] = useState("hot");

    const rentData = useGet({
        url:`/rentercar?order=${align}&start_date=${startDate.replace(/\./g,"-")} ${startTime}&end_date=${endDate.replace(/\./g,"-")} ${endTime}&type=${category !=="전체" ? `${category}` : ""}`,
        loginType:"login"
    });
    const rentList = rentData?.data?.rentcars;

    const categoryArr = [
        {id:"전체",name:"전체"},
        {id:"경차",name:"경차"},
        {id:"소형",name:"소형"},
        {id:"준중형",name:"준중형"},
        {id:"중형",name:"중형"},
        {id:"고급",name:"고급"},
        {id:"RV",name:"RV"},
        {id:"승합",name:"승합"},
        {id:"수입",name:"수입"},
        {id:"전기",name:"전기"}
    ];
    const alignArr = [
        {id:"hot",name:"인기순"},
        {id:"price",name:"가격순"}
    ];

    function RentList(data){
        let sale = 100 - ((data?.data?.price / data?.data?.original_price) * 100);
        return (
        <div className="rentListItem" onClick={()=>{navigate(`/rent/detail/${data?.data?.id}/${startDate.replace(/\./g,"-")} ${startTime}/${endDate.replace(/\./g,"-")} ${endTime}`)}}>
            <div className="rentListItem_img" style={{backgroundImage:`url('${data?.data?.image_url}')`}}></div>
            <div className="rentListItem_info">
                <p className="rentListItem_name">{data?.data?.name} {data?.data?.car_year}</p>
                <p className="rentListItem_price">{sale !== 0 && sale <= 100 ? <span className="rentListItem_sale">{Math.floor(sale)}%</span> : ""}{comFormat(data?.data?.price)}원</p>
                <p className="rentListItem_basicPrice">{comFormat(data?.data?.original_price)}원</p>
                <div className="rentListItem_tag">{data?.data?.fuel_type} / {data?.data?.type} / {data?.data?.seater}인승</div>
            </div>
        </div>
        );
    }

    useEffect(()=>{
        setCategory(param.carInfo);
        setAlign("hot");
    },[param.carInfo])

    return(
        <div className="rentListPage headerInPage pageSizing footerMenuInPage">
            <Header
                pageBack={true}
                headTitle="렌터카 차량 선택"
                func={() => navigate(-1)}
                homeBtn={true}
            />
            <div className="rentListSection">
                <SevicePageTitle
                    title="렌터카 차량 선택"
                    notNavi={true}
                    addClass="notBorder"
                />
                <FixedSection boxAddClass="categoryFixedBox" addClass="categoryFixed" pageBox="rentListPage" headerChk={true}>
                    <CategoryBox
                        data={categoryArr}
                        val={category}
                        func={(id)=>{setCategory(id)}}
                    />
                </FixedSection>
                <AlignBox
                    data={alignArr}
                    val={align}
                    func={(id)=>{setAlign(id)}}
                />
                <div className="rentListArea">
                    {
                        rentList && rentList.length > 0 ? 
                        rentList.map((item,i)=>(
                            <RentList
                                key={i}
                                data={item}
                            />
                        ))
                        :
                        <NotItems text="대여 가능한 차량이 없습니다.<br/>다른 날짜나 차종을 선택해주세요."/>
                    }
                    
                </div>
                <div className="rentList_selInfo">
                    <div className="rentList_selInfoArea">
                        <img src="/assets/images/basic/calendar.svg"/>
                        <div className="rentList_selInfoBox">
                            <h1 className="rentList_selInfoDate">{startDate} ({setDateWeek(startDate)}) {startTime} - {endDate} ({setDateWeek(endDate)}) {endTime}</h1>
                            <p className="rentList_selInfoTotalTime">총 {getDateGap(startDate + " " + startTime,endDate + " " + endTime)}</p>
                        </div>
                    </div>
                    <button type="button" onClick={()=>{props.popupOpen("렌터카 차량 조회")}} className="btn_rentList_selInfoRe">다른 일정 검색<img src="/assets/images/basic/reset.svg"/></button>
                </div>
            </div>
        </div>
    );
}
export default RentList;