import AddCarInfo from "pages/member/AddCarInfo";
import IdFind from "pages/member/IdFind";
import PwFind from "pages/member/PwFind";
import Terms from "pages/member/Terms";
import StoregeGimpo from "pages/storage/Gimpo";
import StoregeGimhae from "pages/storage/Gimhae";
import StoregeJeju from "pages/storage/Jeju";
import React from "react";
import { Navigate } from "react-router-dom";
import Service from "pages/management/Service";
import Maintenance from "pages/management/Maintenance";
import RentDetail from "pages/rentInquiry/Detail";
import CsInfo from "pages/cs/Info";
import CsDetail from "pages/cs/Detail";
import StoregeOrder from "pages/storage/Order";
import ServiceOrder from "pages/management/OrderService";
import MaintenanceOrder from "pages/management/OrderMaintenance";
import RentOrder from "pages/rentInquiry/Order";
import OrderCom from "pages/order/OrderCom";
import EditProfilePhone from "pages/mypage/EditProfilePhone";
import EditProfileCar from "pages/mypage/EditProfileCar";
import MyOrderDetail from "pages/mypage/order/Detail";
import ReservationDetail from "pages/mypage/reservation/Detail";
import MyRentDetail from "pages/mypage/rent/Detail";
import OrderCancel from "pages/private/Cancel";

//로그인,비로그인 무관 페이지
export const publicRoutes = [
  {
    exact: true,
    path: "/",
    component: <Navigate to="/main" />,
  },
  {
    exact: true,
    path: "/terms/termsPrivate",
    component: <Navigate />,
  },
  {
    exact: true,
    path: "/member/addCar",
    component: <AddCarInfo />,
  },
  {
    exact: true,
    path: "/cs/guide/:page",
    component: <CsInfo />,
  },
  {
    exact: true,
    path: "/cs/detail/:type/:id",
    component: <CsDetail />,
  },
];
//비로그인 한정 페이지
export const publicRoutesOnly = [
  {
    exact: true,
    path: "/member/terms/:type",
    component: <Terms />,
  },
  {
    exact: true,
    path: "/member/idFind",
    component: <IdFind />,
  },
  {
    exact: true,
    path: "/member/pwFind",
    component: <PwFind />,
  },
];
//로그인 한정 페이지
export const privateRoutes = [
  {
    exact: true,
    path: "/storege/service/jeju/:addr",
    component: <StoregeJeju />,
  },
  {
    exact: true,
    path: "/storege/service/gimpo/:addr",
    component: <StoregeGimpo />,
  },
  {
    exact: true,
    path: "/storege/service/gimhae/:addr",
    component: <StoregeGimhae />,
  },
  {
    exact: true,
    path: "/storege/order/:type/:subType",
    component: <StoregeOrder />,
  },
  {
    exact: true,
    path: "/service",
    component: <Service />,
  },
  {
    exact: true,
    path: "/service/order/:type/:subType",
    component: <ServiceOrder />,
  },
  {
    exact: true,
    path: "/maintenance",
    component: <Maintenance />,
  },
  {
    exact: true,
    path: "/maintenance/order",
    component: <MaintenanceOrder />,
  },
  {
    exact: true,
    path: "/rent/detail/:id/:start/:end",
    component: <RentDetail />,
  },
  {
    exact: true,
    path: "/rent/order",
    component: <RentOrder />,
  },
  {
    exact: true,
    path: "/order/success/:id/:type",
    component: <OrderCom />,
  },
  {
    exact: true,
    path: "/mypage/edit/phone",
    component: <EditProfilePhone />,
  },
  {
    exact: true,
    path: "/mypage/edit/car",
    component: <EditProfileCar />,
  },
  {
    exact: true,
    path: "/mypage/order/detail/:id/:type",
    component: <MyOrderDetail />,
  },
  {
    exact: true,
    path: "/mypage/reservation/detail/:id/:type",
    component: <ReservationDetail />,
  },
  {
    exact: true,
    path: "/mypage/rent/detail/:id",
    component: <MyRentDetail />,
  },
  {
    exact: true,
    path: "/result/cancel/:type",
    component: <OrderCancel />,
  },
];
