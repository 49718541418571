import { useState } from "react";
import { useNavigate } from "react-router-dom";
import termsData from "data/terms.json";
import useGet from "api/useGet";

//footer 메뉴
function FooterManu(data) {
  const navigate = useNavigate();

  return (
    <div className="footerMenu mItem">
      <button
        onClick={() => navigate("/main")}
        className={`footerMenuItem ${data.menuChk == 0 ? "active" : ""}`}
      >
        <img
          src={`/assets/images/icon/${
            data.menuChk == 0 ? "menu_0_on.svg" : "menu_0_off.svg"
          }`}
        />
        홈
      </button>
      <button
        onClick={() => navigate("/mypage/rent/new")}
        className={`footerMenuItem ${data.menuChk == 1 ? "active" : ""}`}
      >
        <img
          src={`/assets/images/icon/${
            data.menuChk == 1 ? "menu_1_on.svg" : "menu_1_off.svg"
          }`}
        />
        렌터카 내역
      </button>
      <button
        onClick={() => navigate("/mypage/reservation")}
        className={`footerMenuItem ${data.menuChk == 2 ? "active" : ""}`}
      >
        <img
          src={`/assets/images/icon/${
            data.menuChk == 2 ? "menu_2_on.svg" : "menu_2_off.svg"
          }`}
        />
        예약 내역
      </button>
      <button
        onClick={() => navigate("/cs/list/notice")}
        className={`footerMenuItem ${data.menuChk == 3 ? "active" : ""}`}
      >
        <img
          src={`/assets/images/icon/${
            data.menuChk == 3 ? "menu_3_on.svg" : "menu_3_off.svg"
          }`}
        />
        고객센터
      </button>
      <button
        onClick={() => navigate("/mypage/main")}
        className={`footerMenuItem ${data.menuChk == 4 ? "active" : ""}`}
      >
        <img
          src={`/assets/images/icon/${
            data.menuChk == 4 ? "menu_4_on.svg" : "menu_4_off.svg"
          }`}
        />
        마이페이지
      </button>
    </div>
  );
}

//footer
function Footer(data) {
  const navigate = useNavigate();
  const chkData = termsData?.data.filter((el) => el.id !== 3);

  const footerApi = useGet({
    url: `/footer`,
  });
  const footerData = footerApi?.data?.footers;

  const handleClick = (termsItem) => {
    if (termsItem.id == 3) return;
    if (termsItem.id === "privacy") {
      navigate("/terms/private");
    } else {
      data.rightOpenFunc(termsItem.id);
    }
  };
  return (
    <div
      className={`footer ${data.pcType ? "pcItem" : "mItem"}`}
      ref={data.footerRef ? data.footerRef : null}
    >
      <div className="footerSection">
        <h1 className="footer_title">레인보우오토 주식회사</h1>
        <div className="footer_menuBox">
          {chkData?.map((termsItem, key) => (
            <button
              type="button"
              key={key}
              onClick={() => {
                handleClick(termsItem);
              }}
              className="footer_menuItem"
            >
              {termsItem.text == "서비스 이용약관"
                ? "이용약관"
                : termsItem.text}
            </button>
          ))}
        </div>
        <div className="footer_infoArea">
          {data.pcType ? (
            ""
          ) : (
            <p className="footer_infoText">레인보우오토 주식회사</p>
          )}
          {footerData && footerData.length > 0 ? (
            <>
              {footerData.map((item, i) => (
                <p className="footer_infoText" key={i}>
                  <span>{item.name}</span>
                  {data.pcType ? "" : <span> : </span>}
                  {item.value}
                </p>
              ))}
            </>
          ) : (
            ""
          )}
        </div>
        <p className="footer_text">CAHDUGO. ALL RIGHTS RESERVED.</p>
      </div>
    </div>
  );
}

export { FooterManu, Footer };
