import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper";
import useGet from "api/useGet";
import MiddleBanner from "component/banner/banner";
import { CsType } from "component/cs/cs";
import { FooterManu } from "component/elements/footer";
import MainButton from "component/main/MainButton";

const Main = (props) => {
  const navigate = useNavigate();

  const mainData = useGet({
    url: `/main?os=pc`,
    loginType: "login",
  });

  const mainBanner = mainData?.data?.main_banners;
  const mainBannerPc = mainBanner
    ? mainData?.data?.main_banners.filter((el) => el.os == 0)
    : [];
  const mainBannerMo = mainBanner
    ? mainData?.data?.main_banners.filter((el) => el.os == 1)
    : [];
  const middle_banners = mainData?.data?.middle_banners;
  const qnas = mainData?.data?.qnas;
  const faqs = mainData?.data?.faqs;
  const notices = mainData?.data?.notices;

  const [serviceSlide, setServiceSlide] = useState(null);
  const [serviceSlideNumber, setServiceSlideNumber] = useState(0);
  const serviceArr = [
    {
      link: "/cs/guide/1",
      title:
        "소중한 내 차를 두고 떠날 때,<br/>공항에서 차량을 픽업해<br/>안전하게 보관할 수 있어요.",
      text: "공항 주차",
    },
    {
      link: "/cs/guide/2",
      title:
        "안전 운전을 위해 꼭 해야하는 <b>정기 검사!<b/> 30분 검사보증제로 빠르게 이용해 보세요.",
      text: "차량 검사",
    },
    {
      link: "/cs/guide/3",
      title:
        "사고로 인한 판금, 도색부터 부품 교체까지 거품없이 정직하게 수리할 수 있어요.",
      text: "사고 수리",
    },
    {
      link: "/cs/guide/4",
      title:
        "따가운 햇빛을 막아주는 썬팅부터 운전 필수품인 블랙박스까지! 프리미엄 브랜드만 엄선했어요.",
      text: "차량 용품",
    },
    {
      link: "/cs/guide/5",
      title:
        "디테일링 세차부터 에바크리닝, 광택과 코팅 등 꼼꼼하고 깨끗하게 세차해 드려요.",
      text: "디테일링 세차",
    },
    {
      link: "/cs/guide/6",
      title:
        "내 차도 맡기고, 렌터카도 예약하고! 한번에 편안한 제주여행 하세요.",
      text: "렌터카",
    },
  ];

  function MainMenuItem(data) {
    return (
      <button
        type="button"
        onClick={() => data.func()}
        className={`mainMenuItem ${
          data.colorType == "gColor"
            ? "mainMenuItem_gColor"
            : data.colorType == "bColor"
            ? "bColor"
            : "bgColor"
        } ${data.minType ? "minType" : ""}`}
      >
        <div className="mainMenuItem_textInfo">
          <img src={`/assets/images/icon/${data.icon}`} />
          <h1 className="mainMenuItem_name">{data.name}</h1>
          <p className="mainMenuItem_text">{data.text}</p>
        </div>
        <div className="mainMenuItem_captionBox">
          <p className="mainMenuItem_caption">서비스 이용하기</p>
        </div>
      </button>
    );
  }

  function MainBoards(data) {
    return (
      <div className="mainBoardArea">
        <div className="mainBoard_titleBox">
          <button
            type="button"
            onClick={() => {
              navigate(data.link);
            }}
            className="mainBoard_title"
          >
            {data.title}
            <img src="/assets/images/basic/move.svg" />
          </button>
        </div>
        <div className="mainBoardListBox">
          {data?.data && data?.data?.length > 0 ? (
            data?.data.map((item, i) => (
              <button
                type="button"
                key={i}
                onClick={() => {
                  navigate(
                    data.title == "자주 묻는 질문"
                      ? "/cs/list/faq"
                      : `/cs/detail/cs/${item.id}`
                  );
                }}
                className="mainBoardListItem"
              >
                {data.title == "1:1 문의" ? (
                  <CsType text={item.status == 1 ? "답변완료" : "답변대기"} />
                ) : (
                  ""
                )}
                <h1 className="mainBoardListItem_title">{item.question}</h1>
                {data.title == "자주 묻는 질문" ? (
                  <p className="mainBoardListItem_tag"># {item.category}</p>
                ) : (
                  ""
                )}
              </button>
            ))
          ) : (
            <div className="notList">
              <img src="/assets/images/icon/not_list.svg" />
              {data.title} 내역이 없습니다.
            </div>
          )}
        </div>
      </div>
    );
  }

  function serviceSlideTo(id) {
    serviceSlide.slideToLoop(id, 300);
  }

  useEffect(() => {}, []);

  return (
    <div className="mainPage headerInPage pageSizing footerMenuInPage">
      <div className="mainBanner_seciton">
        <div className="mainBannerArea">
          {mainBanner && mainBannerPc?.length > 0 ? (
            <div className="mainBannerBox pcItem">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                modules={[Pagination, Autoplay]}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                pagination={{
                  el: ".custom_pagination",
                  type: "fraction",
                  renderFraction: function (current, total) {
                    return `<span class="${current}"></span><span class="line"></span><span class="${total}"></span>`;
                  },
                }}
                loop={true}
              >
                {mainBannerPc?.map((bannerItem, index) => (
                  <SwiperSlide key={bannerItem.id}>
                    <a
                      target="_blank"
                      href={`${bannerItem.link}`}
                      className="mainBannerItem"
                      style={{
                        backgroundImage: `url('${bannerItem.image_url}')`,
                      }}
                      rel="noreferrer"
                    ></a>
                  </SwiperSlide>
                ))}
                <div className="custom_pagination"></div>
              </Swiper>
            </div>
          ) : (
            ""
          )}
          {mainBanner && mainBannerMo?.length > 0 ? (
            <div className="mainBannerBox mItem">
              <Swiper
                spaceBetween={0}
                slidesPerView={1}
                modules={[Pagination, Autoplay]}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                pagination={{
                  el: ".custom_pagination",
                  type: "fraction",
                  renderFraction: function (current, total) {
                    return `<span class="${current}"></span><span class="line"></span><span class="${total}"></span>`;
                  },
                }}
                loop={true}
              >
                {mainBannerMo?.map((bannerItem, index) => (
                  <SwiperSlide key={bannerItem.id}>
                    <a
                      target="_blank"
                      href={`${bannerItem.link}`}
                      className="mainBannerItem"
                      style={{
                        backgroundImage: `url('${bannerItem.image_url}')`,
                      }}
                      rel="noreferrer"
                    ></a>
                  </SwiperSlide>
                ))}
                <div className="custom_pagination"></div>
              </Swiper>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="mainBanner_menuArea">
          <MainButton
            handleClick={(data) => {
              props.popupOpen(data);
            }}
          />
          {/* <MainMenuItem
            icon="main_0.svg"
            name="공항 주차"
            text="제주/김포 공항 주차"
            colorType="bColor"
            func={() => {
              props.popupOpen("공항 주차 지역 선택");
            }}
          />
          <MainMenuItem
            icon="main_1.svg"
            name="차량 정비"
            text="제주서비스/출장정비/출장세차"
            colorType="bColor"
            func={() => {
              props.popupOpen("차량 정비 지역 선택");
            }}
          />
          <MainMenuItem
            icon="main_2.svg"
            name="렌터카"
            text="간편하고 빠른 렌트 서비스"
            colorType="bColor"
            minType={true}
            func={() => {
              props.popupOpen("렌터카 차량 조회");
            }}
          /> */}
        </div>
      </div>
      <div className="mainServiceSection">
        <h1 className="mainService_title">서비스 이용안내</h1>
        <div className="mainService_slideSection">
          <div className="mainService_controlArea">
            <div className="mainService_control_toBox">
              <button
                type="button"
                onClick={() => {
                  serviceSlideTo(0);
                }}
                className={`mainService_control_toItem mainService_control_toItem_0 ${
                  serviceSlideNumber == 0 ? "active" : ""
                }`}
              >
                공항 주차
              </button>
              <button
                type="button"
                onClick={() => {
                  serviceSlideTo(1);
                }}
                className={`mainService_control_toItem mainService_control_toItem_1 ${
                  serviceSlideNumber == 1 ? "active" : ""
                }`}
              >
                차량 검사
              </button>
              <button
                type="button"
                onClick={() => {
                  serviceSlideTo(2);
                }}
                className={`mainService_control_toItem mainService_control_toItem_2 ${
                  serviceSlideNumber == 2 ? "active" : ""
                }`}
              >
                사고 수리
              </button>
              <button
                type="button"
                onClick={() => {
                  serviceSlideTo(3);
                }}
                className={`mainService_control_toItem mainService_control_toItem_3 ${
                  serviceSlideNumber == 3 ? "active" : ""
                }`}
              >
                차량 용품
              </button>
              <button
                type="button"
                onClick={() => {
                  serviceSlideTo(4);
                }}
                className={`mainService_control_toItem mainService_control_toItem_4 ${
                  serviceSlideNumber == 4 ? "active" : ""
                }`}
              >
                디테일링 세차
              </button>
              <button
                type="button"
                onClick={() => {
                  serviceSlideTo(5);
                }}
                className={`mainService_control_toItem mainService_control_toItem_5 ${
                  serviceSlideNumber == 5 ? "active" : ""
                }`}
              >
                렌터카
              </button>
            </div>
            <div className="mainService_controlBtnBox">
              <button type="button" className="mainService_control_prev">
                <img src="/assets/images/basic/slide_left.svg" />
              </button>
              <button type="button" className="mainService_control_next">
                <img src="/assets/images/basic/slide_right.svg" />
              </button>
            </div>
          </div>
          <div className="mainService_slideArea">
            <Swiper
              slidesPerView={1}
              spaceBetween={20}
              loop={true}
              onSwiper={setServiceSlide}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              loopedSlides={5}
              onSlideChangeTransitionStart={(el) => {
                let id =
                  el.realIndex >= 6 ? Number(el.realIndex) - 6 : el.realIndex;
                setServiceSlideNumber(id);
              }}
              navigation={{
                prevEl: ".mainService_control_prev",
                nextEl: ".mainService_control_next",
              }}
              modules={[Pagination, Navigation, Autoplay]}
              breakpoints={{
                720: {
                  slidesPerView: 1,
                  centeredSlides: false,
                },
                0: {
                  slidesPerView: 1,
                  centeredSlides: true,
                },
              }}
            >
              {serviceArr?.map((serviceItem, i) => (
                <SwiperSlide key={i}>
                  <div
                    onClick={() => {
                      navigate(serviceItem.link);
                    }}
                    className={`mainService_slideItem mainService_slideItem_${i}`}
                  >
                    <h2
                      className="mainService_slideTitle"
                      dangerouslySetInnerHTML={{ __html: serviceItem.title }}
                    ></h2>
                    <p
                      className="mainService_slideText"
                      dangerouslySetInnerHTML={{ __html: serviceItem.text }}
                    ></p>
                  </div>
                </SwiperSlide>
              ))}
              {serviceArr?.map((serviceItem, i) => (
                <SwiperSlide key={i}>
                  <div
                    onClick={() => {
                      navigate(serviceItem.link);
                    }}
                    className={`mainService_slideItem mainService_slideItem_${i}`}
                  >
                    <h2
                      className="mainService_slideTitle"
                      dangerouslySetInnerHTML={{ __html: serviceItem.title }}
                    ></h2>
                    <p
                      className="mainService_slideText"
                      dangerouslySetInnerHTML={{ __html: serviceItem.text }}
                    ></p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>

      <div className="mainSubBanner_seciton">
        <MiddleBanner data={middle_banners} />
      </div>

      <div className="mainBoard_section">
        <MainBoards title="자주 묻는 질문" data={faqs} link="/cs/list/faq" />
        <MainBoards title="1:1 문의" data={qnas} link="/cs/list/cs" />
      </div>
      {notices && notices.length > 0 ? (
        <div className="mainNoticeSection">
          <div className="mainNoticeArea">
            <div className="mainNotice_contents">
              <button
                type="button"
                onClick={() => {
                  navigate("/cs/list/notice");
                }}
                className="mainNoticeLink"
              >
                <img src="/assets/images/basic/notice.svg" />
                공지사항
              </button>
              <div className="mainNoticeSlide">
                <Swiper
                  spaceBetween={15}
                  slidesPerView={1}
                  autoplay={{ delay: 5000, disableOnInteraction: false }}
                  loop={true}
                  direction="vertical"
                  navigation={{
                    prevEl: ".mainNotice_prev",
                    nextEl: ".mainNotice_next",
                  }}
                  modules={[Navigation, Autoplay]}
                >
                  {notices?.map((noticeItem, i) => (
                    <SwiperSlide key={i}>
                      <h1
                        onClick={() => {
                          navigate(`/cs/detail/notice/${noticeItem.id}`);
                        }}
                        className="mainNotice_title"
                      >
                        {noticeItem.title}
                      </h1>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="mainNotice_btnBox">
              {notices.length >= 2 ? (
                <>
                  <button type="button" className="mainNotice_prev">
                    <img src="/assets/images/basic/notice_prev.svg" />
                  </button>
                  <button type="button" className="mainNotice_next">
                    <img src="/assets/images/basic/notice_next.svg" />
                  </button>
                </>
              ) : (
                ""
              )}
              <button
                type="button"
                onClick={() => {
                  navigate("/cs/list/notice");
                }}
                className="mainNotice_link"
              >
                <img src="/assets/images/basic/more.svg" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <FooterManu menuChk={0} />
    </div>
  );
};

export default Main;
