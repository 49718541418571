import React, { useState } from "react";
import { useEffect } from "react";
import * as fatchSet from "../../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { getParam, getParam_google, tokenSave } from "js/function";
import { LoadingBox } from "component/basic/popup";

const Social = (props) => {
  const navigate = useNavigate();
  const param = useParams();
  const type = param.type;
  let varUA = navigator.userAgent;

  const LoginNext = (token) => {
    let formData = new FormData();
    if (type == "kakao") {
      formData.append("code", getParam("code"));
    }
    if (type == "naver") {
      formData.append("token", getParam("code"));
    }
    if (type == "google") {
      formData.append("token", getParam_google("access_token"));
      if (
        varUA.match("chadugo/Android") !== null ||
        varUA.match("chadugo/iOS") !== null
      ) {
        formData.append("is_app", 1);
      }
    }
    if (type == "apple") {
      formData.append("code", getParam("code"));
    }
    if (type == "kakaoApp") {
      formData.append("token", token);
    }
    if (type == "naverApp") {
      formData.append("token", token);
      if (
        varUA.match("chadugo/Android") !== null ||
        varUA.match("chadugo/iOS") !== null
      ) {
        formData.append("is_android", 1);
      }
    }

    fatchSet.FatchApi({
      type: "POST",
      formDataItem: formData,
      url: `/${
        type == "kakaoApp" ? "kakao" : type == "naverApp" ? "naver" : type
      }/login`,
      success: (data) => {
        if (data.data.type == "login") {
          localStorage.setItem("token", data.data.token.access_token);
          localStorage.setItem("refresh_token", data.data.token.refresh_token);

          tokenSave(data.data.token.access_token);

          props.logIn();

          if (sessionStorage.getItem("setUrl")) {
            let reUrl = sessionStorage.getItem("setUrl");
            sessionStorage.removeItem("setUrl");
            navigate(reUrl);
          } else {
            navigate(`/main`);
          }
        } else {
          sessionStorage.setItem("token", data.data.token.access_token);
          sessionStorage.setItem(
            "refresh_token",
            data.data.token.refresh_token
          );

          navigate(
            `/member/terms/${
              type == "kakaoApp" ? "kakao" : type == "naverApp" ? "naver" : type
            }`
          );
        }
      },
      err: (data) => {
        navigate("/member/login");
      },
    });
  };

  window.sns_login = (itemType, getToken) => {
    LoginNext(getToken);
  };

  useEffect(() => {
    if (type == "kakaoApp" || type == "naverApp") {
      if (type == "kakaoApp") {
        if (varUA.match("chadugo/Android") !== null) {
          window.chadugo.postMessage(
            JSON.stringify({ type: "kakaoLoginCheck" })
          );
        } else {
          window.webkit.messageHandlers.kakaoLoginCheckHandler.postMessage("_");
        }
      } else if (type == "naverApp") {
        if (varUA.match("chadugo/Android") !== null) {
          window.chadugo.postMessage(
            JSON.stringify({ type: "naverLoginCheck" })
          );
        } else {
          window.webkit.messageHandlers.naverLoginCheckHandler.postMessage("_");
        }
      }
    } else {
      LoginNext();
    }
  }, []);

  return (
    <div className="memberPage headerInPage pageSizing">
      <LoadingBox
        addClass={"active"}
        loadingItem="<img src='/assets/images/icon/loading.svg' class='spin'/>"
      />
    </div>
  );
};

export default Social;
