import React from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { registerCarModalState } from "recoil/atoms/modal";
import styled from "styled-components";
import ModalBase from "./ModalBase";

function RegisterCarModal() {
  const modal = useRecoilValue(registerCarModalState);
  const reset = useResetRecoilState(registerCarModalState);

  return (
    <ModalBase visible={!!modal}>
      <Wrapper>
        <div className="rightPopup_header_section">
          <h1 className="rightPopup_title">차량 정보 등록</h1>
          <button>
            <img src={`/assets/images/basic/close.svg`} alt="back_icon" />
          </button>
        </div>
      </Wrapper>
    </ModalBase>
  );
}

const Wrapper = styled.div``;

export default RegisterCarModal;
