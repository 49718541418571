import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import { InfoBoxText, ServiceInfoItem, SevicePageTitle } from "component/app/subItems";
import { InputItemBox, TextAreaItem } from "component/basic/formItems";
import { getDateGap, setDateWeek } from "js/function";
import { AppSection } from "component/app/app";
import { comFormat } from "js/function";
import { Header } from "component/elements/header";
import FixedSection from "component/basic/fixedBox";
import { ErrPopup, RightPopup } from "component/basic/popup";
import { Fragment } from "react";
import { CarInfoSubItem, CarInfoSubItemBox } from "component/app/rent";

const RentDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();

    const [dataSet,setDataSet] = useState(0);

    const [id,setId] = useState(param.id);
    const [start,setStart] = useState(param.start);
    const [end,setEnd] = useState(param.end);

    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");
    const [birth,setBirth] = useState("");
    const [contents,setContents] = useState("");

    const [btnChk,setBtnChk] = useState(true);
    const [errPopupData,setErrPopupData] = useState(null);
    const [rightPopupData,setRightPopupData] = useState(null);

    const [price,setPrice] = useState(0);
    const [insurance,setInsurance] = useState(null);
    const [insurancePrice,setInsurancePrice] = useState(0);

    const [sale,setSale] = useState(0);
    
    const [totalPrice,setTotalPrice] = useState(0);
    
    //상세정보
    const carData = useGet({
        url:`/rentercar/detail?id=${id}&start_date=${start.replace(/\./g,"-")}&end_date=${end.replace(/\./g,"-")}`,
        loginType:"login"
    });
      
    //내 정보
    const userData = useGet({
        url:`/user/check`,
        loginType:"login"
    });

    //약관 정보
    const termsData = useGet({
        url:`/term?type=rent`,
        loginType:"login"
    });

    //내 정보 세팅
    useEffect(() => {
        setName(userData.data?.name ? userData.data.name : "");
        setPhone(userData.data?.phone ? userData.data.phone : "");

        setDataSet(dataSet + 1);
    }, [userData]);

    //렌트카 정보 세팅
    useEffect(()=>{
        setId(param.id);
        setStart(param.start);
        setEnd(param.end);

        sessionStorage.setItem("rentId",param.id)
        sessionStorage.setItem("rentStart",param.start)
        sessionStorage.setItem("rentEnd",param.end)
    },[param.id,param.start,param.end]);

    useEffect(()=>{
        sessionStorage.setItem("rentId",param.id)
        sessionStorage.setItem("rentStart",param.start)
        sessionStorage.setItem("rentEnd",param.end)
    },[]);

    //가격 세팅
    useEffect(()=>{
        let sale = 100 - ((carData?.data?.rentcar?.price / carData?.data?.rentcar?.original_price) * 100);
        let insuranceData = carData?.data?.rentcar.inserance_info[0] ? {
            type:carData?.data?.rentcar?.inserance_info[0].type,
            name:carData?.data?.rentcar?.inserance_info[0].name,
            price:carData?.data?.rentcar?.inserance_info[0].price
        } : {}
        
        setPrice(Number(carData?.data?.rentcar?.price) * Number(getDateGap(start,end,"day")));
        
        setInsurance(insuranceData);
        setInsurancePrice(carData?.data?.rentcar?.inserance_info[0] ? Number(carData?.data?.rentcar?.inserance_info[0].price) : 0);

        setSale(sale);
    },[carData]);

    //주문 이동
    function moveOrder(){
        navigate(`/rent/order`,{state: {
            insurance: insurance,
            id: id,
            car_name: carData?.data?.rentcar?.name,
            car_year: carData?.data?.rentcar?.car_year,
            // rental_addr: carData?.data?.rentcar?.rental_addr,
            // return_addr: carData?.data?.rentcar?.return_addr,
            start_date: start.split(" ")[0],
            start_time: start.split(" ")[1],
            end_date: end.split(" ")[0],
            end_time: end.split(" ")[1],
            name: name,
            phone: phone,
            birth: birth,
            memo: contents,
            total_time: getDateGap(start,end),
            price: price,
            totalPrice: insurance?.price ? Number(price) + Number(insurance?.price) : Number(price)
        }})
    }

    //버튼 활성화 체크
    useEffect(() => {
        if(name && phone && birth){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [name,phone,birth]);

    //총 금액 체크
    useEffect(() => {
        let totalPriceItem = price + insurancePrice;
        setTotalPrice(totalPriceItem);
    }, [price,insurancePrice]);

    return (
        <div className="servicePage headerInPage pageSizing mBtnPage">
            <Header
                pageBack={true}
                headTitle="서비스 신청"
                func={() => navigate(-1)}
                homeBtn={true}
            />
            <SevicePageTitle
                title="서비스 신청"
                navi={0}
                navi_0="서비스 신청"
                navi_1="신청 정보 확인"
                navi_2="신청 완료"
            />
            <div className="servicePage_contents">
                <div className="service_inputSection">
                    <AppSection>
                        <div className="rentCar">
                            <div className="rentCarImg" style={{backgroundImage:`url('${carData?.data?.rentcar?.image_url}')`}}/>
                            <div className="rentCar_dateSection">
                                <div className="rentCar_dateArea">
                                    <div className="rentCar_dateItem">
                                        <p className="rentCar_dateName">대여일시</p>
                                        <h2 className="rentCar_date">{`${start.split(" ")[0]} (${setDateWeek(start.split(" ")[0])}) ${start.split(" ")[1]}`}</h2>
                                    </div>
                                    <div className="rentCar_dateItem">
                                        <p className="rentCar_dateName">반납일시</p>
                                        <h2 className="rentCar_date">{`${end.split(" ")[0]} (${setDateWeek(end.split(" ")[0])}) ${end.split(" ")[1]}`}</h2>
                                    </div>
                                </div>
                                <p className="rentCar_dateTimeChk">총 {getDateGap(start,end)}</p>
                            </div>
                            <div className="rentCarInfo">
                                <h1 className="rentCarInfo_name">{carData?.data?.rentcar?.name} {carData?.data?.rentcar?.car_year}</h1>
                                <p className="rentCarInfo_price">{sale !== 0 && sale <= 100 ? <span className="rentListItem_sale">{Math.floor(sale)}%</span> : ""}{comFormat(Number(carData?.data?.rentcar?.price) * Number(getDateGap(start,end,"day")))}원</p>
                            </div>
                            <CarInfoSubItemBox title="차량 정보">
                                <CarInfoSubItem>
                                    <img src="/assets/images/icon/gasstation.svg"/>
                                    {carData?.data?.rentcar?.fuel_type}
                                </CarInfoSubItem>
                                <CarInfoSubItem>
                                    <img src="/assets/images/icon/profile.svg"/>
                                    {carData?.data?.rentcar?.seater}인
                                </CarInfoSubItem>
                                <CarInfoSubItem>
                                    <img src="/assets/images/icon/car.svg"/>
                                    {carData?.data?.rentcar?.type}
                                </CarInfoSubItem>
                            </CarInfoSubItemBox>
                            
                            <CarInfoSubItemBox title="옵션" addClass="dotItemBox">
                                {carData?.data?.rentcar && carData?.data?.rentcar?.options.length > 0 ? 
                                    <>{carData?.data?.rentcar?.options.map((item,i)=>(
                                        <Fragment key={i}>
                                            {i !== 0 ? <span className="dot"></span> : ""}
                                            <CarInfoSubItem>{item.option_name}</CarInfoSubItem>
                                        </Fragment>
                                    ))}</>
                                :<p className="rentCar_notOptions">옵션이 없습니다.</p>}
                            </CarInfoSubItemBox>
                        </div>
                    </AppSection>
                    <AppSection title="예약 정보" text="서비스를 이용하실 분의 정보를 입력해주세요.">
                        <div className="service_minInputSection">
                            <InputItemBox
                                type="text"
                                inputName="이름"
                                placeholder="이름"
                                max={20}
                                value={name}
                                setChk={dataSet}
                                regexp={/[0-9#?!@$ %^&*-]/gi}
                                func={(e) => {setName(e)}}
                            />
                            <InputItemBox
                                type="phoneBasic"
                                inputName="연락처"
                                placeholder="연락처"
                                max={11}
                                value={phone}
                                setChk={dataSet}
                                func={(e) => {setPhone(e)}}
                            />
                            <InputItemBox
                                type="number"
                                inputName="생년월일"
                                placeholder="생년월일"
                                max={6}
                                value={birth}
                                caption="6자리 생년월일 예시)YYMMDD"
                                func={(e) => {setBirth(e)}}
                            />
                        </div>
                    </AppSection>
                    {carData?.data?.rentcar?.inserance_info && carData?.data?.rentcar?.inserance_info.length > 0 ?
                    <AppSection title="보험 선택">
                        <div className="inseranceSection">
                            {carData?.data?.rentcar?.inserance_info.map((item,i)=>(
                            <button type="button" key={i} className={`btn_inserance ${insurance && insurance?.type == item.type ? "active" : ""}`} onClick={()=>{
                                let insuranceData = {
                                    type:item.type,
                                    name:item.name,
                                    price:item.price
                                }
                                setInsurance(insuranceData);
                                setInsurancePrice(Number(item.price));
                            }}>
                                <div className="inseranceInfo">
                                    <img src="/assets/images/basic/chk_icon_w.svg"/>
                                    {item.name} (+{comFormat(item.price)}원)
                                </div>
                                <p className="inseranceCaption">{item.year ? `나이 만 ${item.year}세 이상` : ""}{item.career && item.year ? " / " : ""}{item.career ? `운전경력 ${item.career}년 이상` : ""}</p>
                            </button>
                            ))}
                        </div>
                        <InfoBoxText
                            text="<b>최초 사고 1회 소멸</b><br/>[면책제외내용] - 침수, 단독 및 자기과실 100%사고, 혼유사고, 현장이탈접수시 적용불가, 소모품(타이어,휠 등), 긴급출동제외<br/>보상한도 : 300만원 면책금 : 한도내 없음 휴차보상료 : 한도내 없음"
                        />
                    </AppSection>
                    :""}
                    <AppSection title="기타 정보">
                        <TextAreaItem
                            addClass="outCount"
                            inputName="차량 관련 특이사항 (선택)"
                            placeholder="차량 관련 특이사항 (선택)"
                            max={100}
                            unit="자"
                            maxChk={true}
                            value={contents}
                            func={(e)=>setContents(e)}
                        />
                    </AppSection>
                </div>
                <FixedSection pageBox="servicePage" boxAddClass="service_selInfo_section" addClass="service_selInfo_fixedSection" headerChk={true} footerChk={true}>
                    <div className="service_selInfo_area">
                        <h1 className="service_selInfo_title">결제 정보</h1>
                        <div className="service_selInfo_list">
                            <ServiceInfoItem
                                name="차량 대여 요금"
                                item={`${comFormat(price)}원`}
                            />
                            <ServiceInfoItem
                                name="보험금"
                                item={`${comFormat(insurancePrice)}원`}
                            />
                        </div>
                        <div className="service_selInfo_totalArea">
                            <h2 className="service_selInfo_totalTitle">총 결제금액</h2>
                            <h2 className="service_selInfo_total">{comFormat(totalPrice)}원</h2>
                        </div>
                        <div className="service_selInfo_infoBox">
                            <button type="button" onClick={()=>{
                                setRightPopupData(termsData?.data?.term?.value)
                            }} className="service_selInfo_info"><img src="/assets/images/basic/info.svg"/>취소 및 환불 규정</button>
                        </div>
                        <BtnBox
                            addClass="pageInType mChType"
                            text={"다음으로"}
                            disabled={btnChk}
                            func={()=>{moveOrder()}}
                        />
                    </div>
                    <div className="order_info_imgBox mItem">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="order_info_img pcItem"></div>
                </FixedSection>
            </div>
            <ErrPopup
                data={errPopupData}
            />
            <RightPopup
                text={rightPopupData}
                topTitle="취소 및 환불 규정"
                closePopup={() => {setRightPopupData(null)}}
            />
        </div>
    );
};

export default RentDetail;