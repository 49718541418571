import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MemberBox from "component/member/memberBox";
import { BtnBox, BtnBoxV2, BtnText } from "component/basic/btns";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import {
  CarInfoNumberBox,
  ErrPopup,
  LoadingBox,
  RightPopup,
} from "component/basic/popup";
import { InputItemBox } from "component/basic/formItems";
import useGet from "api/useGet";
import { EditCarInfo } from "component/mypage/mypage";

const EditProfileCar = () => {
  const navigate = useNavigate();

  const mypageData = useGet({
    url: `/user/check`,
    loginType: "login",
  });

  const [carAddType, setCarAddType] = useState(0);

  const [btnChk, setBtnChk] = useState(true);

  const [carNumber, setCarNumber] = useState("");
  const [carSize, setCarSize] = useState("");
  const [carCate, setCarCate] = useState("");
  const [carYear, setCarYear] = useState("");

  const [loading, setLoading] = useState(false);

  const [errPopupData, setErrPopupData] = useState(null);

  const [carInfo, setCarInfo] = useState(null);

  const [carPoup, setCarPopup] = useState("");

  function onLocalStorageRemove() {
    localStorage.removeItem("view");
    localStorage.removeItem("marker");
  }
  function addCarInfo() {
    setLoading(true);

    fatchSet.FatchApi({
      type: "GET",
      url: `/car/info?car_number=${carNumber}`,
      loginType: "login",
      success: (item) => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
        let setData = item.data.car_info;
        setCarInfo(setData);
        setCarPopup("open");
      },
      err: (item) => {
        setTimeout(() => {
          setLoading(false);
        }, 200);

        setErrPopupData({
          addClass: null,
          text: "차량 번호가 정확하게 조회되지 않습니다.<br/>차량 정보를 직접 등록하시겠습니까?",
          closeType: false,
          closeFunc: (e) => {},
          btnFunc: (e) => {
            setCarInfo({
              isDirectRegister: true,
              carNumber,
            });
            setCarPopup("open");
          },
          errBtnText: "직접 입력",
          textSub: "아니오",
          btnAddClassSub: "notLine",
          funcSub: () => {},
        });

        // if(item.alert){
        //     setErrPopupData({
        //         addClass:null,
        //         text:item.alert,
        //         closeType:true,
        //         closeFunc:(e) => {},
        //         btnFunc:(e)=>{}
        //     });
        // }
      },
    });
  }

  function addCarFunc(size, type, year, number) {
    let formData = new FormData();
    formData.append("car_number", carAddType == 0 ? carNumber : number);
    formData.append("car_size", size);
    formData.append("car_type", type);
    formData.append("car_year", year);

    fatchSet.FatchApi({
      type: "POST",
      formDataItem: formData,
      url: `/mypage/car/modify`,
      loginType: "login",
      success: (data) => {
        setErrPopupData({
          addClass: null,
          text: "차량 등록이 완료되었습니다!",
          closeType: false,
          closeFunc: (e) => {
            navigate(0);
          },
          btnFunc: (e) => {
            navigate(0);
          },
        });
      },
      err: (data) => {
        if (data.alert) {
          onLocalStorageRemove();
          setErrPopupData({
            addClass: null,
            text: data.alert,
            closeType: true,
            closeFunc: (e) => {},
            btnFunc: (e) => {},
          });
        }
      },
    });
  }

  useEffect(() => {
    onLocalStorageRemove();
  }, []);

  useEffect(() => {
    if (carNumber) {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [carNumber]);

  useEffect(() => {
    if (mypageData) {
      if (mypageData?.data?.is_car) {
        setCarAddType(1);
        setCarInfo(true);
      } else {
        setCarAddType(0);
        setCarInfo(null);
      }
    }
  }, [mypageData]);

  return (
    <div className="memberPage headerInPage pageSizing btnInPage">
      <Header
        pageBack={true}
        headTitle="내 차량 정보"
        func={() => navigate(-1)}
      />
      <MemberBox
        title="내 차량 정보"
        pcChk={true}
        backFunc={() => {
          navigate(-1);
        }}
      >
        {carAddType == 0 ? (
          <div className="memberInput_section joinInput_section">
            <InputItemBox
              type="text"
              inputName="차량 번호"
              placeholder="차량 번호"
              value={carNumber}
              max={10}
              regexp={/[a-zA-Z#?!@$ %^&*-]/gi}
              func={(e) => {
                setCarNumber(e);
              }}
              caption="예시) 12가1234"
            />
          </div>
        ) : (
          <div className="myEditCarInfo">
            <CarInfoNumberBox
              carNumber={
                mypageData?.data?.car?.car_number
                  ? mypageData?.data?.car?.car_number
                  : "-"
              }
            />
            <div className="myEditCarInfoTextBox">
              <EditCarInfo
                name="제조사"
                val={
                  mypageData?.data?.user_car?.maker
                    ? mypageData?.data?.user_car?.maker
                    : "-"
                }
              />
              <EditCarInfo
                name="차량명 (모델명)"
                val={
                  mypageData?.data?.user_car?.name
                    ? mypageData?.data?.user_car?.name
                    : "-"
                }
              />
              <EditCarInfo
                name="차량 크기"
                val={
                  mypageData?.data?.user_car?.size
                    ? mypageData?.data?.user_car?.size
                    : "-"
                }
              />
              <EditCarInfo
                name="차종"
                val={
                  mypageData?.data?.user_car?.car_type
                    ? mypageData?.data?.user_car?.car_type
                    : "-"
                }
              />
              <EditCarInfo
                name="차량 연식"
                val={
                  mypageData?.data?.user_car?.user_car_year ||
                  mypageData?.data?.user_car?.user_car_year == 0
                    ? mypageData?.data?.user_car?.user_car_year
                    : "-"
                }
              />
            </div>
          </div>
        )}
        {carAddType == 0 ? (
          <BtnBox
            addClass="pageInType mChType"
            text="차량 조회"
            disabled={btnChk}
            func={() => {
              addCarInfo();
            }}
          />
        ) : (
          <BtnBox
            addClass="pageInType mChType"
            text="차량 정보 재등록"
            func={() => {
              setErrPopupData({
                addClass: null,
                text: `현재 등록된 차량을 삭제하고<br/>재등록하시겠습니까?`,
                closeType: true,
                closeFunc: (e) => {},
                btnFunc: (e) => {
                  setCarPopup("open");
                },
                btnAddClass: "wbColor",
                errBtnText: "재등록",
                textSub: "취소",
                btnAddClassSub: "notLine",
                funcSub: () => {},
              });
            }}
          />
        )}
      </MemberBox>
      <LoadingBox
        addClass={loading ? "active" : ""}
        loadingItem="<img src='/assets/images/icon/loading.svg' class='spin'/>"
      />
      <ErrPopup data={errPopupData} />
      <RightPopup
        addClass="minVar"
        text={carPoup}
        topTitle="차량 정보 등록"
        isClosedFunction={true}
        closePopup={() => {
          if (window.localStorage.getItem("success")) {
            setCarPopup("");
            setTimeout(() => {
              localStorage.removeItem("success");
            }, 130);
          } else {
            setErrPopupData({
              addClass: null,
              text: "차량 정보는 <b>마이페이지<b/>에서<br />언제든 등록/수정하실 수 있습니다.<br />다음에 등록하시겠습니까?",
              closeType: false,
              closeFunc: (e) => {},
              btnFunc: (e) => {
                setCarPopup("");
                // setCarInfo(null);
              },
              errBtnText: "네",
              textSub: "아니오",
              btnAddClassSub: "notLine",
              funcSub: () => {},
            });
          }
        }}
        carNumber={carNumber}
        addCarNumber={carAddType == 0 ? null : true}
        car_info={carInfo}
        carAdd={carInfo ? true : false}
        addCarFunc={(size, type, year, number) => {
          addCarFunc(size, type, year, number);
        }}
      />
    </div>
  );
};

export default EditProfileCar;
