import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import { ServiceInfoSubItem, ServiceInfoSubItemBox, SevicePageTitle } from "component/app/subItems";
import { ChkBox, InputItemBox } from "component/basic/formItems";
import { PhoneSetting } from "js/function";
import { comFormat } from "js/function";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import { ErrPopup, LoadingBox, RightPopup} from "component/basic/popup";
import { OrderCarData, OrderOptionArea, OrderPayItem, OrderPriceItem, OrderPriceSubItem, OrderSeciton, OrderTimeArea } from "component/app/order";

const RentOrder = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const location = useLocation();
    const pageData = location?.state ? location?.state : null;

    const formItem = useRef(null);
    const [loading,setLoading] = useState(false);

    const [insurance,setInsurance] = useState(pageData?.insurance ? pageData?.insurance : null);

    const [totalPrice,setTotalPrice] = useState(pageData?.totalPrice ? pageData?.totalPrice : 0);
    const [pointPrice,setPointPrice] = useState(0);
    const [payType,setPayType] = useState("card");
    
    const [termsChk,setTermsChk] = useState(false);

    const [errPopupData,setErrPopupData] = useState(null);

    const [rightPopupText,setRightPopupText] =  useState(null);
    const [rightPopupData,setRightPopupData] = useState(null);

    const [btnChk,setBtnChk] = useState(true);

    const [orderChk,setOrderChk] =  useState(true);

    //약관 정보
    const termsList = useGet({
        url:`/term?type=pay`
    });

    //약관 정보(렌트)
    const rentTerms = useGet({
        url:`/term?type=rent`,
        loginType:"login"
    });

    //내 포인트 정보
    const pointData = useGet({
        url:`/mypage/point?page=1&type=1`,
        loginType:"login"
    });

    //주문
    function orderStart(){
        if(orderChk){
            setLoading(true);
            setOrderChk(false);
            formItem.current.innerHTML = "";

            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="access_token" value="${localStorage.getItem("token")}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="id" value="${pageData?.id}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="start_date" value="${`${pageData?.start_date} ${pageData?.start_time}`}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="end_date" value="${`${pageData?.end_date} ${pageData?.end_time}`}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="user_name" value="${pageData?.name}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="user_phone" value="${pageData?.phone}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="user_birth" value="${pageData?.birth}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="inserance_type" value="${insurance.type}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="inserance_name" value="${insurance.name}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="inserance_price" value="${insurance.price}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="use_point" value="${pointPrice}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="add_point" value="${Math.floor(Number(totalPrice) * 0.03)}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="payment_method" value="${payType}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="price" value="${Number(totalPrice) - Number(pointPrice)}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="discount_price" value="${0}"/>`);
            formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="memo" value="${pageData?.memo}"/>`);
    
            setTimeout(() => {
                setOrderChk(true);
                setLoading(false);
                formItem.current.submit();
            }, 500);
        }
    }

    //버튼 활성화 체크
    useEffect(() => {
        if(termsChk && payType){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [termsChk,payType]);

    return (
        <div className="orderPage headerInPage pageSizing mBtnPage">
            <form action={`${process.env.REACT_APP_API_URL}/rentercar/reserve/kicc`} ref={formItem} method="GET"></form>
            <Header
                pageBack={true}
                headTitle="신청 정보 확인"
                func={() => navigate(-1)}
                homeBtn={true}
            />
            <SevicePageTitle
                title="신청 정보 확인"
                navi={1}
                navi_0="서비스 신청"
                navi_1="신청 정보 확인"
                navi_2="신청 완료"
            />
            <div className="orderPage_contents">
                <OrderSeciton title="신청 내용 확인">
                    <div className="orderDateArea">
                        <OrderTimeArea
                            title="대여일시"
                            date={pageData?.start_date}
                            time={pageData?.start_time}
                        />
                        <OrderTimeArea
                            title="반납일시"
                            date={pageData?.end_date}
                            time={pageData?.end_time}
                        />
                    </div>
                    <div className="orderCarData">
                        <OrderCarData
                            imgKey="car"
                            title="차량 정보"
                            text={`${pageData?.car_name} (${pageData?.car_year})`}
                        />
                        <OrderCarData
                            imgKey="insurance"
                            title="보험명"
                            text={insurance?.name}
                        />
                        <OrderCarData
                            imgKey="name"
                            title="이름"
                            text={pageData?.name ? pageData?.name : "-"}
                        />
                        <OrderCarData
                            imgKey="phone"
                            title="연락처"
                            text={pageData?.phone ? PhoneSetting(pageData?.phone) : "-"}
                        />
                        <OrderCarData
                            imgKey="birth"
                            title="생년월일"
                            text={pageData?.birth ? pageData?.birth : "-"}
                        />
                        {/* <OrderCarData
                            imgKey="addr"
                            title="대여/반납 장소"
                            text={`${pageData?.rental_addr ? pageData?.rental_addr : "-"} / ${pageData?.return_addr ? pageData?.return_addr : "-"}`}
                        /> */}
                        <OrderCarData
                            imgKey="time"
                            title="총 대여기간"
                            text={`${pageData?.total_time ? pageData?.total_time : "-"}`}
                        />
                        <OrderCarData
                            imgKey="memo"
                            title="특이사항"
                            text={pageData?.memo ? pageData?.memo : "-"}
                        />
                    </div>
                </OrderSeciton>
                <OrderSeciton title="이용 요금">
                    <OrderOptionArea
                        name="차량 렌터카 비용"
                        type="rent"
                        del={false}
                    >
                        <b>{comFormat(pageData?.price)}원</b>
                    </OrderOptionArea>
                    <OrderOptionArea
                        name="보험 명"
                        type="insurance"
                        del={false}
                    >
                        <b>{insurance?.price ? comFormat(insurance?.price) : 0}원</b>
                    </OrderOptionArea>
                </OrderSeciton>
                <OrderSeciton title="포인트">
                    <div className="service_minInputSection">
                        <InputItemBox
                            type="number"
                            inputName="사용 포인트"
                            placeholder="사용 포인트"
                            value={pointPrice}
                            numberMax={pointData?.data?.point > totalPrice ? totalPrice : pointData?.data?.point}
                            disabled={pointData?.data?.point == 0 ? true : false}
                            func={(e) => {setPointPrice(Number(e))}}
                            caption={`사용 가능 포인트 <span class="bColor">${comFormat(pointData?.data?.point)} P</span>`}
                        />
                    </div>
                </OrderSeciton>
                <OrderSeciton title="결제 금액">
                <div className="orderPriceArea">
                        <OrderPriceItem
                            type=""
                            text={`${comFormat(totalPrice)}원`}
                            name="총 서비스 요금"
                        />
                        <OrderPriceItem
                            type="upText"
                            text={`-${comFormat(pointPrice)}원`}
                            name="총 할인가 "
                        />
                        {pointPrice !== 0 ? <OrderPriceSubItem>
                            <ServiceInfoSubItemBox>
                                <ServiceInfoSubItem
                                    name="포인트 사용"
                                    item={`-${comFormat(pointPrice)}원`}
                                />
                            </ServiceInfoSubItemBox>
                        </OrderPriceSubItem> : ""}
                    </div>
                    <div className="orderTotalPriceArea">
                        <h1 className="orderTotalPrice_name">총 결제 금액</h1>
                        <div className="orderTotalPriceBox">
                            <h2 className="orderTotalPrice">{comFormat(totalPrice - pointPrice)}원</h2>
                            <p className="orderTotalPrice_point">{comFormat(Math.floor((totalPrice - pointPrice) * 0.03))} P 적립 예정</p>
                        </div>
                    </div>
                </OrderSeciton>
                <OrderSeciton title="결제 수단/주문 동의">
                    <div className="orderPayArea">
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("card")}}
                            val="card"
                            type=""
                            text="신용카드"
                        />
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("bank")}}
                            val="bank"
                            type=""
                            text="계좌이체"
                        />
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("phone")}}
                            val="phone"
                            type=""
                            text="휴대폰 결제"
                        />
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("npay")}}
                            val="npay"
                            type="naver"
                            text=""
                        />
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("kakao")}}
                            val="kakao"
                            type="kakao"
                            text=""
                        />
                    </div>
                    <div className="orderChkArea">
                        <ChkBox
                            addClass="pageIn"
                            func={(e) => {setTermsChk(e.target.checked)}}
                            checkedType={termsChk}
                            chkSubType={"필수"}
                            label="구매조건 확인 및 결제 대행 서비스 약관 동의"
                            chkFunc={(e) => {setRightPopupText(termsList?.data?.term?.value)}}
                        />
                    </div>
                    <div className="payInfoTextBox">
                        <button type="button" onClick={()=>{
                            setRightPopupData(rentTerms?.data?.term?.value)
                        }} className="service_selInfo_info"><img src="/assets/images/basic/info.svg"/>취소 및 환불 규정</button>
                    </div>
                </OrderSeciton>
                <BtnBox
                    addClass="pageInType mChType pcMinBtn"
                    text={"결제하기"}
                    disabled={btnChk}
                    func={()=>{orderStart()}}
                />
            </div>
            <ErrPopup
                data={errPopupData}
            />
            <RightPopup
                topTitle="구매조건 확인 및 결제 대행 서비스 약관 동의"
                text={rightPopupText}
                btnType="only"
                popupBtnFunc={()=>{setTermsChk(true)}}
                closePopup={() => {setRightPopupText(null)}}
            />
            <RightPopup
                text={rightPopupData}
                topTitle="취소 및 환불 규정"
                closePopup={() => {setRightPopupData(null)}}
            />
            <LoadingBox
                addClass={loading ? "active" : ""}
                loadingItem="<img src='/assets/images/icon/loading.svg' class='spin'/>"
            />
        </div>
    );
};

export default RentOrder;