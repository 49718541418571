import { useNavigate } from "react-router-dom";

function MypageMainTabelArea(data){
    let navigate = useNavigate();

    return (
        <div className="mypageMainTableArea">
            <div className="mypageMainTableArea_titleBox">
                <h1 className="mypageMainTableArea_title">{data.title}</h1>
                <button type="button" onClick={()=>{navigate(data.link)}} className="mypageMainTableArea_titleLink">전체보기 <img src="/assets/images/basic/move.svg"/></button>
            </div>
            {data.children}
        </div>
    );
}

function MypageTabelArea(data){
    return (
        <div className={`mypageTableArea ${data.addClass ? data.addClass : ""}`}>
            <div className="mypageTableArea_titleBox pcItem">
                <h1 className="mypageTableArea_title">{data.title}</h1>
            </div>
            {data.children}
        </div>
    );
}

function MypageTable(data){
    return (
        <div className={`mypageTable ${data.mType ? "mTypeItem" : ""}`}>
            {data.children}
        </div>
    );
}

function MypageThBox(data){
    return (
        <div className="mypageTableThBox">
            {data.children}
        </div>
    );
}

function MypageTh(data){
    return (
        <div className="mypageTableTh" style={{width:data.width,minWidth:data.width}}>
            {data.contents}
        </div>
    );
}

function MypageTdBox(data){
    return (
        <div className="mypageTableTdBox" onClick={()=>{data.func()}}>
            {data.children}
        </div>
    );
}

function MypageTd(data){
    return (
        <div className={`mypageTableTd ${data.addClass ? data.addClass : ""}`} style={{width:data.width,minWidth:data.width}}>
            {data.children}
        </div>
    );
}

function MypageTableState(data){
    return (
        <p className={`mypageState ${data.addClass ? data.addClass : ""}`}>
            {data.text}
        </p>
    );
}

function RentTableList(data){
    return (
        <div className="rentTableList">
            <div className="rentTableList_img" style={{backgroundImage:`url('${data.img}')`}}></div>
            <div className="rentTableList_infoBox">
                <h1 className="rentTableList_name">{data.name}</h1>
                <p className="rentTableList_info">{`${data.fuel_type} / ${data.size} / ${data.seater}인승`}</p>
            </div>
        </div>
    );
}

function TableSubTitle(data){
    return (
        <span className="tItem tableSubTitle">{data.text}</span>
    );
}

export {TableSubTitle, RentTableList, MypageMainTabelArea, MypageThBox, MypageTdBox, MypageTabelArea, MypageTable, MypageTh, MypageTd, MypageTableState};