import { CustomPopup, Popup } from "component/basic/popup";
import ManagementLink from "component/popupContents/managementLink";
import StorageLink from "component/popupContents/storageLink";
import { logout } from "js/function";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const PcHeader = (data) => {
  let navigate = useNavigate();

  return (
    <div className="header pcItem">
      <div className="headerSection">
        <button
          type="button"
          onClick={() => {
            navigate("/main");
          }}
          className="btn_logo"
        >
          <img src="/assets/images/icon/logo.svg" />
        </button>
        <div className="headerMenuArea">
          <button
            type="button"
            onClick={() => {
              data.popupOpenFunc("공항 주차 지역 선택");
            }}
            className="headerMenuItem"
          >
            공항 주차
          </button>
          <button
            type="button"
            onClick={() => {
              data.popupOpenFunc("차량 정비 지역 선택");
            }}
            className="headerMenuItem"
          >
            차량 정비
          </button>
          <button
            type="button"
            onClick={() => {
              data.popupOpenFunc("렌터카 차량 조회");
            }}
            className="headerMenuItem"
          >
            렌터카
          </button>
        </div>
        <div className="headerSubMenuArea">
          <button
            type="button"
            onClick={() => {
              navigate("/cs/guide/1");
            }}
            className="headerSubMenuItem"
          >
            이용안내
          </button>
          <button
            type="button"
            onClick={() => {
              navigate("/cs/list/notice");
            }}
            className="headerSubMenuItem"
          >
            고객센터
          </button>
          {!data.token ? (
            <button
              type="button"
              onClick={() => {
                navigate("/member/login");
              }}
              className="headerSubMenuItem"
            >
              로그인/회원가입
            </button>
          ) : (
            <>
              <button
                type="button"
                onClick={() => {
                  navigate("/mypage/main");
                }}
                className="headerSubMenuItem"
              >
                마이페이지
              </button>
              <button
                type="button"
                onClick={() => {
                  logout();
                  data.logOut();
                  navigate("/");
                }}
                className="headerSubMenuItem"
              >
                로그아웃
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

function Header(headerData) {
  let navigate = useNavigate();

  const [shKey, setShKey] = useState("");
  const [info, setInfo] = useState(false);

  function shSet(e) {
    setShKey(e.target.value);
    if (e.keyCode == 13 || e.key == "Enter") {
      headerData.shFunc(shKey);
    }
  }
  return (
    <div className="header mItem">
      <div className="headerSection">
        {!headerData.pageBack || headerData.pageBack === "" ? (
          ""
        ) : (
          <button
            type="button"
            className="btnBack"
            onClick={(e) => headerData.func(e)}
          >
            <img src="/assets/images/basic/back.svg" />
          </button>
        )}
        {headerData.headTitle ? (
          <p
            className={`headTitle ${
              headerData.centerTitle ? "centerTitle" : ""
            }`}
          >
            {headerData.headTitle}
          </p>
        ) : (
          ""
        )}
        {headerData.customHeaderItem ? headerData.customHeaderItem : ""}
        {headerData.shLink ? (
          <button
            type="button"
            className="btnSh btnHeaderRight"
            onClick={(e) => navigate("/sh")}
          >
            <img src="/assets/images/basic/sh.svg" />
          </button>
        ) : (
          ""
        )}
        {headerData.sharing ? (
          <button
            type="button"
            className="btnSharing btnHeaderRight"
            onClick={(e) => headerData.sharingFunc(e)}
          >
            <img src="/assets/images/basic/sharing_min.svg" />
          </button>
        ) : (
          ""
        )}
        {headerData.shBox ? (
          <div className="header_shBox">
            <input
              type="text"
              className="header_sh"
              onInput={(e) => setShKey(e.target.value)}
              onPaste={(e) => setShKey(e.target.value)}
              onChange={(e) => setShKey(e.target.value)}
              onKeyUp={(e) => shSet(e)}
              placeholder="검색어를 입력해보세요"
            />
            <button
              type="button"
              onClick={(e) => headerData.shFunc(shKey)}
              className="btn_header_sh"
            >
              <img src="/assets/images/basic/sh.svg" />
            </button>
          </div>
        ) : (
          ""
        )}
        {headerData.homeBtn ? (
          <button
            type="button"
            className="btnHome btnHeaderRight"
            onClick={(e) => {
              navigate("/");
            }}
          >
            <img src="/assets/images/icon/home_icon.svg" />
          </button>
        ) : (
          ""
        )}
        {headerData.pageClose ? (
          <button
            type="button"
            className="btnClose btnHeaderRight"
            onClick={(e) => headerData.func(e)}
          >
            <img src="/assets/images/basic/close.svg" />
          </button>
        ) : (
          ""
        )}
        {headerData.setting ? (
          <button
            type="button"
            className="btnSetting btnHeaderRight"
            onClick={(e) => navigate("/mypage/setting")}
          >
            <img src="/assets/images/basic/setting.svg" />
          </button>
        ) : (
          ""
        )}
        {headerData.rightLink ? (
          <button
            type="button"
            className="btnSetting btnHeaderRight"
            onClick={(e) => headerData.rightLink()}
          >
            {headerData.rightLinkText}
          </button>
        ) : (
          ""
        )}
        {headerData.info ? (
          <div className="headerInfoBox btnHeaderRight">
            <button
              type="button"
              className="btn_headerInfo"
              onClick={(e) => setInfo(!info)}
            >
              <img src="/assets/images/basic/info.svg" />
            </button>
            <p
              className="btn_headerInfoText"
              style={{ display: `${info ? "block" : "none"}` }}
            >
              <span
                dangerouslySetInnerHTML={{ __html: headerData.info }}
              ></span>
            </p>
          </div>
        ) : (
          ""
        )}
        {headerData.rightBtn ? headerData.rightBtn : ""}
        {headerData.children}
      </div>
    </div>
  );
}

export { PcHeader, Header };
