import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled, { css, keyframes } from "styled-components";

function ModalBase({ visible, children, onClose }) {
  const [closed, setClosed] = useState(true);

  const handleClose = (e) => {
    if (e.target !== e.currentTarget) return;
    onClose && onClose();
  };

  useEffect(() => {
    let timeoutId = 0;
    if (visible) {
      setClosed(false);
    } else {
      timeoutId = setTimeout(() => setClosed(true), 200);
    }
    return () => clearTimeout(timeoutId);
  }, [visible]);

  useEffect(() => {
    if (!visible) return;
    document.documentElement.style.overflow = "hidden";
    return () => (document.documentElement.style.overflow = "auto");
  }, [visible]);

  if (!visible && closed) return null;

  return (
    <Wrapper visible={visible} onClick={handleClose}>
      <ModalBlock visible={visible}>{children}</ModalBlock>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.visible
      ? css`
          animation: ${fadeIn} 0.4s forwards ease-in-out;
        `
      : css`
          animation: ${fadeOut} 0.2s forwards ease-in-out;
        `}
`;

const ModalBlock = styled.div`
  // pc 환경
  width: 23.75em;
  height: 31.75em;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.09);
  border-radius: 10px;

  // 모바일환경
  @media only screen and (max-width: 720px) {
  }

  ${(props) =>
    props.visible
      ? css`
          animation: ${popInFromBottom} 0.4s forwards ease-in-out;
        `
      : css`
          animation: ${popOutFromBottom} 0.2s forwards ease-in-out;
        `}
`;

const fadeIn = keyframes`
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  100% {
    opacity: 1;
  }

  0% {
    opacity: 0.2;
  }
`;

const popInFromBottom = keyframes`
  0% {
    opacity : 0;
    transform : translateY(400px) scale(0.75);
  }
  75% {
    opacity : 1;
    transform : translateY(-15px) scale(1.0);
  }
  100% {
    opacity : 1;
    transform: translateY(0px);
  };
`;

const popOutFromBottom = keyframes`
  0% {
    opacity : 1;
    transform : translateY(0px) scale(1.0);
  }
  100%{
    opacity : 0;
    transform: translateY(400px) scale(0.75);
  };
`;

export default ModalBase;
