import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import { NotItems } from "component/basic/notItems";
import Tap from "component/basic/tap";
import { FooterManu } from "component/elements/footer";
import { Header } from "component/elements/header";
import { MypageContents, MypageMenu, MypageSection } from "component/mypage/mypage";
import { MypageTabelArea, MypageTable, MypageTableState, MypageTd, MypageTdBox, MypageTh, MypageThBox, RentTableList, TableSubTitle } from "component/mypage/mypageTable";
import { setDateTime } from "js/function";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const MyRentList = (props) => {
    const [mainData,setMainData] = useState(null);
    let navigate = useNavigate();
    const param = useParams();
    const type = param.type;

    const [page,setPage] = useState(1);
    const [arrData,setArrData] = useState([]);

    const listData = useGet({
        url:`/mypage/rent?category=${type}&page=${page}`,
        loginType:"login"
    });

    const [lastPage,setLastPage] = useState(false);
    const [settingChk,setSettingChk] = useState(0);

    const category = [
        {id:"new",category:"예약 내역"},
        {id:"past",category:"과거 내역"}
    ]

    function addPage(){
        if(!lastPage && settingChk == 0){
            setPage(page + 1);
            setSettingChk(1);
            setTimeout(() => {
                setSettingChk(0);
            }, 500);
        }
    };

    useEffect(() => {
        if(listData.data?.rentcars?.data && listData.data?.rentcars?.data.length > 0){
            if(page == 1){
                setArrData([...listData.data?.rentcars?.data]);
            }else{    
                setArrData(prev => [...prev, ...listData.data?.rentcars?.data]);
            }
        }else{
            setArrData([]);
        }
        setLastPage(listData.data?.rentcars?.current_page == listData.data?.rentcars?.last_page);
    }, [listData]);

    return(
        <>
        <Header func={() => {}}>
            <h1 className="mypageMainTitle">렌터카 예약 내역</h1>
        </Header>
        <MypageSection>
            <MypageMenu
                rightOpenFunc={(id)=>{props.rightOpenFunc(id)}}
                logOut={()=>{props.logOut()}}
                dataSet={(data)=>{setMainData(data)}}
            />
            <MypageContents>
                <MypageTabelArea
                    title="렌터카 예약 내역"
                    addClass="mTMergin"
                >
                    <div className="myPageTapBox">
                        <Tap
                            val={type}
                            data={category}
                            func={(id)=>{navigate(`/mypage/rent/${id}`)}}
                            addClass="minVar"
                        />
                    </div>
                    {mainData && arrData.length > 0 ? 
                    <MypageTable mType={true}>
                        <MypageThBox>
                            <MypageTh contents="예약 상태" width="22%"/>
                            <MypageTh contents="예약 번호" width="24%"/>
                            <MypageTh contents="예약 차량" width="30%"/>
                            <MypageTh contents="대여/반납 일시" width="24%"/>
                        </MypageThBox>
                        {arrData.map((item,i)=>(
                            <MypageTdBox key={i} func={()=>{navigate(`/mypage/rent/detail/${item.id}`)}}>
                                <MypageTd width="22%">
                                    <MypageTableState 
                                        text={item.status == 1 ? "예약완료" : item.status == 2 ? "이용중" : item.status == 3 ? "이용 완료" : "예약취소"}
                                        addClass={item.status == 1 ? "wbColor" : item.status == 2 ? "bColor" : item.status == 3 ? "" : "rColor"}
                                    />
                                    <span className="tItem"><b>{item.code ? item.code : "-"}</b></span>
                                </MypageTd>
                                <MypageTd width="24%" addClass="pctItem">
                                    <b>{item.code ? item.code : "-"}</b>
                                </MypageTd>
                                <MypageTd width="30%">
                                    <RentTableList
                                        img={item.image_url ? item.image_url : ""}
                                        name={item.name ? item.name : "-"}
                                        fuel_type={item.fuel_type ? item.fuel_type : "-"}
                                        size={item.size ? item.size : "-"}
                                        seater={item.seater ? item.seater : "-"}
                                    />
                                </MypageTd>
                                <MypageTd width="24%">
                                    <TableSubTitle text="서비스 기간"/>{`${item.start_date ? setDateTime(item.start_date,"yymmddhhtt") : "-"} `}<br/>{`- ${item.end_date ? setDateTime(item.end_date,"yymmddhhtt") : "-"}`}
                                </MypageTd>
                            </MypageTdBox>
                        ))}
                    </MypageTable>
                    :
                    <NotItems img="/assets/images/icon/not_list.svg" text="렌터카 예약 내역이 없습니다."/>
                    }
                    {!lastPage ? 
                    <BtnBox
                        btnAddClass="line"
                        addClass="pageInType pcMinBtn addListBtn"
                        text={"더보기"}
                        func={()=>{addPage()}}
                    />:""}
                </MypageTabelArea>
            </MypageContents>
        </MypageSection>
        <FooterManu menuChk={1}/>
        </>
    );
}
export default MyRentList;