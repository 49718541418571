import { NotItems } from "component/basic/notItems";
import { ErrPopup } from "component/basic/popup";
import { FooterManu } from "component/elements/footer";
import { Header } from "component/elements/header";
import { MypageContents, MypageMenu, MypageSection } from "component/mypage/mypage";
import { MypageMainTabelArea, MypageTable, MypageTableState, MypageTd, MypageTdBox, MypageTh, MypageThBox, RentTableList, TableSubTitle } from "component/mypage/mypageTable";
import { setDateTime } from "js/function";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const MypageMain = (props) => {
    const [mainData,setMainData] = useState(null);
    let navigate = useNavigate();

    const [errPopupData,setErrPopupData] = useState(null);

    return(
        <>
        <Header func={() => {}}>
            <h1 className="mypageMainTitle">마이페이지</h1>
        </Header>
        <MypageSection>
            <MypageMenu
                mType={true}
                rightOpenFunc={(id)=>{props.rightOpenFunc(id)}}
                logOut={()=>{props.logOut()}}
                dataSet={(data)=>{setMainData(data)}}
            />
            <MypageContents pcType={true}>
                <MypageMainTabelArea
                    title="렌터카 예약 내역"
                    link="/mypage/rent/new"
                >
                    {mainData && mainData?.data?.user_rents.length > 0 ? 
                    <MypageTable mType={true}>
                        <MypageThBox>
                            <MypageTh contents="예약 상태" width="22%"/>
                            <MypageTh contents="예약 번호" width="24%"/>
                            <MypageTh contents="예약 차량" width="30%"/>
                            <MypageTh contents="대여/반납 일시" width="24%"/>
                        </MypageThBox>
                        {mainData?.data?.user_rents.map((item,i)=>(
                            <MypageTdBox key={i} func={()=>{navigate(`/mypage/rent/detail/${item.id}`)}}>
                                <MypageTd width="22%">
                                    <MypageTableState 
                                        text={item.status == 1 ? "예약완료" : item.status == 2 ? "이용중" : item.status == 3 ? "이용 완료" : "예약취소"}
                                        addClass={item.status == 1 ? "wbColor" : item.status == 2 ? "bColor" : item.status == 3 ? "" : "rColor"}
                                    />
                                    <span className="tItem"><b>{item.code ? item.code : "-"}</b></span>
                                </MypageTd>
                                <MypageTd width="24%" addClass="pctItem">
                                    <b>{item.code ? item.code : "-"}</b>
                                </MypageTd>
                                <MypageTd width="30%">
                                    <RentTableList
                                        img={item.image_url ? item.image_url : ""}
                                        name={item.name ? item.name : "-"}
                                        fuel_type={item.fuel_type ? item.fuel_type : "-"}
                                        size={item.size ? item.size : "-"}
                                        seater={item.seater ? item.seater : "-"}
                                    />
                                </MypageTd>
                                <MypageTd width="24%">
                                    <TableSubTitle text="서비스 기간"/>{`${item.start_date ? setDateTime(item.start_date,"yymmddhhtt") : "-"} `}<br/>{`- ${item.end_date ? setDateTime(item.end_date,"yymmddhhtt") : "-"}`}
                                </MypageTd>
                            </MypageTdBox>
                        ))}
                    </MypageTable>
                    :
                    <NotItems img="/assets/images/icon/not_list.svg" text="렌터카 예약 내역이 없습니다."/>
                    }
                </MypageMainTabelArea>
                <MypageMainTabelArea
                    title="서비스 예약 내역"
                    link="/mypage/reservation"
                >
                    {mainData && mainData?.data?.user_services.length > 0 ? 
                    <MypageTable>
                        <MypageThBox>
                            <MypageTh contents="예약 번호" width="30%"/>
                            <MypageTh contents="예약 신청일자" width="30%"/>
                            <MypageTh contents="서비스 기간" width="40%"/>
                        </MypageThBox>
                        {mainData?.data?.user_services.map((item,i)=>(
                            <MypageTdBox key={i} func={()=>{navigate(`/mypage/reservation/detail/${item.id}/${item.type}`)}}>
                                <MypageTd width="30%">
                                    <b>{item.code ? item.code : "-"}</b>
                                </MypageTd>
                                <MypageTd width="30%">
                                    {item.created_at ? setDateTime(item.created_at,"yymmdd") : "-"}
                                </MypageTd>
                                <MypageTd width="40%">
                                    {item.start_date ? item.end_date && item.end_date !== "" && item.end_date !== "0000-00-00 00:00:00" ? 
                                    `${setDateTime(item.start_date,"yymmdd")} - ${setDateTime(item.end_date,"yymmdd")}`
                                    :setDateTime(item.start_date,"yymmdd") : "-"}
                                </MypageTd>
                            </MypageTdBox>
                        ))}
                    </MypageTable>
                    :
                    <NotItems img="/assets/images/icon/not_list.svg" text="서비스 예약 내역이 없습니다."/>
                    }
                </MypageMainTabelArea>
                <MypageMainTabelArea
                    title="견적 내역"
                    link="/mypage/order/repair"
                >
                    {mainData && mainData?.data?.user_estimate_services.length > 0 ? 
                    <MypageTable mType={true}>
                        <MypageThBox>
                            <MypageTh contents="요청 번호" width="25%"/>
                            <MypageTh contents="견적 신청일자" width="25%"/>
                            <MypageTh contents="입고일" width="25%"/>
                            <MypageTh contents="처리 상태" width="25%"/>
                        </MypageThBox>
                        {mainData?.data?.user_estimate_services.map((item,i)=>(
                            <MypageTdBox key={i} func={()=>{item.status !== 21 && item.status !== 11 ? item.status == 2 ? navigate(`/mypage/reservation/detail/${item.id}/${item.type}`) : navigate(`/mypage/order/detail/${item.id}/${item.category}`) : 
                                setErrPopupData({
                                    addClass:null,
                                    text:"만료 또는 취소된 견적입니다.<br/>새로 견적을 신청하시면<br/>서비스 이용이 가능합니다.",
                                    closeType:true,
                                    closeFunc:(e) => {},
                                    btnFunc:(e)=>{}
                                })}}>
                                <MypageTd width="25%">
                                    <b>{item.estimate_code ? item.estimate_code : "-"}</b>
                                </MypageTd>
                                <MypageTd width="25%">
                                    <TableSubTitle text="견적 신청일자"/>{item.created_at ? setDateTime(item.created_at,"yymmdd") : "-"}
                                </MypageTd>
                                <MypageTd width="25%">
                                    <TableSubTitle text="입고일"/>{item.start_date ? setDateTime(item.start_date,"yymmdd") : "-"}
                                </MypageTd>
                                <MypageTd width="25%">
                                    <MypageTableState 
                                        text={item.status == 3 ? "완료" : item.status == 1 ? "예약 완료" : item.status == 11 ? "예약 취소" : item.status == 2 ? "견적 접수" : item.status == 20 ? "견적 도착" : item.status == 21 ? "견적 취소" : ""}
                                        addClass={item.status == 1 ? "wbColor" : item.status == 2 ? "" : item.status == 20 ? "gColor" : item.status == 21 || item.status == 11 ? "rColor" : ""}
                                    />
                                </MypageTd>
                            </MypageTdBox>
                        ))}
                    </MypageTable>
                    :
                    <NotItems img="/assets/images/icon/not_list.svg" text="견적 내역이 없습니다."/>
                    }
                </MypageMainTabelArea>
            </MypageContents>
        </MypageSection>
        <FooterManu menuChk={4}/>
        <ErrPopup
            data={errPopupData}
        />
        </>
    );
}
export default MypageMain;