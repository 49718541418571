import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MemberBox from "component/member/memberBox";
import { BtnBoxV2, BtnText } from "component/basic/btns";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import { ErrPopup, LoadingBox, RightPopup } from "component/basic/popup";
import { InputItemBox } from "component/basic/formItems";

const AddCarInfo = () => {
    const navigate = useNavigate();

    const [btnChk,setBtnChk] =  useState(true);

    const [carNumber,setCarNumber] =  useState("");
    const [carSize,setCarSize] =  useState("");
    const [carCate,setCarCate] =  useState("");
    const [carYear,setCarYear] =  useState("");

    const [loading,setLoading] =  useState(false);
    
    const [errPopupData,setErrPopupData] = useState(null);

    const [carInfo,setCarInfo] =  useState(null);

    const [carPoup,setCarPopup] =  useState("");

    //차량 정보
    function addCarInfo(){
        setLoading(true);

        fatchSet.FatchApi({
            type:"GET",
            url: `/car/info?car_number=${carNumber}`,
            loginType:"login",
            success: (data) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                let setData = data.data.car_info;
                setCarInfo(setData);
                setCarPopup("open");
            },
            err: (data) => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }
        })
    }

    //차량 등록
    function addCarFunc(size,type,year){
        let formData = new FormData();
        formData.append("car_number", carNumber);
        formData.append("car_size", size);
        formData.append("car_year", year);
        formData.append("car_type", type);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/mypage/car/modify`,
            loginType:"login",
            success: (data) => {
                setErrPopupData({
                    addClass:null,
                    text:"회원가입이 완료되었습니다!",
                    closeType:false,
                    closeFunc:(e) => {navigate("/main")},
                    btnFunc:(e)=>{navigate("/main")}
                });
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }
        })
    }

    function notAddFunc(){
        setErrPopupData({
            addClass:null,
            text:"차량 정보는 <b>마이페이지</b>에서<br/>언제든 등록/수정하실 수 있습니다.<br/>다음에 등록하시겠습니까?",
            closeType:true,
            closeFunc:(e) => {},
            btnFunc:(e)=>{
                setTimeout(() => {
                    setErrPopupData({
                        addClass:null,
                        text:"회원가입이 완료되었습니다!",
                        closeType:false,
                        closeFunc:(e) => {navigate("/main")},
                        btnFunc:(e)=>{navigate("/main")}
                    });
                }, 200);
            },
            errBtnText:"네",
            textSub:"아니오",
            btnAddClassSub:"notLine",
            funcSub:()=>{}
        });
    }

    useEffect(() => {
        if(carNumber){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    }, [carNumber]);

    return (
        <div className="memberPage headerInPage pageSizing btnInPage">
            <Header
                pageBack={true}
                headTitle="차량 정보 등록"
                func={() => navigate(-1)}
            >
                <button type="button" className="headerTextBtn btnHeaderRight" onClick={()=>notAddFunc()}>다음에 입력</button>
            </Header>
            <MemberBox title="차량 정보 등록" pcChk={true} backFunc={()=>{navigate(-1)}}>
                <div className="memberInput_section joinInput_section">
                    <InputItemBox
                        type="text"
                        inputName="차량 번호"
                        placeholder="차량 번호"
                        value={carNumber}
                        max={10}
                        regexp={/[a-zA-Z#?!@$ %^&*-]/gi}
                        func={(e) => {setCarNumber(e)}}
                        caption="예시) 12가1234"
                    />
                </div>
                <BtnBoxV2
                    addClass="pageInType mChType pageBtnV2"
                    rightAddClass="line pcItem"
                    leftText="차량 조회"
                    rightText="다음에 입력"
                    leftDisabled={btnChk}
                    leftFunc={()=>{addCarInfo()}}
                    rightFunc={()=>{notAddFunc()}}
                >
                    <BtnText
                        addClass="mItem"
                        text="차량 정보는 회원가입 후 <b>마이페이지</b>에서 관리하실 수 있습니다."
                    />
                </BtnBoxV2>
            </MemberBox>
            <LoadingBox
                addClass={loading ? "active" : ""}
                loadingItem="<img src='/assets/images/icon/loading.svg' class='spin'/>"
            />
            <ErrPopup
                data={errPopupData}
            />
            <RightPopup
                addClass="minVar"
                text={carPoup}
                topTitle="차량 정보 등록"
                closePopup={() => {setCarPopup("")}}
                carNumber={carNumber}
                car_info={carInfo}
                carAdd={carInfo ? true : false}
                addCarFunc={(size,type,year)=>{addCarFunc(size,type,year)}}
            />
        </div>
    );
};

export default AddCarInfo;