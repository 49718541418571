import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { CsPageDetail, CsPageTitle } from "component/app/subItems";
import FixedSection from "component/basic/fixedBox";
import useGet from "api/useGet";
import { useEffect } from "react";
import Tap from "component/basic/tap";
import { FooterManu } from "component/elements/footer";

const CsInfo = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const pageType = param.page;

    const categoryData = useGet({
        url:`/guide`,
        loginType:"login"
    });
    const categoryList = categoryData?.data?.guides;

    const [category,setCategory] = useState(pageType);

    const csDetailData = useGet({
        url:`/guide/detail?guide_id=${category}`,
        loginType:"login"
    });

    useEffect(()=>{
        setCategory(pageType);
    },[pageType])

    return(
        <div className="csPage headerInPage mHeaderNot pageSizing footerMenuInPage">
            <CsPageTitle title="서비스 이용안내"/>
            {categoryList && categoryList.length > 0 ?
            <FixedSection boxAddClass="csFixedBox" addClass="csFixed" pageBox="csPage" headerChk={true}>
                <Tap
                    val={category}
                    data={categoryList}
                    func={(id)=>{navigate(`/cs/guide/${id}`)}}
                />
            </FixedSection>
            :""}
            <CsPageDetail data={csDetailData?.data?.guide?.contents}/>
            <FooterManu/>
        </div>
    );
}
export default CsInfo;