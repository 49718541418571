import { Divide } from "component/common/CommonStyles";
import { useNavigate } from "react-router-dom";
import { LinkPopupBox, LinkPopupItem } from "./linkPopupItem";
import ServiceDescription from "./ServiceDescription";

//차량관리 선택시
export default function ManagementLink(data) {
  let navigate = useNavigate();

  return (
    <LinkPopupBox>
      <LinkPopupItem
        name="제주 서비스"
        func={() => {
          navigate("/service");
          data.closePopup();
          data.func();
        }}
      />
      <Divide />
      <ServiceDescription />
    </LinkPopupBox>
  );
}
