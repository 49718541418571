import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import { AddServiceItem, AddServiceNotCaption, InfoText, ServiceInfoItem, ServiceInfoSubItem, ServiceInfoSubItemBox, SevicePageTitle } from "component/app/subItems";
import { AddrInputItem, ChkBox, DatePicer, InputItemBox, TextAreaItem } from "component/basic/formItems";
import { setDateTime } from "js/function";
import { AppSection } from "component/app/app";
import { comFormat } from "js/function";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import FixedSection from "component/basic/fixedBox";
import { ErrPopup, PrictInfoPopup, RightPopup } from "component/basic/popup";

const Maintenance = (props) => {
    const navigate = useNavigate();
    const param = useParams();

    //차량정보
    const carData = useGet({
        url:`/user/check`,
        loginType:"login"
    });

    const [dataSet,setDataSet] = useState(0);

    const [name,setName] = useState("");
    const [phone,setPhone] = useState("");

    const [carInfo,setCarInfo] = useState(null);
    const [carId,setCarId] = useState(null);
    const [carName,setCarName] = useState(null);
    const [carNumber,setCarNumber] = useState(null);
    const [carFuelType,setCarFuelType] = useState(null);
    const [addr,setAddr] = useState(null);
    const [detailAddr,setdetailAddr] = useState(null);
    const [addrReset,setAddrReset] = useState(false);

    const [sido,setSido] = useState("");
    const [sigungu,setSigungu] = useState("");
    const [roadname,setRoadname] = useState("");

    const [contents,setContents] = useState("");

    const [startDate,setStartDate] = useState(null);
    const [dateChk,setDateChk] = useState(false);
    const [minDateChk,setMinDateChk] = useState(true);

    const [totalPrice,setTotalPrice] = useState(0);

    const [btnChk,setBtnChk] = useState(true);

    const [carPopup,setCarPopup] =  useState("");

    const [errPopupData,setErrPopupData] = useState(null);

    const [prictInfoData,setPrictInfoData] = useState(null);

    const [maintenancePrice,setMaintenancePrice] = useState(0);
    const [washBTPrice,setWashBTPrice] = useState(0);

    const [maintenance,setMaintenance] = useState(null);
    const [washBT,setWashBT] = useState(null);

    const [maintenanceChk,setMaintenanceChk] = useState(false);
    const [washBTChk,setWashBTChk] = useState(false);
    
    const [maintenancePopup,setMaintenancePopup] = useState("");
    const [washBTPopup,setWashBTPopup] =  useState("");

    //출장정비 세팅
    let dataUrl = "";
    let apiDate = startDate ? startDate.split(".") : "";
    if(addr && startDate){dataUrl = `/oil/items?car_id=${carId}&type=0&year=${apiDate[0]}&month=${apiDate[1]}&day=${apiDate[2]}&sido=${sido}&sigugun=${sigungu}&dong=${roadname}`}else{dataUrl = null}

    const maintenanceData = useGet({
        url:dataUrl,
        loginType:"login"
    });

    function maintenanceDataSet(data,total){
        setMaintenance(data)
        setMaintenancePrice(total)
    }

    function washBTDataSet(data){
        
    }
    
    function addServiceChk(e,type){
        if(type == "maintenance"){
            if(e.target.checked){
                setMaintenancePopup("open");
            }else{
                setMaintenance(null);
                setMaintenancePrice(0);
            }
        }
        if(type == "washBT"){
            if(e.target.checked){
                setWashBTPopup("open");
            }else{
                setWashBT(null);
                setWashBTPrice(0);
            }
        }
    }

    //데이터 초기화
    function allDataReset(){
        setStartDate(null);
        setDateChk(!dateChk);
        setMinDateChk(true);
        setBtnChk(true);

        setMaintenancePrice(0);
        setWashBTPrice(0);

        setMaintenance(null);
        setWashBT(null);

        setMaintenanceChk(false);
        setWashBTChk(false);
    }

    //차량 수정
    function addCarFunc(size,type,year,number,id,name,fuelType){
        let formData = new FormData();
        formData.append("car_id", id);
        formData.append("car_type", type);
        formData.append("car_size", size);
        formData.append("car_year", year);
        
        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/mypage/car/info/modify`,
            loginType:"login",
            success: (data) => {
                setCarInfo(`${type} / ${name} ${number}`);
                setCarName(name);
                setCarNumber(number);
                setCarId(id);
                setCarFuelType(fuelType);
                setErrPopupData({
                    addClass:null,
                    text:"차량 정보가 등록되었습니다!",
                    closeType:true,
                    closeFunc:(e) => {},
                    btnFunc:(e)=>{}
                });
                setDataSet(dataSet + 1);
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }
        })
    }

    //결제 이동
    function moveOrder(){
        if(carFuelType == "BEV"){
            setErrPopupData({
                addClass:null,
                text:"전기차는 해당 서비스를<br/>사용하실 수 없습니다.<br/>다른 차량을 등록해주세요",
                closeType:true,
                closeFunc:(e) => {},
                btnFunc:(e)=>{}
            });
        }else{
            navigate(`/maintenance/order`,{state: {
                name: name,
                phone: phone,
                addr: addr,
                addrDetail: detailAddr,
                sido: sido,
                sigugun: sigungu,
                dong: roadname,
                start_date: startDate,
                car_id: carId,
                car_name: carName,
                car_number: carNumber,
                contents: contents,
                maintenance: maintenance,
                maintenancePrice: maintenancePrice,
                washBT: washBT,
                price: totalPrice,
                discount_price: 0,
            }})
        }
    } 

    //차량 정보 세팅
    useEffect(() => {
        setName(carData.data?.name ? carData.data.name : "");
        setPhone(carData.data?.phone ? carData.data.phone : "");

        if(carData.data?.is_car && carData.data?.user_car?.fuel_type !== "BEV"){
            let carDetailData = carData.data?.user_car;
            setCarInfo(`${carDetailData?.car_type} / ${carDetailData?.name} ${carDetailData?.number}`);
            setCarFuelType(carDetailData?.fuel_type);
            setCarId(carDetailData?.id);
            setCarName(carDetailData?.name);
            setCarNumber(carDetailData?.number);
        }

        setDataSet(dataSet + 1);
    }, [carData]);
    
    //버튼 활성화
    useEffect(() => {
        if(startDate && maintenance && carInfo && name !== "" && phone !== ""){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [carInfo,startDate,name,phone,maintenance]);

    //총 금액
    useEffect(() => {
        let totalPriceItem = 0;

        if(washBT){
            totalPriceItem = Number(totalPriceItem) + Number(washBTPrice);
        }
        if(maintenance){
            totalPriceItem = Number(totalPriceItem) + Number(maintenancePrice);
        }

        setTotalPrice(totalPriceItem);
    }, [washBTPrice,maintenancePrice,totalPrice]);

    return (
        <div className="servicePage headerInPage pageSizing mBtnPage">
            <Header
                pageBack={true}
                headTitle="서비스 신청"
                func={() => navigate(-1)}
                homeBtn={true}
            />
            <SevicePageTitle
                title="서비스 신청"
                navi={0}
                navi_0="서비스 신청"
                navi_1="신청 정보 확인"
                navi_2="신청 완료"
            />
            <div className="servicePage_contents">
                <div className="service_inputSection">
                    <AppSection title="내 정보" text="서비스를 이용하실 분의 이름과 연락처를 입력해주세요.">
                        <div className="service_minInputSection">
                            <InputItemBox
                                type="text"
                                inputName="이름"
                                placeholder="이름"
                                value={name}
                                max={20}
                                setChk={dataSet}
                                regexp={/[0-9#?!@$ %^&*-]/gi}
                                func={(e) => {setName(e)}}
                            />
                            <InputItemBox
                                type="phoneBasic"
                                inputName="연락처"
                                placeholder="연락처"
                                max={11}
                                value={phone}
                                setChk={dataSet}
                                func={(e) => {setPhone(e)}}
                            />
                        </div>
                    </AppSection>
                    <AppSection title="신청 정보">
                        <AddrInputItem
                            inputName="출장지 주소"
                            placeholder="출장지 주소"
                            value={addr}
                            setChk={addrReset}
                            func={(addr,sido,sigungu,roadname) => {
                                if(sido == "제주특별자치도"){
                                    setAddr(null);
                                    setSido(null);
                                    setSigungu(null);
                                    setRoadname(null);

                                    setErrPopupData({
                                        addClass:null,
                                        text:"제주 지역은 출장 서비스가 불가능합니다.",
                                        closeType:true,
                                        closeFunc:(e) => {},
                                        btnFunc:(e)=>{}
                                    });
                                    setAddrReset(!addrReset);
                                }else{
                                    setAddr(addr);
                                    setSido(sido);
                                    setSigungu(sigungu);
                                    setRoadname(roadname);
                                }
                            }}
                        />
                        <div className="service_minInputSection">
                            <InputItemBox
                                type="text"
                                inputName="상세 주소"
                                placeholder="상세 주소"
                                value={detailAddr}
                                func={(e) => {setdetailAddr(e)}}
                            />
                        </div>
                        <InputItemBox
                            addClass={carInfo ? "notInput" : ""}
                            type="btnCom"
                            inputName="차량 정보"
                            placeholder="차량 정보"
                            value={carInfo}
                            setChk={dataSet}
                            func={(e) => {setCarInfo(e)}}
                            btnName={carInfo ? "수정" : "직접 입력"}
                            clickEv={(e) => {setCarPopup("open")}}
                            inputClick={(e) => {setCarPopup("open")}}
                            readOnly={true}
                        />
                        <DatePicer
                            inputName="방문 일"
                            placeholder="방문 일"
                            value={startDate}
                            dataChk={dateChk}
                            minCount={1}
                            func={(start) => {
                                let nowDate = new Date(),
                                    startDateItem = new Date(setDateTime(start,"yymmdd").replace(/-/g,"/"));
                                let setNowDate = new Date(nowDate.setDate(nowDate.getDate() + 6));

                                if(setNowDate <= startDateItem){
                                    setMinDateChk(true);
                                }else{
                                    setMinDateChk(false);
                                }
                                setStartDate(setDateTime(start,"yymmdd"));
                                setDateChk(!dateChk);
                            }}
                        />
                        <InfoText listType="-" text="출장일시는 담당자 배정 후 유선으로 안내드리고 있습니다."/>
                        <InfoText listType="-" text="예약일 변경 또는 취소 요청의 경우 입고일 기준 3일 전 신청 건만 가능합니다. "/>
                        <InfoText listType="-" text="입고일 기준 2일~당일 요청 건은 변경 또는 환불이 불가능하오니 유의해 주시기 바랍니다."/>
                    </AppSection>
                    <AppSection title="출장 정비 옵션 선택" text="출장 정비 옵션을 선택해주세요.">
                        {carInfo && startDate && addr ? 
                        <>
                            <ChkBox
                                addClass="addServiceItem"
                                id="addServiceItem_2"
                                func={(e)=>{addServiceChk(e,"maintenance");setMaintenanceChk(e.target.checked)}}
                                disabled={!minDateChk || !(maintenanceData?.data?.options?.battery?.length > 0 || maintenanceData?.data?.options?.battery_add_items?.length > 0 || maintenanceData?.data?.options?.engin_oil?.length > 0 || maintenanceData?.data?.options?.engin_oil_add_items?.length > 0) ? true : false}
                                checkedType={maintenanceChk}
                            >
                                <AddServiceItem
                                    name={`출장 정비 서비스 ${!minDateChk || !(maintenanceData?.data?.options?.battery?.length > 0 || maintenanceData?.data?.options?.battery_add_items?.length > 0 || maintenanceData?.data?.options?.engin_oil?.length > 0 || maintenanceData?.data?.options?.engin_oil_add_items?.length > 0) ? "" : `<span class="${maintenancePrice > 0 ? "" : "gColor"}">${maintenancePrice > 0 ? "+" : ""}${comFormat(maintenancePrice)}원</span>`}`}
                                >
                                    {!minDateChk ? 
                                    <AddServiceNotCaption
                                        text="서비스 신청이 가능한 일자가 아닙니다."
                                        subText="(예약일로부터 1주일 이전에 신청이 가능합니다.)"
                                    />
                                    : !(maintenanceData?.data?.options?.battery?.length > 0 || maintenanceData?.data?.options?.battery_add_items?.length > 0 || maintenanceData?.data?.options?.engin_oil?.length > 0 || maintenanceData?.data?.options?.engin_oil_add_items?.length > 0) ? 
                                    <AddServiceNotCaption
                                        text="서비스 신청이 가능한 지역이 아닙니다."
                                    />
                                    :""}
                                </AddServiceItem>
                            </ChkBox>
                        </>
                        :<div className="notCar">
                            <img src="/assets/images/icon/not_car.svg"/>
                            신청 정보를 등록해주세요
                        </div>}
                    </AppSection>
                    <AppSection title="기타 정보">
                        <TextAreaItem
                            addClass="outCount"
                            inputName="차량 관련 특이사항 (선택)"
                            placeholder="차량 관련 특이사항 (선택)"
                            max={100}
                            unit="자"
                            maxChk={true}
                            value={contents}
                            func={(e)=>setContents(e)}
                        />
                    </AppSection>
                    <AppSection title="부가서비스 신청">
                        <ChkBox
                            addClass="addServiceItem"
                            id="addServiceItem_1"
                            func={(e)=>{addServiceChk(e,"washBT");setWashBTChk(e.target.checked)}}
                            // disabled={!minDateChk}
                            disabled={true}
                            checkedType={washBTChk}
                        >
                            <AddServiceItem
                                // name={`출장 세차 서비스 ${carFuelType == "BEV" || !minDateChk ? "" : `<span class="gColor">+${comFormat(washBTPrice)}원</span>`}`}
                                name={`출장 세차 서비스`}
                                mChType={true}
                            >
                                <AddServiceNotCaption
                                    text="서비스 준비 중 입니다."
                                />
                                {/* {carFuelType == "BEV" ? 
                                <AddServiceNotCaption
                                    text="서비스가 제공되지 않는 차량입니다."
                                />: !minDateChk ? 
                                <AddServiceNotCaption
                                    text="서비스 신청이 가능한 일자가 아닙니다."
                                    subText="(예약일로부터 1주일 이전에 신청이 가능합니다.)"
                                />
                                :""} */}
                            </AddServiceItem>
                        </ChkBox>
                    </AppSection>
                </div>
                <FixedSection pageBox="servicePage" boxAddClass="service_selInfo_section" addClass="service_selInfo_fixedSection" headerChk={true} footerChk={true}>
                    <div className="service_selInfo_area">
                        <h1 className="service_selInfo_title">서비스 신청 정보</h1>
                        <div className="service_selInfo_list">
                            <ServiceInfoItem
                                name="출장 정비"
                                item={`${comFormat(maintenancePrice)}원`}
                            >
                                {maintenance ? 
                                    <>{maintenance.map((item,i)=>(
                                        <ServiceInfoSubItemBox key={i}>
                                            <ServiceInfoSubItem
                                                name={item.name}
                                                item={`${comFormat(item.price)}원`}
                                            />
                                        </ServiceInfoSubItemBox>
                                    ))}</>
                                : ""}
                            </ServiceInfoItem>
                            {washBT ? 
                            <ServiceInfoItem
                                name="출장 세차"
                                item={`${comFormat(washBTPrice)}원`}
                            >
                                    {washBT.map((item,i)=>(
                                        <ServiceInfoSubItemBox key={i}>
                                            <ServiceInfoSubItem
                                                name={item.name}
                                                item={`${comFormat(item.price)}원`}
                                            />
                                        </ServiceInfoSubItemBox>
                                    ))}
                            </ServiceInfoItem>
                            :""}
                        </div>
                        <div className="service_selInfo_totalArea">
                            <h2 className="service_selInfo_totalTitle">총 결제금액</h2>
                            <h2 className="service_selInfo_total">{comFormat(totalPrice)}원</h2>
                        </div>
                        <BtnBox
                            addClass="pageInType mChType"
                            text={"다음으로"}
                            disabled={btnChk}
                            func={()=>{moveOrder()}}
                        />
                    </div>
                    <div className="order_info_imgBox mItem">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <div className="order_info_img pcItem"></div>
                </FixedSection>
            </div>
            <ErrPopup
                data={errPopupData}
            />
            <RightPopup
                addClass="minVar"
                text={carPopup}
                addCarNumber={true}
                topTitle="차량 정보 등록"
                closePopup={() => {setCarPopup("")}}
                carNumber={""}
                car_info={null}
                carAdd={true}
                addCarFunc={(size,type,year,number,id,name,fuelType)=>{addCarFunc(size,type,year,number,id,name,fuelType);allDataReset()}}
            />
            <RightPopup
                data={maintenanceData}
                text={maintenancePopup}
                topTitle="출장 정비 서비스"
                closePopup={() => {setMaintenancePopup("");setMaintenanceChk(false)}}
                maintenanceAdd={true}
                addPopupFunc={(data,total)=>{setMaintenancePopup("");maintenanceDataSet(data,total)}}
            />
            <RightPopup
                data={null}
                text={washBTPopup}
                topTitle="출장 세차 서비스"
                closePopup={() => {setWashBTPopup("");setWashBTChk(false)}}
                washBTAdd={true}
                addPopupFunc={(data)=>{setWashBTPopup("");washBTDataSet(data)}}
            />
            <PrictInfoPopup
                data={prictInfoData}
                closePopup={() => {setPrictInfoData(null)}}
            />
        </div>
    );
};

export default Maintenance;