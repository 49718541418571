import axios from "axios";
import React from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { SelectCarMakerWrapper } from "./SelectCarMaker";

function SelectCarModel({ token, maker, onSelect }) {
  const [models, setModels] = useState([]);

  const getCarModels = useCallback(async () => {
    if (!token) return;

    const { data } = await axios.get(
      `${process.env.REACT_APP_OPEN_API_URL}/runoil-cars/v1/makers/${maker.id}/models`,
      {
        headers: {
          access_token: token,
        },
      }
    );

    setModels(data.data);
  }, [maker.id, token]);

  useEffect(() => {
    getCarModels();
  }, [getCarModels]);

  return (
    <Wrapper>
      <h2 className="carAdd_title">
        <b>차량 모델</b>를 선택해 주세요.
      </h2>

      <div className="maker_info">
        <img src={maker.emblemImage} alt="emblemImage" />
        <h4>{maker.koreanName}</h4>
      </div>

      <div className="car_info_list_section">
        {models.map((model) => (
          <button
            type="button"
            className="btn_selCarType btn_car_model"
            onClick={() => onSelect(model)}
            key={maker.id}
          >
            {model.koreanName}
          </button>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled(SelectCarMakerWrapper)`
  & .maker_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    margin-bottom: 0.9375em;
  }

  & .maker_info > img {
    width: 3.75em;
    height: 1.875em;
    object-fit: cover;
  }

  & .maker_info > h4 {
    font-size: 1.125;
    font-weight: 500;
    color: rgba(78, 78, 78, 1);
  }

  & .btn_car_model {
    height: 4em;
  }
`;

export default SelectCarModel;
