import React from "react";
import styled from "styled-components";

const SERVICE_LIST = [
  "차량 정비",
  "사고 수리",
  "정기 검사",
  "디테일링 세차",
  "에바크리닝(에어컨 내부청소)",
  "광택",
  "왁스",
  "유리막 코팅",
  "블랙박스",
  "썬팅",
  "기타 서비스(문의)",
];

function ServiceDescription() {
  return (
    <Wrapper>
      <p className="description red">
        * 해당서비스는 제주지역에서만 이용 가능합니다.
      </p>
      <p className="description gray mt-12">* 서비스 항목</p>
      <div className="mt-12 service_list_wrapper">
        {SERVICE_LIST.map((service, index) => (
          <span key={index}>{service}</span>
        ))}
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 1.25em;

  & .description {
    font-size: 0.8125em;
  }

  & .red {
    color: #fc4747;
  }

  & .gray {
    color: #7e7e7e;
  }

  & .mt-12 {
    margin-top: 0.75em;
  }

  & .service_list_wrapper {
    margin-bottom: 2.1875em;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    & > span {
      display: flex;
      align-items: center;
      margin-top: 1px;
      font-size: 0.75em;
      height: 24px;
      padding: 0 8px;
      color: #9e9e9e;
      border-radius: 17px;
      background-color: #f3f3f3;
    }
  }
`;

export default ServiceDescription;
