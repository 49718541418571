function CarInfoSubItemBox(data){
    return (
        <div className="rentCarInfo_subItem">
            <h3 className="rentCarInfo_subItem_title">{data.title}</h3>
            <div className={`rentCarInfo_subItemList ${data.addClass}`}>
                {data.children}
            </div>
        </div>
    );
}

function CarInfoSubItem(data){
    return (
        <p className="rentCarInfo_subItemText">
            {data.children}
        </p>
    );
}

export {CarInfoSubItemBox, CarInfoSubItem}