//memberbox
export default function MemberBox(data){
    return (
        <div className={`memberSection ${data.addClass ? data.addClass : ""}`}>
            <div className="memberTitleArea">
                <div className={`memberTitleBox ${data.pcChk ? "pcItem" : ""}`}>
                    <div className="memberTitleItem">
                        {!data.backFunc ? "" : <button type="button" className="memberTitleBack" onClick={(e) => data.backFunc(e)}><img src="/assets/images/basic/back.svg"/></button>}
                        <h1 className="memberTitle">{data.title}</h1>
                    </div>
                    {data.caption ? <p className="memberCaption" dangerouslySetInnerHTML={{__html:data.caption}}></p> : ""}
                </div>
                {data.subTitle ? <p className="memberSubTitle" dangerouslySetInnerHTML={{__html:data.subTitle}}></p> : ""}
            </div>
            <div className="memberArea">
                {data.children}
            </div>
        </div>
    );
}