import { cloneElement, useEffect, useState } from "react";
import { BtnBox, PopupBtnBox } from "./btns";
import {
  ChkBox,
  CustomSelect,
  FileItemMultiple,
  InputItemBox,
  TextAreaItem,
} from "./formItems";
import * as fatchSet from "../../api/api";
import { useRef } from "react";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import {
  bodyScroll_clear,
  bodyScroll_limit,
  comFormat,
  slideToggle,
} from "js/function";
import { InfoText } from "component/app/subItems";
import useGet from "api/useGet";
import { CarInfoSubItem, CarInfoSubItemBox } from "component/app/rent";
import { Fragment } from "react";
import DirectRegisterCar from "component/common/DirectRegisterCar";

//우측 슬라이드 팝업
function RightPopup(data) {
  const [popupOpen, setPopupOpen] = useState("none");
  const [popupData, setPopupData] = useState(null);
  const [popupClass, setPopupClass] = useState("");

  const childRef = useRef(null);

  function chlidFunc() {
    childRef?.current?.popupInfoReset();
  }

  useEffect(() => {
    if (data.text) {
      setPopupData(data.text);
      bodyScroll_limit();
    } else {
      bodyScroll_clear();
    }
  }, [data.text]);

  useEffect(() => {
    if (popupData) {
      setTimeout(() => {
        setPopupOpen("block");
        setTimeout(() => {
          setPopupClass("active");
        }, 100);
      }, 200);
      bodyScroll_limit();
    } else {
      setPopupClass("");
      setTimeout(() => {
        setPopupOpen("none");
      }, 200);
      bodyScroll_clear();
    }
  }, [popupData]);

  useEffect(() => {
    if (!data.text) return;
    return () => {
      setPopupData(null);
      chlidFunc();
    };
  }, [data.text]);

  return (
    <div
      className={`rightPopup ${popupClass ? popupClass : ""} ${
        data.addClass ? data.addClass : ""
      } ${data.bType ? "bPopup" : ""}`}
      style={{ display: popupOpen }}
    >
      <div
        className="popup_cover"
        onClick={(e) => {
          if (data.isClosedFunction) {
            data.closePopup(e);
            return;
          }
          data.closePopup(e);
          setPopupData(null);
          chlidFunc();
        }}
      ></div>
      <div className="rightPopup_section">
        <div className="rightPopup_header_section">
          {data.topTitle ? (
            <h1
              className="rightPopup_title"
              dangerouslySetInnerHTML={{ __html: data.topTitle }}
            ></h1>
          ) : (
            ""
          )}
          <button
            type="button"
            className="btn_back rightPopupClose"
            onClick={(e) => {
              if (data.isClosedFunction) {
                data.closePopup(e);
                return;
              }
              data.closePopup(e);
              setPopupData(null);
              chlidFunc();
            }}
          >
            <img
              src={`/assets/images/basic/${
                data.bType ? "close_w.svg" : "close.svg"
              }`}
              alt="back_icon"
            />
          </button>
        </div>
        <div
          className={`rightPopup_contents ${
            data.popupBtnFunc ? "addBtnContents" : ""
          }`}
        >
          {data.title ? (
            <div className="rightPopup_title_area">
              <h1
                className="rightPopup_title"
                dangerouslySetInnerHTML={{ __html: data.title }}
              ></h1>
            </div>
          ) : (
            ""
          )}
          {data.text &&
          !data.rentCancelPopup &&
          !data.rentCarInfoPopup &&
          !data.withdrawal &&
          !data.carAdd &&
          !data.oilAdd &&
          !data.washAdd &&
          !data.inspectionAdd &&
          !data.managementAdd &&
          !data.repairAdd &&
          !data.maintenanceAdd &&
          !data.washBTAdd &&
          !data.writePopup ? (
            <div
              className="terms_text yScroll"
              dangerouslySetInnerHTML={{ __html: data.text }}
            ></div>
          ) : (
            ""
          )}
          {data.children && data.children}
          {data.writePopup ? (
            <CsWritePopup
              ref={childRef}
              cancelType={data.cancelType}
              cancelNumber={data.cancelNumber}
              cancelId={data.cancelId}
              popupClose={() => {
                setPopupData(null);
                data.closePopup();
              }}
              func={() => {
                data.addFunc();
                data.closePopup();
                setPopupData(null);
              }}
            />
          ) : (
            ""
          )}
          {data.rentCancelPopup ? (
            <RentCancelPopup
              ref={childRef}
              cancelNumber={data.cancelNumber}
              cancelId={data.cancelId}
              popupClose={() => {
                setPopupData(null);
                data.closePopup();
              }}
              func={() => {
                data.addFunc();
                data.closePopup();
                setPopupData(null);
              }}
            />
          ) : (
            ""
          )}

          {data.carAdd ? (
            <CarAddPopup
              ref={childRef}
              carNumber={data.carNumber}
              carInfo={data.car_info}
              addCarNumber={data.addCarNumber ? true : false}
              isDirectRegister={data.car_info?.isDirectRegister}
              popupClose={() => {
                setPopupData(null);
                data.closePopup();
              }}
              addCarFunc={(
                carSizeVal,
                carType,
                carYear,
                carNumber,
                carId,
                carName,
                fuelType
              ) => {
                data.addCarFunc(
                  carSizeVal,
                  carType,
                  carYear,
                  carNumber,
                  carId,
                  carName,
                  fuelType
                );
              }}
            />
          ) : (
            ""
          )}

          {data.oilAdd ? (
            <OilAddPopup
              ref={childRef}
              data={data.data}
              popupClose={() => {
                setPopupData(null);
              }}
              func={(id, name, price, thum) => {
                data.addPopupFunc(id, name, price, thum);
              }}
            />
          ) : (
            ""
          )}
          {data.washAdd ? (
            <AddServicesPopup
              type="wash"
              ref={childRef}
              data={data.data}
              popupClose={() => {
                setPopupData(null);
              }}
              func={(price, wax, waxPrice, name) => {
                data.addPopupFunc(price, wax, waxPrice, name);
              }}
            />
          ) : (
            ""
          )}
          {data.inspectionAdd ? (
            <AddServicesPopup
              type="inspection"
              ref={childRef}
              data={data.data}
              popupClose={() => {
                setPopupData(null);
              }}
              func={(price, name) => {
                data.addPopupFunc(price, name);
              }}
            />
          ) : (
            ""
          )}
          {data.managementAdd ? (
            <AddServicesPopup
              type="management"
              ref={childRef}
              data={data.data}
              popupClose={() => {
                setPopupData(null);
              }}
              func={(manage, etc) => {
                data.addPopupFunc(manage, etc);
              }}
            />
          ) : (
            ""
          )}
          {data.repairAdd ? (
            <AddServicesPopup
              type="repair"
              ref={childRef}
              data={data.data}
              popupClose={() => {
                setPopupData(null);
              }}
              func={(repairImg) => {
                data.addPopupFunc(repairImg);
              }}
            />
          ) : (
            ""
          )}

          {data.maintenanceAdd ? (
            <AddSMaintenancePopup
              type="maintenance"
              ref={childRef}
              data={data.data}
              popupClose={() => {
                setPopupData(null);
              }}
              func={(item, total) => {
                data.addPopupFunc(item, total);
              }}
            />
          ) : (
            ""
          )}
          {data.washBTAdd ? (
            <AddServicesPopup
              type="washBT"
              ref={childRef}
              data={data.data}
              popupClose={() => {
                setPopupData(null);
              }}
              func={(item) => {
                data.addPopupFunc(item);
              }}
            />
          ) : (
            ""
          )}

          {data.withdrawal ? (
            <WithdrawalPopup
              ref={childRef}
              popupClose={() => {
                setPopupData(null);
              }}
              func={() => {
                data.addPopupFunc();
              }}
            />
          ) : (
            ""
          )}

          {data.rentCarInfoPopup ? (
            <RentCarInfoPopup
              ref={childRef}
              carName={data.carName}
              data={data.data}
              popupClose={() => {
                setPopupData(null);
                data.closePopup();
              }}
              func={() => {
                data.addFunc();
                data.closePopup();
                setPopupData(null);
              }}
            />
          ) : (
            ""
          )}
        </div>
        {data.btnType == "only" ? (
          <BtnBox
            addClass="mChType pcMinBtn"
            btnAddClass="line"
            text="확인하고 동의합니다."
            func={(e) => {
              data.popupBtnFunc(data.id);
              setPopupData(null);
              data.closePopup(e);
            }}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

//로딩
function LoadingBox(data) {
  return (
    <div className={`loadingBox ${data.addClass ? data.addClass : ""}`}>
      <div
        id="loading"
        className="loading"
        dangerouslySetInnerHTML={{ __html: data.loadingItem }}
      ></div>
    </div>
  );
}

//하단 메세지 문구
let timeOut = "";
function BottomErrMsg(data) {
  const [popupOpen, setPopupOpen] = useState("none");
  const [popupData, setPopupData] = useState(null);
  const [popupClass, setPopupClass] = useState("");

  useEffect(() => {
    if (data.text && data.text !== "") {
      clearTimeout(timeOut);

      setPopupData(data.text);

      setPopupOpen("block");
      setTimeout(() => {
        setPopupClass("active");
      }, 100);

      timeOut = setTimeout(() => {
        setPopupClass("");
        setTimeout(() => {
          setPopupOpen("none");
        }, 200);
      }, 2000);
    }
  }, [data.chk]);

  return (
    <p
      className={`bottomErrMsg ${popupClass ? popupClass : ""} ${
        data.addClass ? data.addClass : ""
      }`}
      style={{ display: popupOpen }}
      dangerouslySetInnerHTML={{ __html: popupData }}
    ></p>
  );
}

//기본팝업
function Popup(data) {
  const [popupOpen, setPopupOpen] = useState("none");
  const [popupData, setPopupData] = useState(null);
  const [popupClass, setPopupClass] = useState("");

  useEffect(() => {
    setPopupData(data.data);
  }, [data.data]);

  useEffect(() => {
    if (popupData) {
      setTimeout(() => {
        setPopupOpen("block");
        setTimeout(() => {
          setPopupClass("active");
        }, 100);
      }, 200);
      bodyScroll_limit();
    } else {
      setPopupClass("");
      setTimeout(() => {
        setPopupOpen("none");
      }, 200);
      bodyScroll_clear();
    }
  }, [popupData]);

  return (
    <div
      className={`popup ${popupClass ? popupClass : ""} ${
        popupData?.addClass ? popupData?.addClass : ""
      }`}
      style={{ display: popupOpen }}
    >
      <div
        className="popup_cover"
        onClick={(e) => {
          popupData?.closeType && setPopupData(null);
          popupData?.closeType && popupData?.closeFunc(e);
        }}
      ></div>
      <div className="popupSetion">
        <div className="popupTextBox">
          <h2
            className="popupTitle"
            dangerouslySetInnerHTML={{ __html: popupData?.title }}
          ></h2>
          <p
            className="popupText"
            dangerouslySetInnerHTML={{ __html: popupData?.text }}
          ></p>
        </div>
        <div className={`popup_btnBox ${popupData?.btn1 ? "col2" : ""}`}>
          <button
            type="button"
            className="btn_popup btn_popupLeft"
            onClick={(e) => {
              setPopupData(null);
              popupData?.btnFunc0(e);
            }}
          >
            {popupData?.btn0}
          </button>
          {popupData?.btn1 ? (
            <button
              type="button"
              className="btn_popup btn_popupRight"
              onClick={(e) => {
                setPopupData(null);
                popupData?.btnFunc1(e);
              }}
            >
              {popupData?.btn1}
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

//선택팝업
function SelPopup(data) {
  const [popupOpen, setPopupOpen] = useState("none");
  const [popupData, setPopupData] = useState(null);
  const [popupClass, setPopupClass] = useState("");

  useEffect(() => {
    setPopupData(data.data);
  }, [data.data]);

  useEffect(() => {
    if (popupData) {
      setTimeout(() => {
        setPopupOpen("block");
        setTimeout(() => {
          setPopupClass("active");
        }, 100);
      }, 200);
      bodyScroll_limit();
    } else {
      setPopupClass("");
      setTimeout(() => {
        setPopupOpen("none");
      }, 200);
      bodyScroll_clear();
    }
  }, [popupData]);

  return (
    <div
      className={`selPopup ${popupClass ? popupClass : ""} ${
        popupData?.addClass ? popupData?.addClass : ""
      }`}
      style={{ display: popupOpen }}
    >
      <div
        className="popup_cover"
        onClick={(e) => {
          popupData?.closeType && setPopupData(null);
          popupData?.closeType && popupData?.closeFunc(e);
        }}
      ></div>
      <div className="selPopupSetion">
        {popupData?.title ? (
          <div className="selPopupTextBox">
            <h2
              className="popupTitle"
              dangerouslySetInnerHTML={{ __html: popupData?.title }}
            ></h2>
          </div>
        ) : (
          ""
        )}
        {data.children}
        <div className="selPopup_selBox yScroll">
          {popupData?.dataKey
            ? popupData?.data?.length > 0
              ? popupData?.data.map((item, i) => (
                  <button
                    type="button"
                    key={i}
                    className={`selPopup_sel ${
                      item[popupData?.dataKey] == popupData?.sel ? "active" : ""
                    }`}
                    onClick={(e) => {
                      setPopupData(null);
                      popupData?.func({
                        val: item.id,
                        text: item[popupData?.dataKey],
                      });
                    }}
                  >
                    {item[popupData?.dataKey]}
                  </button>
                ))
              : ""
            : popupData?.items?.map((item, i) => (
                <button
                  type="button"
                  key={i}
                  className={`selPopup_sel ${
                    item == popupData?.sel ? "active" : ""
                  }`}
                  onClick={(e) => {
                    setPopupData(null);
                    popupData?.func({ val: popupData?.val[i], text: item });
                  }}
                >
                  {item}
                </button>
              ))}
        </div>
      </div>
    </div>
  );
}

//커스텀팝업
function CustomPopup(data) {
  const [popupOpen, setPopupOpen] = useState("none");
  const [popupData, setPopupData] = useState(null);
  const [popupClass, setPopupClass] = useState("");

  useEffect(() => {
    setPopupData(data.children);
  }, [data.children]);

  useEffect(() => {
    if (popupData) {
      setTimeout(() => {
        setPopupOpen("block");
        setTimeout(() => {
          setPopupClass("active");
        }, 100);
      }, 200);
      bodyScroll_limit();
    } else {
      setPopupClass("");
      setTimeout(() => {
        setPopupOpen("none");
      }, 200);
      bodyScroll_clear();
    }
  }, [popupData]);

  function closePopup() {
    setPopupData(null);
  }

  return (
    <div
      className={`popup ${popupClass ? popupClass : ""} ${
        data?.addClass ? data?.addClass : ""
      }`}
      style={{ display: popupOpen }}
    >
      <div
        className="popup_cover"
        onClick={(e) => {
          data?.closeType && closePopup();
          data?.closeType && data.closePopup(e);
        }}
      ></div>
      <div className="popupSetion" style={{ maxWidth: data?.maxSize }}>
        <div className="popup_header_section">
          {data.topTitle ? (
            <h1
              className="popup_title"
              dangerouslySetInnerHTML={{ __html: data.topTitle }}
            ></h1>
          ) : (
            ""
          )}
          {data?.closeType ? (
            <button
              type="button"
              className="btn_back popupClose"
              onClick={(e) => {
                closePopup();
                data.closePopup(e);
              }}
            >
              <img
                src={`/assets/images/basic/${
                  data.bType ? "close_w.svg" : "close.svg"
                }`}
                alt="back_icon"
              />
            </button>
          ) : (
            ""
          )}
        </div>
        {data.children && cloneElement(data.children, { closePopup })}
      </div>
    </div>
  );
}

//에러팝업
function ErrPopup(data) {
  const [popupOpen, setPopupOpen] = useState("none");
  const [popupData, setPopupData] = useState(null);
  const [popupClass, setPopupClass] = useState("");

  useEffect(() => {
    setPopupData(data.data);
  }, [data.data]);

  useEffect(() => {
    if (popupData) {
      setTimeout(() => {
        setPopupOpen("block");
        setTimeout(() => {
          setPopupClass("active");
        }, 100);
      }, 200);
      bodyScroll_limit();
    } else {
      setPopupClass("");
      setTimeout(() => {
        setPopupOpen("none");
      }, 200);
      bodyScroll_clear();
    }
  }, [popupData]);

  if (!popupData) return;
  return (
    <div
      className={`errPopup ${popupClass ? popupClass : ""} ${
        popupData?.addClass ? popupData?.addClass : ""
      }`}
      style={{ display: popupOpen }}
    >
      <div
        className="popup_cover"
        onClick={(e) => {
          popupData?.closeType && setPopupData(null);
          popupData?.closeType && popupData?.closeFunc(e);
        }}
      ></div>
      <div className="errPopupSetion">
        <div className="errPopupTextBox">
          <div
            className="errPopupText"
            dangerouslySetInnerHTML={{ __html: popupData?.text }}
          ></div>
        </div>
        <PopupBtnBox
          text={popupData?.errBtnText ? popupData?.errBtnText : "확인"}
          btnAddClass={`line ${
            popupData?.btnAddClass ? popupData?.btnAddClass : ""
          }`}
          func={() => {
            setPopupData(null);
            popupData?.btnFunc();
          }}
          textSub={popupData?.textSub}
          btnAddClassSub={popupData?.btnAddClassSub}
          funcSub={() => {
            setPopupData(null);
            popupData?.funcSub();
          }}
        />
      </div>
    </div>
  );
}

//가격 기준 안내팝업
function PrictInfoPopup(data) {
  const [popupOpen, setPopupOpen] = useState("none");
  const [popupData, setPopupData] = useState(null);
  const [popupClass, setPopupClass] = useState("");

  useEffect(() => {
    setPopupData(data.data);
  }, [data.data]);

  useEffect(() => {
    if (popupData) {
      setTimeout(() => {
        setPopupOpen("block");
        setTimeout(() => {
          setPopupClass("active");
        }, 100);
      }, 200);
      bodyScroll_limit();
    } else {
      setPopupClass("");
      setTimeout(() => {
        setPopupOpen("none");
      }, 200);
      bodyScroll_clear();
    }
  }, [popupData]);

  function PrictInfoItem(infoData) {
    return (
      <div
        className={`prictInfoItem ${
          infoData?.addClass ? infoData?.addClass : ""
        }`}
      >
        <p className="prictInfoName">{infoData.name}</p>
        <p className="prictInfoVal">{infoData.val}</p>
      </div>
    );
  }

  return (
    <div
      className={`prictInfoPopup ${popupClass ? popupClass : ""} ${
        popupData?.addClass ? popupData?.addClass : ""
      }`}
      style={{ display: popupOpen }}
    >
      <div
        className="popup_cover"
        onClick={(e) => {
          setPopupData(null);
          popupData?.closeFunc();
        }}
      ></div>
      <div className="prictInfoPopupSection">
        <div className="order_info_up"></div>
        <div className="prictInfoPopupArea">
          <div className="prictInfoPopupTextBox">
            <h1 className="prictInfoPopupText">
              공항 주차 ({popupData?.type})
            </h1>
          </div>
          <div className="prictInfoList">
            <PrictInfoItem
              name="입고 예정"
              val={
                popupData?.carInfo &&
                popupData?.startDate &&
                popupData?.startTime
                  ? `${popupData?.startDate} ${popupData?.startTime}`
                  : "-"
              }
            />
            <PrictInfoItem
              name="출고 예정"
              val={
                popupData?.carInfo && popupData?.endDate && popupData?.endTime
                  ? `${popupData?.endDate} ${popupData?.endTime}`
                  : "-"
              }
            />
            <PrictInfoItem
              name="평일 (월-목)"
              val={
                popupData?.carInfo &&
                popupData?.startDate &&
                popupData?.weekdayCount > 0
                  ? `${comFormat(popupData?.weekday_price)}원 × ${
                      popupData?.weekdayCount
                    }일`
                  : "-"
              }
            />
            <PrictInfoItem
              name="주말 (금-일) 및 공휴일"
              val={
                popupData?.carInfo &&
                popupData?.startDate &&
                popupData?.weekendCount > 0
                  ? `${comFormat(popupData?.weekend_price)}원 × ${
                      popupData?.weekendCount
                    }일`
                  : "-"
              }
            />
            {popupData?.pickup == 1 ? (
              <PrictInfoItem
                name="픽업서비스"
                val={`${comFormat(popupData?.pickPrice)}원`}
              />
            ) : (
              ""
            )}
          </div>
          <PrictInfoItem
            addClass="prictInfoTotal"
            name="서비스 이용 요금"
            val={`${
              popupData?.carInfo && popupData?.startDate
                ? popupData?.pickup == 1
                  ? comFormat(
                      Number(popupData?.servicePrice) +
                        Number(popupData?.pickPrice)
                    )
                  : comFormat(popupData?.servicePrice)
                : 0
            }원`}
          />
          <PopupBtnBox
            text={"확인"}
            btnAddClass="line"
            func={() => {
              setPopupData(null);
              popupData?.closeFunc();
            }}
          />
        </div>
        <div className="order_info_down"></div>
      </div>
    </div>
  );
}

//차량등록 팝업
function CarInfoNumberBox(data) {
  return (
    <div className="carAdd_carNumberBox">
      <p className="carAdd_carNumber">{data.carNumber}</p>
    </div>
  );
}

function CarInfo(data) {
  return (
    <>
      <CarInfoNumberBox carNumber={data.carNumber} />
      <p className="carAdd_carInfo">{data.children}</p>
    </>
  );
}
const CarAddPopup = forwardRef((data, ref) => {
  const [isDirectRegister, setIsDirectRegister] = useState(
    data?.carInfo?.isDirectRegister
  );

  const [carInfo, setCarInfo] = useState(data?.carInfo?.data);

  const [carPage, setCarPage] = useState(data.addCarNumber ? 0 : 1);
  const [carSize, setCarSize] = useState(null);
  const [carSizeVal, setCarSizeVal] = useState(null);
  const [carType, setCarType] = useState(null);
  const [carYear, setCarYear] = useState("");
  const [carNumber, setCarNumber] = useState("");
  const [carId, setCarId] = useState(null);

  const [loading, setLoading] = useState(false);
  const [errPopupData, setErrPopupData] = useState(null);

  const [btnChk, setBtnChk] = useState(true);
  const [carNumberBtnChk, setCarNumberBtnChk] = useState(true);
  const [viewType, setViewType] = useState("");

  function popupInfoReset() {
    setCarInfo(null);
    setCarPage(data.addCarNumber ? 0 : 1);
    setCarSize(null);
    setCarSizeVal(null);
    setCarType(null);
    setCarYear("");
    setCarNumber("");
    setCarId(null);
    setErrPopupData(null);
    setBtnChk(true);
    setCarNumberBtnChk(true);
  }

  useImperativeHandle(ref, () => ({
    popupInfoReset,
  }));

  function CarInfoText(data) {
    return (
      <p
        className="carInfoText"
        dangerouslySetInnerHTML={{ __html: data.info }}
      ></p>
    );
  }

  function addCarInfo() {
    setLoading(true);

    fatchSet.FatchApi({
      type: "GET",
      url: `/car/info?car_number=${carNumber}`,
      loginType: "login",
      success: (item) => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
        setCarInfo(item.data.car_info.data);
        setCarId(
          item.data.car_id ? item.data.car_id : item.data.car_info.data.id
        );
        setCarPage(1);
      },
      err: (item) => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
        if (item.alert) {
          setErrPopupData({
            addClass: null,
            text: "차량 번호가 정확하게 조회되지 않습니다.<br/>차량 정보를 직접 등록하시겠습니까?",
            closeType: false,
            closeFunc: (e) => {},
            btnFunc: (e) => {
              setIsDirectRegister(true);
              setErrPopupData(null);
            },
            errBtnText: "직접 입력",
            textSub: "아니오",
            btnAddClassSub: "notLine",
            funcSub: () => {},
          });
        }
      },
    });
  }

  useEffect(() => {
    setCarInfo(data?.carInfo?.data);
  }, [data.carInfo]);

  useEffect(() => {
    if (carNumber !== "") {
      setCarNumberBtnChk(false);
    } else {
      setCarNumberBtnChk(true);
    }
  }, [carNumber]);

  useEffect(() => {
    if (carYear !== "") {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [carYear]);

  const handleSelectCarInfo = ({ maker, model }) => {
    setIsDirectRegister(false);
    setCarPage(2);
    setCarInfo({ model, name: `${maker.koreanName} ${model.koreanName}` });
  };

  if (!!isDirectRegister) {
    return (
      <DirectRegisterCar
        viewType={viewType}
        onSelectCarInfo={handleSelectCarInfo}
        handlePreEvent={() => {
          setIsDirectRegister(false);
          setCarPage(1);
          data.popupClose();
          localStorage.removeItem("view");
        }}
      />
    );
  }

  return (
    <>
      <div
        className={`carAddPopup rightPopupPageItemBox ${
          carPage == 0
            ? "onlyBtn"
            : carPage == 1
            ? "onlyBtn_v2"
            : carPage == 4
            ? "maxSize"
            : carPage == 5
            ? "onlyBtn_v2"
            : "minSize"
        }`}
      >
        <div className={`carAddPopup_section rightPopupPageItem`}>
          <h2 className="carAdd_title">
            {carPage == 0 && (
              <>
                <b>차량 번호</b>를 입력해 주세요.
              </>
            )}
            {carPage == 1 && <>등록하시려는 차량이 맞나요?</>}
            {carPage == 2 && (
              <>
                <b>차량 크기</b>를 선택해 주세요.
              </>
            )}
            {carPage == 3 && (
              <>
                <b>차종</b>을 선택해 주세요.
              </>
            )}
            {carPage == 4 && (
              <>
                <b>차량의 연식</b>을 입력해 주세요.
              </>
            )}
            {carPage == 5 && (
              <>
                <b>입력한 차량 정보가 맞나요?</b>
                <span>
                  차량 정보가 부정확할 경우,
                  <br />
                  실제 요금과 상이할 수 있습니다.
                </span>
              </>
            )}
          </h2>
          {carPage == 0 ? (
            <InputItemBox
              type="text"
              inputName="차량 번호"
              placeholder="차량 번호"
              value={carNumber}
              max={10}
              regexp={/[a-zA-Z#?!@$ %^&*-]/gi}
              func={(e) => {
                setCarNumber(e);
              }}
              caption="예시) 12가1234"
            />
          ) : carPage == 1 ? (
            <>
              <CarInfo
                carNumber={data.addCarNumber ? carNumber : data.carNumber}
              >
                {carInfo?.productionStartYear &&
                  carInfo?.productionStartYear + " ~ "}
                {carInfo?.productionEndYear && carInfo?.productionEndYear}
                {carInfo?.productionStartYear && <span className="dot"></span>}
                {carInfo?.model?.maker?.koreanName &&
                  carInfo?.model?.maker?.koreanName}{" "}
                {carInfo?.model?.koreanName && carInfo?.model?.koreanName}
              </CarInfo>
            </>
          ) : carPage == 2 ? (
            <>
              <button
                type="button"
                onClick={() => {
                  setCarSize("승용차");
                  setCarSizeVal("승용차");
                  setCarPage(3);
                }}
                className="btn_selCarSize"
              >
                <img src="/assets/images/icon/car_0.svg" />
                <span></span>승용차
              </button>
              <button
                type="button"
                onClick={() => {
                  setCarSize("SUV");
                  setCarSizeVal("SUV");
                  setCarPage(3);
                }}
                className="btn_selCarSize"
              >
                <img src="/assets/images/icon/car_1.svg" />
                <span></span>SUV
              </button>
              <button
                type="button"
                onClick={() => {
                  setCarSize("승합차");
                  setCarSizeVal("승합차");
                  setCarPage(3);
                }}
                className="btn_selCarSize"
              >
                <img src="/assets/images/icon/car_2.svg" />
                <span></span>승합차
              </button>
            </>
          ) : carPage == 3 ? (
            <>
              {carSizeVal == "승용차" ? (
                <>
                  {carInfo?.model?.maker?.national == "DOMESTIC" ? (
                    <button
                      type="button"
                      onClick={() => {
                        setCarType("경차");
                        setCarPage(4);
                      }}
                      className="btn_selCarType"
                    >
                      <h3 className="btn_selCarType_type">경차</h3>
                      <p className="btn_selCarType_caption">모닝, 스파크 등</p>
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      setCarType("소/준중형");
                      setCarPage(4);
                    }}
                    className="btn_selCarType"
                  >
                    <h3 className="btn_selCarType_type">소/준중형</h3>
                    <p className="btn_selCarType_caption">
                      {carInfo?.model?.maker?.national == "DOMESTIC"
                        ? "아반떼, K3, 레이 등"
                        : "BMW 3, 벤츠 C, 아우디 A4 등"}
                    </p>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setCarType("중형");
                      setCarPage(4);
                    }}
                    className="btn_selCarType"
                  >
                    <h3 className="btn_selCarType_type">중형</h3>
                    <p className="btn_selCarType_caption">
                      {carInfo?.model?.maker?.national == "DOMESTIC"
                        ? "소나타, K5, 말리부 등"
                        : "BMW 5, 벤츠 E, 아우디 A6 등"}
                    </p>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setCarType("대형");
                      setCarPage(4);
                    }}
                    className="btn_selCarType"
                  >
                    <h3 className="btn_selCarType_type">대형</h3>
                    <p className="btn_selCarType_caption">
                      {carInfo?.model?.maker?.national == "DOMESTIC"
                        ? "그랜저, K7, G70 등"
                        : "BMW 7, 벤츠 S, 아우디 8 등"}
                    </p>
                  </button>
                  {carInfo?.model?.maker?.national == "DOMESTIC" ? (
                    <button
                      type="button"
                      onClick={() => {
                        setCarType("특대형");
                        setCarPage(4);
                      }}
                      className="btn_selCarType"
                    >
                      <h3 className="btn_selCarType_type">특대형</h3>
                      <p className="btn_selCarType_caption">
                        제네시스 G80 이상, K9 등
                      </p>
                    </button>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
              {carSizeVal == "SUV" ? (
                <>
                  {carInfo?.model?.maker?.national == "DOMESTIC" ? (
                    <button
                      type="button"
                      onClick={() => {
                        setCarType("소형 SUV");
                        setCarPage(4);
                      }}
                      className="btn_selCarType"
                    >
                      <h3 className="btn_selCarType_type">소형 SUV</h3>
                      <p className="btn_selCarType_caption">
                        소울, 코나, QM3 등
                      </p>
                    </button>
                  ) : (
                    ""
                  )}
                  <button
                    type="button"
                    onClick={() => {
                      setCarType("중형 SUV");
                      setCarPage(4);
                    }}
                    className="btn_selCarType"
                  >
                    <h3 className="btn_selCarType_type">중형 SUV</h3>
                    <p className="btn_selCarType_caption">
                      {carInfo?.model?.maker?.national == "DOMESTIC"
                        ? "산타페, 소렌토, 투싼, 스포티지 등"
                        : "BMW X3, 티구안 등"}
                    </p>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setCarType("대형 SUV");
                      setCarPage(4);
                    }}
                    className="btn_selCarType"
                  >
                    <h3 className="btn_selCarType_type">대형 SUV</h3>
                    <p className="btn_selCarType_caption">
                      {carInfo?.model?.maker?.national == "DOMESTIC"
                        ? "모하비, 베라크루즈, 펠리세이드 등"
                        : "BMW X6, 벤츠 G 등"}
                    </p>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setCarType("특대형 SUV");
                      setCarPage(4);
                    }}
                    className="btn_selCarType"
                  >
                    <h3 className="btn_selCarType_type">특대형 SUV</h3>
                    <p className="btn_selCarType_caption">
                      {carInfo?.model?.maker?.national == "DOMESTIC"
                        ? "엑티언 스포츠, 렉스틴 스포츠, 렉스턴 등"
                        : "익스플로러, 에스컬레이드, 랭글러"}
                    </p>
                  </button>
                </>
              ) : (
                ""
              )}
              {carSizeVal == "승합차" ? (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      setCarType("10인승 이하");
                      setCarPage(4);
                    }}
                    className="btn_selCarType"
                  >
                    <h3 className="btn_selCarType_type">10인승 이하</h3>
                    <p className="btn_selCarType_caption">
                      카니발, 스타렉스 등
                    </p>
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setCarType("11인승 이상");
                      setCarPage(4);
                    }}
                    className="btn_selCarType"
                  >
                    <h3 className="btn_selCarType_type">11인승 이상</h3>
                    <p className="btn_selCarType_caption">
                      카니발, 스타렉스 등
                    </p>
                  </button>
                </>
              ) : (
                ""
              )}
            </>
          ) : carPage == 4 ? (
            <InputItemBox
              type="number"
              inputName="연식 입력"
              placeholder="연식 입력"
              max={4}
              value={carYear}
              func={(e) => {
                setCarYear(e);
              }}
              caption="숫자만 입력해주세요. 예시) 2022"
            />
          ) : (
            <CarInfo carNumber={data.addCarNumber ? carNumber : data.carNumber}>
              {carInfo?.model?.maker?.koreanName &&
                carInfo?.model?.maker?.koreanName}{" "}
              {carInfo?.model?.koreanName && carInfo?.model?.koreanName}
              <span className="dot"></span>
              {carSize}
              <span className="dot"></span>
              {carType}
              <span className="dot"></span>
              {carYear}
            </CarInfo>
          )}
        </div>
        {carPage == 0 ? (
          <PopupBtnBox
            text="차량 조회"
            btnAddClass="full"
            func={() => {
              addCarInfo();
            }}
            disabled={carNumberBtnChk}
          />
        ) : carPage == 1 ? (
          <PopupBtnBox
            text="네, 맞아요."
            btnAddClass="full"
            func={() => {
              setCarPage(2);
            }}
            textSub="직접 입력"
            btnAddClassSub="line full"
            funcSub={() => {
              setCarPage(0);
              setIsDirectRegister(true);
              setViewType("");
              // popupInfoReset()
            }}
          />
        ) : carPage == 2 ? (
          <PopupBtnBox
            text="이전"
            btnAddClass="line full"
            func={() => {
              if (localStorage.getItem("view")) {
                setIsDirectRegister(true);
                setViewType(localStorage.getItem("view"));
              } else {
                setCarPage(carPage - 1);
              }
            }}
          >
            <CarInfoText
              info={`${
                data.addCarNumber ? carNumber : data.carNumber
              }<span class="dot"></span>${carInfo?.model?.maker?.koreanName} ${
                carInfo?.model?.koreanName
              }`}
            />
          </PopupBtnBox>
        ) : carPage == 3 ? (
          <PopupBtnBox
            text="이전"
            btnAddClass="line full"
            func={() => {
              setCarPage(carPage - 1);
            }}
          >
            <CarInfoText
              info={`${
                data.addCarNumber ? carNumber : data.carNumber
              }<span class="dot"></span>
                    ${carInfo?.model?.maker?.koreanName} ${
                carInfo?.model?.koreanName
              }<span class="dot"></span>
                    ${carSize}
                    `}
            />
          </PopupBtnBox>
        ) : carPage == 4 ? (
          <PopupBtnBox
            text="다음"
            btnAddClass="full"
            func={() => {
              setCarPage(5);
            }}
            disabled={btnChk}
            textSub="이전"
            btnAddClassSub="line full"
            funcSub={() => {
              setCarPage(carPage - 1);
              setCarYear("");
            }}
          >
            <CarInfoText
              info={`${
                data.addCarNumber ? carNumber : data.carNumber
              }<span class="dot"></span>
                    ${carInfo?.model?.maker?.koreanName} ${
                carInfo?.model?.koreanName
              }<span class="dot"></span>
                    ${carSize}<span class="dot"></span>
                    ${carType}
                `}
            />
          </PopupBtnBox>
        ) : (
          <PopupBtnBox
            text="맞아요"
            btnAddClass="full"
            func={() => {
              window.localStorage.setItem("success", true);
              data.addCarFunc(
                carSizeVal,
                carType,
                carYear,
                carNumber,
                carId,
                carInfo?.model?.koreanName,
                carInfo?.fuelType
              );
              data.popupClose();
              popupInfoReset();
            }}
            textSub="이전"
            btnAddClassSub="line full"
            funcSub={() => {
              setCarPage(carPage - 1);
              setCarYear("");
            }}
          />
        )}
      </div>
      <ErrPopup data={errPopupData} />
      <LoadingBox
        addClass={loading ? "active" : ""}
        loadingItem="<img src='/assets/images/icon/loading.svg' class='spin'/>"
      />
    </>
  );
});

//서비스 팝업 component
function AddServiceTotal(data) {
  return (
    <div className="addServiceTotal">
      <p className="addServiceTotal_name">서비스 이용 요금</p>
      <h1 className="addServiceTotal_price">{comFormat(data.price)}원</h1>
    </div>
  );
}

function AddServiceOptions(data) {
  return (
    <div className="addServiceOptions">
      <p className="addServiceOptions_name">선택 서비스</p>
      <div className="addServiceOptionsList">
        {data.data.map((item, i) => (
          <div key={i} className="addServiceOptionsItem">
            {item.name}
            <button
              type="button"
              onClick={() => {
                data.delFunc(i);
              }}
              className="btn_addServiceOptionDel"
            >
              <img src="/assets/images/basic/del_min.svg" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

function AddServiceItemBox(data) {
  const [openChk, setOpenChk] = useState(false);
  const slideItem = useRef(null);

  return (
    <div className={`addServiceItemArea ${openChk ? "active" : ""}`}>
      <button
        type="button"
        onClick={(e) => {
          slideToggle(slideItem.current, "flex");
          setOpenChk(!openChk);
        }}
        className="addServiceItem_title"
      >
        {data.name}
        <span>{data.nameSub}</span>
      </button>
      <div className="addServiceItem_list" ref={slideItem}>
        {data.children}
      </div>
    </div>
  );
}

//오일 팝업
const OilAddPopup = forwardRef((data, ref) => {
  const [dataInfo, setDataInfo] = useState(data?.data);

  const [oilId, setOilId] = useState(null);
  const [oilName, setOilName] = useState(null);
  const [oilPrice, setOilPrice] = useState(0);
  const [oilThumbnail, setOilThumbnail] = useState(null);

  const [selData, setSelData] = useState([]);

  const [btnChk, setBtnChk] = useState(true);

  function popupInfoReset(resetData) {
    setOilId(null);
    setOilName(null);
    setOilPrice(0);
    setOilThumbnail(null);
    setBtnChk(true);
    let resetArr = [];
    if (resetData) {
      resetArr = [...resetData];
    }
    setSelData(resetArr);
  }

  useImperativeHandle(ref, () => ({
    popupInfoReset,
  }));

  function dateReset(resetData) {
    setOilId(null);
    setOilName(null);
    setOilPrice(0);
    setOilThumbnail(null);
    let resetArr = [];
    if (resetData) {
      resetArr = [...resetData];
    }
    setSelData(resetArr);
  }

  useEffect(() => {
    setDataInfo(data?.data);
  }, [data?.data]);

  function selOil(id, name, price, thum) {
    setOilId(id);
    setOilName(name);
    setOilPrice(price);
    setOilThumbnail(thum);

    let selDataSet = [];
    selDataSet.push({
      id: id,
      name: name,
      price: price,
      thum: thum,
    });
    setSelData(selDataSet);
  }

  useEffect(() => {
    if (oilId && oilName && oilPrice && oilThumbnail) {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [oilId, oilName, oilPrice, oilThumbnail]);

  return (
    <>
      <div className="addServicePopup rightPopupPageItemBox onlyBtn">
        <div className="addServicePopupConstents rightPopupPageItem">
          <AddServiceItemBox
            name="항목 선택"
            nameSub={oilPrice > 0 ? `+${comFormat(oilPrice)}원` : ""}
          >
            <div className="oilList">
              {dataInfo &&
                dataInfo.length > 0 &&
                dataInfo.map((item, i) => (
                  <div key={i} className="oilItem">
                    <input
                      type="radio"
                      name="oilItem"
                      onChange={(e) =>
                        selOil(
                          item?.product.id,
                          item?.product.name,
                          item?.price,
                          item?.product.thumbnailImage
                        )
                      }
                      checked={
                        selData?.filter((el) => el.id == item?.product.id)
                          .length > 0
                      }
                      className="oilItemInput"
                      id={`oilItem_${item?.product.id}`}
                    />
                    <label htmlFor={`oilItem_${item?.product.id}`}>
                      <div
                        className="oilThum"
                        style={{
                          backgroundImage: `url('${item?.product.thumbnailImage}')`,
                        }}
                      >
                        <img src="/assets/images/basic/chk_on.svg" />
                      </div>
                      <div className="oilInfo">
                        <p className="oilName">{item?.product.name}</p>
                        <p className="oilPrice">{comFormat(item?.price)}원</p>
                      </div>
                    </label>
                  </div>
                ))}
            </div>
          </AddServiceItemBox>
          <AddServiceOptions
            data={selData}
            delFunc={(i) => {
              selData.splice(i, 1);
              let selDataArr = [...selData];
              selDataArr.splice(i, 1);
              setSelData(selDataArr);
              dateReset(selDataArr);
            }}
          />
          <AddServiceTotal price={oilPrice} />
        </div>
        <PopupBtnBox
          text="확인"
          addClass="mChType"
          btnAddClass=""
          func={() => {
            data.func(oilId, oilName, oilPrice, oilThumbnail);
            popupInfoReset();
            data.popupClose();
          }}
          disabled={btnChk}
        />
      </div>
    </>
  );
});

function AddServicesRadioItem(data) {
  function dataFunc(e) {
    if (data.type == "wash") {
      data.func(data.i, data.price, data.waxPrice, data.text);
    } else if (data.type == "inspection") {
      data.func(data.i, data.price, data.text);
    } else if (data.type == "management") {
      data.func(data.i, data.text, e.target.checked);
    }
  }

  return (
    <div className="addServicesRadioItem">
      <input
        type={"radio"}
        name={
          data.chkType
            ? `addServicesRadioItem_${data.i}`
            : "addServicesRadioItem"
        }
        onChange={(e) => dataFunc(e)}
        checked={data?.data?.filter((el) => el.id == data.i).length > 0}
        className="addServicesRadio"
        id={`addServicesRadioItem_${data.i}`}
      />
      <label htmlFor={`addServicesRadioItem_${data.i}`}>
        <div className="addServicesRadioItemText">
          <img src="/assets/images/basic/chk_icon.svg" />
          {data.text}
        </div>
        {data.price ? (
          <p className="addServicesRadioItemPrice">
            {data.type == "wash"
              ? `${comFormat(data.waxPrice + data.price)}원`
              : `${comFormat(data.price)}원`}
          </p>
        ) : (
          ""
        )}
      </label>
    </div>
  );
}

//출장정비 팝업
const AddSMaintenancePopup = forwardRef((data, ref) => {
  const [dataInfo, setDataInfo] = useState(data?.data?.data?.options);

  const [maintenancePrice, setMaintenancePrice] = useState(0);
  const [maintenanceAddPrice, setMaintenanceAddPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const [tap, setTap] = useState("engin_oil");
  const [addTap, setAddTap] = useState("engin_oil_add_items");

  const [selData, setSelData] = useState([]);
  const [btnChk, setBtnChk] = useState(true);

  function dateReset() {
    let resetArr = [];
    setSelData(resetArr);
    setMaintenancePrice(0);
    setMaintenanceAddPrice(0);
    setTotalPrice(0);
  }

  function popupInfoReset() {
    setDataInfo(null);
    dateReset();
    setBtnChk(true);
  }

  function dataPost() {
    data.func(selData, totalPrice);
  }

  useImperativeHandle(ref, () => ({
    popupInfoReset,
  }));

  useEffect(() => {
    setDataInfo(data?.data);
  }, [data?.data]);

  function priceSetting(data) {
    let normalArr = [...data],
      normalPrice = 0;
    normalArr = normalArr.filter((el) => el.type == "normal");
    if (normalArr.length == 0) {
      setMaintenancePrice(0);
    } else {
      normalArr.forEach(function (item, i) {
        normalPrice = normalPrice + Number(item.price);
        setMaintenancePrice(normalPrice);
      });
    }

    let addArr = [...data],
      addPrice = 0;
    addArr = addArr.filter((el) => el.type !== "normal");
    if (addArr.length == 0) {
      setMaintenanceAddPrice(0);
    } else {
      addArr.forEach(function (item, i) {
        addPrice = addPrice + Number(item.price);
        setMaintenanceAddPrice(addPrice);
      });
    }
  }

  function selDataSetting(selItem) {
    let selDataSet = [...selData];
    selDataSet = selDataSet.filter((el) => el.subType !== selItem.subType);

    if (selItem.chkType) {
      selDataSet.push({
        id: selItem.id,
        name: selItem.name,
        price: selItem.price,
        thum: selItem.thum,
        type: selItem.type,
        subType: selItem.subType,
      });
    }

    setSelData(selDataSet);
    priceSetting(selDataSet);
  }

  useEffect(() => {
    if (selData.length > 0) {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [selData]);

  useEffect(() => {
    setTotalPrice(maintenanceAddPrice + maintenancePrice);
  }, [maintenanceAddPrice, maintenancePrice]);

  return (
    <>
      <div className="addServicePopup rightPopupPageItemBox onlyBtn">
        <div className="addServicePopupConstents rightPopupPageItem">
          <AddServiceItemBox
            name="일반 상품 선택"
            nameSub={
              maintenancePrice > 0 ? `+${comFormat(maintenancePrice)}원` : ""
            }
          >
            <div className="addServicesListTap">
              <button
                type="button"
                onClick={() => {
                  setTap("engin_oil");
                }}
                className={`addServicesListTapItem ${
                  tap == "engin_oil" ? "active" : ""
                }`}
              >
                엔진 오일
              </button>
              <button
                type="button"
                onClick={() => {
                  setTap("battery");
                }}
                className={`addServicesListTapItem ${
                  tap == "battery" ? "active" : ""
                }`}
              >
                배터리
              </button>
            </div>
            <div className="addServicesList">
              {data?.data?.data?.options &&
              data?.data?.data?.options[tap] &&
              data?.data?.data?.options[tap].length > 0 ? (
                <>
                  {data?.data?.data?.options[tap].map((item, i) => (
                    <div key={i} className="oilItem">
                      <input
                        type="checkbox"
                        name={`${tap}Item`}
                        onChange={(e) =>
                          selDataSetting({
                            id: item.product.id,
                            name: item.product.name,
                            price: item.price,
                            thum: item.product.thumbnailImage,
                            type: "normal",
                            subType: tap,
                            chkType: e,
                          })
                        }
                        checked={
                          selData?.filter(
                            (el) =>
                              el.id == item?.product.id && el.subType == tap
                          ).length > 0
                        }
                        className="oilItemInput"
                        id={`oilItem_${item.product.id}_${tap}`}
                      />
                      <label htmlFor={`oilItem_${item.product.id}_${tap}`}>
                        <div
                          className="oilThum"
                          style={{
                            backgroundImage: `url('${item.product.thumbnailImage}')`,
                          }}
                        >
                          <img src="/assets/images/basic/chk_on.svg" />
                        </div>
                        <div className="oilInfo">
                          <p className="oilName">{item.product.name}</p>
                          <p className="oilPrice">{comFormat(item?.price)}원</p>
                        </div>
                      </label>
                    </div>
                  ))}
                </>
              ) : (
                <p className="notAddServicesList">상품이 없습니다.</p>
              )}
            </div>
          </AddServiceItemBox>
          <AddServiceItemBox
            name="추가 상품 선택"
            nameSub={
              maintenanceAddPrice > 0
                ? `+${comFormat(maintenanceAddPrice)}원`
                : ""
            }
          >
            <div className="addServicesListTap">
              <button
                type="button"
                onClick={() => {
                  setAddTap("engin_oil_add_items");
                }}
                className={`addServicesListTapItem ${
                  addTap == "engin_oil_add_items" ? "active" : ""
                }`}
              >
                기타
              </button>
              <button
                type="button"
                onClick={() => {
                  setAddTap("battery_add_items");
                }}
                className={`addServicesListTapItem ${
                  addTap == "battery_add_items" ? "active" : ""
                }`}
              >
                배터리
              </button>
            </div>
            <div className="addServicesList">
              {data?.data?.data?.options &&
              data?.data?.data?.options[addTap] &&
              data?.data?.data?.options[addTap].length > 0 ? (
                <>
                  {data?.data?.data?.options[addTap].map((item, i) => (
                    <div key={i} className="oilItem">
                      <input
                        type="checkbox"
                        name={`${addTap}Item`}
                        onChange={(e) =>
                          selDataSetting({
                            id: item.id,
                            name: item.name,
                            price: item.price,
                            thum: item.explainThumbnail,
                            type: "add",
                            subType: addTap,
                            chkType: e,
                          })
                        }
                        checked={
                          selData?.filter(
                            (el) => el.id == item?.id && el.subType == addTap
                          ).length > 0
                        }
                        className="oilItemInput"
                        id={`oilItem_${item.id}_${addTap}`}
                      />
                      <label htmlFor={`oilItem_${item.id}_${addTap}`}>
                        <div
                          className="oilThum"
                          style={{
                            backgroundImage: `url('${item.explainThumbnail}')`,
                          }}
                        >
                          <img src="/assets/images/basic/chk_on.svg" />
                        </div>
                        <div className="oilInfo">
                          <p className="oilName">{item.name}</p>
                          <p className="oilPrice">{comFormat(item?.price)}원</p>
                        </div>
                      </label>
                    </div>
                  ))}
                </>
              ) : (
                <p className="notAddServicesList">상품이 없습니다.</p>
              )}
            </div>
          </AddServiceItemBox>
          <AddServiceOptions
            data={selData}
            type={data.type}
            delFunc={(i) => {
              // selData.splice(i,1);
              let selDataArr = [...selData];
              selDataArr.splice(i, 1);
              setSelData(selDataArr);
              priceSetting(selDataArr);
            }}
          />
          <AddServiceTotal price={totalPrice} />
        </div>
        <PopupBtnBox
          text="확인"
          addClass="mChType"
          btnAddClass=""
          func={() => {
            dataPost();
            popupInfoReset();
            data.popupClose();
          }}
          disabled={btnChk}
        />
      </div>
    </>
  );
});

//세차/관리/검사/수리 팝업
const AddServicesPopup = forwardRef((data, ref) => {
  const [dataInfo, setDataInfo] = useState(data?.data);
  // maintenance
  const [washPrice, setWashPrice] = useState(null);
  const [washName, setWashName] = useState(null);
  const [waxPrice, setWaxPrice] = useState(0);

  const [inspectionPrice, setInspectionPrice] = useState(null);
  const [inspectionName, setInspectionName] = useState(null);

  const [manageType, setManageType] = useState([]);
  const [manageEtc, setManageEtc] = useState(null);
  const [manageEtcDataChk, setManageEtcDataChk] = useState(true);

  let repairArr = [];
  const [repairReSet, setRepairReSet] = useState(true);

  const [selData, setSelData] = useState([]);

  const [btnChk, setBtnChk] = useState(true);

  function dateReset(resetArrItem) {
    setWashPrice(null);
    setWaxPrice(0);
    setWashName(null);

    setInspectionPrice(null);
    setInspectionName(null);

    let manageResetArr = [];
    if (resetArrItem) {
      manageResetArr = [...resetArrItem];
    }
    setManageType(manageResetArr);
    setManageEtc(null);
    setManageEtcDataChk(!manageEtcDataChk);

    repairArr = [];
    setRepairReSet(!repairReSet);

    let resetArr = [];
    if (resetArrItem) {
      resetArr = [...resetArrItem];
    }
    setSelData(resetArr);
  }

  function popupInfoReset() {
    setDataInfo(null);

    dateReset();

    setBtnChk(true);
  }

  function dataPost() {
    if (data.type == "wash") {
      let waxType = washPrice == 0 ? 0 : 1;
      data.func(washPrice, waxType, waxPrice, washName);
    } else if (data.type == "inspection") {
      data.func(inspectionPrice, inspectionName);
    } else if (data.type == "management") {
      data.func(manageType, manageEtc);
    } else if (data.type == "repair") {
      data.func(repairArr);
    }
  }

  useImperativeHandle(ref, () => ({
    popupInfoReset,
  }));

  useEffect(() => {
    setDataInfo(data?.data);
  }, [data?.data]);

  function selDataSetting(selItem) {
    if (data.type == "wash") {
      setWashPrice(selItem.price);
      setWaxPrice(selItem.waxPrice);
      setWashName(selItem.name);
      let selDataSet = [];
      selDataSet.push({
        id: selItem.id,
        wax: selItem.waxPrice,
        price: selItem.price,
        name: selItem.name,
      });
      setSelData(selDataSet);
    } else if (data.type == "inspection") {
      setInspectionPrice(selItem.price);
      setInspectionName(selItem.name);
      let selDataSet = [];
      selDataSet.push({
        id: selItem.id,
        price: selItem.price,
        name: selItem.name,
      });
      setSelData(selDataSet);
    } else if (data.type == "management") {
      let setManageArr = [...selData];

      if (selItem.chkType) {
        setManageArr.push({
          id: selItem.id,
          type: selItem.type,
          name: selItem.type,
        });
      } else {
        setManageArr = setManageArr.filter((el) => el.id !== selItem.id);
      }

      setManageType(setManageArr);
      setSelData(setManageArr);
    }
  }

  useEffect(() => {
    if (data.type == "wash" && washPrice) {
      setBtnChk(false);
    } else if (data.type == "inspection" && inspectionPrice) {
      setBtnChk(false);
    } else if (data.type == "management" && manageType.length !== 0) {
      if (manageType.find((e) => e.type === "기타 (직접 입력)")) {
        if (manageEtc) {
          setBtnChk(false);
        } else {
          setBtnChk(true);
        }
      } else {
        setBtnChk(false);
      }
    } else if (data.type == "repair" && repairArr.length >= 3) {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [washPrice, inspectionPrice, manageType, manageEtc, repairArr]);

  return (
    <>
      <div className="addServicePopup rightPopupPageItemBox onlyBtn">
        <div className="addServicePopupConstents rightPopupPageItem">
          <AddServiceItemBox
            name={
              data.type == "wash"
                ? "세차 옵션 선택"
                : data.type == "inspection"
                ? "차량 검사 선택"
                : data.type == "management"
                ? "견적 신청 항목 선택"
                : data.type == "repair"
                ? "수리 부위 사진 업로드"
                : ""
            }
            nameSub={
              data.type == "wash"
                ? `${
                    washPrice > 0 ? `+${comFormat(washPrice + waxPrice)}원` : ""
                  }`
                : data.type == "inspection"
                ? `${
                    inspectionPrice > 0
                      ? `+${comFormat(inspectionPrice)}원`
                      : ""
                  }`
                : ""
            }
          >
            <div
              className={`addServicesList ${
                data.type == "repair" ? "blockList" : ""
              }`}
            >
              {data.type == "wash" ? (
                <>
                  <AddServicesRadioItem
                    type={data.type}
                    i="wash0"
                    price={data?.data?.data?.price?.default_price}
                    waxPrice={0}
                    text="기본 세차"
                    data={selData}
                    func={(id, price, wax, name) => {
                      selDataSetting({
                        id: id,
                        price: price,
                        waxPrice: wax,
                        name: name,
                      });
                    }}
                  />
                  <AddServicesRadioItem
                    type={data.type}
                    i="wash1"
                    price={data?.data?.data?.price?.default_price}
                    waxPrice={data?.data?.data?.price?.wax_add_price}
                    text="고체왁스(코팅) 추가"
                    data={selData}
                    func={(id, price, wax, name) => {
                      selDataSetting({
                        id: id,
                        price: price,
                        waxPrice: wax,
                        name: name,
                      });
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {data.type == "inspection" ? (
                <>
                  <AddServicesRadioItem
                    type={data.type}
                    i="inspection0"
                    price={data?.data?.data?.price}
                    text="차량 검사"
                    data={selData}
                    func={(id, price, name) => {
                      selDataSetting({
                        id: id,
                        price: price,
                        name: name,
                      });
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {data.type == "management" ? (
                <>
                  <AddServicesRadioItem
                    type={data.type}
                    i="management0"
                    chkType={true}
                    text="썬팅"
                    data={selData}
                    func={(id, type, e) => {
                      selDataSetting({
                        id: id,
                        type: type,
                        chkType: e,
                      });
                    }}
                  />
                  <AddServicesRadioItem
                    type={data.type}
                    i="management1"
                    chkType={true}
                    text="블랙박스"
                    data={selData}
                    func={(id, type, e) => {
                      selDataSetting({
                        id: id,
                        type: type,
                        chkType: e,
                      });
                    }}
                  />
                  <AddServicesRadioItem
                    type={data.type}
                    i="management2"
                    chkType={true}
                    text="광택"
                    data={selData}
                    func={(id, type, e) => {
                      selDataSetting({
                        id: id,
                        type: type,
                        chkType: e,
                      });
                    }}
                  />
                  <AddServicesRadioItem
                    type={data.type}
                    i="management3"
                    chkType={true}
                    text="유리막 코팅"
                    data={selData}
                    func={(id, type, e) => {
                      selDataSetting({
                        id: id,
                        type: type,
                        chkType: e,
                      });
                    }}
                  />
                  <AddServicesRadioItem
                    type={data.type}
                    i="management4"
                    chkType={true}
                    text="에바크리닝"
                    data={selData}
                    func={(id, type, e) => {
                      selDataSetting({
                        id: id,
                        type: type,
                        chkType: e,
                      });
                    }}
                  />
                  <AddServicesRadioItem
                    type={data.type}
                    i="management5"
                    chkType={true}
                    text="기타 (직접 입력)"
                    data={selData}
                    func={(id, type, e) => {
                      selDataSetting({
                        id: id,
                        type: type,
                        chkType: e,
                      });
                    }}
                  />
                </>
              ) : (
                ""
              )}
              {data.type == "repair" ? (
                <>
                  <p className="repairCaption">
                    * 견적 산정을 위해 수리가 필요한 부위의 사진을 3장 이상, 5장
                    이하로 업로드해 주세요.
                  </p>
                  <FileItemMultiple
                    inputName=""
                    id="repairImages"
                    max={5}
                    maxSise={null}
                    dataReset={repairReSet}
                    func={(e) => {
                      if (e) {
                        repairArr = [...e];

                        if (repairArr.length >= 3) {
                          setBtnChk(false);
                        } else {
                          setBtnChk(true);
                        }
                        // let files = [...e];
                        // setRepairArr(files);
                      } else {
                        setBtnChk(true);
                      }
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>
            {manageType.length > 0 &&
            manageType.find((e) => e.type === "기타 (직접 입력)") ? (
              <InputItemBox
                addClass="outCount"
                type="count"
                inputName="기타 (직접 입력)"
                placeholder="기타 (직접 입력)"
                max={20}
                unit="자"
                dataChk={manageEtcDataChk}
                value={manageEtc}
                func={(e) => {
                  setManageEtc(e);
                }}
              />
            ) : (
              ""
            )}
          </AddServiceItemBox>
          {data.type !== "repair" ? (
            <AddServiceOptions
              data={selData}
              type={data.type}
              delFunc={(i) => {
                // selData.splice(i,1);
                let selDataArr = [...selData];
                selDataArr.splice(i, 1);
                setSelData(selDataArr);
                if (data.type !== "management") {
                  dateReset();
                } else {
                  setManageType(selDataArr);
                  if (
                    selDataArr.filter((el) => el.type == "기타 (직접 입력)")
                      .length < 1
                  ) {
                    setManageEtc(null);
                    setManageEtcDataChk(!manageEtcDataChk);
                  }
                }
              }}
            />
          ) : (
            ""
          )}
          {data.type == "repair" || data.type == "management" ? (
            <div className="addServiceCaptionList">
              {data.type == "repair" ? (
                <InfoText
                  listType="-"
                  text="외관 수리는 차량의 상태에 따라 가격이 상이하기 때문에 신청, 상담을 통한 금액 예상 견적 후 예약을 확정, 현장 결제합니다."
                />
              ) : data.type == "management" ? (
                <InfoText
                  listType="-"
                  text="관리서비스는 제품별, 차종별 가격이 상이하기 때문에 신청, 상담을 통한 금액 견적 후 고지된 최종 금액을 앱 내에서 결제합니다."
                />
              ) : (
                ""
              )}
              <InfoText
                listType="-"
                text="견적 신청만으로는 서비스 이용이 불가하오니 유의해주시기바랍니다."
              />
            </div>
          ) : (
            ""
          )}
          {data.type == "wash" || data.type == "inspection" ? (
            <AddServiceTotal
              price={
                data.type == "wash" ? washPrice + waxPrice : inspectionPrice
              }
            />
          ) : (
            ""
          )}
        </div>
        <PopupBtnBox
          text="확인"
          addClass="mChType"
          btnAddClass=""
          func={() => {
            dataPost();
            popupInfoReset();
            data.popupClose();
          }}
          disabled={btnChk}
        />
      </div>
    </>
  );
});

//문의 등록
const CsWritePopup = forwardRef((data, ref) => {
  const [type, setType] = useState(
    data.cancelType ? "예약 취소 및 변경 요청" : null
  );
  const [title, setTitle] = useState(
    data.cancelType ? data.cancelNumber : null
  );
  const [contents, setContents] = useState(null);
  const [cancelId, setCancelId] = useState(
    data.cancelType ? data.cancelId : null
  );

  const [setChk, setSetChk] = useState(true);

  const [errPopupData, setErrPopupData] = useState(null);
  const [btnChk, setBtnChk] = useState(true);

  const categoryData = useGet({
    url: `/qna/category`,
    loginType: "login",
  });
  const categoryList = categoryData?.data?.qna_categories;

  function popupInfoReset() {
    if (!data.cancelType) {
      setType(null);
      setTitle(null);
    }
    setContents(null);
    setErrPopupData(null);
    setBtnChk(true);
    setSetChk(!setChk);
  }

  useImperativeHandle(ref, () => ({
    popupInfoReset,
  }));

  function addCs() {
    let formData = new FormData();
    formData.append("category_id", data.cancelType ? 5 : type);
    if (data.cancelType) {
      formData.append("service_id", cancelId);
      formData.append("code", title);
    } else {
      formData.append("title", title);
    }
    formData.append("question", contents);
    // formData.append("service_type", pw);
    // formData.append("service_id", pw);
    // formData.append("code", pw);

    fatchSet.FatchApi({
      type: "POST",
      url: `/qna/register`,
      loginType: "login",
      formDataItem: formData,
      success: (item) => {
        setErrPopupData({
          addClass: null,
          text: `${
            data.cancelType
              ? "예약 취소 요청이 완료되었습니다."
              : "1:1 문의가 완료되었습니다."
          }`,
          closeType: true,
          closeFunc: (e) => {
            data.func();
            popupInfoReset();
            data.popupClose();
            popupInfoReset();
          },
          btnFunc: (e) => {
            data.func();
            popupInfoReset();
            data.popupClose();
            popupInfoReset();
          },
        });
      },
      err: (data) => {
        if (data.alert) {
          setErrPopupData({
            addClass: null,
            text: data.alert,
            closeType: true,
            closeFunc: (e) => {},
            btnFunc: (e) => {},
          });
        }
      },
    });
  }

  useEffect(() => {
    if (type && title && contents && contents.length >= 10) {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [type, title, contents]);

  useEffect(() => {
    if (data.cancelType) {
      setTitle(data.cancelNumber);
      setCancelId(data.cancelId);
    }
    setSetChk(!setChk);
  }, [data.cancelNumber]);

  return (
    <>
      <div className="csWritePopup rightPopupPageItemBox onlyBtn_v2">
        <div className="csWrite_inputSection rightPopupPageItem">
          <CustomSelect
            inputName="문의 유형"
            placeholder="문의 유형"
            value={type}
            func={(e) => {
              setType(e);
            }}
            disabled={data.cancelType ? true : false}
            valKey="id"
            setChk={setChk}
            setValueText={data.cancelType ? true : false}
            nameKey="category"
            options={categoryList}
          />
          <InputItemBox
            type="text"
            inputName={data.cancelType ? "예약 번호" : "문의 제목"}
            placeholder={data.cancelType ? "예약 번호" : "문의 제목"}
            disabled={data.cancelType ? true : false}
            value={title}
            addClass="bColor"
            setChk={setChk}
            func={(e) => {
              setTitle(e);
            }}
          />
          <TextAreaItem
            addClass="outCount"
            inputName="문의 내용"
            placeholder="문의 내용"
            caption="내용은 최소 10자 이상 작성해주세요."
            max={1000}
            unit="자"
            setChk={setChk}
            maxChk={true}
            value={contents}
            func={(e) => setContents(e)}
          />
        </div>
        <PopupBtnBox
          text="작성 완료"
          addClass="mChType"
          btnAddClass="full"
          func={() => {
            addCs();
          }}
          disabled={btnChk}
          textSub="취소"
          btnAddClassSub="line full"
          funcSub={() => {
            data.popupClose();
            popupInfoReset();
          }}
        />
      </div>
      <ErrPopup data={errPopupData} />
    </>
  );
});

//탈퇴
const WithdrawalPopup = forwardRef((data, ref) => {
  const [chk, setChk] = useState(false);
  const [errPopupData, setErrPopupData] = useState(null);
  const [btnChk, setBtnChk] = useState(true);

  function popupInfoReset() {
    setChk(false);
    setErrPopupData(null);
    setBtnChk(true);
  }

  useImperativeHandle(ref, () => ({
    popupInfoReset,
  }));

  function withdrawal() {
    let formData = new FormData();

    fatchSet.FatchApi({
      type: "POST",
      url: `/mypage/withdrawal`,
      loginType: "login",
      formDataItem: formData,
      success: (item) => {
        popupInfoReset();
        data.popupClose();
        data.func();
      },
      err: (data) => {
        if (data.alert) {
          setErrPopupData({
            addClass: null,
            text: data.alert,
            closeType: true,
            closeFunc: (e) => {},
            btnFunc: (e) => {},
          });
        }
      },
    });
  }

  useEffect(() => {
    setBtnChk(!chk);
  }, [chk]);

  return (
    <>
      <div className="withdrawalPopup rightPopupPageItemBox onlyBtn">
        <div className="withdrawalSection rightPopupPageItem">
          <div className="withdrawalArea">
            <h3 className="withdrawal_title">
              탈퇴 전 유의사항을 확인해주세요.
            </h3>
            <InfoText
              listType="1."
              text="탈퇴 시 서비스 이용 기록은 30일 이후 모두 삭제되며, 삭제한 데이터는 복구할 수 없습니다. 단, 서비스 이용시 작성된 게시물은 삭제되지 않습니다."
            />
            <InfoText
              listType="2."
              text="전자상거래법, 통신비밀보호법 등 관련 법령에 의거하여 일정기간 동안 개인 정보가 보관됩니다."
            />
            <p className="withdrawal_subText">
              <b>[개인정보 보존기간]</b>
              <br />
              계약 또는 청약철회 등에 관한 기록 : 5년
              <br />
              결제 및 재화 등의 공급에 의한 기록 : 5년
              <br />
              소비자 불만 및 분쟁처리에 관한 기록 : 5년
              <br />
              웹사이트 방문기록 : 3개월
            </p>
          </div>
          <div className="withdrawalChkBox">
            <ChkBox
              addClass=""
              func={(e) => setChk(e.target.checked)}
              checkedType={chk}
              label="회원 탈퇴 유의사항을 모두 확인했으며,<br/>해당 내용에 동의합니다."
              key="withdrawalChk"
            />
          </div>
        </div>
        <PopupBtnBox
          text="확인"
          addClass="mChType"
          btnAddClass="full line"
          func={() => {
            withdrawal();
          }}
          disabled={btnChk}
        />
      </div>
      <ErrPopup data={errPopupData} />
    </>
  );
});

//렌트카 정보
const RentCarInfoPopup = forwardRef((data, ref) => {
  function popupInfoReset() {}

  useImperativeHandle(ref, () => ({
    popupInfoReset,
  }));

  return (
    <div className="rentCarInfoPopup rightPopupPageItemBox onlyBtn">
      <div className="rentCarInfo_contents rightPopupPageItem">
        <div
          className="rentCarInfo_img"
          style={{ backgroundImage: `url('${data?.data?.image_url}')` }}
        ></div>
        <div className="rentCarInfo">
          <h1 className="rentCarInfo_name">
            {data.carName ? data.carName : ""}
          </h1>
          <CarInfoSubItemBox title="차량 정보">
            <CarInfoSubItem>
              <img src="/assets/images/icon/gasstation.svg" />
              {data?.data?.fuel_type ? data?.data?.fuel_type : ""}
            </CarInfoSubItem>
            <CarInfoSubItem>
              <img src="/assets/images/icon/profile.svg" />
              {data?.data?.seater ? data?.data?.seater : 0}인
            </CarInfoSubItem>
            <CarInfoSubItem>
              <img src="/assets/images/icon/car.svg" />
              {data?.data?.type ? data?.data?.type : ""}
            </CarInfoSubItem>
          </CarInfoSubItemBox>

          <CarInfoSubItemBox title="옵션" addClass="dotItemBox">
            {data?.data &&
            data?.data.option &&
            data?.data?.option.length > 0 ? (
              <>
                {data?.data?.option.map((item, i) => (
                  <Fragment key={i}>
                    {i !== 0 ? <span className="dot"></span> : ""}
                    <CarInfoSubItem>{item.option_name}</CarInfoSubItem>
                  </Fragment>
                ))}
              </>
            ) : (
              <p className="rentCar_notOptions">옵션이 없습니다.</p>
            )}
          </CarInfoSubItemBox>
        </div>
      </div>
      <PopupBtnBox
        text="확인"
        addClass="mChType"
        btnAddClass=""
        func={() => {
          data.popupClose();
          data.func();
        }}
      />
    </div>
  );
});

//렌트카 취소
const RentCancelPopup = forwardRef((data, ref) => {
  const [type, setType] = useState(null);
  const [code, setCode] = useState(
    data.cancelNumber ? data.cancelNumber : null
  );
  const [contents, setContents] = useState(null);
  const [cancelId, setCancelId] = useState(
    data.cancelId ? data.cancelId : null
  );

  const [setChk, setSetChk] = useState(true);

  const [errPopupData, setErrPopupData] = useState(null);
  const [btnChk, setBtnChk] = useState(true);

  const categoryList = [
    {
      id: "일정, 차종 등 변경을 위한 취소",
      category: "일정, 차종 등 변경을 위한 취소",
    },
    {
      id: "질병으로 인한 취소",
      category: "질병으로 인한 취소",
    },
    {
      id: "서비스 불만족",
      category: "서비스 불만족",
    },
    {
      id: "직접 입력",
      category: "직접 입력",
    },
  ];

  function popupInfoReset() {
    setType(null);
    setCode(null);
    setContents(null);
    setErrPopupData(null);
    setBtnChk(true);
    setSetChk(!setChk);
  }

  useImperativeHandle(ref, () => ({
    popupInfoReset,
  }));

  function addCs() {
    let formData = new FormData();
    formData.append("category_id", 5);
    formData.append("service_id", cancelId);
    formData.append("code", code);
    formData.append("question", type == "직접 입력" ? contents : type);

    fatchSet.FatchApi({
      type: "POST",
      url: `/qna/register`,
      loginType: "login",
      formDataItem: formData,
      success: (item) => {
        setErrPopupData({
          addClass: null,
          text: "예약 취소 요청이 완료되었습니다.",
          closeType: true,
          closeFunc: (e) => {
            data.func();
            popupInfoReset();
            data.popupClose();
            popupInfoReset();
          },
          btnFunc: (e) => {
            data.func();
            popupInfoReset();
            data.popupClose();
            popupInfoReset();
          },
        });
      },
      err: (data) => {
        if (data.alert) {
          setErrPopupData({
            addClass: null,
            text: data.alert,
            closeType: true,
            closeFunc: (e) => {},
            btnFunc: (e) => {},
          });
        }
      },
    });
  }

  useEffect(() => {
    if (type && code && contents) {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [type, code, contents]);

  useEffect(() => {
    setCode(data.cancelNumber);
    setCancelId(data.cancelId);
    setSetChk(!setChk);
  }, [data.cancelNumber]);

  return (
    <>
      <div className="csWritePopup rightPopupPageItemBox onlyBtn_v2">
        <div className="csWrite_inputSection rightPopupPageItem rentCancel_inputSection">
          <CustomSelect
            inputName="취소 사유"
            placeholder="취소 사유"
            value={type}
            func={(e) => {
              setType(e);
            }}
            valKey="id"
            nameKey="category"
            setChk={setChk}
            options={categoryList}
          />
          {type == "직접 입력" ? (
            <TextAreaItem
              addClass="outCount"
              inputName="상세 사유"
              placeholder="상세 사유"
              max={200}
              unit="자"
              maxChk={true}
              setChk={setChk}
              value={contents}
              func={(e) => setContents(e)}
            />
          ) : (
            ""
          )}
        </div>
        <PopupBtnBox
          text="확인"
          addClass="mChType"
          btnAddClass="full"
          func={() => {
            addCs();
          }}
          disabled={btnChk}
          textSub="취소"
          btnAddClassSub="line full"
          funcSub={() => {
            data.popupClose();
            popupInfoReset();
          }}
        />
      </div>
      <ErrPopup data={errPopupData} />
    </>
  );
});

export {
  Popup,
  SelPopup,
  LoadingBox,
  RightPopup,
  BottomErrMsg,
  CustomPopup,
  ErrPopup,
  PrictInfoPopup,
  CarInfoNumberBox,
  RentCancelPopup,
  RentCarInfoPopup,
  CarAddPopup,
  OilAddPopup,
  AddServicesPopup,
  WithdrawalPopup,
};
