import React from "react";
import ReactDOM from "react-dom/client";
import Router from "./router/Router";
import { ThemeProvider } from "styled-components";
import "swiper/css";
import "./css/quill.snow.css";
import "./css/basic.css";
import "./css/style.css";
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <RecoilRoot>
    <ThemeProvider theme={{}}>
      <Router />
    </ThemeProvider>
  </RecoilRoot>
);
