import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import {
  AddServiceCaption,
  AddServiceItem,
  AddServiceNotCaption,
  InfoText,
  ServiceInfoItem,
  ServiceInfoSubItem,
  ServiceInfoSubItemBox,
  SevicePageTitle,
} from "component/app/subItems";
import {
  ChkBox,
  DatePicerRange,
  InputItemBox,
  RadioBox,
  TextAreaItem,
  TimePicer,
} from "component/basic/formItems";
import { getWeekCount, setDateTime, setDateWeek } from "js/function";
import { AppSection, AppSubSection } from "component/app/app";
import { comFormat } from "js/function";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import FixedSection from "component/basic/fixedBox";
import { ErrPopup, PrictInfoPopup, RightPopup } from "component/basic/popup";
import moment from "moment";
import { useMemo } from "react";

const StoregeService = (props) => {
  const navigate = useNavigate();
  const param = useParams();
  const addr = param.addr;

  //차량 정보
  const carData = useGet({
    url: `/user/check`,
    loginType: "login",
  });

  //금액 데이터
  const priceData = useGet({
    url: `/service/price?region=${addr}`,
    loginType: "login",
  });

  const [date, setDate] = useState(moment().format("YYYY-MM-01"));
  const nextMonthDate = useMemo(
    () => moment(date).add("2", "M").subtract("1", "day").format("YYYY-MM-DD"),
    [date]
  );

  // 서비스 이용불가한 날짜들
  const unavaliableDates = useGet({
    url: `/service/qty?region=${param.addr}&start_date=${date}&end_date=${nextMonthDate}`,
  });

  // 공항 주차 대

  const [dataSet, setDataSet] = useState(0);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [carInfo, setCarInfo] = useState(null);
  const [carId, setCarId] = useState(null);
  const [carFuelType, setCarFuelType] = useState(null);
  const [carName, setCarName] = useState(null);
  const [carNumber, setCarNumber] = useState(null);

  const [pickup, setPickup] = useState(0);
  const [contents, setContents] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [dataSetChk, setDataSetChk] = useState(false);
  const [timeDataSet, setTimeDataSet] = useState(false);

  const [minDateChk, setMinDateChk] = useState(true);

  const [weekdayCount, setWeekdayCount] = useState(0);
  const [weekendCount, setWeekendCount] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [pickPrice, setPickPrice] = useState(0);

  const [oilPrice, setOilPrice] = useState(0);
  const [washPrice, setWashPrice] = useState(0);
  const [inspectionPrice, setInspectionPrice] = useState(0);
  const [maintenancePrice, setMaintenancePrice] = useState(0);
  const [washBTPrice, setWashBTPrice] = useState(0);

  const [salePrice, setSalePrice] = useState(0);

  const [totalPrice, setTotalPrice] = useState(0);

  const [addServiceOpen, setAddServiceOpen] = useState(false);

  const [oil, setOil] = useState(null);
  const [wash, setWash] = useState(null);
  const [inspection, setInspection] = useState(null);
  const [management, setManagement] = useState(null);
  const [managementEtc, setManagementEtc] = useState(null);
  const [repair, setRepair] = useState(null);

  const [maintenance, setMaintenance] = useState(null);
  const [washBT, setWashBT] = useState(null);

  const [oilChk, setOilChk] = useState(false);
  const [washChk, setWashChk] = useState(false);
  const [inspectionChk, setInspectionChk] = useState(false);
  const [managementChk, setManagementChk] = useState(false);
  const [repairChk, setRepairChk] = useState(false);

  const [maintenanceChk, setMaintenanceChk] = useState(false);
  const [washBTChk, setWashBTChk] = useState(false);

  const [oilPopup, setOilPopup] = useState("");
  const [washPopup, setWashPopup] = useState("");
  const [inspectionPopup, setInspectionPopup] = useState("");
  const [managementPopup, setManagementPopup] = useState("");
  const [repairPopup, setRepairPopup] = useState("");

  const [maintenancePopup, setMaintenancePopup] = useState("");
  const [washBTPopup, setWashBTPopup] = useState("");

  const [oilSale, setOilSale] = useState(10000);
  const [washSale, setWashSale] = useState(10000);

  const [btnChk, setBtnChk] = useState(true);

  const [carPopup, setCarPopup] = useState("");

  const [errPopupData, setErrPopupData] = useState(null);

  const [prictInfoData, setPrictInfoData] = useState(null);

  //서비스 정보
  const oliData = useGet({
    url: `/oil/items?car_id=${carId}&type=1`,
    loginType: "login",
  });
  const washData = useGet({
    url: `/wash/price?car_id=${carId}&type=1`,
    loginType: "login",
  });
  const inspectionData = useGet({
    url: `/inspection/price?car_id=${carId}&type=1`,
    loginType: "login",
  });

  let dataUrl = "";
  if (addr == "김해") {
    let apiDate = startDate ? startDate.split(".") : "";
    if (startDate) {
      dataUrl = `/oil/items?car_id=${carId}&type=0&year=${apiDate[0]}&month=${apiDate[1]}&day=${apiDate[2]}&sido=부산광역시&sigugun=강서구&dong=대저2동`;
    } else {
      dataUrl = null;
    }
  } else if (addr == "김포") {
    let apiDate = startDate ? startDate.split(".") : "";
    if (startDate) {
      dataUrl = `/oil/items?car_id=${carId}&type=0&year=${apiDate[0]}&month=${apiDate[1]}&day=${apiDate[2]}&sido=서울특별시&sigugun=강서구&dong=공항동`;
    } else {
      dataUrl = null;
    }
  } else {
    dataUrl = null;
  }
  const maintenanceData = useGet({
    url: dataUrl,
    loginType: "login",
  });

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  //서비스 선택 세팅
  function oilDataSet(id, name, price, thum) {
    let setOilData = {
      id: id,
      name: name,
      price: price,
      thum: thum,
    };
    setOil(setOilData);
    setOilPrice(price);
  }

  function washDataSet(price, wax, waxPrice, name) {
    let setWashData = {
      name: name,
      price: price,
      wax: wax,
      waxPrice: waxPrice,
    };
    setWash(setWashData);
    setWashPrice(Number(price) + Number(waxPrice));
  }

  function inspectionDataSet(price, name) {
    let setInspectionData = {
      price: price,
      name: name,
    };
    setInspection(setInspectionData);
    setInspectionPrice(price);
  }

  function managementDataSet(manage, etc) {
    let setManagementData = [...manage];
    setManagement(setManagementData);
    setManagementEtc(etc);
  }

  function repairDataSet(repairImg) {
    let setRepairData = [...repairImg];
    setRepair(setRepairData);
  }

  function maintenanceDataSet(data, total) {
    setMaintenance(data);
    setMaintenancePrice(total);
  }

  function washBTDataSet(data) {}

  //서비스 초기화
  function allDataReset() {
    setOil(null);
    setOilPrice(0);

    setWash(null);
    setWashPrice(0);

    setInspection(null);
    setInspectionPrice(0);

    setManagement(null);
    setManagementEtc(null);

    setRepair(null);

    setMaintenance(null);
    setMaintenancePrice(0);

    setOilChk(false);
    setWashChk(false);
    setInspectionChk(false);
    setManagementChk(false);
    setRepairChk(false);
    setRepairChk(false);
    setMaintenanceChk(false);

    setAddServiceOpen(false);

    setMinDateChk(true);
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);

    setTimeDataSet(!timeDataSet);
    setDataSetChk(!dataSetChk);

    setPickup(0);
    setServicePrice(0);
  }

  //서비스 체크
  function addServiceChk(e, type) {
    if (type == "oil") {
      if (e.target.checked) {
        setOilPopup("open");
      } else {
        setOil(null);
        setOilPrice(0);
      }
    }
    if (type == "wash") {
      if (e.target.checked) {
        setWashPopup("open");
      } else {
        setWash(null);
        setWashPrice(0);
      }
    }
    if (type == "inspection") {
      if (e.target.checked) {
        setInspectionPopup("open");
      } else {
        setInspection(null);
        setInspectionPrice(0);
      }
    }
    if (type == "management") {
      if (e.target.checked) {
        setManagementPopup("open");
      } else {
        setManagement(null);
      }
    }
    if (type == "repair") {
      if (e.target.checked) {
        setRepairPopup("open");
      } else {
        setRepair(null);
      }
    }
    if (type == "maintenance") {
      if (e.target.checked) {
        setMaintenancePopup("open");
      } else {
        setMaintenance(null);
        setMaintenancePrice(0);
      }
    }
    if (type == "washBT") {
      if (e.target.checked) {
        setWashBTPopup("open");
      } else {
        setWashBT(null);
        setWashBTPrice(0);
      }
    }
  }

  //차량 변경
  function addCarFunc(size, type, year, number, id, name, fuelType) {
    let formData = new FormData();
    formData.append("car_id", id);
    formData.append("car_type", type);
    formData.append("car_size", size);
    formData.append("car_year", year);

    fatchSet.FatchApi({
      type: "POST",
      formDataItem: formData,
      url: `/mypage/car/info/modify`,
      loginType: "login",
      success: (data) => {
        setCarInfo(`${type} / ${name} ${number}`);
        setCarName(name);
        setCarNumber(number);
        setCarId(id);
        setCarFuelType(fuelType);
        setErrPopupData({
          addClass: null,
          text: "차량 정보가 등록되었습니다!",
          closeType: true,
          closeFunc: (e) => {},
          btnFunc: (e) => {},
        });
        setDataSet(dataSet + 1);
      },
      err: (data) => {
        if (data.alert) {
          setErrPopupData({
            addClass: null,
            text: data.alert,
            closeType: true,
            closeFunc: (e) => {},
            btnFunc: (e) => {},
          });
        }
      },
    });
  }

  //주문페이지 이동
  function moveOrder() {
    navigate(`/storege/order/storage/${addr}`, {
      state: {
        region: addr,
        name: name,
        phone: phone,
        car_id: carId,
        car_number: carNumber,
        car_name: carName,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        user_pickup: pickup,
        pickPrice: pickPrice,
        contents: contents,
        category: "차량보관",
        use_option: 1,
        storage_price: servicePrice,
        price: totalPrice,
        discount_price: salePrice,
        oil: oil,
        wash: wash,
        inspection: inspection,
        management: management,
        etc: managementEtc,
        repair: repair,
        maintenance: maintenance,
        maintenancePrice: maintenancePrice,
        washBT: washBT,
      },
    });
  }

  // 서비스 이용불가능한 날짜 필터처리
  function filterDate(date) {
    const dates = unavaliableDates?.data?.max_qtys || [];
    const finded = dates.find(
      (unavaliableDate) => unavaliableDate === moment(date).format("YYYY-MM-DD")
    );
    return !finded;
  }

  // 월이 변했을 때의 동작
  function handleChangeMonth(date) {
    setDate(moment(date).format("YYYY-MM-01"));
  }

  //가격 설정
  useEffect(() => {
    if (startDate && endDate && startTime && endTime) {
      let price = priceData?.data?.price;
      setWeekdayCount(getWeekCount(startDate, endDate)[0]);
      setWeekendCount(getWeekCount(startDate, endDate)[1]);
      setServicePrice(
        Number(price?.weekday_price) *
          Number(getWeekCount(startDate, endDate)[0]) +
          Number(price?.weekend_price) *
            Number(getWeekCount(startDate, endDate)[1])
      );
    }
  }, [startDate, endDate, priceData, startTime, endTime]);

  useEffect(() => {
    if (priceData?.data?.price) {
      let price = priceData?.data?.price;
      setPickPrice(price.pickup_price);
    }
  }, [priceData]);

  //차량 정보 세팅
  useEffect(() => {
    setName(carData.data?.name ? carData.data.name : "");
    setPhone(carData.data?.phone ? carData.data.phone : "");

    if (carData.data?.is_car) {
      let carDetailData = carData.data?.user_car;
      setCarInfo(
        `${carDetailData?.car_type} / ${carDetailData?.name} ${carDetailData?.number}`
      );
      setCarFuelType(carDetailData?.fuel_type);
      setCarId(carDetailData?.id);
      setCarName(carDetailData?.name);
      setCarNumber(carDetailData?.number);
    }

    setDataSet(dataSet + 1);
  }, [carData]);

  //부가서비스 여부 체크
  useEffect(() => {
    if (startDate && endDate && startTime && endTime && carInfo) {
      setAddServiceOpen(true);
    } else {
      setAddServiceOpen(false);
    }
  }, [carInfo, startDate, endDate, startTime, endTime]);

  //버튼 활성화 체크
  useEffect(() => {
    if (
      startDate &&
      endDate &&
      startTime &&
      endTime &&
      carInfo &&
      name !== "" &&
      phone !== ""
    ) {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [carInfo, startDate, endDate, startTime, endTime, name, phone]);

  //할인가 세팅
  useEffect(() => {
    let totalOptionPrice =
      oil && wash ? oilSale + washSale : oil ? oilSale : wash ? washSale : 0;

    setSalePrice(totalOptionPrice);
  }, [oil, wash, inspection]);

  //총 가격 세팅
  useEffect(() => {
    let totalPriceItem = servicePrice;
    if (pickup == 1) {
      totalPriceItem = Number(totalPriceItem) + Number(pickPrice);
    }

    if (oil) {
      totalPriceItem = Number(totalPriceItem) + Number(oilPrice);
    }
    if (wash) {
      totalPriceItem = Number(totalPriceItem) + Number(washPrice);
    }
    if (inspection) {
      totalPriceItem = Number(totalPriceItem) + Number(inspectionPrice);
    }

    if (oil || wash) {
      totalPriceItem = Number(totalPriceItem) - Number(salePrice);
    }

    if (washBT) {
      totalPriceItem = Number(totalPriceItem) + Number(washBTPrice);
    }
    if (maintenance) {
      totalPriceItem = Number(totalPriceItem) + Number(maintenancePrice);
    }

    setTotalPrice(totalPriceItem);
  }, [
    servicePrice,
    pickup,
    oilPrice,
    washPrice,
    inspectionPrice,
    salePrice,
    totalPrice,
    washBTPrice,
    maintenancePrice,
  ]);

  return (
    <div className="servicePage headerInPage pageSizing mBtnPage">
      <Header
        pageBack={true}
        headTitle="서비스 신청"
        func={() => navigate(-1)}
        homeBtn={true}
      />
      <SevicePageTitle
        title="서비스 신청"
        navi={0}
        navi_0="서비스 신청"
        navi_1="신청 정보 확인"
        navi_2="신청 완료"
      />
      <div className="servicePage_contents">
        <div className="service_inputSection">
          <AppSection
            title="내 정보"
            text="서비스를 이용하실 분의 이름과 연락처를 입력해주세요."
          >
            <div className="service_minInputSection">
              <InputItemBox
                type="text"
                inputName="이름"
                placeholder="이름"
                max={20}
                value={name}
                setChk={dataSet}
                regexp={/[0-9#?!@$ %^&*-]/gi}
                func={(e) => {
                  setName(e);
                }}
              />
              <InputItemBox
                type="phoneBasic"
                inputName="연락처"
                placeholder="연락처"
                max={11}
                value={phone}
                setChk={dataSet}
                func={(e) => {
                  setPhone(e);
                }}
              />
            </div>
          </AppSection>
          <AppSection title="신청 정보">
            <InputItemBox
              addClass={carInfo ? "notInput" : ""}
              type="btnCom"
              inputName="차량 정보"
              placeholder="차량 정보"
              value={carInfo}
              setChk={dataSet}
              func={(e) => {
                setCarInfo(e);
              }}
              btnName={carInfo ? "수정" : "직접 입력"}
              clickEv={(e) => {
                setCarPopup("open");
              }}
              inputClick={(e) => {
                setCarPopup("open");
              }}
              readOnly={true}
            />
            <DatePicerRange
              inputName="입고 일 - 출고 일"
              placeholder="입고 일 - 출고 일"
              value={startDate && endDate ? `${startDate} - ${endDate}` : null}
              minCount={addr == "제주" ? 1 : 0}
              dataChk={dataSetChk}
              func={(start, end) => {
                setStartDate(setDateTime(start, "yymmdd"));
                setEndDate(setDateTime(end, "yymmdd"));
                let nowDate = new Date(),
                  startDateItem = new Date(
                    setDateTime(start, "yymmdd").replace(/-/g, "/")
                  );
                let setNowDate = new Date(
                  nowDate.setDate(nowDate.getDate() + (addr == "제주" ? 2 : 6))
                );

                if (setNowDate <= startDateItem) {
                  setMinDateChk(true);
                } else {
                  setMinDateChk(false);
                }
              }}
              filterDate={filterDate}
              onMonthChange={handleChangeMonth}
            />
            <div className="col2Input">
              <TimePicer
                inputName="입고 시간"
                placeholder="입고 시간"
                value={startTime}
                dataChk={timeDataSet}
                min={addr == "제주" ? "08" : null}
                max={addr == "제주" ? "21" : null}
                date={`${setDateTime(startDate, "yymmdd")} (${setDateWeek(
                  startDate
                )})`}
                disabled={startDate && endDate ? false : true}
                func={(start, end) => {
                  if (
                    startDate &&
                    addr !== "제주" &&
                    (new Date(
                      `${setDateTime(startDate, "yymmdd").replace(
                        /-/g,
                        "/"
                      )} ${`${start}:${end}`}`
                    ).getTime() -
                      new Date().getTime()) /
                      (1000 * 60 * 60) <
                      2
                  ) {
                    setStartTime(`${start}:${end}`);
                    setErrPopupData({
                      addClass: null,
                      text: "입고 시간은 최소 2시간 이후 부터 신청이 가능합니다.",
                      closeType: true,
                      closeFunc: (e) => {},
                      btnFunc: (e) => {},
                    });
                    setStartTime(null);
                    setTimeDataSet(!timeDataSet);
                  } else if (startDate && endDate && endTime) {
                    if (
                      new Date(
                        `${setDateTime(startDate, "yymmdd").replace(
                          /-/g,
                          "/"
                        )} ${`${start}:${end}`}`
                      ) >
                      new Date(
                        `${setDateTime(endDate, "yymmdd").replace(/-/g, "/")} ${
                          endTime ? endTime : "00:00"
                        }`
                      )
                    ) {
                      setStartTime(`${start}:${end}`);
                      setErrPopupData({
                        addClass: null,
                        text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                        closeType: true,
                        closeFunc: (e) => {},
                        btnFunc: (e) => {},
                      });
                      setStartTime(null);
                      setTimeDataSet(!timeDataSet);
                    } else {
                      setStartTime(`${start}:${end}`);
                      setTimeDataSet(!timeDataSet);
                    }
                  } else {
                    setStartTime(`${start}:${end}`);
                    setTimeDataSet(!timeDataSet);
                  }
                }}
              />
              <TimePicer
                inputName="출고 시간"
                placeholder="출고 시간"
                value={endTime}
                dataChk={timeDataSet}
                min={addr == "제주" ? "08" : null}
                max={addr == "제주" ? "21" : null}
                date={`${setDateTime(endDate, "yymmdd")} (${setDateWeek(
                  endDate
                )})`}
                disabled={startDate && endDate ? false : true}
                func={(start, end) => {
                  if (startDate && endDate && startTime) {
                    if (
                      new Date(
                        `${setDateTime(startDate, "yymmdd").replace(
                          /-/g,
                          "/"
                        )} ${startTime ? startTime : "00:00"}`
                      ) >
                      new Date(
                        `${setDateTime(endDate, "yymmdd").replace(
                          /-/g,
                          "/"
                        )} ${`${start}:${end}`}`
                      )
                    ) {
                      setEndTime(`${start}:${end}`);
                      setErrPopupData({
                        addClass: null,
                        text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                        closeType: true,
                        closeFunc: (e) => {},
                        btnFunc: (e) => {},
                      });
                      setEndTime(null);
                      setTimeDataSet(!timeDataSet);
                    } else {
                      setEndTime(`${start}:${end}`);
                      setTimeDataSet(!timeDataSet);
                    }
                  } else {
                    setEndTime(`${start}:${end}`);
                    setTimeDataSet(!timeDataSet);
                  }
                }}
              />
            </div>
            <InfoText
              listType="-"
              text="예약일 변경 또는 취소 요청의 경우 입고일 기준 3일 전 신청 건만 가능합니다."
            />
            <InfoText
              listType="-"
              text="입고일 기준 2일~당일 요청 건은 변경 또는 환불이 불가능하오니 유의해 주시기 바랍니다."
            />
            <InfoText
              listType="-"
              text="운영시간 이외의 입∙출고 건은 추가 비용이 발생함에 따라 고객센터로 문의주시기 바랍니다."
            />
            {addr == "김해" ? (
              <AppSubSection title="무료 셔틀버스 안내">
                <InfoText text="무료 셔틀버스 이용 가능 시간 - 평일 10:00 ~ 16:00 주말 운영 X<br/>공항 픽업 장소 - 국내선 2번게이트 앞" />
              </AppSubSection>
            ) : (
              <AppSubSection title="입/출고 픽업 서비스">
                <RadioBox
                  id="pickupTypeChk"
                  val={0}
                  label="미사용"
                  colorType="bgFullChk"
                  checkedType={pickup == 0 ? true : false}
                  func={(e) => setPickup(e)}
                  addClass="roundType"
                />
                <RadioBox
                  id="pickupTypeChk"
                  val={1}
                  label="사용"
                  colorType="bgFullChk"
                  checkedType={pickup == 1 ? true : false}
                  func={(e) => setPickup(e)}
                  addClass="roundType"
                />
                {addr == "제주" ? (
                  <InfoText text="픽업서비스 미사용 시 [주차장에 직접 차량 입고 → 직접 공항 이동 / 공항에서 직접 주차장 이동 → 직접 차량 출고] 의 형태로 서비스를 이용해야 합니다. 참고 바랍니다." />
                ) : (
                  ""
                )}
              </AppSubSection>
            )}
          </AppSection>
          <AppSection title="기타 정보">
            <TextAreaItem
              addClass="outCount"
              inputName="차량 관련 특이사항 (선택)"
              placeholder="차량 관련 특이사항 (선택)"
              max={100}
              unit="자"
              maxChk={true}
              value={contents}
              func={(e) => setContents(e)}
            />
          </AppSection>

          <AppSection title="부가서비스 신청">
            {addServiceOpen ? (
              <>
                {addr == "제주" ? (
                  <>
                    <ChkBox
                      addClass="addServiceItem"
                      id="addServiceItem_0"
                      func={(e) => {
                        addServiceChk(e, "oil");
                        setOilChk(e.target.checked);
                      }}
                      disabled={
                        carFuelType == "BEV" ||
                        !(
                          oliData?.data?.options &&
                          oliData?.data?.options.length > 0
                        )
                          ? true
                          : false
                      }
                      checkedType={oilChk}
                    >
                      <AddServiceItem
                        name={`엔진 오일 ${
                          carFuelType == "BEV" ||
                          !(
                            oliData?.data?.options &&
                            oliData?.data?.options.length > 0
                          )
                            ? ""
                            : `<span class="${oilPrice > 0 ? "" : "gColor"}">${
                                oilPrice > 0 ? "+" : ""
                              }${comFormat(oilPrice)}원</span>`
                        }`}
                        mChType={true}
                      >
                        {carFuelType == "BEV" ||
                        !(
                          oliData?.data?.options &&
                          oliData?.data?.options.length > 0
                        ) ? (
                          <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                        ) : (
                          <AddServiceCaption
                            colorType="g_color"
                            mChType={true}
                            text={`결합시 ${comFormat(oilSale)}원 할인`}
                          />
                        )}
                      </AddServiceItem>
                    </ChkBox>
                    <ChkBox
                      addClass="addServiceItem"
                      id="addServiceItem_1"
                      func={(e) => {
                        addServiceChk(e, "wash");
                        setWashChk(e.target.checked);
                      }}
                      disabled={!minDateChk || !(washData && washData?.success)}
                      checkedType={washChk}
                    >
                      <AddServiceItem
                        name={`세차 ${
                          !minDateChk || !(washData && washData?.success)
                            ? ""
                            : `<span class="${washPrice > 0 ? "" : "gColor"}">${
                                washPrice > 0 ? "+" : ""
                              }${comFormat(washPrice)}원</span>`
                        }`}
                        mChType={true}
                      >
                        {!minDateChk ? (
                          <AddServiceNotCaption
                            text="서비스 신청이 가능한 일자가 아닙니다."
                            subText="(예약일로부터 3일 이전에 신청이 가능합니다.)"
                          />
                        ) : !(washData && washData?.success) ? (
                          <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                        ) : (
                          <AddServiceCaption
                            colorType="g_color"
                            mChType={true}
                            text={`결합시 ${comFormat(washSale)}원 할인`}
                          />
                        )}
                      </AddServiceItem>
                    </ChkBox>
                    <ChkBox
                      addClass="addServiceItem"
                      id="addServiceItem_2"
                      func={(e) => {
                        addServiceChk(e, "inspection");
                        setInspectionChk(e.target.checked);
                      }}
                      disabled={
                        carFuelType == "BEV" ||
                        !minDateChk ||
                        !(inspectionData && inspectionData?.success)
                          ? true
                          : false
                      }
                      checkedType={inspectionChk}
                    >
                      <AddServiceItem
                        name={`차량 검사 ${
                          carFuelType == "BEV" ||
                          !minDateChk ||
                          !(inspectionData && inspectionData?.success)
                            ? ""
                            : `<span class="${
                                inspectionPrice > 0 ? "" : "gColor"
                              }">${inspectionPrice > 0 ? "+" : ""}${comFormat(
                                inspectionPrice
                              )}원</span>`
                        }`}
                      >
                        {carFuelType == "BEV" ||
                        !(inspectionData && inspectionData?.success) ? (
                          <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                        ) : !minDateChk ? (
                          <AddServiceNotCaption
                            text="서비스 신청이 가능한 일자가 아닙니다."
                            subText="(예약일로부터 3일 이전에 신청이 가능합니다.)"
                          />
                        ) : (
                          ""
                        )}
                      </AddServiceItem>
                    </ChkBox>
                    <ChkBox
                      addClass="addServiceItem"
                      id="addServiceItem_3"
                      func={(e) => {
                        addServiceChk(e, "management");
                        setManagementChk(e.target.checked);
                      }}
                      disabled={carFuelType == "BEV" ? true : false}
                      checkedType={managementChk}
                    >
                      <AddServiceItem name={`관리 서비스 신청`}>
                        {carFuelType == "BEV" ? (
                          <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                        ) : (
                          <AddServiceCaption
                            colorType="blue_color"
                            text={`예약 시 추가 할인`}
                          />
                        )}
                      </AddServiceItem>
                    </ChkBox>
                    <ChkBox
                      addClass="addServiceItem"
                      id="addServiceItem_4"
                      func={(e) => {
                        addServiceChk(e, "repair");
                        setRepairChk(e.target.checked);
                      }}
                      disabled={carFuelType == "BEV" ? true : false}
                      checkedType={repairChk}
                    >
                      <AddServiceItem name={`외관 수리 신청`}>
                        {carFuelType == "BEV" ? (
                          <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                        ) : (
                          ""
                        )}
                      </AddServiceItem>
                    </ChkBox>
                  </>
                ) : (
                  <>
                    <ChkBox
                      addClass="addServiceItem"
                      id="addServiceItem_2"
                      func={(e) => {
                        addServiceChk(e, "maintenance");
                        setMaintenanceChk(e.target.checked);
                      }}
                      disabled={
                        carFuelType == "BEV" || !minDateChk ? true : false
                      }
                      checkedType={maintenanceChk}
                    >
                      <AddServiceItem
                        name={`출장 정비 서비스 ${
                          carFuelType == "BEV" || !minDateChk
                            ? ""
                            : `<span class="${
                                maintenancePrice > 0 ? "" : "gColor"
                              }">${maintenancePrice > 0 ? "+" : ""}${comFormat(
                                maintenancePrice
                              )}원</span>`
                        }`}
                      >
                        {carFuelType == "BEV" ? (
                          <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                        ) : !minDateChk ? (
                          <AddServiceNotCaption
                            text="서비스 신청이 가능한 일자가 아닙니다."
                            subText="(예약일로부터 1주일 이전에 신청이 가능합니다.)"
                          />
                        ) : (
                          ""
                        )}
                      </AddServiceItem>
                    </ChkBox>
                    <ChkBox
                      addClass="addServiceItem"
                      id="addServiceItem_1"
                      func={(e) => {
                        addServiceChk(e, "washBT");
                        setWashBTChk(e.target.checked);
                      }}
                      // disabled={!minDateChk}
                      disabled={true}
                      checkedType={washBTChk}
                    >
                      <AddServiceItem
                        // name={`출장 세차 서비스 ${carFuelType == "BEV" || !minDateChk ? "" : `<span class="gColor">+${comFormat(washBTPrice)}원</span>`}`}
                        name={`출장 세차 서비스`}
                        mChType={true}
                      >
                        <AddServiceNotCaption text="서비스 준비 중 입니다." />
                        {/* {carFuelType == "BEV" ? 
                                    <AddServiceNotCaption
                                        text="서비스가 제공되지 않는 차량입니다."
                                    />: !minDateChk ? 
                                    <AddServiceNotCaption
                                        text="서비스 신청이 가능한 일자가 아닙니다."
                                        subText="(예약일로부터 1주일 이전에 신청이 가능합니다.)"
                                    />
                                    :""} */}
                      </AddServiceItem>
                    </ChkBox>
                  </>
                )}
              </>
            ) : (
              <div className="notCar">
                <img src="/assets/images/icon/not_car.svg" />
                신청 정보를 등록해주세요
              </div>
            )}
          </AppSection>
        </div>
        <FixedSection
          pageBox="servicePage"
          boxAddClass="service_selInfo_section"
          addClass="service_selInfo_fixedSection"
          headerChk={true}
          footerChk={true}
        >
          <div className="service_selInfo_area">
            <h1 className="service_selInfo_title">서비스 신청 정보</h1>
            <div className="service_selInfo_list">
              <ServiceInfoItem
                name="공항 주차"
                item={`${comFormat(servicePrice)}원`}
              />
              {pickup == 1 ? (
                <ServiceInfoItem
                  name="픽업 서비스"
                  item={`${comFormat(pickPrice)}원`}
                />
              ) : (
                ""
              )}
              {oil ? (
                <ServiceInfoItem
                  name="엔진 오일"
                  item={`${comFormat(oilPrice)}원`}
                >
                  <ServiceInfoSubItemBox>
                    <ServiceInfoSubItem
                      name={oil?.name}
                      item={`${comFormat(oilPrice)}원`}
                    />
                  </ServiceInfoSubItemBox>
                </ServiceInfoItem>
              ) : (
                ""
              )}
              {wash ? (
                <ServiceInfoItem name="세차" item={`${comFormat(washSale)}원`}>
                  <ServiceInfoSubItemBox>
                    <ServiceInfoSubItem
                      name={wash?.name}
                      item={`${comFormat(washSale)}원`}
                    />
                  </ServiceInfoSubItemBox>
                </ServiceInfoItem>
              ) : (
                ""
              )}
              {inspection ? (
                <ServiceInfoItem
                  name="차량 검사"
                  item={`${comFormat(inspectionPrice)}원`}
                >
                  <ServiceInfoSubItemBox>
                    <ServiceInfoSubItem
                      name={inspection?.name}
                      item={`${comFormat(inspectionPrice)}원`}
                    />
                  </ServiceInfoSubItemBox>
                </ServiceInfoItem>
              ) : (
                ""
              )}
              {management ? (
                <ServiceInfoItem
                  name="관리서비스 신청"
                  item="상담 후 견적가 안내"
                  type="notPrice"
                />
              ) : (
                ""
              )}
              {repair ? (
                <ServiceInfoItem
                  name="외관 수리 신청"
                  item="상담 후 견적가 안내"
                  type="notPrice"
                />
              ) : (
                ""
              )}
              {maintenance ? (
                <ServiceInfoItem
                  name="출장 정비"
                  item={`${comFormat(maintenancePrice)}원`}
                >
                  {maintenance.map((item, i) => (
                    <ServiceInfoSubItemBox key={i}>
                      <ServiceInfoSubItem
                        name={item.name}
                        item={`${comFormat(item.price)}원`}
                      />
                    </ServiceInfoSubItemBox>
                  ))}
                </ServiceInfoItem>
              ) : (
                ""
              )}
              {washBT ? (
                <ServiceInfoItem
                  name="출장 세차"
                  item={`${comFormat(washBTPrice)}원`}
                >
                  {washBT.map((item, i) => (
                    <ServiceInfoSubItemBox key={i}>
                      <ServiceInfoSubItem
                        name={item.name}
                        item={`${comFormat(item.price)}원`}
                      />
                    </ServiceInfoSubItemBox>
                  ))}
                </ServiceInfoItem>
              ) : (
                ""
              )}

              <ServiceInfoItem
                name="총 할인가"
                item={`-${comFormat(salePrice)}원`}
              >
                <ServiceInfoSubItemBox>
                  {oil ? (
                    <ServiceInfoSubItem
                      name="엔진오일 결합할인"
                      item={`-${comFormat(oilSale)}원`}
                    />
                  ) : (
                    ""
                  )}
                </ServiceInfoSubItemBox>
                <ServiceInfoSubItemBox>
                  {wash ? (
                    <ServiceInfoSubItem
                      name="세차 결합할인"
                      item={`-${comFormat(washSale)}원`}
                    />
                  ) : (
                    ""
                  )}
                </ServiceInfoSubItemBox>
              </ServiceInfoItem>
            </div>
            <div className="service_selInfo_totalArea">
              <h2 className="service_selInfo_totalTitle">총 결제금액</h2>
              <h2 className="service_selInfo_total">
                {comFormat(totalPrice)}원
              </h2>
            </div>
            <div className="service_selInfo_infoBox">
              <button
                type="button"
                onClick={() => {
                  setPrictInfoData({
                    type: addr,
                    weekdayCount: weekdayCount,
                    weekendCount: weekendCount,
                    startDate: startDate,
                    startTime: startTime,
                    endDate: endDate,
                    endTime: endTime,
                    weekday_price: priceData?.data?.price?.weekday_price,
                    weekend_price: priceData?.data?.price?.weekend_price,
                    servicePrice: servicePrice,
                    pickup: pickup,
                    pickPrice: pickPrice,
                    carInfo: carInfo,
                    closeFunc: () => {
                      setTimeout(() => {
                        setPrictInfoData(null);
                      }, 200);
                    },
                  });
                }}
                className="service_selInfo_info"
              >
                <img src="/assets/images/basic/info.svg" />
                공항 주차 요금 산정기준 조회
              </button>
            </div>
            <BtnBox
              addClass="pageInType mChType"
              text={"다음으로"}
              disabled={btnChk}
              func={() => {
                moveOrder();
              }}
            />
          </div>
          <div className="order_info_imgBox mItem">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="order_info_img pcItem"></div>
          <InfoText
            listType="-"
            text="관리 서비스는 견적 후 웹 또는 어플에서만 결제가 가능합니다."
          />
          <InfoText
            listType="-"
            text="외관 수리는 견적 후 예약하여 실제 외관 상태를 확인 후 현장에서만 결제가 가능합니다."
          />
        </FixedSection>
      </div>
      <ErrPopup data={errPopupData} />
      <RightPopup
        addClass="minVar"
        text={carPopup}
        addCarNumber={true}
        topTitle="차량 정보 등록"
        isClosedFunction={true}
        closePopup={() => {
          setErrPopupData({
            addClass: null,
            text: "차량 정보는 <b>마이페이지<b/>에서<br />언제든 등록/수정하실 수 있습니다.<br />다음에 등록하시겠습니까?",
            closeType: false,
            closeFunc: (e) => {},
            btnFunc: (e) => {
              setCarPopup("");
            },
            errBtnText: "네",
            textSub: "아니오",
            btnAddClassSub: "notLine",
            funcSub: () => {},
          });
        }}
        carNumber={""}
        car_info={null}
        carAdd={true}
        addCarFunc={(size, type, year, number, id, name, fuelType) => {
          addCarFunc(size, type, year, number, id, name, fuelType);
          allDataReset();
        }}
      />
      <RightPopup
        data={oliData?.data?.options}
        text={oilPopup}
        topTitle="엔진 오일"
        closePopup={() => {
          setOilPopup("");
          setOilChk(false);
        }}
        oilAdd={true}
        addPopupFunc={(id, name, price, thum) => {
          setOilPopup("");
          oilDataSet(id, name, price, thum);
        }}
      />
      <RightPopup
        data={washData}
        text={washPopup}
        topTitle="세차"
        closePopup={() => {
          setWashPopup("");
          setWashChk(false);
        }}
        washAdd={true}
        addPopupFunc={(price, wax, waxPrice, name) => {
          setWashPopup("");
          washDataSet(price, wax, waxPrice, name);
        }}
      />
      <RightPopup
        data={inspectionData}
        text={inspectionPopup}
        topTitle="차량 검사"
        closePopup={() => {
          setInspectionPopup("");
          setInspectionChk(false);
        }}
        inspectionAdd={true}
        addPopupFunc={(price, name) => {
          setInspectionPopup("");
          inspectionDataSet(price, name);
        }}
      />
      <RightPopup
        data={null}
        text={managementPopup}
        topTitle="관리 서비스"
        closePopup={() => {
          setManagementPopup("");
          setManagementChk(false);
        }}
        managementAdd={true}
        addPopupFunc={(manage, etc) => {
          setManagementPopup("");
          managementDataSet(manage, etc);
        }}
      />
      <RightPopup
        data={null}
        text={repairPopup}
        topTitle="외관 수리 신청"
        closePopup={() => {
          setRepairPopup("");
          setRepairChk(false);
        }}
        repairAdd={true}
        addPopupFunc={(repairImg) => {
          setRepairPopup("");
          repairDataSet(repairImg);
        }}
      />

      <RightPopup
        data={maintenanceData}
        text={maintenancePopup}
        topTitle="출장 정비 서비스"
        closePopup={() => {
          setMaintenancePopup("");
          setMaintenanceChk(false);
        }}
        maintenanceAdd={true}
        addPopupFunc={(data, total) => {
          setMaintenancePopup("");
          maintenanceDataSet(data, total);
        }}
      />
      <RightPopup
        data={null}
        text={washBTPopup}
        topTitle="출장 세차 서비스"
        closePopup={() => {
          setWashBTPopup("");
          setWashBTChk(false);
        }}
        washBTAdd={true}
        addPopupFunc={(data) => {
          setWashBTPopup("");
          washBTDataSet(data);
        }}
      />
      <PrictInfoPopup
        data={prictInfoData}
        closePopup={() => {
          setPrictInfoData(null);
        }}
      />
    </div>
  );
};

export default StoregeService;
