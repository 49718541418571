import { useNavigate } from "react-router-dom";
import { LinkPopupBox, LinkPopupItem } from "./linkPopupItem";

//공항 주차 선택시
export default function StorageLink(data) {
  let navigate = useNavigate();

  return (
    <LinkPopupBox>
      <LinkPopupItem
        name="제주 공항 주차"
        func={() => {
          navigate("/storege/service/jeju/제주");
          data.closePopup();
          data.func();
        }}
      />
      <LinkPopupItem
        name="김포 공항 주차"
        func={() => {
          navigate("/storege/service/gimpo/김포");
          data.closePopup();
          data.func();
        }}
      />
    </LinkPopupBox>
  );
}
