import React, { useState } from "react";
import { useEffect } from "react";
import * as fatchSet from "../../api/api";
import { useNavigate } from "react-router-dom";
import MemberBox from "component/member/memberBox";
import { BtnBox } from "component/basic/btns";
import { InputItemBox } from "component/basic/formItems";
import { ErrPopup } from "component/basic/popup";
import { Footer } from "component/elements/footer";
import queryStringify from "qs-stringify";
import { tokenSave } from "js/function";

const Login = (props) => {
  const navigate = useNavigate();

  const [id, setId] = useState("");
  const [pw, setPw] = useState("");

  const [err, setErr] = useState("");

  const [errPopupData, setErrPopupData] = useState(null);
  let varUA = navigator.userAgent;

  const googleLogin = () => {
    const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT;
    const REDIRECT_URL = process.env.REACT_APP_GOOGLE_REDIRECT_URL;
    const AUTHORIZE_URI = "https://accounts.google.com/o/oauth2/v2/auth";

    const queryStr = queryStringify({
      client_id: CLIENT_ID,
      redirect_uri: REDIRECT_URL,
      response_type: "token",
      scope:
        "https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email",
    });
    window.location.href = AUTHORIZE_URI + "?" + queryStr;
  };

  const kakaoLogin = () => {
    if (
      varUA.match("chadugo/Android") != null ||
      varUA.match("chadugo/iOS") != null
    ) {
      navigate("/social/kakaoApp");
      return;
    }

    window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${process.env.REACT_APP_KAKAO_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_KAKAO_REDIRECT_URL}&response_type=code&state=kakao`;
  };

  const naverLogin = () => {
    if (
      varUA.match("chadugo/Android") != null ||
      varUA.match("chadugo/iOS") != null
    ) {
      navigate("/social/naverApp");
      return;
    }
    window.location.href = `https://nid.naver.com/oauth2.0/authorize?client_id=${process.env.REACT_APP_NAVER_CLIENT_ID}&response_type=code&redirect_uri=${process.env.REACT_APP_NAVER_REDIRECT_URL}&state=naver`;
  };

  const LoginNext = () => {
    let formData = new FormData();
    formData.append("email", id);
    formData.append("password", pw);

    fatchSet.FatchApi({
      type: "POST",
      formDataItem: formData,
      url: "/login",
      success: (data) => {
        localStorage.setItem("token", data.data.token.access_token);
        localStorage.setItem("refresh_token", data.data.token.refresh_token);

        tokenSave(data.data.token.access_token);

        props.logIn();

        if (sessionStorage.getItem("setUrl")) {
          let reUrl = sessionStorage.getItem("setUrl");
          sessionStorage.removeItem("setUrl");
          navigate(reUrl);
        } else {
          navigate(`/main`);
        }
      },
      err: (data) => {
        setErr("err");
        setErrPopupData({
          addClass: null,
          text: "아이디/비밀번호를 정확히 입력해주세요.",
          closeType: true,
          closeFunc: (e) => {},
          btnFunc: (e) => {},
        });
      },
    });
  };
  useEffect(() => {
    window.AppleID.auth.init({
      clientId: "com.chadugo",
      scope: "name email",
      redirectURI: `${process.env.REACT_APP_APPLE_REDIRECT_URL}`,
      state: "",
      nonce: "",
      usePopup: true,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("AppleIDSignInOnSuccess", (data) => {
      window.location.href = `${process.env.REACT_APP_APPLE_REDIRECT_URL}?code=${data?.detail?.authorization?.code}`;
    });
    document.addEventListener("AppleIDSignInOnFailure", (event) => {
      console.log(event.detail.error);
    });
    return () => {
      document.removeEventListener("AppleIDSignInOnSuccess", (data) => {
        window.location.href = `${process.env.REACT_APP_APPLE_REDIRECT_URL}?code=${data?.detail?.authorization?.code}`;
      });
      document.addEventListener("AppleIDSignInOnFailure", (event) => {
        console.log(event.detail.error);
      });
    };
  }, []);

  return (
    <>
      <div className="memberPage headerInPage pageSizing">
        <MemberBox title="로그인" subTitle="<b>차두고</b> 계정으로 로그인">
          <div className="loginInputBox">
            <InputItemBox
              type="text"
              addClass={err}
              inputName="아이디"
              placeholder="아이디"
              val={id}
              max={20}
              regexp={/[^a-zA-Z0-9]/gi}
              func={(value) => {
                setId(value);
                setErr("");
              }}
            />
            <InputItemBox
              type="pw"
              addClass={err}
              inputName="패스워드"
              placeholder="패스워드"
              pwChType={true}
              val={pw}
              max={20}
              regexp={/[^a-zA-Z0-9#?!@$ %^&*-]/gi}
              func={(value) => {
                setPw(value);
                setErr("");
              }}
            />
          </div>
          <BtnBox
            addClass="pageInType"
            text="로그인"
            func={() => {
              LoginNext();
            }}
          />
          <div className="snsLoginBox">
            <p className="snsLoginTitle">
              <b>SNS</b> 간편 로그인
            </p>
            <div className="snsLoginItemBox">
              <button type="button" className="snsLoginItem">
                <img
                  src="/assets/images/sns/apple.svg"
                  id="appleid-signin"
                  className="appleSnsButton"
                />
              </button>
              <button
                type="button"
                onClick={() => {
                  googleLogin();
                }}
                className="snsLoginItem"
              >
                <img src="/assets/images/sns/google.svg" />
              </button>
              <button
                type="button"
                onClick={() => {
                  naverLogin();
                }}
                className="snsLoginItem"
              >
                <img src="/assets/images/sns/naver.svg" />
              </button>
              <button
                type="button"
                onClick={() => {
                  kakaoLogin();
                }}
                className="snsLoginItem"
              >
                <img src="/assets/images/sns/kakao.svg" />
              </button>
            </div>
          </div>
          <div className="loginSubMenu">
            <button
              type="button"
              className="linkJoin"
              onClick={() => {
                navigate("/member/terms/normal");
              }}
            >
              회원가입
            </button>
            <div className="linkFindBox">
              <button
                type="button"
                className="linkFindItem"
                onClick={() => {
                  navigate("/member/idFind");
                }}
              >
                아이디 찾기
              </button>
              <button
                type="button"
                className="linkFindItem"
                onClick={() => {
                  navigate("/member/pwFind");
                }}
              >
                비밀번호 찾기
              </button>
            </div>
          </div>
        </MemberBox>
        <ErrPopup data={errPopupData} />
      </div>
      <Footer rightOpenFunc={(id) => props.rightOpenFunc(id)} />
    </>
  );
};

export default Login;
