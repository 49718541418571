//AppSection
function AppSection(data) {
  return (
    <div className="appSection">
      {data.title ? (
        <div className="appTiileArea">
          <h1 className="appTitle">{data.title}</h1>
          {data.text ? <p className="appText">{data.text}</p> : ""}
        </div>
      ) : (
        ""
      )}
      <div className="appContents">{data.children}</div>
    </div>
  );
}

function AppSubSection(data) {
  return (
    <div className="appSection_subArea">
      <h1 className="appSection_subTitle">{data.title}</h1>
      <div className="appSection_subContents">{data.children}</div>
    </div>
  );
}

export { AppSection, AppSubSection };
