import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import {
  InfoText,
  ServiceInfoSubItem,
  ServiceInfoSubItemBox,
  SevicePageTitle,
} from "component/app/subItems";
import { ChkBox, InputItemBox } from "component/basic/formItems";
import { PhoneSetting } from "js/function";
import { comFormat } from "js/function";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import { ErrPopup, LoadingBox, RightPopup } from "component/basic/popup";
import {
  OrderCarData,
  OrderNotPrice,
  OrderOptionArea,
  OrderPayItem,
  OrderPriceItem,
  OrderPriceSubItem,
  OrderSeciton,
  OrderTimeArea,
} from "component/app/order";

const StoregeOrder = (props) => {
  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const pageData = location?.state ? location?.state : null;
  const pageType = param.type;
  const pageSubType = param.subType;

  const formItem = useRef(null);
  const [loading, setLoading] = useState(false);

  const [oil, setOil] = useState(pageData?.oil);
  const [wash, setWash] = useState(pageData?.wash);
  const [inspection, setInspection] = useState(pageData?.inspection);
  const [management, setManagement] = useState(pageData?.management);
  const [managementEtc, setManagementEtc] = useState(pageData?.etc);
  const [repair, setRepair] = useState(pageData?.repair);
  const [repairImg, setRepairImg] = useState([]);

  const [maintenance, setMaintenance] = useState(pageData?.maintenance);
  const [washBT, setWashBT] = useState(pageData?.washBT);

  const [pointPrice, setPointPrice] = useState(0);
  const [payType, setPayType] = useState("card");

  const [totalPrice, setTotalPrice] = useState(
    pageData?.price ? pageData?.price : 0
  );
  const [salePrice, setSalePrice] = useState(
    pageData?.discount_price ? pageData?.discount_price : 0
  );

  const [termsChk, setTermsChk] = useState(false);

  const [errPopupData, setErrPopupData] = useState(null);

  const [rightPopupText, setRightPopupText] = useState("");

  const [btnChk, setBtnChk] = useState(true);

  const [orderChk, setOrderChk] = useState(true);

  //약관 체크
  const termsList = useGet({
    url: `/term?type=pay`,
  });

  //내 포인트 체크
  const pointData = useGet({
    url: `/mypage/point?page=1&type=1`,
    loginType: "login",
  });

  //pg
  function orderDateSet() {
    formItem.current.innerHTML = "";

    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="access_token" value="${localStorage.getItem(
        "token"
      )}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="category" value="${pageData?.category}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="region" value="${pageData?.region}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="name" value="${pageData?.name}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="phone" value="${pageData?.phone}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="car_id" value="${pageData?.car_id}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="start_date" value="${`${pageData?.start_date} ${pageData?.start_time}`}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="end_date" value="${`${pageData?.end_date} ${pageData?.end_time}`}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="user_pickup" value="${pageData?.user_pickup}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="contents" value="${pageData?.contents}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="use_point" value="${pointPrice}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="add_point" value="${Math.floor(
        totalPrice * 0.03
      )}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="storage_price" value="${pageData?.storage_price}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="default_price" value="${totalPrice}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="price" value="${
        Number(totalPrice) - Number(salePrice) - Number(pointPrice)
      }"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="discount_price" value="${salePrice}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="is_wash" value="${wash ? 1 : 0}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="wash_price" value="${
        wash ? wash.price : ""
      }"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="is_wax" value="${
        wash && wash.waxPrice > 0 ? 1 : 0
      }"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="wax_price" value="${
        wash && wash.waxPrice > 0 ? wash.waxPrice : ""
      }"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="is_inspection" value="${
        inspection ? 1 : 0
      }"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="inspection_price" value="${
        inspection ? inspection.price : ""
      }"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="payment_method" value="${payType}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="use_option" value="${1}"/>`
    );
    formItem.current.insertAdjacentHTML(
      "beforeend",
      `<input type="hidden" name="is_repair" value="${repair ? 1 : 0}"/>`
    );

    if (management) {
      management.forEach((item, i) => {
        formItem.current.insertAdjacentHTML(
          "beforeend",
          `<input type="hidden" name="manage_items[]" value="${item.type}"/>`
        );
        if (item.type == "기타 (직접 입력)") {
          formItem.current.insertAdjacentHTML(
            "beforeend",
            `<input type="hidden" name="manage_etc" value="${pageData?.etc}"/>`
          );
        }
      });
    }

    if (repair) {
      repairImg.forEach((item, i) => {
        formItem.current.insertAdjacentHTML(
          "beforeend",
          `<input type="hidden" name="repair_images[]" value="${item}"/>`
        );
      });
    }

    if (pageData?.region == "제주") {
      formItem.current.insertAdjacentHTML(
        "beforeend",
        `<input type="hidden" name="oil_id" value="${oil ? oil.id : ""}"/>`
      );
      formItem.current.insertAdjacentHTML(
        "beforeend",
        `<input type="hidden" name="oil_name" value="${oil ? oil.name : ""}"/>`
      );
      formItem.current.insertAdjacentHTML(
        "beforeend",
        `<input type="hidden" name="oil_price" value="${
          oil ? oil.price : ""
        }"/>`
      );
      formItem.current.insertAdjacentHTML(
        "beforeend",
        `<input type="hidden" name="oil_thumbnail" value="${
          oil ? oil.thum : ""
        }"/>`
      );
    } else {
      maintenance &&
        maintenance.length > 0 &&
        maintenance.forEach(function (item, i) {
          if (item.subType == "engin_oil") {
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="oil_id" value="${item.id}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="oil_name" value="${item.name}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="oil_price" value="${item.price}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="oil_thumbnail" value="${item.thum}"/>`
            );
          } else if (item.subType == "battery") {
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="battery_id" value="${item.id}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="battery_name" value="${item.name}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="battery_price" value="${item.price}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="battery_thumbnail" value="${item.thum}"/>`
            );
          } else if (item.subType == "engin_oil_add_items") {
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="add_oil_id" value="${item.id}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="add_oil_name" value="${item.name}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="add_oil_price" value="${item.price}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="add_oil_thumbnail" value="${item.thum}"/>`
            );
          } else if (item.subType == "battery_add_items") {
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="add_battery_id" value="${item.id}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="add_battery_name" value="${item.name}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="add_battery_price" value="${item.price}"/>`
            );
            formItem.current.insertAdjacentHTML(
              "beforeend",
              `<input type="hidden" name="add_battery_thumbnail" value="${item.thum}"/>`
            );
          }
        });
    }

    setTimeout(() => {
      setRepairImg([]);
      setOrderChk(true);
      setLoading(false);
      formItem.current.submit();
    }, 500);
  }

  function orderStart() {
    if (orderChk) {
      setLoading(true);
      setOrderChk(false);

      if (repair) {
        let repairImgData = [];
        repair.forEach((item, i) => {
          let formData = new FormData();

          formData.append("image", item);

          fatchSet.FatchApi({
            type: "POST",
            formDataItem: formData,
            url: `/image/download`,
            loginType: "login",
            success: (data) => {
              repairImgData.push(data.data.image_url);
              if (repairImgData.length == repair.length) {
                setRepairImg(repairImgData);
              }
            },
            err: (data) => {
              setOrderChk(true);
              setLoading(false);
              setErrPopupData({
                addClass: null,
                text: "일시적인 문제로 결제에 실패하였습니다.<br/>잠시 후 다시 시도 해주세요.<br/><br/>증상이 반복 될 경우 고객센터에 문의해주세요.",
                closeType: true,
                closeFunc: (e) => {},
                btnFunc: (e) => {},
              });
            },
          });
        });
      } else {
        orderDateSet();
      }
    }
  }

  useEffect(() => {
    if (repair && repair.length == repairImg.length) {
      orderDateSet();
    }
  }, [repairImg]);

  //총 금액 체크
  useEffect(() => {
    let totalPrictSet = Number(pageData?.storage_price);

    if (pageData?.user_pickup == 1) {
      totalPrictSet = totalPrictSet + Number(pageData?.pickPrice);
    }
    if (oil) {
      totalPrictSet = totalPrictSet + Number(oil.price);
    }
    if (wash) {
      totalPrictSet =
        totalPrictSet + Number(wash.price) + Number(wash.waxPrice);
    }
    if (inspection) {
      totalPrictSet = totalPrictSet + Number(inspection.price);
    }

    if (maintenance) {
      totalPrictSet = totalPrictSet + Number(pageData?.maintenancePrice);
    }
    if (washBT) {
      totalPrictSet = totalPrictSet + Number(washBT.price);
    }

    setTotalPrice(totalPrictSet);
  }, [pointPrice, oil, wash, inspection, maintenance, washBT]);

  useEffect(() => {
    let totalPrictSet = 0;

    if (oil) {
      totalPrictSet = totalPrictSet + 10000;
    }
    if (wash) {
      totalPrictSet = totalPrictSet + 10000;
    }

    setSalePrice(totalPrictSet);
  }, [oil, wash]);

  //버튼 활성화 체크
  useEffect(() => {
    if (termsChk && payType) {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [termsChk, payType]);

  return (
    <div className="orderPage headerInPage pageSizing mBtnPage">
      <form
        action={`${process.env.REACT_APP_API_URL}/purchase/service/kicc`}
        ref={formItem}
        method="GET"
      ></form>
      <Header
        pageBack={true}
        headTitle="신청 정보 확인"
        func={() => navigate(-1)}
        homeBtn={true}
      />
      <SevicePageTitle
        title="신청 정보 확인"
        navi={1}
        navi_0="서비스 신청"
        navi_1="신청 정보 확인"
        navi_2="신청 완료"
      />
      <div className="orderPage_contents">
        <OrderSeciton title="신청 내용 확인">
          <div className="orderDateArea">
            <OrderTimeArea
              title="입고일시"
              date={pageData?.start_date}
              time={pageData?.start_time}
            />
            <OrderTimeArea
              title="출고일시"
              date={pageData?.end_date}
              time={pageData?.end_time}
            />
          </div>
          <div className="orderCarData">
            <OrderCarData
              imgKey="car"
              title="차량 정보"
              text={`${pageData?.car_name} ${pageData?.car_number}`}
            />
            <OrderCarData
              imgKey="phone"
              title="연락처"
              text={PhoneSetting(pageData?.phone)}
            />
            {pageData?.region == "김포" || pageData?.region == "김해" ? (
              <OrderCarData
                imgKey="addr"
                title="지역"
                text={`${pageData?.region} 공항`}
              />
            ) : (
              ""
            )}
            <OrderCarData
              imgKey="memo"
              title="특이사항"
              text={pageData?.contents ? pageData?.contents : "-"}
            />
          </div>
        </OrderSeciton>
        <OrderSeciton title="이용 요금">
          <OrderOptionArea name="공항 주차" type="storage" del={false}>
            <b>{comFormat(pageData?.storage_price)}원</b>
          </OrderOptionArea>
          {pageData?.user_pickup == 1 ? (
            <OrderOptionArea name="픽업 서비스" type="pick" del={false}>
              <b>{comFormat(pageData?.pickPrice)}원</b>
            </OrderOptionArea>
          ) : (
            ""
          )}
          {oil ? (
            <OrderOptionArea
              name="엔진 오일"
              type="oil"
              del={true}
              func={() => {
                setErrPopupData({
                  addClass: null,
                  text: `엔진 오일 서비스를<br/>삭제하시겠습니까?`,
                  closeType: true,
                  closeFunc: (e) => {},
                  btnFunc: (e) => {
                    setOil(null);
                  },
                  errBtnText: "확인",
                  textSub: "취소",
                  btnAddClassSub: "notLine",
                  funcSub: () => {},
                });
              }}
            >
              <b>{comFormat(oil.price)}원</b>
            </OrderOptionArea>
          ) : (
            ""
          )}
          {wash ? (
            <OrderOptionArea
              name="세차"
              type="wash"
              del={true}
              func={() => {
                setErrPopupData({
                  addClass: null,
                  text: `세차 서비스를<br/>삭제하시겠습니까?`,
                  closeType: true,
                  closeFunc: (e) => {},
                  btnFunc: (e) => {
                    setWash(null);
                  },
                  errBtnText: "확인",
                  textSub: "취소",
                  btnAddClassSub: "notLine",
                  funcSub: () => {},
                });
              }}
            >
              <b>{comFormat(Number(wash.price) + Number(wash.waxPrice))}원</b>
            </OrderOptionArea>
          ) : (
            ""
          )}
          {inspection ? (
            <OrderOptionArea
              name="차량 검사"
              type="inspection"
              del={true}
              func={() => {
                setErrPopupData({
                  addClass: null,
                  text: `차량 검사 서비스를<br/>삭제하시겠습니까?`,
                  closeType: true,
                  closeFunc: (e) => {},
                  btnFunc: (e) => {
                    setInspection(null);
                  },
                  errBtnText: "확인",
                  textSub: "취소",
                  btnAddClassSub: "notLine",
                  funcSub: () => {},
                });
              }}
            >
              <b>{comFormat(inspection.price)}원</b>
            </OrderOptionArea>
          ) : (
            ""
          )}
          {management ? (
            <OrderOptionArea
              name="관리 서비스 신청"
              type="management"
              del={true}
              func={() => {
                setErrPopupData({
                  addClass: null,
                  text: `관리 서비스 신청 서비스를<br/>삭제하시겠습니까?`,
                  closeType: true,
                  closeFunc: (e) => {},
                  btnFunc: (e) => {
                    setManagement(null);
                    setManagementEtc(null);
                  },
                  errBtnText: "확인",
                  textSub: "취소",
                  btnAddClassSub: "notLine",
                  funcSub: () => {},
                });
              }}
            >
              <OrderNotPrice text="상담 후 견적가 안내" />
            </OrderOptionArea>
          ) : (
            ""
          )}
          {repair ? (
            <OrderOptionArea
              name="외관 수리 신청"
              type="repair"
              del={true}
              func={() => {
                setErrPopupData({
                  addClass: null,
                  text: `외관 수리 신청 서비스를<br/>삭제하시겠습니까?`,
                  closeType: true,
                  closeFunc: (e) => {},
                  btnFunc: (e) => {
                    setRepair(null);
                  },
                  errBtnText: "확인",
                  textSub: "취소",
                  btnAddClassSub: "notLine",
                  funcSub: () => {},
                });
              }}
            >
              <OrderNotPrice text="상담 후 견적가 안내" />
            </OrderOptionArea>
          ) : (
            ""
          )}
          {maintenance ? (
            <OrderOptionArea
              name="출장 정비"
              type="maintenance"
              del={true}
              func={() => {
                setErrPopupData({
                  addClass: null,
                  text: `출장 정비 서비스를<br/>삭제하시겠습니까?`,
                  closeType: true,
                  closeFunc: (e) => {},
                  btnFunc: (e) => {
                    setMaintenance(null);
                  },
                  errBtnText: "확인",
                  textSub: "취소",
                  btnAddClassSub: "notLine",
                  funcSub: () => {},
                });
              }}
            >
              <b>{comFormat(pageData?.maintenancePrice)}원</b>
            </OrderOptionArea>
          ) : (
            ""
          )}
          {washBT ? (
            <OrderOptionArea
              name="출장 세차"
              type="washBT"
              del={true}
              func={() => {
                setErrPopupData({
                  addClass: null,
                  text: `출장 세차 서비스를<br/>삭제하시겠습니까?`,
                  closeType: true,
                  closeFunc: (e) => {},
                  btnFunc: (e) => {
                    setWashBT(null);
                  },
                  errBtnText: "확인",
                  textSub: "취소",
                  btnAddClassSub: "notLine",
                  funcSub: () => {},
                });
              }}
            >
              <b>{comFormat(washBT.price)}원</b>
            </OrderOptionArea>
          ) : (
            ""
          )}
        </OrderSeciton>
        <OrderSeciton title="포인트">
          <div className="service_minInputSection">
            <InputItemBox
              type="number"
              inputName="사용 포인트"
              placeholder="사용 포인트"
              value={pointPrice}
              numberMax={
                pointData?.data?.point > totalPrice - salePrice
                  ? totalPrice - salePrice
                  : pointData?.data?.point
              }
              disabled={pointData?.data?.point == 0 ? true : false}
              func={(e) => {
                setPointPrice(Number(e));
              }}
              caption={`사용 가능 포인트 <span class="bColor">${comFormat(
                pointData?.data?.point
              )} P</span>`}
            />
          </div>
        </OrderSeciton>
        <OrderSeciton title="결제 금액">
          <div className="orderPriceArea">
            <OrderPriceItem
              type=""
              text={`${comFormat(totalPrice)}원`}
              name="총 서비스 요금"
            />
            <OrderPriceItem
              type="upText"
              text={`-${comFormat(salePrice + pointPrice)}원`}
              name="총 할인가 "
            />
            {oil || wash || pointPrice !== 0 ? (
              <OrderPriceSubItem>
                {oil ? (
                  <ServiceInfoSubItemBox>
                    <ServiceInfoSubItem
                      name="엔진오일 결합할인"
                      item={`-${comFormat(10000)}원`}
                    />
                  </ServiceInfoSubItemBox>
                ) : (
                  ""
                )}
                {wash ? (
                  <ServiceInfoSubItemBox>
                    <ServiceInfoSubItem
                      name="세차 결합할인"
                      item={`-${comFormat(10000)}원`}
                    />
                  </ServiceInfoSubItemBox>
                ) : (
                  ""
                )}

                {pointPrice !== 0 ? (
                  <ServiceInfoSubItemBox>
                    <ServiceInfoSubItem
                      name="포인트 사용"
                      item={`-${comFormat(pointPrice)}원`}
                    />
                  </ServiceInfoSubItemBox>
                ) : (
                  ""
                )}
              </OrderPriceSubItem>
            ) : (
              ""
            )}
          </div>
          <div className="orderTotalPriceArea">
            <h1 className="orderTotalPrice_name">총 결제 금액</h1>
            <div className="orderTotalPriceBox">
              <h2 className="orderTotalPrice">
                {comFormat(totalPrice - salePrice - Number(pointPrice))}원
              </h2>
              <p className="orderTotalPrice_point">
                {comFormat(
                  Math.floor(
                    (totalPrice - salePrice - Number(pointPrice)) * 0.03
                  )
                )}{" "}
                P 적립 예정
              </p>
            </div>
          </div>
        </OrderSeciton>
        <OrderSeciton title="결제 수단/주문 동의">
          <div className="orderPayArea">
            <OrderPayItem
              setVal={payType}
              func={() => {
                setPayType("card");
              }}
              val="card"
              type=""
              text="신용카드"
            />
            <OrderPayItem
              setVal={payType}
              func={() => {
                setPayType("bank");
              }}
              val="bank"
              type=""
              text="계좌이체"
            />
            <OrderPayItem
              setVal={payType}
              func={() => {
                setPayType("phone");
              }}
              val="phone"
              type=""
              text="휴대폰 결제"
            />
            <OrderPayItem
              setVal={payType}
              func={() => {
                setPayType("npay");
              }}
              val="npay"
              type="naver"
              text=""
            />
            <OrderPayItem
              setVal={payType}
              func={() => {
                setPayType("kakao");
              }}
              val="kakao"
              type="kakao"
              text=""
            />
          </div>
          <div className="orderChkArea">
            <ChkBox
              addClass="pageIn"
              func={(e) => {
                setTermsChk(e.target.checked);
              }}
              checkedType={termsChk}
              chkSubType={"필수"}
              label="구매조건 확인 및 결제 대행 서비스 약관 동의"
              chkFunc={(e) => {
                setRightPopupText(termsList?.data?.term?.value);
              }}
            />
          </div>
          <div className="payInfoTextBox">
            <InfoText
              listType="-"
              text="예약일 변경 또는 취소 요청의 경우 입고일 기준 3일 전 신청 건만 가능합니다. "
            />
            <InfoText
              listType="-"
              text="입고일 기준 2일~당일 요청 건은 변경 또는 환불이 불가능 하오니 유의해 주시기 바랍니다."
            />
          </div>
        </OrderSeciton>
        <BtnBox
          addClass="pageInType mChType pcMinBtn"
          text={"결제하기"}
          disabled={btnChk}
          func={() => {
            orderStart();
          }}
        />
      </div>
      <ErrPopup data={errPopupData} />
      <RightPopup
        topTitle="구매조건 확인 및 결제 대행 서비스 약관 동의"
        text={rightPopupText}
        closePopup={() => {
          setRightPopupText("");
        }}
        btnType="only"
        popupBtnFunc={() => {
          setTermsChk(true);
        }}
      />
      <LoadingBox
        addClass={loading ? "active" : ""}
        loadingItem="<img src='/assets/images/icon/loading.svg' class='spin'/>"
      />
    </div>
  );
};

export default StoregeOrder;
