import { ErrPopup } from "component/basic/popup";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

//결제 취소시
const OrderCancel = () => {
    const navigate = useNavigate();
    const param = useParams();
    const type = param.type;

    const [errPopupData,setErrPopupData] =  useState(null);

    useEffect(() => {
        setErrPopupData({
            addClass:null,
            text:"결제를 취소하여<br/>서비스 신청 페이지로 돌아갑니다.",
            closeType:false,
            closeFunc:(e) => {},
            btnFunc:(e)=>{
                if(type == "storageJeju"){
                    navigate("/storege/service/jeju/제주");
                }else if(type == "storageGimpo"){
                    navigate("/storege/service/gimpo/김포");
                }else if(type == "storageGimhea"){
                    navigate("/storege/service/gimhae/김해");
                }else if(type == "maintenance"){
                    navigate("/maintenance");
                }else if(type == "service"){
                    navigate("/service");
                }else if(type == "rent"){
                    if(sessionStorage.getItem("rentId")){
                        navigate(`/rent/detail/${sessionStorage.getItem("rentId")}/${sessionStorage.getItem("rentStart")}/${sessionStorage.getItem("rentEnd")}`)
                    }else{
                        navigate("/")
                    }
                }else if(type == "management"){
                    if(sessionStorage.getItem("orderId")){
                        navigate(`/mypage/order/detail/${sessionStorage.getItem("orderId")}/${sessionStorage.getItem("orderType")}`)
                    }else{
                        navigate("/mypage/order/manages")
                    }
                }else if(type == "repair"){
                    if(sessionStorage.getItem("orderId")){
                        navigate(`/mypage/order/detail/${sessionStorage.getItem("orderId")}/${sessionStorage.getItem("orderType")}`)
                    }else{
                        navigate("/mypage/order/repair")
                    }
                }else{
                    navigate("/");
                }
            },
            errBtnText:"확인"
        });
    }, []);

    return (
        <div className="pageSizing">
            <ErrPopup
                data={errPopupData}
            />
        </div>
    );
};

export default OrderCancel;