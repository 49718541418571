import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { AccordionItem, CsPageTitle } from "component/app/subItems";
import FixedSection from "component/basic/fixedBox";
import useGet from "api/useGet";
import { NotItems } from "component/basic/notItems";
import { comFormat } from "js/function";
import Tap from "component/basic/tap";
import { FooterManu } from "component/elements/footer";
import PageNation from "component/basic/pageNation";
import { setDateTime } from "js/function";
import { CsType } from "component/cs/cs";
import { ErrPopup, RightPopup } from "component/basic/popup";
import { useEffect } from "react";

const CsList = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const pageType = param.page;

    const [page,setPage] = useState(1);
    const [write,setWrite] =  useState("");
    const [update,setUpdate] =  useState(0);

    const [faqCategory,setFaqCategory] = useState("전체");
    const [faqCloseChk,setFaqCloseChk] = useState("");

    const [errPopupData,setErrPopupData] =  useState(null);

    const categoryList = [
        {id:"notice",category:"공지사항"},
        {id:"cs",category:"1:1 문의"},
        {id:"faq",category:"자주 묻는 질문"}
    ];

    const apiUrl = pageType == "notice" ? `/notice?page=${page}` : pageType == "cs" ? `/qna?page=${page}&update=${update}` : `/faq?category=${faqCategory}`
    const listApi = useGet({
        url:apiUrl,
        loginType:"login"
    });

    const listData = pageType == "notice" ? listApi?.data?.notices : pageType == "cs" ? listApi?.data?.qnas : listApi?.data?.faqs;

    function CsListItem(data){
        return (
            <button type="button" className="csListItem" onClick={()=>{navigate(`/cs/detail/${pageType}/${data.data.id}`)}}>
                {pageType == "cs" ? <CsType text={data.data.status == 1 ? "답변완료" : "답변대기"}/> : ""}
                <div className="csListItemInfo">
                    <h1 className="csListItemInfo_title">{data.data.title}</h1>
                    <div className="csListItemInfo_dateBox">
                        <p className="csListItemInfo_date">{pageType == "cs" ? <span className="csListItemInfo_type">{data.data.category}</span> : ""}{setDateTime(data.data.created_at,"yymmdd")}</p>
                    </div>
                </div>
            </button>
        );
    }

    function FaqCategory(data){
        return (
            <button type="button" className={`faqCateItem ${data.addClass ? data.addClass : ""}`} onClick={()=>{setFaqCategory(data.id)}}>
                <img src={data.img}/>
                <p className="faqCateItem_name">{data.name}</p>
            </button>
        );
    }

    function CsListItem(data){
        return (
            <button type="button" className="csListItem" onClick={()=>{navigate(`/cs/detail/${pageType}/${data.data.id}`)}}>
                {pageType == "cs" ? <CsType text={data.data.status == 1 ? "답변완료" : "답변대기"}/> : ""}
                <div className="csListItemInfo">
                    <h1 className="csListItemInfo_title">{data.data.title}</h1>
                    <div className="csListItemInfo_dateBox">
                        <p className="csListItemInfo_date">{pageType == "cs" ? <span className="csListItemInfo_type">{data.data.category}</span> : ""}{setDateTime(data.data.created_at,"yymmdd")}</p>
                    </div>
                </div>
            </button>
        );
    }

    useEffect(()=>{
        if(!props.token && pageType == "cs"){
            setErrPopupData({
                addClass:null,
                text:"로그인이 필요한 페이지 입니다.",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc:(e)=>{
                    let nowUrl = new URL(window.location.href);
                    sessionStorage.setItem("setUrl",nowUrl.pathname);
                    navigate("/member/login");
                },
                errBtnText:"로그인",
                textSub:"취소",
                btnAddClassSub:"notLine",
                funcSub:()=>{navigate(-1)}
            });
        }
    },[pageType])

    return(
        <div className="csPage headerInPage mHeaderNot pageSizing footerMenuInPage">
            <CsPageTitle title="고객센터"/>
            {categoryList && categoryList.length > 0 ?
            <FixedSection boxAddClass="csFixedBox" addClass="csFixed" pageBox="csPage" headerChk={true}>
                <Tap
                    val={pageType}
                    data={categoryList}
                    func={(id)=>{navigate(`/cs/list/${id}`)}}
                />
            </FixedSection>
            :""}
            <div className="csList">
                {pageType == "notice" ? <p className="csTotalcount">총 <span>{listData ? comFormat(listData?.total) : 0}</span>건</p> : ""}
                {pageType == "cs" ? <button type="button" onClick={()=>{setWrite("open")}} className="openCsWeight">1:1 문의 작성</button> : ""}
                {pageType == "faq" ? 
                <div className="faqCateSection">
                    <FaqCategory
                        img={faqCategory == "전체" ? "/assets/images/icon/faq_0_on.svg" : "/assets/images/icon/faq_0_off.svg"}
                        name="전체"
                        id="전체"
                        addClass={faqCategory == "전체" ? "active" : ""}
                    />
                    <FaqCategory
                        img={faqCategory == "견적" ? "/assets/images/icon/faq_1_on.svg" : "/assets/images/icon/faq_1_off.svg"}
                        name="견적"
                        id="견적"
                        addClass={faqCategory == "견적" ? "active" : ""}
                    />
                    <FaqCategory
                        img={faqCategory == "예약 및 결제" ? "/assets/images/icon/faq_2_on.svg" : "/assets/images/icon/faq_2_off.svg"}
                        name="예약 및 결제"
                        id="예약 및 결제"
                        addClass={faqCategory == "예약 및 결제" ? "active" : ""}
                    />
                    <FaqCategory
                        img={faqCategory == "서비스 이용" ? "/assets/images/icon/faq_3_on.svg" : "/assets/images/icon/faq_3_off.svg"}
                        name="서비스 이용"
                        id="서비스 이용"
                        addClass={faqCategory == "서비스 이용" ? "active" : ""}
                    />
                    <FaqCategory
                        img={faqCategory == "기타" ? "/assets/images/icon/faq_4_on.svg" : "/assets/images/icon/faq_4_off.svg"}
                        name="기타"
                        id="기타"
                        addClass={faqCategory == "기타" ? "active" : ""}
                    />
                </div>
                : ""}
                <div className="csList_section">
                    {listData && (listData?.length > 0 || listData?.data?.length > 0) ? 
                        pageType == "faq" ? 
                        listData?.map((item,i)=>(
                            <AccordionItem
                                id={i}
                                key={i}
                                closeChk={faqCloseChk}
                                title={item.question}
                                cate={item.category}
                                text={item.answer}
                                func={(id)=>{
                                    setFaqCloseChk(id)
                                }}
                            />
                        ))
                        : 
                        listData?.data?.map((item,i)=>(
                            <CsListItem key={i} data={item}/>
                        ))
                    :
                        <NotItems img="/assets/images/icon/not_list.svg" text={pageType == "notice" ? "공지사항이 없습니다." : pageType == "cs" ? "1:1 문의 내역이 없습니다." : "자주 묻는 질문이 없습니다."}/>
                    }
                </div>
                {pageType == "notice" || pageType == "cs" ?
                <PageNation perPage={5} total={listData?.last_page} page={page} func={(i)=>{setPage(i);window.scrollTo(0,0)}}/>
                :""}
            </div>
            <FooterManu menuChk={3}/>
            <RightPopup
                addClass="minVar"
                text={write}
                topTitle="1:1 문의 작성"
                closePopup={() => {setWrite("")}}
                writePopup={true}
                addFunc={()=>{setUpdate(update + 1)}}
            />
            <ErrPopup
                data={errPopupData}
            />
        </div>
    );
}
export default CsList;