import { useNavigate } from "react-router-dom";
import { DatePicerRange, RadioBox, TimePicer } from "component/basic/formItems";
import { useState } from "react";
import { useEffect } from "react";
import { PopupBtnBox } from "component/basic/btns";
import ReactDatePicker from "react-datepicker";
import { ko } from 'date-fns/esm/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { setDateTime, setDateWeek } from "js/function";
import { ErrPopup } from "component/basic/popup";

//렌터카 선택시
function RentInquiryBox(data){
    return (
        <div className={`rentInquiryBox ${data.addClass ? data.addClass : ""}`}>
            <h1 className="rentInquiryBoxTitle" dangerouslySetInnerHTML={{__html:data.title}}></h1>
            <div className="rentInquiryItemBox">
                {data.children}
            </div>
        </div>
    );
}

export default function RentInquiryLink(data){
    let navigate = useNavigate();
    const [carType,setCarType] = useState("전체");
    const carTypeArr = ["전체","경차","소형","준중형","중형","고급","RV","승합","수입","전기"];

    const [btnChk,setBtnChk] = useState(true);

    const [startDate,setStartDate] = useState(null);
    const [endDate,setEndDate] = useState(null);
    const [startTime,setStartTime] = useState(null);
    const [endTime,setEndTime] = useState(null);
    const [timeDataSet,setTimeDataSet] = useState(false);

    const [errPopupData,setErrPopupData] = useState(null);

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    useEffect(()=>{
        if(startDate && endDate && startTime && endTime){
            setBtnChk(false);
        }else{
            setBtnChk(true);
        }
    },[startDate,endDate,startTime,endTime]);
    
    return (
        <>
            <div className="rentInquiryArea popupPageItem">
                <RentInquiryBox title="대여 일시 / 반납 일시 선택">
                    <DatePicerRange
                        inputName="대여 일 - 반납 일"
                        placeholder="대여 일 - 반납 일"
                        value={`${startDate} - ${endDate}`}
                        func={(start, end) => {setStartDate(setDateTime(start,"yymmdd"));setEndDate(setDateTime(end,"yymmdd"))}}
                    />
                    <div className="col2Input">
                        <TimePicer
                            inputName="대여 시간"
                            placeholder="대여 시간"
                            value={startTime}
                            dataChk={timeDataSet}
                            date={`${setDateTime(startDate,"yymmdd")} (${setDateWeek(startDate)})`}
                            disabled={startDate && endDate ? false : true}
                            func={(start, end) => {
                                if(startDate && endDate && endTime){
                                    if(new Date(`${setDateTime(startDate,"yymmdd").replace(/-/g,"/")} ${`${start}:${end}`}`) > new Date(`${setDateTime(endDate,"yymmdd").replace(/-/g,"/")} ${endTime ? endTime : "00:00"}`)){
                                        setStartTime(`${start}:${end}`)
                                        setErrPopupData({
                                            addClass:null,
                                            text:"반납일은 대여일보다<br/>빠르게 설정할 수 없습니다.",
                                            closeType:true,
                                            closeFunc:(e) => {},
                                            btnFunc:(e)=>{}
                                        });
                                        setStartTime(null);
                                        setTimeDataSet(!timeDataSet);
                                    }else{
                                        setStartTime(`${start}:${end}`)
                                        setTimeDataSet(!timeDataSet);
                                    }
                                }else{
                                    setStartTime(`${start}:${end}`)
                                    setTimeDataSet(!timeDataSet);
                                }
                            }}
                        />
                        <TimePicer
                            inputName="반납 시간"
                            placeholder="반납 시간"
                            value={endTime}
                            dataChk={timeDataSet}
                            date={`${setDateTime(endDate,"yymmdd")} (${setDateWeek(endDate)})`}
                            disabled={startDate && endDate ? false : true}
                            func={(start, end) => {
                                if(startDate && endDate && startTime){
                                    if(new Date(`${setDateTime(startDate,"yymmdd").replace(/-/g,"/")} ${startTime ? startTime : "00:00"}`) > new Date(`${setDateTime(endDate,"yymmdd").replace(/-/g,"/")} ${`${start}:${end}`}`)){
                                        setEndTime(`${start}:${end}`)
                                        setErrPopupData({
                                            addClass:null,
                                            text:"반납일은 대여일보다<br/>빠르게 설정할 수 없습니다.",
                                            closeType:true,
                                            closeFunc:(e) => {},
                                            btnFunc:(e)=>{}
                                        });
                                        setEndTime(null);
                                        setTimeDataSet(!timeDataSet)
                                    }else{
                                        setEndTime(`${start}:${end}`)
                                        setTimeDataSet(!timeDataSet)
                                    }
                                }else{
                                    setEndTime(`${start}:${end}`)
                                    setTimeDataSet(!timeDataSet)
                                }
                            }}
                        />
                    </div>
                </RentInquiryBox>
                <RentInquiryBox title="차종 선택" addClass="flexBox">
                    {carTypeArr.map((item, i) => (
                        <RadioBox
                            id="rentInquiryTypeChk"
                            val={item}
                            label={item}
                            checkedType={carType == item ? true : false}
                            func={(e) => setCarType(e)}
                            key={i}
                        />
                    ))}
                </RentInquiryBox>
            </div>
            <PopupBtnBox
                text="확인"
                disabled={btnChk}
                func={()=>{navigate(`/rent/list/${startDate}/${startTime}/${endDate}/${endTime}/${carType}`);data.func();data.closePopup()}}
            />
            <ErrPopup
                data={errPopupData}
            />
        </>
    );
}