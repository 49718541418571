import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import { PhoneSetting, getDateGap, payTypeText, setDateTime, setDateWeek } from "js/function";
import { comFormat } from "js/function";
import { Header } from "component/elements/header";
import { OrderComHead, OrderComInfoText, OrderComSection, OrderComTable, OrderComTableItem } from "component/app/order";
import MiddleBanner from "component/banner/banner";
import { Footer } from "component/elements/footer";
import { MypageDetailOrderLink, MypageDetailTitle } from "component/mypage/mypage";
import { InfoText } from "component/app/subItems";
import { ErrPopup, RightPopup } from "component/basic/popup";
import { MypageTableState } from "component/mypage/mypageTable";

const MyRentDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param.id;

    const [write,setWrite] =  useState("");
    const [carInfo,setCarInfo] =  useState("");
    const [code,setCode] =  useState(null);
    const [errPopupData,setErrPopupData] = useState(null);
    const [rightPopupData,setRightPopupData] = useState(null);

    const orderApi = useGet({
        url:`/rentercar/reserve/view?order_number=${id}`,
        loginType:"login"
    });
    const orderData = orderApi?.data?.reserve;

    const rentTerms = useGet({
        url:`/term?type=rent`,
        loginType:"login"
    });

    useEffect(() => {
        setCode(orderData?.reserve_number);
    }, [orderData]);

    return (
        <>
        <div className="myDetailPage headerInPage pageSizing">
            <Header
                pageBack={true}
                headTitle="렌터카 예약 내역"
                func={() => {navigate(-1)}}
            />
            <div className="myDetailPage_contents">
                <MypageDetailTitle
                    title="렌터카 예약 내역"
                    subNumber={code}
                    pcType={true}
                >
                    <MypageTableState 
                        text={
                            orderData?.status == 1 ? "예약완료" : orderData?.status == 2 ? "이용중" : orderData?.status == 3 ? "이용 완료" : "예약취소"
                        }
                        addClass={
                            orderData?.status == 1 ? "wbColor" : orderData?.status == 2 ? "bColor" : orderData?.status == 3 ? "" : "rColor"
                        }/>
                </MypageDetailTitle>
                <OrderComSection title="신청 내용">
                    <OrderComTable>
                        <OrderComTableItem
                            title="선택 차량"
                            link={true}
                            linkFunc={()=>{setCarInfo("open")}}
                            text={orderData?.rent_car_name && orderData?.car_year ? `${orderData?.rent_car_name} (${orderData?.car_year})` : "-"}
                        />
                        <OrderComTableItem
                            title="보험 명"
                            text={orderData?.insurance_name ? orderData?.insurance_name : "-"}
                        />
                        <OrderComTableItem
                            title="이름"
                            text={orderData?.reserve_name ? orderData?.reserve_name : "-"}
                        />
                        <OrderComTableItem
                            title="연락처"
                            text={orderData?.reserve_phone ? PhoneSetting(orderData?.reserve_phone) : "-"}
                        />
                        <OrderComTableItem
                            title="생년월일"
                            text={orderData?.reserve_birth ? orderData?.reserve_birth : "-"}
                        />
                        <OrderComTableItem
                            title="대여일시"
                            text={orderData?.start_date && orderData?.start_date !== "" ? `${setDateTime(orderData?.start_date,"yymmdd")} (${setDateWeek(setDateTime(orderData?.start_date,"yymmdd"))}) ${setDateTime(orderData?.start_date,"hhtt")}`:"-"}
                        />
                        <OrderComTableItem
                            title="대여 장소"
                            text={orderData?.rental_addr ? orderData?.rental_addr : "-"}
                        />
                        <OrderComTableItem
                            title="반납일시"
                            text={orderData?.end_date && orderData?.end_date !== "" ? `${setDateTime(orderData?.end_date,"yymmdd")} (${setDateTime(orderData?.end_date,"yymmdd")}) ${setDateTime(orderData?.end_date,"hhtt")}`:"-"}
                        />
                        <OrderComTableItem
                            title="반납 장소"
                            text={orderData?.return_addr ? orderData?.return_addr : "-"}
                        />
                        <OrderComTableItem
                            title="총 대여기간"
                            text={orderData?.start_date && orderData?.end_date ? getDateGap(orderData?.start_date,orderData?.end_date) : "-"}
                        />
                        <OrderComTableItem
                            title="특이사항"
                            text={orderData?.contents ? orderData?.contents : "-"}
                        />
                    </OrderComTable>
                </OrderComSection>
                <OrderComSection title="이용 요금">
                    <OrderComTable>
                        <OrderComTableItem
                            title="차량 렌터카 비용"
                            text={orderData?.price ? `${comFormat(orderData?.price)} 원` : "0 원"}
                        />
                        <OrderComTableItem
                            title={orderData?.insurance_name ? orderData?.insurance_name : "-"}
                            text={orderData?.insurance_price ? `${comFormat(orderData?.insurance_price)} 원` : "0 원"}
                        />
                    </OrderComTable>
                </OrderComSection>
                <OrderComSection title="결제 정보">
                    <OrderComTable>
                        <OrderComTableItem
                            title="결제 수단 / 결제 일시"
                            text={`${orderData?.payment_method ? payTypeText(orderData?.payment_method) : "-"} / ${orderData?.purchase_date ? setDateTime(orderData?.purchase_date,"yymmddhhttss") : "-"}`}
                        />
                        <OrderComTableItem
                            title="총 서비스 요금"
                            text={orderData?.price && orderData?.insurance_price ? `${comFormat(Number(orderData?.price) + Number(orderData?.insurance_price))} 원` : "0 원"}
                        />
                        <OrderComTableItem
                            title="사용 포인트"
                            text={orderData?.use_point ? `${comFormat(orderData?.use_point)} P` : "0 P"}
                        />
                        <OrderComTableItem
                            title="총 할인가"
                            text={orderData?.discount_price ? `${comFormat(orderData?.discount_price)} 원` : "0 원"}
                        />
                        <OrderComTableItem
                            title="총 결제 금액"
                            text={orderData?.price && orderData?.insurance_price ? `${comFormat(Number(orderData?.price) + Number(orderData?.insurance_price) - Number(orderData?.discount_price ? orderData?.discount_price : 0) - Number(orderData?.use_point ? orderData?.use_point : 0))} 원` : "0 원"}
                        />
                        <OrderComTableItem
                            title="적립 포인트"
                            text={orderData?.add_point ? `${comFormat(orderData?.add_point)} P` : "0 P"}
                        />
                    </OrderComTable>
                </OrderComSection>
                {orderData?.status == 10 ? 
                <OrderComSection title="취소 정보">
                    <OrderComTable>
                        <OrderComTableItem
                            title="취소 일시"
                            text={orderData?.cancel_date ? setDateTime(orderData?.cancel_date,"yymmddhhtt") : "-"}
                        />
                        <OrderComTableItem
                            title="환급 포인트"
                            text={orderData?.return_point ? `${comFormat(orderData?.return_point)} P` : "0 P"}
                        />
                        <OrderComTableItem
                            title="환불 금액"
                            text={orderData?.return_price ? `${comFormat(orderData?.return_price)} 원` : "0 원"}
                        />
                        <OrderComTableItem
                            title="취소 사유"
                            text={orderData?.cancelType ? orderData?.cancelType : "-"}
                        />
                    </OrderComTable>
                </OrderComSection>
                :
                <>
                <BtnBox
                    btnAddClass="line"
                    addClass="pageInType pcMinBtn"
                    text={"예약 취소 요청"}
                    func={()=>{setWrite("open")}}
                />
                <span className="sub_lineItem"></span>
                </>
                }
                <button type="button" onClick={()=>{
                    setRightPopupData(rentTerms?.data?.term?.value)
                }} className="service_selInfo_info"><img src="/assets/images/basic/info.svg"/>취소 및 환불 규정</button>
            </div>
        </div>
        <Footer rightOpenFunc={(id) => props.rightOpenFunc(id)}/>
        <ErrPopup
            data={errPopupData}
        />
        <RightPopup
            text={carInfo}
            topTitle="차량 상세정보"
            carName={orderData?.rent_car_name && orderData?.car_year ? `${orderData?.rent_car_name} (${orderData?.car_year})` : "-"}
            data={orderData?.car_info}
            closePopup={() => {setCarInfo("")}}
            rentCarInfoPopup={true}
            addFunc={()=>{setCarInfo("")}}
        />
        <RightPopup
            addClass="minVar"
            text={write}
            topTitle="예약 취소"
            rentCancelPopup={true}
            cancelNumber={code}
            cancelId={id}
            closePopup={() => {setWrite("")}}
            addFunc={()=>{navigate(-1)}}
        />
        <RightPopup
            text={rightPopupData}
            topTitle="취소 및 환불 규정"
            closePopup={() => {setRightPopupData(null)}}
        />
        </>
    );
};

export default MyRentDetail;