import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import MemberBox from "component/member/memberBox";
import { BtnBox } from "component/basic/btns";
import { Header } from "component/elements/header";
import * as fatchSet from "../../api/api";
import { BottomErrMsg, ErrPopup } from "component/basic/popup";
import { InputItemBox } from "component/basic/formItems";
import { useRef } from "react";

const PwFind = () => {
    const navigate = useNavigate();

    const [btnChk,setBtnChk] =  useState(true);

    const [FindPage,setFindPage] = useState(0);

    const [name,setName] =  useState("");
    const [phone,setPhone] =  useState("");
    const [certify,setCertify] =  useState("");
    const [id,setId] =  useState("");
    const [pw,setPw] =  useState("");
    const [pwChk,setPwChk] =  useState("");

    const [idErr,setIdErr] =  useState(null);
    const [pwChkErr,setPwChkErr] =  useState(null);

    const [certifyType,setCertifyType] =  useState(false);
    const [errPopupData,setErrPopupData] =  useState(null);

    const [timerCount,setTimerCount] =  useState("3:00");

    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const sval = useRef(null);
    function timerStart(){
        //타이머
        setTimerCount("3:00");
        
        if (sval.current !== null) {
            clearInterval(sval.current);
            sval.current = null;
        }
        
        timerChk(2.99);

        function timerChk(duration) {
            let timerNumber = duration * 60;
            let m, s;
            sval.current = setInterval(function() {
                m = parseInt(timerNumber / 60 % 60, 10);
                s = parseInt(timerNumber % 60, 10);
                s = s < 10 ? "0" + s : s;

                setTimerCount(m + ":" + s);

                if (--timerNumber < 0) {
                    timerNumber = 0;
                    clearInterval(sval.current);
                    sval.current = null;

                    setErrPopupData({
                        addClass:null,
                        text:"입력 시간이 만료되었습니다.",
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }, 1000);
        }
    }

    function cetifiedStert(){
        let formData = new FormData();
        formData.append("phone", phone);
        formData.append("name", name);
        formData.append("email", id);
        formData.append("is_join", 0);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/sms/certification`,
            success: (data) => {
                setCertifyType(true);
                timerStart();
                setBottomMsgData({
                    addClass : "blueMsg",
                    text : "인증번호가 발송되었습니다.",
                    chk : bottomMsgData.chk + 1
                });
            },
            err: (data) => {
                if(data.alert == "금일 SMS 인증 요청 제한 횟수를 초과했습니다."){
                    setBottomMsgData({
                        addClass : "redMsg",
                        text : data.alert,
                        chk : bottomMsgData.chk + 1
                    });
                }else{
                    setErrPopupData({
                        addClass:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }
        })
    }

    function cetifiedCom(){
        let formData = new FormData();
        formData.append("phone", phone);
        formData.append("code", certify);
        formData.append("is_find", 1);
        formData.append("name", name);
        formData.append("email", id);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: `/sms/certification/check`,
            success: (data) => {
                clearInterval(sval.current);
                sval.current = null;
                setTimerCount("3:00");
                setFindPage(1);
            },
            err: (data) => {
                if(data.alert){
                    setErrPopupData({
                        addClass:null,
                        text:data.alert,
                        closeType:true,
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{}
                    });
                }
            }
        })
    }

    function pwChkFunc(){
        let pwType = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{6,20}$/;

        if(!pwType.test(pw)){
            setPwChkErr("영문, 숫자, 특수문자 조합 6~20자로 입력해주세요.");
        }else{
            let formData = new FormData();
            formData.append("email", id);
            formData.append("phone", phone);
            formData.append("code", certify);
            formData.append("password", pw);
            formData.append("password_check", pwChk);

            fatchSet.FatchApi({
                type:"POST",
                formDataItem: formData,
                url: "/change/password",
                success: (data) => {
                    setErrPopupData({
                        addClass:null,
                        text:"비밀번호 변경이 완료되었습니다.",
                        closeType:false,
                        errBtnText:"로그인 하러가기",
                        closeFunc:(e) => {},
                        btnFunc:(e)=>{navigate("/member/login")}
                    });
                },
                err: (data) => {
                    if(data.alert){
                        setErrPopupData({
                            addClass:null,
                            text:data.alert,
                            closeType:true,
                            closeFunc:(e) => {},
                            btnFunc:(e)=>{}
                        });
                    }
                }
            })
        }
    }

    useEffect(() => {
        if(pwChk !== ""){
            if(pw == pwChk){
                setPwChkErr(null)
            }else{
                setPwChkErr("비밀번호와 일치하지 않습니다.")
            }
        }
    }, [pw,pwChk]);

    useEffect(() => {
        if(FindPage == 0){
            if(id !== "" && name !== "" && phone !== "" && certify !== ""){
                setBtnChk(false);
            }else{
                setBtnChk(true);
            }
        }else{
            if(pw !== "" && pwChk !== "" && pw == pwChk){
                setBtnChk(false);
            }else{
                setBtnChk(true);
            }
        }
    }, [name,phone,certify,id,pw,pwChk,FindPage]);

    return (
        <div className="memberPage headerInPage pageSizing btnInPage bottomMsgPage">
            <Header
                pageBack={true}
                headTitle={FindPage == 0 ? "비밀번호 찾기" : "비밀번호 재설정"}
                func={() => FindPage == 0 ? navigate(-1) : setFindPage(0)}
            />
            <MemberBox title={FindPage == 0 ? "비밀번호 찾기" : "비밀번호 재설정"} pcChk={true} backFunc={()=>{FindPage == 0 ? navigate(-1) : setFindPage(0)}}>
                <div className="memberInput_section joinInput_section" style={{display:FindPage == 0 ? "block" : "none"}}>
                    <InputItemBox
                        type="text"
                        inputName="아이디"
                        placeholder="아이디"
                        value={id}
                        max={20}
                        regexp={/[^a-zA-Z0-9]/gi}
                        func={(e) => {setId(e);setIdErr(null)}}
                        errMsg={idErr}
                        addClass={idErr ? "err" : ""}
                    />
                    <InputItemBox
                        type="text"
                        inputName="이름"
                        placeholder="이름"
                        value={name}
                        max={20}
                        regexp={/[0-9#?!@$ %^&*-]/gi}
                        func={(e) => {setName(e)}}
                    />
                    <InputItemBox
                        type="smsChk_phone"
                        inputName="휴대폰 번호"
                        placeholder="휴대폰 번호"
                        max={11}
                        value={phone}
                        certifyType={certifyType}
                        func={(e) => {setPhone(e)}}
                        clickEv={(e) => {cetifiedStert()}}
                        caption="‘-’를 제외하고 입력해주세요."
                    />
                    {certifyType ? 
                    <InputItemBox
                        type="smsChk_basic"
                        inputName="인증번호"
                        placeholder="인증번호"
                        max={6}
                        value={certify}
                        timer={timerCount}
                        func={(e) => {setCertify(e)}}
                    />
                    :""}
                </div>
                <div className="memberInput_section joinInput_section" style={{display:FindPage == 1 ? "block" : "none"}}>
                    <InputItemBox
                        type="pw"
                        inputName="새 비밀번호"
                        placeholder="새 비밀번호"
                        value={pw}
                        max={20}
                        regexp={/[^a-zA-Z0-9#?!@$ %^&*-]/gi}
                        func={(e) => {setPw(e)}}
                        addClass={pwChkErr ? "err" : ""}
                    />
                    <InputItemBox
                        type="pw"
                        inputName="새 비밀번호 확인"
                        placeholder="새 비밀번호 확인"
                        value={pwChk}
                        max={20}
                        regexp={/[^a-zA-Z0-9#?!@$ %^&*-]/gi}
                        func={(e) => {setPwChk(e)}}
                        caption={pwChkErr ? null : "영문, 숫자, 특수문자 조합 6~20자"}
                        errMsg={pwChkErr}
                        addClass={pwChkErr ? "err" : ""}
                    />
                </div>
                <BtnBox
                    addClass="pageInType mChType"
                    text={FindPage == 0 ? "인증 완료" : "완료"}
                    disabled={btnChk}
                    func={()=>{FindPage == 0 ? cetifiedCom() : pwChkFunc()}}
                />
            </MemberBox>
            <ErrPopup
                data={errPopupData}
            />
            <BottomErrMsg
                addClass={bottomMsgData.addClass ? bottomMsgData.addClass : ""}
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </div>
    );
};

export default PwFind;