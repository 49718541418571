import { useNavigate, useParams } from "react-router-dom";
import { CsPageDetail, CsPageTitle } from "component/app/subItems";
import useGet from "api/useGet";
import { Header } from "component/elements/header";
import { setDateTime } from "js/function";
import { CsType } from "component/cs/cs";
import { PopupBtnBox } from "component/basic/btns";

const CsDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const pageType = param.type;
    const id = param.id;

    const apiUrl = pageType == "cs" ? `/qna/detail?qna_id=${id}` : `/notice/detail?notice_id=${id}`;
    const csDetailApi = useGet({
        url:apiUrl,
        loginType:"login"
    });
    const csDetailData = pageType == "cs" ? csDetailApi?.data?.qna : csDetailApi?.data?.notice;

    return(
        <div className="csPage csDetailPage headerInPage pageSizing footerMenuInPage">
            <CsPageTitle title="고객센터" addClass="pcItem"/>
            <Header
                pageBack={true}
                headTitle="고객센터"
                func={() => navigate(-1)}
            />
            <div className="csDetailHead">
                {pageType == "cs" ? <CsType text={csDetailData?.status == 1 ? "답변완료" : "답변대기"}/> : ""}
                <div className="csDetailHeadTitleBox">
                    <h1 className="csDetailHeadTitle">{csDetailData?.title}</h1>
                    <p className="csDetailHead_date">{pageType == "cs" ? <span>{csDetailData?.category}</span>:""}{setDateTime(csDetailData?.created_at,"yymmdd")}</p>
                </div>
            </div>
            {pageType == "cs" ? 
                <div className="qnaDetailBox">
                    <div className="question_detailText" dangerouslySetInnerHTML={{__html:csDetailData?.question}}/>
                    {csDetailData?.answer ? <div className="answer_detailText" dangerouslySetInnerHTML={{__html:csDetailData?.answer}}/>:""}
                </div>
                :
                <CsPageDetail data={csDetailData?.contents}/>
            }
            <div className="csDetailBtnBox">
                <PopupBtnBox
                    text="목록으로"
                    addClass="mChType"
                    btnAddClass="full line"
                    func={()=>{navigate(-1)}}
                />
            </div>
        </div>
    );
}
export default CsDetail;