import { slideToggle, slideUp } from "js/function";
import { useEffect } from "react";
import { useRef, useState } from "react";

//서비스
function InfoText(data){
    return (
        <p className="infoText" dangerouslySetInnerHTML={{__html:`${data.listType ? "<span>"+data.listType+"</span>" : ""}${data.text}`}}></p>
    );
}

function InfoBoxText(data){
    return (
        <div className="infoBoxTextItem">
            <p className="infoBoxText" dangerouslySetInnerHTML={{__html:data.text}}></p>
        </div>
    );
}

function ServiceInfoSubItemBox(data){
    return (
        <div className="serviceInfoSubItemArea">
            {data.children}
        </div>
    );
}

function ServiceInfoSubItem(data){
    return (
        <>
            <p className="serviceInfoSubItem_name"><img src="/assets/images/basic/subList_icon.svg"/>{data.name}</p>
            <div className="serviceInfoSubItem" dangerouslySetInnerHTML={{__html:data.item}}></div>
        </>
    );
}

function ServiceInfoItem(data){
    const slideItem = useRef(null);
    const [openChk,setOpenChk] = useState(false);

    function DownIcon(){
        return (
            <img src="/assets/images/basic/down_info.svg" onClick={()=>{slideToggle(slideItem.current,"block");setOpenChk(!openChk)}}/>
        );
    }

    return (
        <div className={`serviceInfoItemBox ${openChk ? "active" : ""}`}>
            <div className="serviceInfoItem">
                <p className="serviceInfoItem_name">{data.name}</p>
                <div className="serviceInfoItem_textBox">{data.type=="notPrice" ? <p className="serviceInfo_notPrice">{data.item}</p> : data.item}{data.children ? <DownIcon/> : ""}</div>
            </div>
            {data.children ? <div className="serviceInfoSubItemBox" ref={slideItem}>
                {data.children}
            </div>:""}
        </div>
    );
}

function AddServiceItem(data){
    return (
        <div className="addServiceItem">
            <h2 className={`addServiceItem_name ${data.mChType ? "mChType" : ""}`} dangerouslySetInnerHTML={{__html:data.name}}></h2>
            {data.children ? <div className="addServiceItem_caption">{data.children}</div> : ""}
        </div>
    );
}

function AddServiceCaption(data){
    return (
        <p className={`addServiceCaption ${data.colorType ? data.colorType : ""} ${data.mChType ? "mChType" : ""}`}>{data.text}</p>
    );
}

function AddServiceNotCaption(data){
    return (
        <p className="addServiceNotCaption">{data.text}{data.subText ? <span>{data.subText}</span> : ""}</p>
    );
}

//서비스 공통 타이틀
function SevicePageTitle(data){
    return (
        <div className={`sevicePage_titleBox pcItem ${data.addClass ? data.addClass : ""}`}>
            <h1 className="sevicePage_title">{data.title}</h1>
            {data.notNavi ? "" : 
            <div className="sevicePage_navi">
                {data.navi_0 ? <p className={`sevicePage_naviItem ${data.navi == 0 ? "active": ""}`}>{data.navi_0}</p> : ""}
                {data.navi_1 ? <><img src="/assets/images/basic/move.svg"/><p className={`sevicePage_naviItem ${data.navi == 1 ? "active": ""}`}>{data.navi_1}</p></> : ""}
                {data.navi_2 ? <><img src="/assets/images/basic/move.svg"/><p className={`sevicePage_naviItem ${data.navi == 2 ? "active": ""}`}>{data.navi_2}</p></> : ""}
            </div>
            }
        </div>
    );
}

//고객센터
function CsPageTitle(data){
    return (
        <div className={`csPage_titleBox ${data.addClass ? data.addClass : ""}`}>
            <h1 className="csPage_title">{data.title}</h1>
        </div>
    );
}

function CsPageDetail(data){
    return (
        <div className="csDetail" dangerouslySetInnerHTML={{__html:data.data}}/>
    );
}

//아코디언 목록
function AccordionItem(data){
    const slideItem = useRef(null);
    const [openChk,setOpenChk] = useState(false);

    useEffect(()=>{
        if(data.id !== data.closeChk){
            slideUp(slideItem.current,300)
            setOpenChk(!openChk)
        }
    },[data.closeChk])

    return (
        <div className={`accordionBox ${openChk ? "active" : ""}`}>
            <button type="button" onClick={(e)=>{data.func(data.id);setTimeout(function(){slideToggle(slideItem.current,"flex");setOpenChk(!openChk)},0)}} className="accordionTitle faqItem">
                <h1 className="faqIcon questionIcon">Q.</h1>
                <div className="faqTextBox">
                    <h1 className="faqTitle">{data.title}</h1>
                    <p className="faqCate"># {data.cate}</p>
                </div>
                <img src="/assets/images/basic/faqIcon.svg"/>
            </button>
            <div className="accordionText answerText faqItem" ref={slideItem}>
                <h1 className="faqIcon answerIcon">A.</h1>
                <div className="faqTextBox">
                    <p className="faqText" dangerouslySetInnerHTML={{__html:data.text}}/>
                </div>
            </div>
        </div>
    );
}

export {InfoText, ServiceInfoItem, ServiceInfoSubItemBox, ServiceInfoSubItem, AddServiceItem, AddServiceCaption, SevicePageTitle, AddServiceNotCaption, InfoBoxText, CsPageTitle, CsPageDetail, AccordionItem};