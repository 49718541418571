import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import {
  AddServiceCaption,
  AddServiceItem,
  AddServiceNotCaption,
  InfoText,
  ServiceInfoItem,
  ServiceInfoSubItem,
  ServiceInfoSubItemBox,
  SevicePageTitle,
} from "component/app/subItems";
import {
  ChkBox,
  DatePicer,
  DatePicerRange,
  InputItemBox,
  RadioBox,
  TextAreaItem,
  TimePicer,
} from "component/basic/formItems";
import { getWeekCount, setDateTime, setDateWeek } from "js/function";
import { AppSection, AppSubSection } from "component/app/app";
import { comFormat } from "js/function";
import * as fatchSet from "../../api/api";
import { Header } from "component/elements/header";
import FixedSection from "component/basic/fixedBox";
import { ErrPopup, PrictInfoPopup, RightPopup } from "component/basic/popup";

const Service = (props) => {
  const navigate = useNavigate();
  const param = useParams();

  //차량 정보
  const carData = useGet({
    url: `/user/check`,
    loginType: "login",
  });

  //내 포인트 정보
  const priceData = useGet({
    url: `/service/price?region=제주`,
    loginType: "login",
  });

  const [dataSet, setDataSet] = useState(0);

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  const [carInfo, setCarInfo] = useState(null);
  const [carId, setCarId] = useState(null);
  const [carFuelType, setCarFuelType] = useState(null);
  const [carName, setCarName] = useState(null);
  const [carNumber, setCarNumber] = useState(null);

  const [pickup, setPickup] = useState(0);
  const [contents, setContents] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeDataSet, setTimeDataSet] = useState(false);

  const [dateChk, setDateChk] = useState(false);

  const [minDateChk, setMinDateChk] = useState(true);

  const [weekdayCount, setWeekdayCount] = useState(0);
  const [weekendCount, setWeekendCount] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [pickPrice, setPickPrice] = useState(0);

  const [oilPrice, setOilPrice] = useState(0);
  const [washPrice, setWashPrice] = useState(0);
  const [inspectionPrice, setInspectionPrice] = useState(0);

  const [salePrice, setSalePrice] = useState(0);

  const [totalPrice, setTotalPrice] = useState(0);

  const [addServiceOpen, setAddServiceOpen] = useState(false);

  const [oil, setOil] = useState(null);
  const [wash, setWash] = useState(null);
  const [inspection, setInspection] = useState(null);
  const [management, setManagement] = useState(null);
  const [managementEtc, setManagementEtc] = useState(null);
  const [repair, setRepair] = useState(null);

  const [storege, setStorege] = useState(false);

  const [oilChk, setOilChk] = useState(false);
  const [washChk, setWashChk] = useState(false);
  const [inspectionChk, setInspectionChk] = useState(false);
  const [managementChk, setManagementChk] = useState(false);
  const [repairChk, setRepairChk] = useState(false);

  const [oilPopup, setOilPopup] = useState("");
  const [washPopup, setWashPopup] = useState("");
  const [inspectionPopup, setInspectionPopup] = useState("");
  const [managementPopup, setManagementPopup] = useState("");
  const [repairPopup, setRepairPopup] = useState("");

  const [oilSale, setOilSale] = useState(10000);
  const [washSale, setWashSale] = useState(10000);

  const [btnChk, setBtnChk] = useState(true);

  const [carPopup, setCarPopup] = useState("");

  const [errPopupData, setErrPopupData] = useState(null);

  const [prictInfoData, setPrictInfoData] = useState(null);

  //부가서비스 정보
  const oliData = useGet({
    url: `/oil/items?car_id=${carId}&type=1`,
    loginType: "login",
  });
  const washData = useGet({
    url: `/wash/price?car_id=${carId}&type=1`,
    loginType: "login",
  });
  const inspectionData = useGet({
    url: `/inspection/price?car_id=${carId}&type=1`,
    loginType: "login",
  });

  function oilDataSet(id, name, price, thum) {
    let setOilData = {
      id: id,
      name: name,
      price: price,
      thum: thum,
    };
    setOil(setOilData);
    setOilPrice(price);
  }

  function washDataSet(price, wax, waxPrice, name) {
    let setWashData = {
      name: name,
      price: price,
      wax: wax,
      waxPrice: waxPrice,
    };
    setWash(setWashData);
    setWashPrice(Number(price) + Number(waxPrice));
  }

  function inspectionDataSet(price, name) {
    let setInspectionData = {
      price: price,
      name: name,
    };
    setInspection(setInspectionData);
    setInspectionPrice(price);
  }

  function managementDataSet(manage, etc) {
    let setManagementData = [...manage];
    setManagement(setManagementData);
    setManagementEtc(etc);
  }

  function repairDataSet(repairImg) {
    let setRepairData = [...repairImg];
    setRepair(setRepairData);
  }

  //부가서비스 선택
  function addServiceChk(e, type) {
    if (type == "oil") {
      if (e.target.checked) {
        setOilPopup("open");
      } else {
        setOil(null);
        setOilPrice(0);
      }
    }
    if (type == "wash") {
      if (e.target.checked) {
        setWashPopup("open");
      } else {
        setWash(null);
        setWashPrice(0);
      }
    }
    if (type == "inspection") {
      if (e.target.checked) {
        setInspectionPopup("open");
      } else {
        setInspection(null);
        setInspectionPrice(0);
      }
    }
    if (type == "management") {
      if (e.target.checked) {
        setManagementPopup("open");
      } else {
        setManagement(null);
      }
    }
    if (type == "repair") {
      if (e.target.checked) {
        setRepairPopup("open");
      } else {
        setRepair(null);
      }
    }

    if (type == "storege") {
      if (e.target.checked) {
        setStorege(true);
      } else {
        setStorege(false);
        setStartTime(null);
      }
    }
  }

  //초기화
  function allDataReset() {
    setPickup(0);
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
    setTimeDataSet(!timeDataSet);
    setDateChk(!dateChk);

    setMinDateChk(true);

    setWeekdayCount(0);
    setWeekendCount(0);
    setServicePrice(0);

    setOilPrice(0);
    setWashPrice(0);
    setInspectionPrice(0);

    setAddServiceOpen(false);

    setOil(null);
    setWash(null);
    setInspection(null);
    setManagement(null);
    setManagementEtc(null);
    setRepair(null);

    setStorege(false);

    setOilChk(false);
    setWashChk(false);
    setInspectionChk(false);
    setManagementChk(false);
    setRepairChk(false);

    setBtnChk(true);
  }

  //차량 등록
  function addCarFunc(size, type, year, number, id, name, fuelType) {
    let formData = new FormData();
    formData.append("car_id", id);
    formData.append("car_type", type);
    formData.append("car_size", size);
    formData.append("car_year", year);

    fatchSet.FatchApi({
      type: "POST",
      formDataItem: formData,
      url: `/mypage/car/info/modify`,
      loginType: "login",
      success: (data) => {
        setCarInfo(`${type} / ${name} ${number}`);
        setCarName(name);
        setCarNumber(number);
        setCarId(id);
        setCarFuelType(fuelType);
        setErrPopupData({
          addClass: null,
          text: "차량 정보가 등록되었습니다!",
          closeType: true,
          closeFunc: (e) => {},
          btnFunc: (e) => {},
        });
        setDataSet(dataSet + 1);
      },
      err: (data) => {
        if (data.alert) {
          setErrPopupData({
            addClass: null,
            text: data.alert,
            closeType: true,
            closeFunc: (e) => {},
            btnFunc: (e) => {},
          });
        }
      },
    });
  }

  //주문 이동
  function moveOrder() {
    navigate(`/service/order/service/제주`, {
      state: {
        region: "제주",
        name: name,
        phone: phone,
        car_id: carId,
        car_number: carNumber,
        car_name: carName,
        start_date: startDate,
        end_date: endDate,
        start_time: startTime,
        end_time: endTime,
        user_pickup: pickup,
        pickPrice: pickPrice,
        contents: contents,
        category: "차량관리",
        use_option: 1,
        storage: servicePrice !== 0 ? { price: servicePrice } : null,
        price: totalPrice,
        discount_price: salePrice,
        oil: oil,
        wash: wash,
        inspection: inspection,
        management: management,
        etc: managementEtc,
        repair: repair,
      },
    });
  }

  //가격 세팅
  useEffect(() => {
    if (storege && startDate && endDate && startTime && endTime) {
      let price = priceData?.data?.price;
      setWeekdayCount(getWeekCount(startDate, endDate)[0]);
      setWeekendCount(getWeekCount(startDate, endDate)[1]);
      setServicePrice(
        Number(price?.weekday_price) *
          Number(getWeekCount(startDate, endDate)[0]) +
          Number(price?.weekend_price) *
            Number(getWeekCount(startDate, endDate)[1])
      );
    } else {
      setServicePrice(0);
      setPickup(0);
    }
  }, [startDate, endDate, priceData, startTime, endTime, storege]);

  useEffect(() => {
    if (priceData?.data?.price) {
      setPickPrice(priceData?.data?.price.pickup_price);
    }
  }, [priceData]);

  //차량 정보 세팅
  useEffect(() => {
    setName(carData.data?.name ? carData.data.name : "");
    setPhone(carData.data?.phone ? carData.data.phone : "");

    if (carData.data?.is_car) {
      let carDetailData = carData.data?.user_car;
      setCarInfo(
        `${carDetailData?.car_type} / ${carDetailData?.name} ${carDetailData?.number}`
      );
      setCarFuelType(carDetailData?.fuel_type);
      setCarId(carDetailData?.id);
      setCarName(carDetailData?.name);
      setCarNumber(carDetailData?.number);
    }

    setDataSet(dataSet + 1);
  }, [carData]);

  //부가서비스 활성화 체크
  useEffect(() => {
    if (startDate && carInfo) {
      setAddServiceOpen(true);
    } else {
      setAddServiceOpen(false);
    }
  }, [carInfo, startDate, startTime]);

  //버튼 체크
  useEffect(() => {
    if (
      startDate &&
      carInfo &&
      name !== "" &&
      phone !== "" &&
      (oil || wash || management || repair || inspection) &&
      ((storege && endDate && endTime && startTime) || !storege)
    ) {
      setBtnChk(false);
    } else {
      setBtnChk(true);
    }
  }, [
    carInfo,
    startDate,
    endDate,
    startTime,
    endTime,
    name,
    phone,
    oil,
    wash,
    management,
    repair,
    inspection,
    storege,
  ]);

  //총 금액
  useEffect(() => {
    let totalOptionPrice = storege
      ? oil && wash
        ? oilSale + washSale
        : oil
        ? oilSale
        : wash
        ? washSale
        : 0
      : 0;

    setSalePrice(totalOptionPrice);
  }, [oil, wash, inspection, storege]);

  useEffect(() => {
    let totalPriceItem = servicePrice;

    if (pickup == 1) {
      totalPriceItem = Number(totalPriceItem) + Number(pickPrice);
    }

    if (oil) {
      totalPriceItem = Number(totalPriceItem) + Number(oilPrice);
    }
    if (wash) {
      totalPriceItem = Number(totalPriceItem) + Number(washPrice);
    }
    if (inspection) {
      totalPriceItem = Number(totalPriceItem) + Number(inspectionPrice);
    }

    if (oil || wash) {
      totalPriceItem = Number(totalPriceItem) - Number(salePrice);
    }

    setTotalPrice(totalPriceItem);
  }, [
    servicePrice,
    pickup,
    oilPrice,
    washPrice,
    inspectionPrice,
    salePrice,
    totalPrice,
  ]);

  return (
    <div className="servicePage headerInPage pageSizing mBtnPage">
      <Header
        pageBack={true}
        headTitle="서비스 신청"
        func={() => navigate(-1)}
        homeBtn={true}
      />
      <SevicePageTitle
        title="서비스 신청"
        navi={0}
        navi_0="서비스 신청"
        navi_1="신청 정보 확인"
        navi_2="신청 완료"
      />
      <div className="servicePage_contents">
        <div className="service_inputSection">
          <AppSection
            title="내 정보"
            text="서비스를 이용하실 분의 이름과 연락처를 입력해주세요."
          >
            <div className="service_minInputSection">
              <InputItemBox
                type="text"
                inputName="이름"
                placeholder="이름"
                value={name}
                max={20}
                setChk={dataSet}
                regexp={/[0-9#?!@$ %^&*-]/gi}
                func={(e) => {
                  setName(e);
                }}
              />
              <InputItemBox
                type="phoneBasic"
                inputName="연락처"
                placeholder="연락처"
                max={11}
                value={phone}
                setChk={dataSet}
                func={(e) => {
                  setPhone(e);
                }}
              />
            </div>
          </AppSection>
          <AppSection title="신청 정보">
            <InputItemBox
              addClass={carInfo ? "notInput" : ""}
              type="btnCom"
              inputName="차량 정보"
              placeholder="차량 정보"
              value={carInfo}
              setChk={dataSet}
              func={(e) => {
                setCarInfo(e);
              }}
              btnName={carInfo ? "수정" : "직접 입력"}
              clickEv={(e) => {
                setCarPopup("open");
              }}
              inputClick={(e) => {
                setCarPopup("open");
              }}
              readOnly={true}
            />
            <div className="col2Input">
              <DatePicer
                inputName={`${storege ? "입고 일" : "입고 예정일"}`}
                placeholder={`${storege ? "입고 일" : "입고 예정일"}`}
                value={startDate}
                dataChk={dateChk}
                minCount={1}
                func={(start) => {
                  function minDateSet() {
                    let nowDate = new Date(),
                      startDateItem = new Date(
                        setDateTime(start, "yymmdd").replace(/-/g, "/")
                      );
                    let setNowDate = new Date(
                      nowDate.setDate(nowDate.getDate() + 2)
                    );

                    if (setNowDate <= startDateItem) {
                      setMinDateChk(true);
                    } else {
                      setMinDateChk(false);
                    }
                  }

                  if (endDate) {
                    if (
                      new Date(
                        `${setDateTime(start, "yymmdd").replace(/-/g, "/")} ${
                          startTime && endTime ? startTime : "00:00"
                        }`
                      ) >
                      new Date(
                        `${setDateTime(endDate, "yymmdd").replace(/-/g, "/")} ${
                          startTime && endTime ? endTime : "00:00"
                        }`
                      )
                    ) {
                      setErrPopupData({
                        addClass: null,
                        text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                        closeType: true,
                        closeFunc: (e) => {},
                        btnFunc: (e) => {},
                      });
                      setStartDate(null);
                      setStorege(false);
                      setEndDate(null);
                      setEndTime(null);
                      setTimeDataSet(!timeDataSet);
                      setDateChk(!dateChk);
                    } else {
                      setStartDate(setDateTime(start, "yymmdd"));
                      minDateSet();
                      setDateChk(!dateChk);
                    }
                  } else {
                    setStartDate(setDateTime(start, "yymmdd"));
                    minDateSet();
                    setDateChk(!dateChk);
                  }
                }}
              />
              {storege ? (
                <TimePicer
                  inputName="입고 시간"
                  placeholder="입고 시간"
                  value={startTime}
                  dataChk={timeDataSet}
                  min={"08"}
                  max={"21"}
                  date={`${setDateTime(startDate, "yymmdd")} (${setDateWeek(
                    startDate
                  )})`}
                  disabled={startDate ? false : true}
                  func={(start, end) => {
                    if (startDate && endDate && endTime) {
                      if (
                        new Date(
                          `${setDateTime(startDate, "yymmdd").replace(
                            /-/g,
                            "/"
                          )} ${`${start}:${end}`}`
                        ) >
                        new Date(
                          `${setDateTime(endDate, "yymmdd").replace(
                            /-/g,
                            "/"
                          )} ${endTime ? endTime : "00:00"}`
                        )
                      ) {
                        setStartTime(`${start}:${end}`);
                        setErrPopupData({
                          addClass: null,
                          text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                          closeType: true,
                          closeFunc: (e) => {},
                          btnFunc: (e) => {},
                        });
                        setStartDate(null);
                        setStorege(false);
                        setEndDate(null);
                        setEndTime(null);
                        setTimeDataSet(!timeDataSet);
                        setDateChk(!dateChk);
                      } else {
                        setStartTime(`${start}:${end}`);
                        setTimeDataSet(!timeDataSet);
                      }
                    } else {
                      setStartTime(`${start}:${end}`);
                      setTimeDataSet(!timeDataSet);
                    }
                  }}
                />
              ) : (
                ""
              )}
            </div>
            {storege ? (
              <div className="col2Input">
                <DatePicer
                  inputName="출고 일"
                  placeholder="출고 일"
                  value={endDate}
                  dataChk={dateChk}
                  minCount={1}
                  func={(end) => {
                    if (startDate) {
                      if (
                        new Date(
                          `${setDateTime(startDate, "yymmdd").replace(
                            /-/g,
                            "/"
                          )} ${startTime && endTime ? startTime : "00:00"}`
                        ) >
                        new Date(
                          `${setDateTime(end, "yymmdd").replace(/-/g, "/")} ${
                            startTime && endTime ? endTime : "00:00"
                          }`
                        )
                      ) {
                        setEndDate(setDateTime(end, "yymmdd"));
                        setErrPopupData({
                          addClass: null,
                          text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                          closeType: true,
                          closeFunc: (e) => {},
                          btnFunc: (e) => {},
                        });
                        setEndDate(null);
                        setDateChk(!dateChk);
                      } else {
                        setEndDate(setDateTime(end, "yymmdd"));
                        setDateChk(!dateChk);
                      }
                    } else {
                      setEndDate(setDateTime(end, "yymmdd"));
                      setDateChk(!dateChk);
                    }
                  }}
                />
                <TimePicer
                  inputName="출고 시간"
                  placeholder="출고 시간"
                  value={endTime}
                  dataChk={timeDataSet}
                  min={"08"}
                  max={"21"}
                  date={`${setDateTime(endDate, "yymmdd")} (${setDateWeek(
                    endDate
                  )})`}
                  disabled={endDate ? false : true}
                  func={(start, end) => {
                    if (startDate && endDate && startTime) {
                      if (
                        new Date(
                          `${setDateTime(startDate, "yymmdd").replace(
                            /-/g,
                            "/"
                          )} ${startTime ? startTime : "00:00"}`
                        ) >
                        new Date(
                          `${setDateTime(endDate, "yymmdd").replace(
                            /-/g,
                            "/"
                          )} ${`${start}:${end}`}`
                        )
                      ) {
                        setEndTime(`${start}:${end}`);
                        setErrPopupData({
                          addClass: null,
                          text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                          closeType: true,
                          closeFunc: (e) => {},
                          btnFunc: (e) => {},
                        });
                        setEndTime(null);
                        setTimeDataSet(!timeDataSet);
                      } else {
                        setEndTime(`${start}:${end}`);
                        setTimeDataSet(!timeDataSet);
                      }
                    } else {
                      setEndTime(`${start}:${end}`);
                      setTimeDataSet(!timeDataSet);
                    }
                  }}
                />
              </div>
            ) : (
              ""
            )}
            <InfoText
              listType="-"
              text="예약일 변경 또는 취소 요청의 경우 입고일 기준 3일 전 신청 건만 가능합니다."
            />
            <InfoText
              listType="-"
              text="입고일 기준 2일~당일 요청 건은 변경 또는 환불이 불가능하오니 유의해 주시기 바랍니다."
            />
          </AppSection>
          <AppSection title="서비스 선택" text="서비스 옵션을 선택해주세요.">
            {carInfo && startDate ? (
              <>
                <ChkBox
                  addClass="addServiceItem"
                  id="addServiceItem_0"
                  func={(e) => {
                    addServiceChk(e, "oil");
                    setOilChk(e.target.checked);
                  }}
                  disabled={
                    carFuelType == "BEV" ||
                    !(
                      oliData?.data?.options &&
                      oliData?.data?.options.length > 0
                    )
                      ? true
                      : false
                  }
                  checkedType={oilChk}
                >
                  <AddServiceItem
                    name={`엔진 오일 ${
                      carFuelType == "BEV" ||
                      !(
                        oliData?.data?.options &&
                        oliData?.data?.options.length > 0
                      )
                        ? ""
                        : `<span class="${oilPrice > 0 ? "" : "gColor"}">${
                            oilPrice > 0 ? "+" : ""
                          }${comFormat(oilPrice)}원</span>`
                    }`}
                    mChType={true}
                  >
                    {carFuelType == "BEV" ||
                    !(
                      oliData?.data?.options &&
                      oliData?.data?.options.length > 0
                    ) ? (
                      <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                    ) : (
                      ""
                    )}
                  </AddServiceItem>
                </ChkBox>
                <ChkBox
                  addClass="addServiceItem"
                  id="addServiceItem_1"
                  func={(e) => {
                    addServiceChk(e, "wash");
                    setWashChk(e.target.checked);
                  }}
                  disabled={!minDateChk || !(washData && washData?.success)}
                  checkedType={washChk}
                >
                  <AddServiceItem
                    name={`세차 ${
                      !minDateChk || !(washData && washData?.success)
                        ? ""
                        : `<span class="${washPrice > 0 ? "" : "gColor"}">${
                            washPrice > 0 ? "+" : ""
                          }${comFormat(washPrice)}원</span>`
                    }`}
                    mChType={true}
                  >
                    {!minDateChk ? (
                      <AddServiceNotCaption
                        text="서비스 신청이 가능한 일자가 아닙니다."
                        subText="(예약일로부터 3일 이전에 신청이 가능합니다.)"
                      />
                    ) : !(washData && washData?.success) ? (
                      <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                    ) : (
                      ""
                    )}
                  </AddServiceItem>
                </ChkBox>
                <ChkBox
                  addClass="addServiceItem"
                  id="addServiceItem_2"
                  func={(e) => {
                    addServiceChk(e, "inspection");
                    setInspectionChk(e.target.checked);
                  }}
                  disabled={
                    carFuelType == "BEV" ||
                    !minDateChk ||
                    !(inspectionData && inspectionData?.success)
                      ? true
                      : false
                  }
                  checkedType={inspectionChk}
                >
                  <AddServiceItem
                    name={`차량 검사 ${
                      carFuelType == "BEV" ||
                      !minDateChk ||
                      !(inspectionData && inspectionData?.success)
                        ? ""
                        : `<span class="${
                            inspectionPrice > 0 ? "" : "gColor"
                          }">${inspectionPrice > 0 ? "+" : ""}${comFormat(
                            inspectionPrice
                          )}원</span>`
                    }`}
                  >
                    {carFuelType == "BEV" ||
                    !(inspectionData && inspectionData?.success) ? (
                      <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                    ) : !minDateChk ? (
                      <AddServiceNotCaption
                        text="서비스 신청이 가능한 일자가 아닙니다."
                        subText="(예약일로부터 3일 이전에 신청이 가능합니다.)"
                      />
                    ) : (
                      ""
                    )}
                  </AddServiceItem>
                </ChkBox>
                <ChkBox
                  addClass="addServiceItem"
                  id="addServiceItem_3"
                  func={(e) => {
                    addServiceChk(e, "management");
                    setManagementChk(e.target.checked);
                  }}
                  disabled={carFuelType == "BEV" ? true : false}
                  checkedType={managementChk}
                >
                  <AddServiceItem name={`관리 서비스 신청`}>
                    {carFuelType == "BEV" ? (
                      <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                    ) : (
                      ""
                    )}
                  </AddServiceItem>
                </ChkBox>
                <ChkBox
                  addClass="addServiceItem"
                  id="addServiceItem_4"
                  func={(e) => {
                    addServiceChk(e, "repair");
                    setRepairChk(e.target.checked);
                  }}
                  disabled={carFuelType == "BEV" ? true : false}
                  checkedType={repairChk}
                >
                  <AddServiceItem name={`외관 수리 신청`}>
                    {carFuelType == "BEV" ? (
                      <AddServiceNotCaption text="서비스가 제공되지 않는 차량입니다." />
                    ) : (
                      ""
                    )}
                  </AddServiceItem>
                </ChkBox>
              </>
            ) : (
              <div className="notCar">
                <img src="/assets/images/icon/not_car.svg" />
                신청 정보를 등록해주세요
              </div>
            )}
          </AppSection>
          <AppSection title="기타 정보">
            <TextAreaItem
              addClass="outCount"
              inputName="차량 관련 특이사항 (선택)"
              placeholder="차량 관련 특이사항 (선택)"
              max={100}
              unit="자"
              maxChk={true}
              value={contents}
              func={(e) => setContents(e)}
            />
          </AppSection>
          {addServiceOpen ? (
            <AppSection title="부가서비스 신청">
              <ChkBox
                addClass="addServiceItem"
                id="addStorege_0"
                func={(e) => {
                  addServiceChk(e, "storege");
                }}
                disabled={false}
                checkedType={storege}
              >
                <AddServiceItem
                  name={`공항 주차 서비스 <span class="${
                    servicePrice > 0 ? "" : "gColor"
                  }">${servicePrice > 0 ? "+" : ""}${comFormat(
                    servicePrice
                  )}원</span>`}
                  mChType={true}
                >
                  <AddServiceCaption
                    colorType="g_color"
                    mChType={true}
                    text={`결합시 엔진오일, 세차, 관리서비스 할인!`}
                  />
                </AddServiceItem>
              </ChkBox>
              {storege ? (
                <>
                  <div className="col2Input">
                    <DatePicer
                      inputName="입고 일"
                      placeholder="입고 일"
                      value={startDate}
                      dataChk={dateChk}
                      minCount={1}
                      func={(start) => {
                        function minDateSet() {
                          let nowDate = new Date(),
                            startDateItem = new Date(
                              setDateTime(start, "yymmdd").replace(/-/g, "/")
                            );
                          let setNowDate = new Date(
                            nowDate.setDate(nowDate.getDate() + 2)
                          );

                          if (setNowDate <= startDateItem) {
                            setMinDateChk(true);
                          } else {
                            setMinDateChk(false);
                          }
                        }

                        if (endDate) {
                          if (
                            new Date(
                              `${setDateTime(start, "yymmdd").replace(
                                /-/g,
                                "/"
                              )} ${startTime && endTime ? startTime : "00:00"}`
                            ) >
                            new Date(
                              `${setDateTime(endDate, "yymmdd").replace(
                                /-/g,
                                "/"
                              )} ${startTime && endTime ? endTime : "00:00"}`
                            )
                          ) {
                            setErrPopupData({
                              addClass: null,
                              text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                              closeType: true,
                              closeFunc: (e) => {},
                              btnFunc: (e) => {},
                            });
                            setStartDate(null);
                            setStorege(false);
                            setEndDate(null);
                            setEndTime(null);
                            setTimeDataSet(!timeDataSet);
                            setDateChk(!dateChk);
                          } else {
                            setStartDate(setDateTime(start, "yymmdd"));
                            minDateSet();
                            setDateChk(!dateChk);
                          }
                        } else {
                          setStartDate(setDateTime(start, "yymmdd"));
                          minDateSet();
                          setDateChk(!dateChk);
                        }
                      }}
                    />
                    <TimePicer
                      inputName="입고 시간"
                      placeholder="입고 시간"
                      value={startTime}
                      dataChk={timeDataSet}
                      min={"08"}
                      max={"21"}
                      date={`${setDateTime(startDate, "yymmdd")} (${setDateWeek(
                        startDate
                      )})`}
                      disabled={startDate ? false : true}
                      func={(start, end) => {
                        if (startDate && endDate && endTime) {
                          if (
                            new Date(
                              `${setDateTime(startDate, "yymmdd").replace(
                                /-/g,
                                "/"
                              )} ${`${start}:${end}`}`
                            ) >
                            new Date(
                              `${setDateTime(endDate, "yymmdd").replace(
                                /-/g,
                                "/"
                              )} ${endTime ? endTime : "00:00"}`
                            )
                          ) {
                            setStartTime(`${start}:${end}`);
                            setErrPopupData({
                              addClass: null,
                              text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                              closeType: true,
                              closeFunc: (e) => {},
                              btnFunc: (e) => {},
                            });
                            setStartTime(null);
                            setStorege(false);
                            setEndDate(null);
                            setEndTime(null);
                            setTimeDataSet(!timeDataSet);
                            setDateChk(!dateChk);
                          } else {
                            setStartTime(`${start}:${end}`);
                            setTimeDataSet(!timeDataSet);
                          }
                        } else {
                          setStartTime(`${start}:${end}`);
                          setTimeDataSet(!timeDataSet);
                        }
                      }}
                    />
                  </div>
                  <div className="col2Input">
                    <DatePicer
                      inputName="출고 일"
                      placeholder="출고 일"
                      value={endDate}
                      dataChk={dateChk}
                      minCount={1}
                      func={(end) => {
                        if (startDate) {
                          if (
                            new Date(
                              `${setDateTime(startDate, "yymmdd").replace(
                                /-/g,
                                "/"
                              )} ${startTime && endTime ? startTime : "00:00"}`
                            ) >
                            new Date(
                              `${setDateTime(end, "yymmdd").replace(
                                /-/g,
                                "/"
                              )} ${startTime && endTime ? endTime : "00:00"}`
                            )
                          ) {
                            setEndDate(setDateTime(end, "yymmdd"));
                            setErrPopupData({
                              addClass: null,
                              text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                              closeType: true,
                              closeFunc: (e) => {},
                              btnFunc: (e) => {},
                            });
                            setEndDate(null);
                            setDateChk(!dateChk);
                          } else {
                            setEndDate(setDateTime(end, "yymmdd"));
                            setDateChk(!dateChk);
                          }
                        } else {
                          setEndDate(setDateTime(end, "yymmdd"));
                          setDateChk(!dateChk);
                        }
                      }}
                    />
                    <TimePicer
                      inputName="출고 시간"
                      placeholder="출고 시간"
                      value={endTime}
                      dataChk={timeDataSet}
                      min={"08"}
                      max={"21"}
                      date={`${setDateTime(endDate, "yymmdd")} (${setDateWeek(
                        endDate
                      )})`}
                      disabled={endDate ? false : true}
                      func={(start, end) => {
                        if (startDate && endDate && startTime) {
                          if (
                            new Date(
                              `${setDateTime(startDate, "yymmdd").replace(
                                /-/g,
                                "/"
                              )} ${startTime ? startTime : "00:00"}`
                            ) >
                            new Date(
                              `${setDateTime(endDate, "yymmdd").replace(
                                /-/g,
                                "/"
                              )} ${`${start}:${end}`}`
                            )
                          ) {
                            setEndTime(`${start}:${end}`);
                            setErrPopupData({
                              addClass: null,
                              text: "출고일은 입고일보다<br/>빠르게 설정할 수 없습니다.",
                              closeType: true,
                              closeFunc: (e) => {},
                              btnFunc: (e) => {},
                            });
                            setEndTime(null);
                            setTimeDataSet(!timeDataSet);
                          } else {
                            setEndTime(`${start}:${end}`);
                            setTimeDataSet(!timeDataSet);
                          }
                        } else {
                          setEndTime(`${start}:${end}`);
                          setTimeDataSet(!timeDataSet);
                        }
                      }}
                    />
                  </div>
                  <InfoText
                    listType="-"
                    text="예약일 변경 또는 취소 요청의 경우 입고일 기준 3일 전 신청 건만 가능합니다."
                  />
                  <InfoText
                    listType="-"
                    text="입고일 기준 2일~당일 요청 건은 변경 또는 환불이 불가능하오니 유의해 주시기 바랍니다."
                  />
                  <AppSubSection title="입/출고 픽업 서비스">
                    <RadioBox
                      id="pickupTypeChk"
                      val={0}
                      label="미사용"
                      colorType="bgFullChk"
                      checkedType={pickup == 0 ? true : false}
                      func={(e) => setPickup(e)}
                      addClass="roundType"
                    />
                    <RadioBox
                      id="pickupTypeChk"
                      val={1}
                      label="사용"
                      colorType="bgFullChk"
                      checkedType={pickup == 1 ? true : false}
                      func={(e) => setPickup(e)}
                      addClass="roundType"
                    />
                    <InfoText text="픽업서비스 미사용 시 [주차장에 직접 차량 입고 → 직접 공항 이동 / 공항에서 직접 주차장 이동 → 직접 차량 출고] 의 형태로 서비스를 이용해야 합니다. 참고 바랍니다." />
                  </AppSubSection>
                </>
              ) : (
                ""
              )}
            </AppSection>
          ) : (
            ""
          )}
        </div>
        <FixedSection
          pageBox="servicePage"
          boxAddClass="service_selInfo_section"
          addClass="service_selInfo_fixedSection"
          headerChk={true}
          footerChk={true}
        >
          <div className="service_selInfo_area">
            <h1 className="service_selInfo_title">서비스 신청 정보</h1>
            <div className="service_selInfo_list">
              {storege && startDate && startTime && endDate && endTime ? (
                <ServiceInfoItem
                  name="공항 주차"
                  item={`${comFormat(servicePrice)}원`}
                />
              ) : (
                ""
              )}
              {pickup == 1 ? (
                <ServiceInfoItem
                  name="픽업 서비스"
                  item={`${comFormat(pickPrice)}원`}
                />
              ) : (
                ""
              )}
              {oil ? (
                <ServiceInfoItem
                  name="엔진 오일"
                  item={`${comFormat(oilPrice)}원`}
                >
                  <ServiceInfoSubItemBox>
                    <ServiceInfoSubItem
                      name={oil?.name}
                      item={`${comFormat(oilPrice)}원`}
                    />
                  </ServiceInfoSubItemBox>
                </ServiceInfoItem>
              ) : (
                ""
              )}
              {wash ? (
                <ServiceInfoItem name="세차" item={`${comFormat(washSale)}원`}>
                  <ServiceInfoSubItemBox>
                    <ServiceInfoSubItem
                      name={wash?.name}
                      item={`${comFormat(washSale)}원`}
                    />
                  </ServiceInfoSubItemBox>
                </ServiceInfoItem>
              ) : (
                ""
              )}
              {inspection ? (
                <ServiceInfoItem
                  name="차량 검사"
                  item={`${comFormat(inspectionPrice)}원`}
                >
                  <ServiceInfoSubItemBox>
                    <ServiceInfoSubItem
                      name={inspection?.name}
                      item={`${comFormat(inspectionPrice)}원`}
                    />
                  </ServiceInfoSubItemBox>
                </ServiceInfoItem>
              ) : (
                ""
              )}
              {management ? (
                <ServiceInfoItem
                  name="관리서비스 신청"
                  item="상담 후 견적가 안내"
                  type="notPrice"
                />
              ) : (
                ""
              )}
              {repair ? (
                <ServiceInfoItem
                  name="외관 수리 신청"
                  item="상담 후 견적가 안내"
                  type="notPrice"
                />
              ) : (
                ""
              )}

              <ServiceInfoItem
                name="총 할인가"
                item={`-${comFormat(salePrice)}원`}
              >
                <ServiceInfoSubItemBox>
                  {oil ? (
                    <ServiceInfoSubItem
                      name="엔진오일 결합할인"
                      item={`-${comFormat(oilSale)}원`}
                    />
                  ) : (
                    ""
                  )}
                </ServiceInfoSubItemBox>
                <ServiceInfoSubItemBox>
                  {wash ? (
                    <ServiceInfoSubItem
                      name="세차 결합할인"
                      item={`-${comFormat(washSale)}원`}
                    />
                  ) : (
                    ""
                  )}
                </ServiceInfoSubItemBox>
              </ServiceInfoItem>
            </div>
            <div className="service_selInfo_totalArea">
              <h2 className="service_selInfo_totalTitle">총 결제금액</h2>
              <h2 className="service_selInfo_total">
                {comFormat(totalPrice)}원
              </h2>
            </div>
            <div className="service_selInfo_infoBox">
              <button
                type="button"
                onClick={() => {
                  setPrictInfoData({
                    type: "제주",
                    weekdayCount: weekdayCount,
                    weekendCount: weekendCount,
                    startDate: startDate,
                    startTime: startTime,
                    endDate: endDate,
                    endTime: endTime,
                    weekday_price: priceData?.data?.price?.weekday_price,
                    weekend_price: priceData?.data?.price?.weekend_price,
                    servicePrice: servicePrice,
                    pickup: pickup,
                    pickPrice: pickPrice,
                    carInfo: carInfo,
                    closeFunc: () => {
                      setTimeout(() => {
                        setPrictInfoData(null);
                      }, 200);
                    },
                  });
                }}
                className="service_selInfo_info"
              >
                <img src="/assets/images/basic/info.svg" />
                공항 주차 요금 산정기준 조회
              </button>
            </div>
            <BtnBox
              addClass="pageInType mChType"
              text={"다음으로"}
              disabled={btnChk}
              func={() => {
                moveOrder();
              }}
            />
          </div>
          <div className="order_info_imgBox mItem">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="order_info_img pcItem"></div>
          <InfoText
            listType="-"
            text="관리 서비스는 견적 후 웹 또는 어플에서만 결제가 가능합니다."
          />
          <InfoText
            listType="-"
            text="외관 수리는 견적 후 예약하여 실제 외관 상태를 확인 후 현장에서만 결제가 가능합니다."
          />
        </FixedSection>
      </div>
      <ErrPopup data={errPopupData} />
      <RightPopup
        addClass="minVar"
        text={carPopup}
        addCarNumber={true}
        topTitle="차량 정보 등록"
        closePopup={() => {
          setCarPopup("");
        }}
        carNumber={""}
        car_info={null}
        carAdd={true}
        addCarFunc={(size, type, year, number, id, name, fuelType) => {
          addCarFunc(size, type, year, number, id, name, fuelType);
          allDataReset();
        }}
      />
      <RightPopup
        data={oliData?.data?.options}
        text={oilPopup}
        topTitle="엔진 오일"
        closePopup={() => {
          setOilPopup("");
          setOilChk(false);
        }}
        oilAdd={true}
        addPopupFunc={(id, name, price, thum) => {
          setOilPopup("");
          oilDataSet(id, name, price, thum);
        }}
      />
      <RightPopup
        data={washData}
        text={washPopup}
        topTitle="세차"
        closePopup={() => {
          setWashPopup("");
          setWashChk(false);
        }}
        washAdd={true}
        addPopupFunc={(price, wax, waxPrice, name) => {
          setWashPopup("");
          washDataSet(price, wax, waxPrice, name);
        }}
      />
      <RightPopup
        data={inspectionData}
        text={inspectionPopup}
        topTitle="차량 검사"
        closePopup={() => {
          setInspectionPopup("");
          setInspectionChk(false);
        }}
        inspectionAdd={true}
        addPopupFunc={(price, name) => {
          setInspectionPopup("");
          inspectionDataSet(price, name);
        }}
      />
      <RightPopup
        data={null}
        text={managementPopup}
        topTitle="관리 서비스"
        closePopup={() => {
          setManagementPopup("");
          setManagementChk(false);
        }}
        managementAdd={true}
        addPopupFunc={(manage, etc) => {
          setManagementPopup("");
          managementDataSet(manage, etc);
        }}
      />
      <RightPopup
        data={null}
        text={repairPopup}
        topTitle="외관 수리 신청"
        closePopup={() => {
          setRepairPopup("");
          setRepairChk(false);
        }}
        repairAdd={true}
        addPopupFunc={(repairImg) => {
          setRepairPopup("");
          repairDataSet(repairImg);
        }}
      />
      <PrictInfoPopup
        data={prictInfoData}
        closePopup={() => {
          setPrictInfoData(null);
        }}
      />
    </div>
  );
};

export default Service;
