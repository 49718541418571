import { useState } from "react";
import useGet from "api/useGet";
import { useEffect } from "react";
import { PhoneSetting, comFormat, logout } from "js/function";
import { CustomPopup, ErrPopup, RightPopup } from "component/basic/popup";
import {
  LinkPopupBox,
  LinkPopupItem,
} from "component/popupContents/linkPopupItem";
import { useNavigate } from "react-router-dom";
import termsData from "data/terms.json";
import * as fatchSet from "api/api";

function MypageSection(data) {
  return (
    <div className="mypage headerInPage pageSizing footerMenuInPage">
      {data.children}
    </div>
  );
}

function MypageContents(data) {
  return (
    <div className={`mypageContents ${data.pcType ? "pcItem" : ""}`}>
      {data.children}
    </div>
  );
}

function MypageMenuItem(data) {
  return (
    <div
      onClick={() => {
        data.func();
      }}
      className={`mypageMenu_item ${data.addClass ? data.addClass : ""}`}
    >
      <div className="mypageMenu_item_nameBox">
        {data.icon ? <img src={`/assets/images/icon/${data.icon}`} /> : ""}
        <h1 className="mypageMenu_item_name">{data.name}</h1>
      </div>
      <div className="mypageMenu_item_subBox">
        {data.subText ? (
          <p
            className={`mypageMenu_item_subText ${
              data.subTextAddClass ? data.subTextAddClass : ""
            }`}
          >
            {data.subText}
          </p>
        ) : (
          ""
        )}
        {data.subIcon == "not" ? (
          ""
        ) : (
          <img src="/assets/images/basic/move.svg"></img>
        )}

        {data.toggleBox ? (
          <div className="toggleBox">
            <input
              type="checkbox"
              onChange={(e) => {
                data.toggleFunc(e.target.checked);
              }}
              id={data.toggleId}
              checked={data.checkedType}
              className="toggleChk"
            />
            <label htmlFor={data.toggleId}>
              <span></span>
            </label>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

function MypageMenu(data) {
  let navigate = useNavigate();
  const chkData = termsData?.data.filter((el) => el.id !== 3);

  const mypageData = useGet({
    url: `/mypage/main`,
    loginType: "login",
  });

  const [imgData, setImgData] = useState(null);
  const [popupData, setPopupData] = useState("");
  const [withdrawalData, setWithdrawalData] = useState("");
  const [errPopupData, setErrPopupData] = useState(null);

  const [alarm, setAlarm] = useState(
    mypageData?.data?.use_alarm == 1 ? true : false
  );

  useEffect(() => {
    if (mypageData?.data?.user_provider == "kakao") {
      setImgData("/assets/images/sns/kakao.svg");
    } else if (mypageData?.data?.user_provider == "apple") {
      setImgData("/assets/images/sns/apple.svg");
    } else if (mypageData?.data?.user_provider == "naver") {
      setImgData("/assets/images/sns/naver.svg");
    } else if (mypageData?.data?.user_provider == "google") {
      setImgData("/assets/images/sns/google.svg");
    } else {
      setImgData(null);
    }

    setAlarm(mypageData?.data?.use_alarm == 1 ? true : false);

    data.dataSet && data.dataSet(mypageData);
  }, [mypageData]);

  function marketingPost() {
    let formData = new FormData();

    fatchSet.FatchApi({
      type: "POST",
      url: `/mypage/marketing`,
      loginType: "login",
      formDataItem: formData,
      success: (item) => {},
      err: (data) => {
        if (data.alert) {
          setErrPopupData({
            addClass: null,
            text: data.alert,
            closeType: true,
            closeFunc: (e) => {},
            btnFunc: (e) => {},
          });
        }
      },
    });
  }
  const handleClick = (termsItem) => {
    if (termsItem.id == 3) return;
    if (termsItem.id === "privacy") {
      navigate("/terms/private");
    } else {
      data.rightOpenFunc(termsItem.id);
    }
  };
  return (
    <>
      <div className={`mypageMenu ${data.mType ? "" : "pcItem"}`}>
        <div className="mypageMenu_myInfo">
          <div className="mypageMenu_myInfoBox">
            <h1 className="mypageMenu_myInfo_name">
              <span>{mypageData?.data?.user_name}</span>님{" "}
              {imgData ? <img src={imgData} /> : ""}
            </h1>
            <p className="mypageMenu_myInfo_number">
              {mypageData?.data?.phone
                ? `+82 ${PhoneSetting(mypageData?.data?.phone).substr(1, 13)}`
                : ""}
            </p>
          </div>
          <div className="mypageMenu_myInfo_btnBox">
            <div className="mypageMenu_editLinkBox">
              <button
                type="button"
                onClick={() => {
                  imgData
                    ? navigate("/mypage/edit/phone")
                    : popupData == ""
                    ? setPopupData("회원정보 수정")
                    : setPopupData("");
                }}
                className="mypageMenu_editLink"
              >
                회원정보 수정
              </button>
              <CustomPopup
                topTitle={popupData}
                closeType={true}
                closePopup={() => {
                  setTimeout(() => {
                    setPopupData(null);
                  }, 200);
                }}
                maxSize={"240px"}
                addClass="sizingPopup btnInPopup"
              >
                {popupData ? (
                  <LinkPopupBox>
                    <LinkPopupItem
                      name="연락처 변경"
                      func={() => {
                        navigate("/mypage/edit/phone");
                      }}
                    />
                    <LinkPopupItem
                      name="비밀번호 변경"
                      func={() => {
                        navigate("/mypage/edit/pw");
                      }}
                    />
                  </LinkPopupBox>
                ) : (
                  ""
                )}
              </CustomPopup>
            </div>
          </div>
        </div>
        <div className="mypageMenu_carInfo">
          <div className="mypageMenu_carInfo_titleBox">
            <h1 className="mypageMenu_carInfo_title">내 차량 정보</h1>
            <button
              type="button"
              onClick={() => {
                navigate("/mypage/edit/car");
              }}
              className="mypageMenu_carInfoBtn"
            >
              등록
            </button>
          </div>
          <div className="mypageMenu_carInfoBox">
            {!mypageData?.data?.user_car ? (
              <>
                <h1 className="mypageMenu_carInfo_notTitle">
                  등록된 차량 정보가 없습니다.
                </h1>
                <p className="mypageMenu_carInfo_notText">
                  차량을 등록하고 간편하게 서비스를 이용하세요!
                </p>
              </>
            ) : (
              <>
                <h3 className="mypageMenu_carInfo_carNumber">
                  {mypageData?.data?.user_car?.number}
                </h3>
                <p className="mypageMenu_carInfo_carName">
                  {mypageData?.data?.user_car?.user_car_year}
                  <span className="dot"></span>
                  {mypageData?.data?.user_car?.name}
                </p>
              </>
            )}
          </div>
        </div>
        <MypageMenuItem
          icon="mypage_0.svg"
          name="포인트"
          subText={`${
            mypageData?.data?.user_point
              ? comFormat(mypageData?.data?.user_point)
              : 0
          } P`}
          func={() => {
            navigate("/mypage/point");
          }}
          subTextAddClass="boldText"
        />
        <MypageMenuItem
          icon="mypage_1.svg"
          name="렌터카 예약 내역"
          subText={`${
            mypageData?.data?.user_rents
              ? comFormat(mypageData?.data?.user_rents.length)
              : 0
          } 건`}
          func={() => {
            navigate("/mypage/rent/new");
          }}
        />
        <MypageMenuItem
          icon="mypage_2.svg"
          name="서비스 예약 내역"
          subText={`${
            mypageData?.data?.user_service_count
              ? comFormat(mypageData?.data?.user_service_count)
              : 0
          } 건`}
          func={() => {
            navigate("/mypage/reservation");
          }}
        />
        <MypageMenuItem
          icon="mypage_3.svg"
          name="견적 내역"
          subText={`${
            mypageData?.data?.user_estimate_service_count
              ? comFormat(mypageData?.data?.user_estimate_service_count)
              : 0
          } 건`}
          func={() => {
            navigate("/mypage/order/repair");
          }}
        />
        <MypageMenuItem
          icon="mypage_4.svg"
          name="공지사항"
          func={() => {
            navigate("/cs/list/notice");
          }}
        />
        <MypageMenuItem
          icon="mypage_5.svg"
          name="1:1 문의"
          func={() => {
            navigate("/cs/list/cs");
          }}
        />
        <MypageMenuItem
          icon="mypage_6.svg"
          name="자주 묻는 질문"
          func={() => {
            navigate("/cs/list/faq");
          }}
        />
        <MypageMenuItem
          name="마케팅 정보 알림 동의"
          subIcon="not"
          addClass="notLink"
          toggleBox={true}
          toggleId="alarmToggle"
          checkedType={alarm}
          func={() => {}}
          toggleFunc={(e) => {
            setAlarm(e);
            marketingPost();
          }}
        />
        <MypageMenuItem
          addClass="mItem"
          name="로그아웃"
          subIcon="not"
          func={() => {
            logout();
            data.logOut();
            navigate("/");
          }}
        />
        <div className="mypageMenuTermsBox">
          {chkData?.map((termsItem, key) => (
            <button
              type="button"
              key={key}
              onClick={() => {
                handleClick(termsItem);
              }}
              className="mypageMenuTerms"
            >
              {termsItem.text}
            </button>
          ))}
        </div>
        <div className="withdrawalBtnBox">
          <button
            type="button"
            onClick={() => {
              setWithdrawalData("open");
            }}
            className="btn_withdrawal"
          >
            회원탈퇴
          </button>
        </div>
      </div>
      <RightPopup
        data={null}
        addClass="minVar"
        withdrawal={true}
        topTitle="회원탈퇴"
        text={withdrawalData}
        closePopup={() => {
          setWithdrawalData("");
        }}
        addPopupFunc={(item) => {
          setWithdrawalData("");
          setTimeout(() => {
            logout();
            data.logOut();
            navigate("/");
          }, 2000);

          setErrPopupData({
            addClass: null,
            text: `탈퇴 처리되었습니다.`,
            closeType: false,
            closeFunc: (e) => {},
            btnFunc: (e) => {
              logout();
              data.logOut();
              navigate("/");
            },
          });
        }}
      />
      <ErrPopup data={errPopupData} />
    </>
  );
}

function EditCarInfo(data) {
  return (
    <div className="editCarInfo">
      <p className="editCarInfoName">{data.name}</p>
      <p className="editCarInfoText">{data.val}</p>
    </div>
  );
}

function MypageDetailTitle(data) {
  return (
    <div className="mypageDetailTitleBox">
      <h1 className={`mypageDetailTitle_title ${data.pcType ? "pcItem" : ""}`}>
        {data.title}
      </h1>
      {data.subNumber ? (
        <div className="mypageDetailNumber">
          {data.children}예약번호 <span>{data.subNumber}</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

function MypageDetailOrderLink(data) {
  return (
    <div className="mypageDetailOrderLinkArea">
      <p
        className="mypageDetailOrderLink_text"
        dangerouslySetInnerHTML={{ __html: data.text }}
      ></p>
      {data.link ? (
        <button
          type="button"
          onClick={() => {
            data.func();
          }}
          className="mypageDetailOrderLink"
        >
          견적 확인하기
          <img src="/assets/images/basic/sh.svg" />
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export {
  MypageSection,
  MypageContents,
  MypageMenu,
  EditCarInfo,
  MypageDetailTitle,
  MypageDetailOrderLink,
};
