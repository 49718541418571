import { ErrPopup } from "component/basic/popup";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PublicOnly = () => {
    const navigate = useNavigate();

    const [errPopupData,setErrPopupData] =  useState(null);

    useEffect(() => {
        if(!localStorage.getItem("token")){
            navigate(0);
        }else{
            setErrPopupData({
                addClass:null,
                text:"잘못된 접근입니다.",
                closeType:false,
                closeFunc:(e) => {},
                btnFunc:(e)=>{
                    navigate("/main");
                },
                errBtnText:"확인",
            });
        }
    }, []);

    return (
        <div className="pageSizing">
            <ErrPopup
                data={errPopupData}
            />
        </div>
    );
};

export default PublicOnly;