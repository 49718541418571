import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";

function SelectCarMaker({ onSelect, token }) {
  const [makers, setMakers] = useState([]);
  const [tab, setTab] = useState("DOMESTIC");

  useEffect(() => {
    localStorage.removeItem("view");
    localStorage.removeItem("marker");
  }, []);

  const handleChangeTab = (tab) => () => {
    setTab(tab);
  };

  const handleSelectMake = (maker) => () => {
    localStorage.setItem("marker", JSON.stringify(maker));
    onSelect(maker);
  };

  const getCarData = useCallback(async () => {
    if (!token) return;
    const { data: makerData } = await axios.get(
      `${process.env.REACT_APP_OPEN_API_URL}/runoil-cars/v1/makers`,
      {
        headers: {
          access_token: token,
        },
      }
    );

    setMakers(makerData.data);
  }, [token]);

  useEffect(() => {
    getCarData();
  }, [getCarData]);

  return (
    <SelectCarMakerWrapper>
      <h2 className="carAdd_title">
        <b>차량 모델</b>를 선택해 주세요.
      </h2>

      <div className="car_maker_tab">
        <button
          className={`${tab === "DOMESTIC" ? "active" : ""}`}
          onClick={handleChangeTab("DOMESTIC")}
        >
          국산차
        </button>
        <button
          className={`${tab === "FOREIGN" ? "active" : ""}`}
          onClick={handleChangeTab("FOREIGN")}
        >
          수입차
        </button>
      </div>

      <div className="car_info_list_section">
        {makers
          .filter((maker) => maker.national === tab)
          .map((maker) => (
            <button
              type="button"
              className="btn_selCarSize"
              onClick={handleSelectMake(maker)}
              key={maker.id}
            >
              <img
                src={maker.emblemImage}
                className="car_maker_img"
                alt="car_maker_img"
              />
              <span></span>
              {maker.koreanName}
            </button>
          ))}
      </div>
    </SelectCarMakerWrapper>
  );
}

export const SelectCarMakerWrapper = styled.div`
  background: #fff;
  padding: 0 20px;
  padding-bottom: 1.875rem;

  & .car_maker_tab {
    display: flex;
    gap: 0.714em;
    font-size: 0.875em;
    color: rgba(30, 30, 30, 1);
    margin: 0 0 2.727em;
  }

  & .car_maker_tab > button {
    width: 5em;
    height: 3em;
    border-radius: 6.25em;
    background: rgba(243, 243, 243, 1);
    color: rgba(126, 126, 126, 1);

    &:hover {
      opacity: 0.7;
    }
    &:active {
      opacity: 1;
    }
  }

  & .car_maker_tab > button.active {
    border: 1px solid rgba(30, 30, 30, 1);
    color: rgba(30, 30, 30, 1);
    background: #fff;
  }

  & .car_info_list_section {
    height: 21.6875em;
    overflow-y: scroll;
    margin-bottom: 60px;
  }

  & .car_makers_wrapper {
    height: 100%;
    overflow-y: scroll;
  }

  & .car_maker_img {
    width: 3.125em;
  }

  @media (max-width: 721px) {
    max-height: inherit;

    display: flex;
    flex-direction: column;

    & .car_maker_tab {
      margin: 0 0 2em;
    }
    & .car_info_list_section {
      height: 100%;
      overflow-y: scroll;
    }
  }
`;

export default SelectCarMaker;
