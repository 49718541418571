import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useGet from "api/useGet";
import { BtnBox } from "component/basic/btns";
import { InfoText, ServiceInfoSubItem, ServiceInfoSubItemBox, SevicePageTitle } from "component/app/subItems";
import { ChkBox, InputItemBox } from "component/basic/formItems";
import { PhoneSetting, setDateTime } from "js/function";
import { comFormat } from "js/function";
import * as fatchSet from "../../../api/api";
import { Header } from "component/elements/header";
import { ErrPopup, LoadingBox, RightPopup} from "component/basic/popup";
import { OrderCarData, OrderComInfoText, OrderNotPrice, OrderOptionArea, OrderPayItem, OrderPriceItem, OrderPriceSubItem, OrderSeciton, OrderTimeArea } from "component/app/order";
import { MypageTable, MypageTableState, MypageTd, MypageTdBox, MypageTh, MypageThBox } from "component/mypage/mypageTable";
import { NotItems } from "component/basic/notItems";

const MyOrderDetail = (props) => {
    const navigate = useNavigate();
    const param = useParams();
    const pageId = param.id;
    const type = param.type;

    const formItem = useRef(null);
    const [loading,setLoading] = useState(false);

    const termsList = useGet({
        url:`/term?type=pay`
    });

    const pointData = useGet({
        url:`/mypage/point?page=1&type=1`,
        loginType:"login"
    });

    const detailApi = useGet({
        url:`/service/estimate/detail?service_id=${pageId}&category=${type}`,
        loginType:"login"
    });
    const detailData = detailApi?.data?.user_service;

    const [pointPrice,setPointPrice] = useState(0);
    const [payType,setPayType] = useState("card");

    const [termsChk,setTermsChk] = useState(false);

    const [errPopupData,setErrPopupData] = useState(null);

    const [rightPopupText,setRightPopupText] =  useState("");

    const [btnChk,setBtnChk] = useState(true);

    const [totalPrice,setTotalPrice] = useState(detailData?.price ? detailData?.price : 0);
    const [salePrice,setSalePrice] = useState(detailData?.discount_price ? detailData?.discount_price : 0);

    const [orderChk,setOrderChk] =  useState(true);

    function orderStart(){
        if(orderChk){
            formItem.current.innerHTML = "";

            setLoading(true);
            setOrderChk(false);

            if(type == "manage_service"){
                formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="access_token" value="${localStorage.getItem("token")}"/>`);
                formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="service_id" value="${pageId}"/>`);
                formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="payment_method" value="${payType}"/>`);
                formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="use_point" value="${pointPrice}"/>`);
                formItem.current.insertAdjacentHTML("beforeend",`<input type="hidden" name="add_point" value="${Math.floor(totalPrice * 0.03)}"/>`);

                setTimeout(() => {
                    setOrderChk(true);
                    setLoading(false);
                    formItem.current.submit();
                }, 500);
            }else{
                let formData = new FormData();
                formData.append("service_id", pageId);

                fatchSet.FatchApi({
                    type:"POST",
                    url: `/service/repair/status/modify`,
                    loginType:"login",
                    formDataItem: formData,
                    success: (item) => {
                        setOrderChk(true);
                        setLoading(false);

                        navigate(`/order/success/${item.data.order_id}/repair`);
                    },
                    err: (item) => {
                        setOrderChk(true);
                        setLoading(false);
                        if(item.alert){
                            setErrPopupData({
                                addClass:null,
                                text:item.alert,
                                closeType:true,
                                closeFunc:(e) => {},
                                btnFunc:(e)=>{}
                            });
                        }
                    }
                })
            }
        }
    }

    useEffect(() => {
        if(termsChk && payType){
            setBtnChk(false)
        }else{
            setBtnChk(true)
        }
    }, [termsChk,payType]);

    useEffect(() => {
        if(detailData?.price){
            setTotalPrice(detailData?.price)
        }
        if(detailData?.discount_price){
            setSalePrice(detailData?.discount_price)
        }
    }, [detailData]);

    useEffect(() => {
        sessionStorage.setItem("orderId",param.id)
        sessionStorage.setItem("orderType",param.type)
    }, []);

    return (
        <div className="orderPage headerInPage pageSizing mBtnPage">
            <form action={`${process.env.REACT_APP_API_URL}/service/estimate/payment/kicc`} ref={formItem} method="GET"></form>
            <Header
                pageBack={true}
                headTitle={type == "manage_service" ? "관리 서비스 예약" : "외관 수리 예약"}
                func={() => navigate(-1)}
                homeBtn={true}
            />
            <SevicePageTitle
                title={type == "manage_service" ? "관리 서비스 예약" : "외관 수리 예약"}
            />
            <div className="orderPage_contents">
                <OrderSeciton addClass="notBorder" title="이용 안내">
                    <OrderComInfoText text={type == "manage_service" ? "서비스 예약을 확정하지 않을 경우 견적이 자동 취소됩니다. 기간 만료 후 같은 견적으로 서비스를 이용하려면 다시 견적 예약을 신청해 주세요." : "서비스 일시 1일 전까지 서비스를 신청 해야 합니다.<span class='bColor'>이후 견적서 만료</span>"}/>
                    {type == "repair_service" ? 
                    <>
                        <InfoText listType="-" text="서비스 일시 이후 견적서 자동 소멸, 서비스 이용 시 재견적 신청해야 함"/>
                        <InfoText listType="-" text="공휴일 및 주말은 견적 불가"/>
                    </>
                    :""}
                </OrderSeciton>
                <OrderSeciton addClass="notBorder" title={`신청 내용 확인 <p class="mypageState ${detailData?.status == 1 ? "wbColor" : detailData?.status == 2 ? "" : detailData?.status == 20 ? "gColor" : detailData?.status == 21 || detailData?.status == 11 ? "rColor" : ""}">${detailData?.status == 3 ? "완료" : detailData?.status == 1 ? "예약 완료" : detailData?.status == 11 ? "예약 취소" : detailData?.status == 2 ? "견적 접수" : detailData?.status == 20 ? "견적 도착" : detailData?.status == 21 ? "견적 취소" : ""}</p>`}>
                    <div className="orderDateArea">
                        <OrderTimeArea
                            title="입고일시"
                            date={detailData?.start_date ? setDateTime(detailData?.start_date,"yymmdd") : "-"}
                            time={detailData?.start_date ? setDateTime(detailData?.start_date,"hhtt") : "-"}
                        />
                        {detailData?.end_date ? 
                        <OrderTimeArea
                            title="출고일시"
                            date={setDateTime(detailData?.end_date,"yymmdd")}
                            time={setDateTime(detailData?.end_date,"hhtt")}
                        />
                        :""}
                    </div>
                    <div className="orderCarData">
                        <OrderCarData
                            imgKey="car"
                            title="차량 정보"
                            text={`${detailData?.name ? detailData?.name : "-"} ${detailData?.number ? detailData?.number : "-"}`}
                        />
                        <OrderCarData
                            imgKey="phone"
                            title="연락처"
                            text={detailData?.phone ? PhoneSetting(detailData?.phone) : "-"}
                        />
                        <OrderCarData
                            imgKey="memo"
                            title="특이사항"
                            text={detailData?.contents ? detailData?.contents : "-"}
                        />
                    </div>
                </OrderSeciton>

                <OrderSeciton addClass="notBorder" title="예상 견적">
                    <MypageTable>
                        <MypageThBox>
                            <MypageTh contents="서비스 범위" width="30%"/>
                            {type !== "manage_service" ? <MypageTh contents="서비스 방법" width="30%"/> : ""}
                            <MypageTh contents="금액" width={type !== "manage_service" ? "40%" : "70%"}/>
                        </MypageThBox>
                        {detailData && detailData?.[type == "manage_service" ? "manages" : "repair"]?.length > 0 ? 
                        <>
                        {detailData?.[type == "manage_service" ? "manages" : "repair"]?.map((item,i)=>(
                            <MypageTdBox key={i} func={()=>{}}>
                                <MypageTd width="30%" addClass="notLink">
                                    <b>{type == "manage_service" && item.manage_name ? item.manage_name : item.repair_name ? item.repair_name : "-"}</b>
                                </MypageTd>
                                {type !== "manage_service" ?
                                <MypageTd width="30%" addClass="notLink">
                                    {item.etc ? item.etc : "-"}
                                </MypageTd>:""}
                                <MypageTd width={type !== "manage_service" ? "40%" : "70%"} addClass="notLink">
                                    {type == "manage_service" && item.manage_price ? `${comFormat(item.manage_price)}원` : item.repair_price ? `${comFormat(item.repair_price)}원` : "0원"}
                                </MypageTd>
                            </MypageTdBox>
                        ))} 
                        </>
                        :
                        <NotItems img="/assets/images/icon/not_list.svg" text="예상 견적 내역이 없습니다."/>
                        }
                    </MypageTable>
                </OrderSeciton>
                {type == "manage_service" && detailData?.status == 20 ?
                <>
                <OrderSeciton addClass="notBorder" title="포인트">
                    <div className="service_minInputSection">
                        <InputItemBox
                            type="number"
                            inputName="사용 포인트"
                            placeholder="사용 포인트"
                            value={pointPrice}
                            numberMax={pointData?.data?.point > totalPrice - salePrice ? totalPrice - salePrice : pointData?.data?.point}
                            disabled={pointData?.data?.point == 0 ? true : false}
                            func={(e) => {setPointPrice(Number(e))}}
                            caption={`사용 가능 포인트 <span class="bColor">${comFormat(pointData?.data?.point)} P</span>`}
                        />
                    </div>
                </OrderSeciton>
                <OrderSeciton addClass="notBorder" title="결제 금액">
                <div className="orderPriceArea">
                        <OrderPriceItem
                            type=""
                            text={`${comFormat(totalPrice)}원`}
                            name="총 서비스 요금"
                        />
                        <OrderPriceItem
                            type="upText"
                            text={`-${comFormat(salePrice + pointPrice)}원`}
                            name="총 할인가 "
                        />
                        {pointPrice !== 0 ? 
                        <OrderPriceSubItem>
                            <ServiceInfoSubItemBox>
                                <ServiceInfoSubItem
                                    name="포인트 사용"
                                    item={`-${comFormat(pointPrice)}원`}
                                />
                            </ServiceInfoSubItemBox>
                        </OrderPriceSubItem>
                         : ""}
                    </div>
                    <div className="orderTotalPriceArea">
                        <h1 className="orderTotalPrice_name">총 결제 금액</h1>
                        <div className="orderTotalPriceBox">
                            <h2 className="orderTotalPrice">{comFormat(totalPrice - salePrice - pointPrice)}원</h2>
                            <p className="orderTotalPrice_point">{comFormat(Math.floor((totalPrice - salePrice - pointPrice) * 0.03))} P 적립 예정</p>
                        </div>
                    </div>
                </OrderSeciton>
                <OrderSeciton addClass="notBorder" title="결제 수단/주문 동의">
                    <div className="orderPayArea">
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("card")}}
                            val="card"
                            type=""
                            text="신용카드"
                        />
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("bank")}}
                            val="bank"
                            type=""
                            text="계좌이체"
                        />
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("phone")}}
                            val="phone"
                            type=""
                            text="휴대폰 결제"
                        />
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("npay")}}
                            val="npay"
                            type="naver"
                            text=""
                        />
                        <OrderPayItem
                            setVal={payType}
                            func={()=>{setPayType("kakao")}}
                            val="kakao"
                            type="kakao"
                            text=""
                        />
                    </div>
                    <div className="orderChkArea">
                        <ChkBox
                            addClass="pageIn"
                            func={(e) => {setTermsChk(e.target.checked)}}
                            checkedType={termsChk}
                            chkSubType={"필수"}
                            label="구매조건 확인 및 결제 대행 서비스 약관 동의"
                            chkFunc={(e) => {setRightPopupText(termsList?.data?.term?.value)}}
                        />
                    </div>
                    <div className="payInfoTextBox">
                        <InfoText listType="-" text="예약일 변경 또는 취소 요청의 경우 입고일 기준 3일 전 신청 건만 가능합니다. "/>
                        <InfoText listType="-" text="입고일 기준 2일~당일 요청 건은 변경 또는 환불이 불가능 하오니 유의해 주시기 바랍니다."/>
                    </div>
                </OrderSeciton>
                <BtnBox
                    addClass="pageInType mChType pcMinBtn"
                    text={"결제하기"}
                    disabled={btnChk}
                    func={()=>{orderStart()}}
                />
                </>
                :""}
                {type == "repair_service" && (detailData?.status == 2 || detailData?.status == 20) ?
                <>
                <OrderSeciton addClass="notBorder" title="결제 금액">
                <div className="orderPriceArea">
                        <OrderPriceItem
                            type=""
                            text={`${comFormat(Number(totalPrice) + Number(salePrice))}원`}
                            name="결제 예상 금액"
                        />
                    </div>
                    <div className="orderTotalPriceArea">
                        <h1 className="orderTotalPrice_name">총 결제 예상 금액</h1>
                        <div className="orderTotalPriceBox">
                            <h2 className="orderTotalPrice">{comFormat(totalPrice)}원</h2>
                        </div>
                    </div>
                    <div className="payInfoTextBox">
                        <InfoText listType="-" text="외관 수리는 차량의 상태에 따라 가격이 상이합니다. "/>
                        <InfoText listType="-" text="엔지니어가 직접 차량을 확인 후 정확한 금액 책정을 할 수 있으므로 견적서 가격과 실제 결제 금액은 차이가 있을 수 있습니다."/>
                        <InfoText listType="-" text="외관 수리는 공업사에서 책정된 최종 금액으로 현장 결제만 가능합니다."/>
                    </div>
                </OrderSeciton>
                <BtnBox
                    addClass="pageInType mChType pcMinBtn"
                    text={"예약하기"}
                    func={()=>{orderStart()}}
                />
                </>:""}
            </div>
            <ErrPopup
                data={errPopupData}
            />
            <RightPopup
                topTitle="구매조건 확인 및 결제 대행 서비스 약관 동의"
                text={rightPopupText}
                btnType="only"
                popupBtnFunc={()=>{setTermsChk(true)}}
                closePopup={() => {setRightPopupText("")}}
            />
            <LoadingBox
                addClass={loading ? "active" : ""}
                loadingItem="<img src='/assets/images/icon/loading.svg' class='spin'/>"
            />
        </div>
    );
};

export default MyOrderDetail;