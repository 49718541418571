//목록 없을시
function NotItems(data){
    return (
        <div className={`notItemArea ${data.addClass ? data.addClass : ""}`}>
            <img src={data.img ? data.img : "/assets/images/icon/not_car.svg"}/>
            <p className="notItemText" dangerouslySetInnerHTML={{__html:`${data.text ? data.text : "목록이 없습니다."}`}}></p>
        </div>
    );
}

export {NotItems};