import useGet from "api/useGet";
import { RightPopup } from "component/basic/popup";
import React from "react";
import { useNavigate } from "react-router-dom";

function TermsPrivate() {
  const navigate = useNavigate();
  const termsList = useGet({
    url: `/term?type=privacy`,
  });

  return (
    <div className="mainPage headerInPage pageSizing footerMenuInPage">
      <RightPopup
        topTitle="개인정보처리방침"
        text={termsList?.data?.term?.value}
        closePopup={() => {
          navigate(-1);
        }}
      />
    </div>
  );
}

export default TermsPrivate;
